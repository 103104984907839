// import md5 from 'blueimp-md5';
import Immutable from 'immutable';
import { memoize } from 'utils/memoize';
import idGenerator from 'utils/id-generator';
import createImmutableSelector from 'utils/immutable-selector';

// import { createSelector } from 'reselect';
import { featureCollection, point, lineString } from '@turf/helpers';
import {
  getBookingType,
  getWalkingRouteType,
  getRouteCoordinates,
} from 'utils/CommuteOffer';
import walkingRouting from 'config/walkingRouting';
import { fetchJSON } from 'api/net';

import debug from 'utils/debug';
import {
  activeBookingIdSelector,
  activeRouteStopUidSelector,
  activeVehicleIdsSelector,
  editableBookingIdSelector,
  routingEngineSelector,
} from 'modules/ui/selectors';

import {
  filteredBookingsSelector,
  commuteOfferCurrentDataSelector,
  editableBookingsSelector,
  nodesSelector,
  availableVehiclesSelector,
  filteredVehiclesSelector,
  commuteOfferRoutesSelector,
  serialNumberSelector,
  validOrdersToDisplay,
} from 'modules/commuteOffer/selectors';

const D2 = debug('m:maps:selectors');

global.WALKING_ROUTES_SN = 1;
global.WALKING_ROUTES = {};
global.WALKING_ROUTES_BY_IDS = {};

const incSerialNumber = () => {
  global.WALKING_ROUTES_SN += 1;
  D2.S.INFO('incSerialNumber', { serialNumber: global.WALKING_ROUTES_SN });
};

export const mapsSelector = state =>
  D2.S.FUNCTION(
    'mapsSelector',
    { state, $fn: { state: () => state.toJS() } },
    () => state.get('maps')
  );

export const datasetViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    D2.S.FUNCTION('datasetViewportSelector', { maps }, () =>
      maps.getIn(['dataset', 'viewport'])
    )
);

export const commuteOfferViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    D2.S.FUNCTION('commuteOfferViewportSelector', { maps }, () =>
      maps.getIn(['commuteOffer', 'viewport'])
    )
);

export const simulationViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    D2.S.FUNCTION('simulationViewportSelector', { maps }, () =>
      maps.getIn(['simulation', 'viewport'])
    )
);

export const geofencesViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    D2.S.FUNCTION('geofencesViewportSelector', { maps }, () =>
      maps.getIn(['geofences', 'viewport'])
    )
);

export const newGeofenceViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    D2.S.FUNCTION('newGeofenceViewportSelector', { maps }, () =>
      maps.getIn(['newGeofence', 'viewport'])
    )
);

// commuteOffer sources
export const bookingsSourceSelector = createImmutableSelector(
  // filteredBookingsSelector,
  validOrdersToDisplay,
  activeBookingIdSelector,
  activeVehicleIdsSelector,
  commuteOfferCurrentDataSelector,
  filteredVehiclesSelector,
  (bookings, activeBookingId, activeVehicleIds, data) =>
    D2.S.FUNCTION(
      'bookingsSourceSelector',
      { bookings, activeBookingId, activeVehicleIds, data },
      ({ $D2 }) => {
        if (!data) {
          return featureCollection([]);
        }

        const bookingCoordinates = data.stateless_api_request_data.bookings;

        const showOnlyUnassigned = activeVehicleIds.size === 0;
        $D2.S.INFO('showOnlyUnassigned', {
          activeVehicleIds,
          showOnlyUnassigned,
        });

        const filterBookings = bookingsToFilter =>
          $D2.S.FUNCTION(
            'filterBookings',
            {
              bookingsToFilter,
              bookings,
              activeVehicleIds,
              showOnlyUnassigned,
            },
            () =>
              showOnlyUnassigned
                ? bookingsToFilter.filter(
                    x =>
                      x.properties.status === 'unassigned' ||
                      x.properties.status === 'unassignedActive'
                  )
                : bookingsToFilter
          );

        return featureCollection(
          bookings
            ? filterBookings(
                bookings.reduce((acc, booking) => {
                  const requestBooking = bookingCoordinates[booking.uid];
                  if (!requestBooking) {
                    $D2.S.INFO('bookings.reduce:saBooking:Missing', {
                      booking,
                      error: 'Booking not found in stateless_api_request_data',
                    });
                    return acc;
                  }
                  $D2.S.INFO('bookings.reduce:saBooking:Found', {
                    requestBooking,
                    booking,
                  });

                  if (
                    !booking.assigned_vehicle_id ||
                    activeVehicleIds.includes(booking.assigned_vehicle_id) ||
                    !activeVehicleIds.size
                  ) {
                    const {
                      uid,
                      assigned_vehicle_id,
                      pickup_location_name,
                      dropoff_location_name,
                      scheduled_pickup_stop_id,
                      scheduled_dropoff_stop_id,
                      scheduled_pickup_time,
                      scheduled_dropoff_time,
                    } = booking;
                    const publicBooking = {
                      uid,
                      assigned_vehicle_id,
                      pickup_location_name,
                      dropoff_location_name,
                      scheduled_pickup_stop_id,
                      scheduled_dropoff_stop_id,
                      scheduled_pickup_time,
                      scheduled_dropoff_time,
                    };

                    const calculatedBookingProperties = {
                      noActive: !activeVehicleIds.size,
                      isActive:
                        activeVehicleIds.includes(
                          booking.assigned_vehicle_id
                        ) || !booking.assigned_vehicle_id,
                      status: getBookingType(
                        activeVehicleIds.includes(booking.assigned_vehicle_id),
                        activeBookingId === booking.uid,
                        booking.assigned_vehicle_id
                      ),
                    };

                    const pickupCoordinates = (() => {
                      const { pickup_location_lon, pickup_location_lat } =
                        requestBooking;

                      if (pickup_location_lon && pickup_location_lat) {
                        return [pickup_location_lon, pickup_location_lat];
                      }

                      const bookingNode =
                        data.stateless_api_request_data.nodes.find(
                          node =>
                            node.node_type === 'pickup' &&
                            node.booking_uid === booking.uid
                        );

                      if (bookingNode) {
                        return [bookingNode.lon, bookingNode.lat];
                      }

                      return null;
                    })();

                    if (pickupCoordinates) {
                      acc.push(
                        $D2.S.V(
                          'point',
                          { publicBooking, booking },
                          point(pickupCoordinates, {
                            ...publicBooking,
                            ...calculatedBookingProperties,
                            type: 'pickup',
                          })
                        )
                      );
                    }

                    const dropoffCoordinates = (() => {
                      const { dropoff_location_lon, dropoff_location_lat } =
                        requestBooking;

                      if (dropoff_location_lon && dropoff_location_lat) {
                        return [dropoff_location_lon, dropoff_location_lat];
                      }

                      const bookingNode =
                        data.stateless_api_request_data.nodes.find(
                          node =>
                            node.node_type === 'dropoff' &&
                            node.booking_uid === booking.uid
                        );

                      if (bookingNode) {
                        return [bookingNode.lon, bookingNode.lat];
                      }

                      return null;
                    })();

                    if (dropoffCoordinates) {
                      acc.push(
                        $D2.S.V(
                          'point',
                          { publicBooking, booking },
                          point(dropoffCoordinates, {
                            ...publicBooking,
                            ...calculatedBookingProperties,
                            type: 'dropoff',
                          })
                        )
                      );
                    }
                  }

                  return acc;
                }, [])
              )
            : []
        );
      }
    )
);

export const vehiclesStopsSelector = createImmutableSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  activeRouteStopUidSelector,
  (vehicles, activeVehicleIds, activeRouteStopUid) =>
    D2.S.FUNCTION(
      'vehiclesStopsSelector',
      { vehicles, activeVehicleIds, activeRouteStopUid },
      () => {
        const idgen = idGenerator();
        return vehicles
          ? vehicles.reduce((vehiclesMemo, vehicle) => {
              const {
                agent_id,
                route,
                $activeColor,
                $draggableColor,
                $hasResult,
              } = vehicle;
              return vehiclesMemo.concat(
                route
                  .sort(
                    (a, b) =>
                      Number(b.partial_route_index) -
                      Number(a.partial_route_index)
                  )
                  .map((item, $waypoint_index) => {
                    const id = idgen.next().value;
                    return {
                      ...item,
                      agent_id,
                      // color,
                      $activeColor,
                      $hasResult,
                      $draggableColor,
                      noActive: !activeVehicleIds.size,
                      isActive: activeVehicleIds.includes(agent_id),
                      isActiveStop:
                        activeRouteStopUid === item.uid &&
                        activeVehicleIds.includes(agent_id),
                      id,
                      $waypoint_index,
                    };
                  })
              );
            }, Immutable.List())
          : Immutable.List();
      }
    )
);

export const vehiclesStopSourceSelector = createImmutableSelector(
  vehiclesStopsSelector,
  vehicleStops =>
    D2.S.FUNCTION('vehiclesStopSourceSelector', { vehicleStops }, () => {
      return vehicleStops
        ? featureCollection(
            vehicleStops
              .filter(stop => stop.$hasResult)
              .reduce((stopsMemo, item) => {
                const { id, agent_id, location_name, stop_id } = item;
                return [
                  ...stopsMemo,
                  {
                    ...point([item.lon, item.lat], {
                      ...item,
                      id,
                      agent_id,
                      location_name,
                      stop_id,
                      bookings: undefined,
                      $bookingsById: undefined,
                    }),
                  },
                ];
              }, [])
          )
        : featureCollection([]);
    })
);

// const getVehiclePathId = (vehicle, coordinates) =>
//   md5(JSON.stringify([vehicle.agent_id, coordinates]));

export const routeSourceSelector = createImmutableSelector(
  filteredVehiclesSelector,
  commuteOfferRoutesSelector,
  routingEngineSelector,
  serialNumberSelector,
  (vehicles, routes, routingEngine) => {
    return D2.S.FUNCTION(
      'routeSourceSelector',
      { vehicles, routes, routingEngine },
      ({ $D2 }) => {
        if (!vehicles) {
          return featureCollection([]);
        }

        const vehiclesWithValidRoutes = vehicles.filter(
          vehicle => vehicle.route.length >= 2 && vehicle.$hasResult
        );
        $D2.S.INFO('vehiclesWithValidRoutes', { vehiclesWithValidRoutes });

        const vehiclesWithPath = vehiclesWithValidRoutes.map(vehicle => ({
          ...vehicle,
          $path: routes.get(vehicle.agent_id),
        }));
        global.GEODISC_CO_VEHICLES_WITH_PATH = vehiclesWithPath;

        const shortVehicles = vehiclesWithPath.map(vehicle => ({
          name: 'routeSourceSelector',
          agent_id: vehicle.agent_id,
          route: vehicle.route.map(x => [x.lon, x.lat, x.scheduled_ts]),
          path_length:
            vehicle.$path?.routes?.[0]?.geometry?.coordinates?.length,
        }));

        return memoize(() =>
          featureCollection(
            vehiclesWithPath.map((vehicle) => {
              const id = vehicle.agent_id;

              const route = routes.get(id);

              if (
                routingEngine !== 'euclidean' &&
                routingEngine !== 'spheroid' &&
                route?.routes?.length
              ) {
                const routeCoordinates = route.routes[0].geometry.coordinates;

                const routeLine = memoize(() =>
                  lineString(routeCoordinates, {
                    agent_id: vehicle.agent_id,
                  })
                )({
                  name: 'routeSourceSelector:route',
                  agent_id: vehicle.agent_id,
                  routingEngine,
                  route: vehicle.route.map(x => [
                    x.lon,
                    x.lat,
                    x.scheduled_ts,
                  ]),
                });

                return routeLine;
              }

              return lineString(
                vehicle.route.map(item => [item.lon, item.lat]),
                {
                  agent_id: vehicle.agent_id,
                }
              );
            })
          )
        )(shortVehicles);
      }
    );
  }
);

export const detailedRouteSourceSelector = createImmutableSelector(
  filteredVehiclesSelector,
  commuteOfferRoutesSelector,
  routingEngineSelector,
  (vehicles, routes, routingEngine) =>
    D2.S.FUNCTION(
      'detailedRouteSourceSelector',
      { vehicles, routes, routingEngine },
      ({ $D2 }) => {
        if (!vehicles) {
          return featureCollection([]);
        }

        return featureCollection(
          vehicles
            .reduce((acc, vehicle) => {
              const { agent_id } = vehicle;

              const route = routes.get(agent_id);
              $D2.S.INFO('route', { route, agent_id, vehicle });

              if (!route || !route.routes) {
                return acc;
              }

              if (vehicle.route.length === 0) {
                return acc;
              }

              if (vehicle.route.length === 1) {
                const stop = vehicle.route[0];
                const segment = lineString(
                  [
                    [stop.lon, stop.lat],
                    [stop.lon, stop.lat],
                  ],
                  {
                    agent_id,
                    position: 0,
                    nodesCount: 0,
                  }
                );
                return [...acc, segment];
              }

              const legs = route.routes?.[0]?.legs;

              const segments = vehicle.route.reduce(
                (segmentsMemo, stop, position) => {
                  const leg = legs?.[position];
                  const nextStop = vehicle.route[position + 1];
                  const nodesCount = vehicle.route[position].bookings.length;
                  const status = vehicle.route[position].status;
                  const partialRouteIndex =
                    vehicle.route[position].partial_route_index;
                  const nodeType = vehicle.route[position].node_type;

                  if (nextStop) {
                    if (
                      leg &&
                      routingEngine !== 'euclidean' &&
                      routingEngine !== 'spheroid'
                    ) {
                      const steps = leg.steps || [];

                      const coordinates = steps
                        .filter(step => step.geometry.type === 'LineString')
                        .reduce(
                          (memo, step) => [
                            ...memo,
                            ...step.geometry.coordinates,
                          ],
                          []
                        );
                      $D2.S.INFO('coordinates', {
                        coordinates,
                        steps,
                        leg,
                        route,
                        agent_id,
                        vehicle,
                      });

                      const segment = lineString(coordinates, {
                        position,
                        agent_id,
                        nodesCount,
                        status,
                        partialRouteIndex,
                        nodeType,
                      });

                      return [...segmentsMemo, segment];
                    }
                    const segment = lineString(
                      [
                        [stop.lon, stop.lat],
                        [nextStop.lon, nextStop.lat],
                      ],
                      {
                        position,
                        agent_id,
                        nodesCount,
                        status,
                        partialRouteIndex,
                        nodeType,
                      }
                    );

                    return [...segmentsMemo, segment];
                  }
                  return segmentsMemo;
                },
                []
              );

              return [...acc, ...segments];
            }, [])
            .filter(feature => !!feature)
        );
      }
    )
);

export const routeLayerSelector = createImmutableSelector(
  availableVehiclesSelector,
  activeVehicleIdsSelector,
  serialNumberSelector,
  (vehicles, activeVehicleIds) => {
    return D2.S.FUNCTION(
      'routeLayerSelector',
      { vehicles, activeVehicleIds },
      () => {
        const colorValues = vehicles.reduce(
          (acc, vehicle) => [...acc, vehicle.agent_id, vehicle.$activeColor],
          []
        );
        const layer = (inactiveOpacity, activeOpacity) => {
          const opacityValues = vehicles.reduce(
            (acc, vehicle) => [
              ...acc,
              vehicle.agent_id,
              activeVehicleIds.size === 0 ||
              activeVehicleIds.includes(vehicle.agent_id)
                ? activeOpacity
                : inactiveOpacity,
            ],
            []
          );
          return {
            type: 'line',
            before: 'vehicles',
            paint: {
              'line-color': colorValues.length
                ? [
                    ...['match', ['to-string', ['get', 'agent_id']]],
                    ...colorValues,
                    ...['#000000'],
                  ]
                : '#000000',
              'line-opacity': opacityValues.length
                ? [
                    ...['match', ['to-string', ['get', 'agent_id']]],
                    ...opacityValues,
                    ...[0],
                  ]
                : 0,
              'line-width': [
                'interpolate',
                ['exponential', 1.5],
                ['zoom'],
                11,
                2,
                22,
                11,
              ],
            },
          };
        };

        const arrowsLayer = (
          inactiveVisibility = 0,
          activeVisibility = 0.045
        ) => {
          const visibilityValues = vehicles.reduce(
            (acc, vehicle) => [
              ...acc,
              vehicle.agent_id,
              activeVehicleIds.includes(vehicle.agent_id)
                ? activeVisibility
                : inactiveVisibility,
            ],
            []
          );
          return {
            type: 'symbol',
            before: 'vehicles',
            layout: {
              'symbol-placement': 'line',
              'symbol-spacing': 1,
              'icon-allow-overlap': true,
              'icon-image': 'arrow',
              'icon-size': visibilityValues.length
                ? [
                    ...['match', ['to-string', ['get', 'agent_id']]],
                    ...visibilityValues,
                    ...[inactiveVisibility],
                  ]
                : inactiveVisibility,
              visibility: 'visible',
            },
          };
        };

        return {
          arrows: arrowsLayer(0, 0.045),
          active: layer(0, 1),
        };
      }
    );
  }
);

const routingEngineDrivers = {
  here: {
    fetch: async (route, opts) => {
      D2.S.INFO('routing:here:fetch', { route, opts });

      const hereUrl = window.GEODISC_WALKING_HERE_ROUTE_URL;
      const hereAppId = window.GEODISC_WALKING_HERE_APP_ID;
      const hereAppCode = window.GEODISC_WALKING_HERE_APP_CODE;
      const hereMode = opts.mode || window.GEODISC_WALKING_HERE_MODE;

      const coordinates = route
        .map((herePoint, index) => {
          return `waypoint${index}=geo!${herePoint.lat},${herePoint.lon}`;
        }, [])
        .join('&');

      // eslint-disable-next-line
      const url = `${hereUrl}/calculateroute.json?app_id=${hereAppId}&app_code=${hereAppCode}&${coordinates}&mode=${hereMode}`;

      const responseJSON = await fetchJSON(url);

      D2.S.DEBUG('Response:', responseJSON);

      const responseRoute =
        responseJSON.response &&
        responseJSON.response.route &&
        responseJSON.response.route[0];

      const res =
        responseRoute && responseRoute.waypoint
          ? {
              routes: [
                {
                  duration: responseRoute.leg.reduce(
                    (acc, item) => acc + item.travelTime,
                    0
                  ),
                  distance: responseRoute.leg.reduce(
                    (acc, item) => acc + item.length,
                    0
                  ),
                  geometry: {
                    coordinates:
                      global.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISPLAY_EUCLIDEAN
                        ? route.map(item => [item.lon, item.lat])
                        : responseRoute.leg.reduce((legAcc, leg) => {
                            const maneuver = leg.maneuver.reduce(
                              (maneuverAcc, maneuverPoint) => {
                                maneuverAcc.push([
                                  maneuverPoint.position.longitude,
                                  maneuverPoint.position.latitude,
                                ]);
                                return maneuverAcc;
                              },
                              []
                            );
                            return [...legAcc, ...maneuver];
                          }, []),
                    type: 'LineString',
                  },
                  legs: responseRoute.leg.map(leg => ({
                    duration: leg.travelTime,
                    distance: leg.length,
                  })),
                },
              ],
            }
          : {
              routes: [
                {
                  duration: 0,
                  distance: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString',
                  },
                  legs: route.map(() => ({ duration: 0 })),
                },
              ],
            };

      D2.S.INFO('routing:here:fetch:result', {
        route,
        opts,
        responseJSON,
        data: res,
      });
      return res;
    },
  },
};

export const walkingRoutesSourceSelector = createImmutableSelector(
  vehiclesStopsSelector,
  bookingsSourceSelector,
  activeBookingIdSelector,
  activeVehicleIdsSelector,
  (vehicleStops, bookings, activeBookingId, activeVehicleIds) =>
    D2.S.FUNCTION(
      'walkingRoutesSourceSelector',
      { vehicleStops, bookings, activeBookingId, activeVehicleIds },
      ({ $D2 }) => {
        const allLines = vehicleStops
          .filter(vehicleStop => vehicleStop.lon && vehicleStop.lat)
          .reduce((acc, vehicleStop) => {
            // D2.S.INFO('walkingRoutesSourceSelector', {
            //   a: 'vehicles.features.reduce',
            //   vehicle
            // });
            const { node_type } = vehicleStop;

            if (node_type === 'point') {
              return acc;
            }

            const bookingsIds = vehicleStop.bookings
              .filter(booking => !booking.$isPhantom)
              .map(item => item.id);

            const filteredBookings = bookings.features.filter(
              item =>
                !item.$isPhantom &&
                bookingsIds.includes(item.properties.uid) &&
                node_type === item.properties.type
            );

            const lines = filteredBookings
              .filter(
                booking =>
                  booking.geometry.coordinates[0] &&
                  booking.geometry.coordinates[1]
              )
              .map((booking) => {
                // D2.S.INFO('walkingRoutesSourceSelector', {
                //   a: 'filteredBookings.map',
                //   booking
                // });

                const properties = {
                  noActive: !activeVehicleIds.length,
                  status: getWalkingRouteType(
                    vehicleStop.isActive,
                    vehicleStop.isActiveStop,
                    activeBookingId === booking.properties.uid
                  ),
                };

                const bookingId = booking.properties.uid;
                const vehicleId = vehicleStop.uid;
                const routeId = `${bookingId}:${vehicleId}`;

                const coordinates = [
                  {
                    lon: booking.geometry.coordinates[0],
                    lat: booking.geometry.coordinates[1],
                  },
                  {
                    lon: vehicleStop.lon,
                    lat: vehicleStop.lat,
                  },
                ];

                const routeCoordinates = getRouteCoordinates(
                  coordinates,
                  true,
                  false
                );

                global.WALKING_ROUTES = global.WALKING_ROUTES || {};
                global.WALKING_ROUTES_BY_IDS =
                  global.WALKING_ROUTES_BY_IDS || {};

                if (global.GEODISC_WALKING_ROUTING_ENGINE === 'euclidean') {
                  global.WALKING_ROUTES[routeCoordinates] = {
                    route: {
                      routes: [
                        {
                          duration: 0,
                          geometry: {
                            coordinates: [
                              booking.geometry.coordinates,
                              [vehicleStop.lon, vehicleStop.lat],
                            ],
                            type: 'LineString',
                          },
                          legs: [{ duration: 0 }],
                        },
                      ],
                    },
                  };
                }

                if (!global.WALKING_ROUTES[routeCoordinates]) {
                  if (global.GEODISC_WALKING_ROUTING_ENGINE !== 'osrm') {
                    const driver =
                      routingEngineDrivers[
                        global.GEODISC_WALKING_ROUTING_ENGINE
                      ];

                    global.WALKING_ROUTES[routeCoordinates] = {};

                    global.WALKING_ROUTES[routeCoordinates].result = driver
                      .fetch(coordinates, {})
                      .then(
                        (route) => {
                          $D2.S.INFO(
                            [global.GEODISC_WALKING_ROUTING_ENGINE, 'resolved'],
                            {
                              vehicleId,
                              bookingId,
                              route,
                            }
                          );
                          global.WALKING_ROUTES[routeCoordinates].route = route;
                          incSerialNumber();
                        },
                        (reason) => {
                          $D2.S.INFO(
                            [global.GEODISC_WALKING_ROUTING_ENGINE, 'rejected'],
                            {
                              vehicleId,
                              bookingId,
                              reason,
                            }
                          );
                          global.WALKING_ROUTES[routeCoordinates].error =
                            reason;
                          incSerialNumber();
                        }
                      );

                    global.WALKING_ROUTES_BY_IDS[routeId] =
                      global.WALKING_ROUTES[routeCoordinates];
                  } else {
                    const { url } =
                      walkingRouting[global.GEODISC_WALKING_ROUTING_ENGINE];

                    global.WALKING_ROUTES[routeCoordinates] = {
                      addr: url(coordinates, {}),
                      opts: {
                        booking,
                        bookingId,
                        vehicle: vehicleStop,
                        vehicleId,
                        properties,
                      },
                    };

                    global.WALKING_ROUTES[routeCoordinates].result = fetchJSON(
                      global.WALKING_ROUTES[routeCoordinates].addr
                    ).then(
                      (route) => {
                        $D2.S.INFO('resolved', {
                          vehicleId,
                          bookingId,
                          route,
                        });
                        setTimeout(() => {
                          global.WALKING_ROUTES[routeCoordinates].route = route;
                          if (
                            global.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISPLAY_EUCLIDEAN
                          ) {
                            global.WALKING_ROUTES[
                              routeCoordinates
                            ].route.routes[0].geometry.coordinates = [
                              booking.geometry.coordinates,
                              [vehicleStop.lon, vehicleStop.lat],
                            ];
                          }
                          incSerialNumber();
                        }, 0);
                      },
                      (reason) => {
                        $D2.S.INFO('rejected', {
                          vehicleId,
                          bookingId,
                          reason,
                        });
                        setTimeout(() => {
                          global.WALKING_ROUTES[routeCoordinates].error =
                            reason;
                          incSerialNumber();
                        }, 0);
                      }
                    );

                    global.WALKING_ROUTES_BY_IDS[routeId] =
                      global.WALKING_ROUTES[routeCoordinates];
                  }
                }

                const walkingRoute =
                  global.WALKING_ROUTES[routeCoordinates] &&
                  global.WALKING_ROUTES[routeCoordinates].route;
                if (
                  walkingRoute &&
                  walkingRoute.routes &&
                  walkingRoute.routes[0]
                ) {
                  $D2.S.INFO('route', {
                    coordinates: walkingRoute.routes[0].geometry.coordinates,
                  });
                  return lineString(
                    walkingRoute.routes[0].geometry.coordinates,
                    properties
                  );
                }
                return lineString(
                  [
                    booking.geometry.coordinates,
                    [vehicleStop.lon, vehicleStop.lat],
                  ],
                  properties
                );
              });

            return [...acc, ...lines];
          }, []);

        return featureCollection(allLines);
      }
    )
);

export const nodesSourceSelector = createImmutableSelector(
  nodesSelector,
  editableBookingIdSelector,
  (nodes, editableBookingId) => {
    D2.S.COUNTER('nodesSourceSelector');

    return featureCollection(
      nodes && editableBookingId
        ? nodes
            .filter(item => item.booking_uid === editableBookingId)
            .map((item, i) => ({
              ...point([item.lon, item.lat], item),
              id: i,
            }))
        : []
    );
  }
);

export const editableBookingsSourceSelector = createImmutableSelector(
  editableBookingsSelector,
  activeVehicleIdsSelector,
  (editableBookings, activeVehicleIds) =>
    D2.S.FUNCTION(
      'editableBookingsSourceSelector',
      { editableBookings, activeVehicleIds },
      ({ $D2 }) => {
        if (
          !editableBookings ||
          !activeVehicleIds.size ||
          !editableBookings[activeVehicleIds.get(0)] ||
          !editableBookings[activeVehicleIds.get(0)][0]
        ) {
          return featureCollection([]);
        }

        const { bookingPickup, bookingDropoff } =
          editableBookings[activeVehicleIds][0];
        $D2.S.INFO('nodes', { bookingPickup, bookingDropoff });

        return featureCollection([
          point(bookingPickup, { type: 'pickup' }),
          point(bookingDropoff, { type: 'dropoff' }),
        ]);
      }
    )
);
