import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';
import MapGL, {
  Layer,
  Source,
  FeatureState,
  Marker,
} from '@urbica/react-map-gl';

import LanguageControl from 'components/Map/LanguageControl';

import { map, commuteOfferlayers as layers } from 'config';
import findNearestPoint from 'utils/nearest-point';
import { point } from '@turf/helpers';

import { getLayers } from 'utils/geofences';
import debug from 'utils/debug';
import BusMarker from 'components/BusMarker';
import WaypointMarker from 'components/WaypointMarker';
import ErrorReporter from 'components/ErrorReporter';
import { getPopup, getClickPopup } from './utils';

import Container from './Container';
import MarkerPoint from './MarkerPoint';

import arrowImage from './arrow.png';

const D2 = debug('c:MapCommuteOfferEditor:Map');

class Map extends React.PureComponent {
  state = {
    cursorStyle: '',
    hoveredLayerId: null,
    hoveredStateId: null,
    popupData: null,
    clickPopup: null,
  };

  componentDidMount() {
    D2.S.INFO('componentDidMount');

    this.map = this._map.current.getMap();
    this.map.loadImage(arrowImage, (error, image) => {
      if (!error) {
        try {
          this.map.addImage('arrow', image);
        } catch (e) {
          // do nothing
        }
      }
    });
  }

  _map = React.createRef();

  onLayerHover = e =>
    D2.S.FUNCTION('onLayerHover', { e }, () => {
      this.setState({
        cursorStyle: 'pointer',
        hoveredLayerId: e.features[0].layer.source,
        hoveredStateId: e.features[0].id,
        popupData: e.features[0],
      });
    });

  onLayerLeave = e =>
    D2.S.FUNCTION('onLayerLeave', { e }, () => {
      this.setState({
        cursorStyle: '',
        hoveredLayerId: null,
        hoveredStateId: null,
        popupData: null,
      });
    });

  onMapClick = e =>
    D2.S.FUNCTION('onMapClick', { e, props: this.props }, ({ $D2 }) => {
      const {
        pointEditing,
        addPointToRoute,
        changeRoutePoint,
        activeVehicleIds,
        activeRouteStopUid,
      } = this.props;

      const { lng, lat } = e.lngLat;

      const features =
        e.features ||
        this.map
          .queryRenderedFeatures(e.point)
          .filter(item =>
            [
              'bookings_circle',
              'bookings',
              'vehicles',
              'nodes',
              'stops',
            ].includes(item.layer.id)
          );

      $D2.S.INFO('features', { features });

      if (!features.length) {
        this.setState({
          clickPopup: null,
          popupData: null,
        });
      }

      if (features.length > 1) {
        this.setState({
          clickPopup: { event: e, features },
          popupData: null,
        });
      }

      if (features.length === 1) {
        if (this.state.clickPopup) {
          this.setState({ clickPopup: null });
        }

        const feature = features[0];
        const { id } = feature.layer;

        $D2.S.INFO('id', { id, feature });

        switch (id) {
          case 'nodes': {
            $D2.S.FUNCTION('onClickNodesLayer', { feature }, () => {
              this.props.onClickNodesLayer(feature);
            });
            return;
          }
          case 'bookings_circle': {
            $D2.S.FUNCTION('onClickBookingLayer', { feature }, () => {
              this.props.onClickBookingLayer(feature);
            });
            return;
          }
          case 'bookings': {
            $D2.S.FUNCTION('onClickBookingLayer', { feature }, () => {
              this.props.onClickBookingLayer(feature);
            });
            return;
          }
          case 'stops': {
            $D2.S.FUNCTION('onClickStopsLayer', { feature }, () => {
              this.props.onClickStopsLayer(e, feature);
            });
            return;
          }
          case 'vehicles': {
            $D2.S.FUNCTION('onClickVehiclesLayer', { feature }, () => {
              // if (this.props.editableBookingId) {
              //   return;
              // }

              this.props.onClickVehiclesLayer(feature, {
                ...feature.properties,
                id: feature.properties.uid,
                color: feature.properties.$activeColor,
                node_type: feature.properties.node_type,
              });
            });
            return;
          }
          default:
            return;
        }
      }

      if (pointEditing) {
        if (activeRouteStopUid) {
          changeRoutePoint(
            lng,
            lat,
            activeRouteStopUid,
            activeVehicleIds.toJS()
          );
        } else {
          addPointToRoute(lng, lat, activeVehicleIds.toJS());
        }
      }
    });

  render() {
    return D2.S.FUNCTION(
      'render',
      { state: this.state, props: this.props, layers },
      () => {
        const {
          t,
          viewport,
          onViewportChange,
          stops,
          vehicles,
          nodes,
          editableBookingsSource,
          bookings,
          routeSource,
          routeLayer,
          walkingRoutes,
          busStopsVisible,
          activeVehicleIds,
          pointEditing,
          activeRouteStopUid,
          geofencesSource,
          visibleTransitstopTypes,
          visibleTransitstopSets,
          activeMapStyle,
          activeOnlineVehicles,
          activeOnlineVehiclesSource,
          commuteOfferIsReadOnly,
          isDeliveryLayout,
        } = this.props;

        const { hoveredStateId, hoveredLayerId, popupData, clickPopup } =
          this.state;

        const isActive = activeVehicleIds.size > 0;

        const activeVehicleIdsSet = activeVehicleIds.reduce(
          (memo, agent_id) => {
            memo.add(agent_id);
            return memo;
          },
          new Set()
        );

        const geofencesLayers = getLayers(0, 0.5, 1.0);

        const visibleWaypoints = vehicles.features
          .filter(
            waypoint =>
              !isActive ||
              activeVehicleIdsSet.has(
                waypoint.properties.assigned_vehicle_id
              ) ||
              activeVehicleIdsSet.has(waypoint.properties.agent_id)
          )
          .sort(
            (a, b) =>
              a.properties.$waypoint_index - b.properties.$waypoint_index
          );

        const activeWaypoint = activeRouteStopUid
          ? visibleWaypoints.find(
              waypoint => waypoint.properties.uid === activeRouteStopUid
            )
          : undefined;

        const isLocationEditingEnabled =
          activeWaypoint &&
          !activeWaypoint.properties.$isWaypointReadOnly &&
          !commuteOfferIsReadOnly;

        const onActiveWaypointDragEnd = e =>
          D2.S.FUNCTION(
            'onActiveWaypointDragEnd',
            { e, props: this.props },
            () => {
              const targetPoint = point([e.lng, e.lat]);
              D2.S.INFO('targetPoint', { targetPoint, e, props: this.props });

              const draggableWaypoint = activeWaypoint.properties;

              if (draggableWaypoint.node_type !== 'point') {
                const nearestPoint = isDeliveryLayout
                  ? targetPoint
                  : findNearestPoint(targetPoint, this.props.stops);
                this.props.setDraggablePoint({
                  ...draggableWaypoint,
                  lon: nearestPoint.geometry.coordinates[0],
                  lat: nearestPoint.geometry.coordinates[1],
                  nearestPoint: nearestPoint,
                  keepAddress: isDeliveryLayout,
                });
              } else {
                this.props.setDraggablePoint({
                  ...draggableWaypoint,
                  lon: targetPoint.geometry.coordinates[0],
                  lat: targetPoint.geometry.coordinates[1],
                });
              }
            }
          );

        const activeOnlineVehiclesSourceWithLatLon = {
          ...activeOnlineVehiclesSource,
          features: activeOnlineVehiclesSource.features.filter((vehicle) => {
            if (
              vehicle.geometry.coordinates[0] ||
              vehicle.geometry.coordinates[1]
            ) {
              return vehicle;
            }
          }),
        };

        return (
          <Container>
            <MapGL
              ref={this._map}
              style={{ width: '100%', height: '100vh' }}
              mapStyle={activeMapStyle}
              accessToken={map.token}
              onViewportChange={onViewportChange}
              cursorStyle={pointEditing ? 'crosshair' : this.state.cursorStyle}
              viewportChangeMethod='flyTo'
              boxZoom={false}
              onClick={this.onMapClick}
              {...viewport}
            >
              <LanguageControl />
              {geofencesSource && [
                <Source
                  key='source'
                  id='geofences'
                  type='geojson'
                  data={geofencesSource}
                />,
                ...geofencesLayers.map(layer => (
                  <Layer key={layer.id} {...layer} />
                )),
              ]}
              {visibleTransitstopSets.map(visibleTransitstopSet =>
                visibleTransitstopTypes.map(transitstopType => (
                  <Source
                    id={`transitstop-${visibleTransitstopSet}-${transitstopType}`}
                    key={`transitstop-${visibleTransitstopSet}-${transitstopType}`}
                    type='vector'
                    url={`${global.GEODISC_TRANSTOPSTOP_TILES_URL}?${[
                      `transitstopset_id=${visibleTransitstopSet}`,
                      `stop_type=${transitstopType}`,
                    ].join('&')}`}
                  >
                    <Layer
                      id={`transitstop-${visibleTransitstopSet}-${transitstopType}`}
                      key={`transitstop-${visibleTransitstopSet}-${transitstopType}`}
                      type='circle'
                      source={`transitstop-${visibleTransitstopSet}-${transitstopType}`}
                      source-layer={
                        global.GEODISC_TRANSTOPSTOP_TILES_SOURCE_LAYER ||
                        'transitstop'
                      }
                      {...layers.transitstops}
                    />
                  </Source>
                ))
              )}
              <Source id='nodes' type='geojson' data={nodes}>
                <Layer
                  {...layers.nodes}
                  onHover={this.onLayerHover}
                  onLeave={this.onLayerLeave}
                />
              </Source>

              <Source id='routes-source' type='geojson' data={routeSource} />
              <Layer
                id='routes-layer-arrows'
                source='routes-source'
                {...routeLayer.arrows}
              />
              <Layer
                id='routes-layer-active'
                source='routes-source'
                {...routeLayer.active}
              />

              {true && (
                <Source id='vehicles' type='geojson' data={vehicles}>
                  <Layer
                    {...layers.vehicles}
                    onHover={isActive ? null : this.onLayerHover}
                    onLeave={isActive ? null : this.onLayerLeave}
                  />
                  <Layer
                    {...layers.vehicles_active}
                    onHover={this.onLayerHover}
                    onLeave={this.onLayerLeave}
                  />
                  <Layer {...layers.vehicle_active_stop} />
                </Source>
              )}

              {global.GEODISC_COMMUTE_OFFER_WAYPOINT_MARKERS_ENABLED &&
                visibleWaypoints.map(waypoint => (
                  <ErrorReporter
                    key={`waypoint-${waypoint.properties.uid}`}
                    D2={D2}
                  >
                    <Marker
                      key={`waypoint-${waypoint.properties.uid}`}
                      longitude={waypoint.geometry.coordinates[0]}
                      latitude={waypoint.geometry.coordinates[1]}
                    >
                      <WaypointMarker color={waypoint.properties.$activeColor}>
                        {waypoint.properties.$waypoint_index + 1}
                      </WaypointMarker>
                    </Marker>
                  </ErrorReporter>
                ))}

              {global.GEODISC_COMMUTE_OFFER_WAYPOINT_MARKERS_ENABLED &&
                activeWaypoint && (
                  <Marker
                    key={`active-waypoint-${activeWaypoint.properties.uid}`}
                    longitude={activeWaypoint.geometry.coordinates[0]}
                    latitude={activeWaypoint.geometry.coordinates[1]}
                  >
                    <WaypointMarker
                      color={activeWaypoint.properties.$activeColor}
                    >
                      {activeWaypoint.properties.$waypoint_index + 1}
                    </WaypointMarker>
                  </Marker>
                )}

              {isLocationEditingEnabled && (
                <Marker
                  key={`draggable-waypoint-${activeWaypoint.properties.uid}`}
                  longitude={activeWaypoint.geometry.coordinates[0]}
                  latitude={activeWaypoint.geometry.coordinates[1]}
                  onDragEnd={onActiveWaypointDragEnd}
                  draggable
                >
                  <MarkerPoint
                    color={
                      activeWaypoint.properties.$draggableColor ||
                      activeWaypoint.properties.$activeColor
                    }
                    isActive
                  >
                    {global.GEODISC_COMMUTE_OFFER_WAYPOINT_MARKERS_ENABLED
                      ? activeWaypoint.properties.$waypoint_index + 1
                      : ''}
                  </MarkerPoint>
                </Marker>
              )}

              <Source id='bookings' type='geojson' data={bookings}>
                <Layer
                  {...layers.bookings}
                  onHover={isActive ? null : this.onLayerHover}
                  onLeave={isActive ? null : this.onLayerLeave}
                />
                <Layer
                  {...layers.bookings_active}
                  onHover={this.onLayerHover}
                  onLeave={this.onLayerLeave}
                />
                <Layer
                  {...layers.bookings_circle}
                  onHover={isActive ? null : this.onLayerHover}
                  onLeave={isActive ? null : this.onLayerLeave}
                />
                <Layer
                  {...layers.bookings_circle_active}
                  onHover={this.onLayerHover}
                  onLeave={this.onLayerLeave}
                />
              </Source>

              {!window.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISABLE && (
                <Source id='walkingRoutes' type='geojson' data={walkingRoutes}>
                  <Layer {...layers.walkingRoutes} />
                </Source>
              )}

              <Source id='stops' type='geojson' data={stops}>
                <Layer
                  {...layers.stops}
                  onHover={this.onLayerHover}
                  onLeave={this.onLayerLeave}
                  filter={['in', 'stop_type', ...busStopsVisible]}
                />
                <Layer
                  {...layers.stops_label}
                  filter={['in', 'stop_type', ...busStopsVisible]}
                />
              </Source>

              <Source
                id='editableBookings'
                type='geojson'
                data={editableBookingsSource}
              >
                <Layer
                  id='editableBookings'
                  type='circle'
                  source='editableBookings'
                  paint={{
                    'circle-radius': 12,
                    'circle-color': '#0077c8',
                  }}
                />
              </Source>

              <Source
                id='activeOnlineVehicles'
                type='geojson'
                data={activeOnlineVehiclesSourceWithLatLon}
              >
                <Layer
                  id='activeOnlineVehicles'
                  type='circle'
                  source='activeOnlineVehicles'
                  paint={{
                    'circle-radius': 12,
                    'circle-color': '#0077c8',
                  }}
                  onHover={this.onLayerHover}
                  onLeave={this.onLayerLeave}
                />
              </Source>

              {activeOnlineVehicles
                .filter(vehicle => vehicle.lat || vehicle.lon)
                .map(vehicle => (
                  <Marker
                    key={`vehicle_marker_${vehicle.id}`}
                    longitude={vehicle.lon}
                    latitude={vehicle.lat}
                  >
                    <BusMarker color={vehicle.$activeColor} />
                  </Marker>
                ))}

              {popupData && getPopup(hoveredLayerId, popupData, t)}
              {clickPopup && getClickPopup(clickPopup, this.onMapClick)}
              {hoveredStateId && hoveredLayerId && (
                <FeatureState
                  id={hoveredStateId}
                  source={hoveredLayerId}
                  state={{ hover: true }}
                />
              )}
            </MapGL>
          </Container>
        );
      }
    );
  }
}

export default withTranslation()(Map);
