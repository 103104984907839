import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerWrapper } from 'components/AdvancedSettings/styles';
import DataTable from 'components/DataTable';
import ModalHeader from 'components/ModalHeader';
import { styled, useStyletron } from 'baseui';
import { getAddressList } from './utils';
import debug from 'utils/debug';
import compare from 'just-compare';
import { useMemo } from 'react';

const Container = styled('div', ({ $theme }) => {
  return {
    margin: '0 auto',
    padding: '40px 15px',
    background: $theme.colors.containerBackground,
    height: '100%',
  };
});

const Verification = (props) => {
  const [addressesData, setAddressesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    orders,
    confirmMatchedAddress,
    currentOffer,
    updateCommuteOffer,
    originalData,
    history,
  } = props;
  const { t } = useTranslation();
  const [css, theme] = useStyletron();
  const pageSize = 50;
  const statusLabels = {
    check: t('c.Geocoding.content.addressType.check'),
    matched: t('c.Geocoding.content.addressType.matched'),
    manually: t('c.Geocoding.content.addressType.manually'),
    enter: t('c.Geocoding.content.addressType.enter'),
  };
  const D2 = debug('p:Logistics:addressVerification');
  useEffect(() => {
    const addressesList = getAddressList({
      orders,
      statusLabels: statusLabels,
    });
    setAddressesData(addressesList);
  }, [orders]);

  const originalAddressesData = useMemo(() => {
    return (
      getAddressList({
        orders,
        statusLabels: statusLabels,
      }) || []
    );
  }, []);

  const columnName = [
    {
      name: t('c.Geocoding.content.table.address'),
      key: 'enteredAddress',
      width: '40%',
    },
    {
      name: t('c.Geocoding.content.table.match_address'),
      key: 'matchedAddress',
      width: '40%',
    },
    {
      name: t('c.Geocoding.content.table.status'),
      key: 'status',
      width: '20%',
    },
  ];

  const cellColors = {
    status: {
      [statusLabels.enter]: theme.colors.negative,
      [statusLabels.check]: theme.colors.warning,
      [statusLabels.manually]: theme.colors.positive,
    },
  };

  const getMenuItems = ({ selectedRow }) => {
    const items = [];
    if (selectedRow?.status === t('c.Geocoding.content.addressType.check')) {
      items.push({ label: t('c.Geocoding.content.address.option.confirm') });
    }
    // Uncomment when the edit feature has been build
    // if (selectedRow?.status === t('c.Geocoding.content.addressType.enter')) {
    //   items.push({ label: t('c.Geocoding.content.address.option.edit') });
    // }
    return items;
  };

  const menuActions = ({ item, selectedRow }) => {
    switch (item?.label) {
      case t('c.Geocoding.content.address.option.edit'):
        // action upon pressing Edit matched address
        break;
      case t('c.Geocoding.content.address.option.confirm'):
        const newCommuteOffer = { ...currentOffer };
        selectedRow?.AddressTypesList?.map((data) => {
          const bookings = {
            ...newCommuteOffer.stateless_api_request_data.bookings,
          };
          const isPickup = data?.type === 'pickup';
          const verifiedAddressTypeKey = isPickup
            ? 'confirm_pickup_address'
            : 'confirm_dropoff_address';
          const bookingData = bookings?.[data?.uid]?.data;
          const confirmPickupLocation = {
            pickup_location_lat:
              bookingData?.pickup_location_variants?.[0]?.lat,
            pickup_location_lon:
              bookingData?.pickup_location_variants?.[0]?.lon,
            pickup_location_name:
              bookingData?.pickup_location_variants?.[0]?.address,
          };
          const confirmDropOffLocation = {
            dropoff_location_lat:
              bookingData?.dropoff_location_variants?.[0]?.lat,
            dropoff_location_lon:
              bookingData?.dropoff_location_variants?.[0]?.lon,
            dropoff_location_name:
              bookingData?.dropoff_location_variants?.[0]?.address,
          };
          const confirmedBooking = {
            ...bookings?.[data?.uid],
            data: {
              ...bookingData,
              [verifiedAddressTypeKey]: true,
            },
            ...(isPickup ? confirmPickupLocation : confirmDropOffLocation),
            state: 'prepared',
          };

          newCommuteOffer.stateless_api_request_data.bookings = {
            ...newCommuteOffer.stateless_api_request_data.bookings,
            [data?.uid]: confirmedBooking,
          };
        });

        confirmMatchedAddress({
          commuteOffer: {
            ...newCommuteOffer,
          },
        });
        // action upon pressing Confirm matched address
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    if (!compare(originalAddressesData, addressesData)) {
      global.openWarningMessage({
        title: t('c.Geocoding.warning.close.title'),
        message: t('c.Geocoding.warning.close.message'),
        buttons: [
          {
            text: t('c.Geocoding.warning.close.btn.no'),
            fill: false,
          },
          {
            text: t('c.Geocoding.warning.close.btn.yes'),
            action: () => {
              confirmMatchedAddress({
                commuteOffer: {
                  ...originalData,
                },
              });
              global.closeWarningMessage();
              global.closeFullScreen();
            },
            fill: true,
          },
        ],
      });
    } else {
      global.closeFullScreen();
    }
  };

  const saveAddresses = () => {
    const data = currentOffer;
    const isDataValid = !!data;

    return D2.S.FUNCTION('onSave', { data, id: data.id, isDataValid }, () => {
      if ((isDataValid && data.id) || data.$source?.simulation) {
        setTimeout(() => {
          updateCommuteOffer(data.id, data, originalData, false, t);
        }, 0);
        global.closeFullScreen();
      }
    });
  };
  const successMessage = () => {};
  const isReadOnly = false;
  return (
    <React.Fragment>
      <ModalHeader
        handleClose={handleClose}
        pageTitle={t('c.Geocoding.content.title')}
        btnTwoAction={saveAddresses}
        btnTwoText={t('c.messages.Save')}
        disableSaveBtn={!history.past.size}
        // backBtn={!!title}
      />
      <ContainerWrapper>
        <Container>
          <DataTable
            columnName={columnName}
            menuItems={getMenuItems}
            menuActions={menuActions}
            data={addressesData}
            setData={setAddressesData}
            // bulkRemove={false}
            checkbox={false}
            successMessage={successMessage()}
            isReadOnly={isReadOnly}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalRecords={addressesData?.length}
            cellColors={cellColors}
            isFixedHeader={true}
          />
        </Container>
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default Verification;
