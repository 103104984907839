import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  availableBookingsSelector,
  filteredBookingsSelector,
  filteredVehiclesSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferLoadedSimulationSelector,
  numberOfUnresolvedAddressSelector,
  bookingReadySelector,
  validOrdersToDisplay,
  commuteOfferHasOnlineVehiclesSelector,
  commuteOfferRemoveAllOrdersResultSelector,
  commuteOfferRemoveAllOrdersLoadingSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';
import {
  currentProjectSelector,
  currentProjectConfigSelector,
  projectsSelector,
} from 'modules/user/selectors';
import { routePageAddressSelector } from 'modules/router/selectors';
import { commuteOfferViewportSelector } from '../../../modules/maps/selectors';

import { changeBookingsFilter } from 'modules/ui/actions';
import {
  commuteOfferRequestUpdate,
  enableForceReload,
  removeOrder,
  invalidateAllBookings,
} from 'modules/commuteOffer/actions';

import OrdersPanel from './OrdersPanel';
import { changeViewportCommuteOffer } from '../../../modules/maps/actions';
import { updateSimulation } from 'modules/simulations/actions';

const mapStateToProps = createSelector(
  commuteOfferViewportSelector,
  filteredBookingsSelector,
  availableBookingsSelector,
  currentProjectSelector,
  currentProjectConfigSelector,
  projectsSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferLoadedSimulationSelector,
  routePageAddressSelector,
  numberOfUnresolvedAddressSelector,
  bookingReadySelector,
  validOrdersToDisplay,
  commuteOfferHasOnlineVehiclesSelector,
  commuteOfferRemoveAllOrdersResultSelector,
  commuteOfferRemoveAllOrdersLoadingSelector,
  commuteOfferIsReadOnlySelector,
  filteredVehiclesSelector,
  (
    viewport,
    orders,
    unfilteredOrders,
    currentProject,
    currentProjectConfig,
    projects,
    originalData,
    data,
    simulation,
    pageAddress,
    numberOfUnResolvedAddresses,
    finishOfferLoading,
    validOrders,
    hasOnlineVehicles,
    removeAllOrdersResult,
    removeAllOrdersLoading,
    isReadOnly,
    vehicles
  ) => ({
    viewport,
    orders,
    unfilteredOrders,
    currentProject,
    currentProjectConfig,
    projects,
    originalData,
    data,
    simulation,
    pageAddress,
    numberOfUnResolvedAddresses,
    finishOfferLoading,
    validOrders,
    hasOnlineVehicles,
    removeAllOrdersResult,
    removeAllOrdersLoading,
    isReadOnly,
    vehicles,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportCommuteOffer,
  changeBookingsFilter,
  commuteOfferRequestUpdate,
  enableForceReload,
  removeOrder,
  invalidateAllBookings,
  updateSimulation,
};

const connectOrdersPanel = connect(mapStateToProps, mapDispatchToProps);

export default connectOrdersPanel(OrdersPanel);
