import Object$fromEntries from 'fromentries';

import exportColumnNamesByLanguage from '../Export/columns';

const fixedColumnNames = Object$fromEntries([
  ['id', 'id'],
  ['order id', 'id'],
  ['order_id', 'id'],
  ['order number', 'id'],
  ['order_number', 'id'],

  ['date', 'date'],

  ['name', 'name'],
  ['recipient_name', 'name'],

  ['name2', 'name2'],

  ['first name', 'first_name'],
  ['first name (shipping)', 'first_name'],
  ['shipping_first_name', 'first_name'],

  ['last Name', 'last_name'],
  ['last Name (shipping)', 'last_name'],
  ['shipping_last_name', 'last_name'],

  ['vehicle characteristics json', 'vehicle_characteristics_json'],
  ['vehicle_characteristics_json', 'vehicle_characteristics_json'],

  ['demand json', 'demand_json'],
  ['demand_json', 'demand_json'],

  ['pickup postal code', 'pickup_zip_code'],

  ['pickup_address', 'pickup_address'],
  ['pickup address', 'pickup_address'],

  ['pickup_address2', 'pickup_address2'],
  ['pickup address 2', 'pickup_address2'],

  ['pickup_city', 'pickup_city'],
  ['pickup city', 'pickup_city'],
  ['pickup_region', 'pickup_region'],
  ['pickup_country', 'pickup_country'],
  ['pickup country', 'pickup_country'],

  ['earliest pickup time', 'pickup_open_time_ts'],

  ['latest pickup time', 'pickup_close_time_ts'],

  ['dropoff postal code', 'dropoff_zip_code'],
  ['postcode (shipping)', 'dropoff_zip_code'],
  ['shipping_postcode', 'dropoff_zip_code'],
  ['postal', 'dropoff_zip_code'],

  ['dropoff_address', 'dropoff_address'],
  ['dropoff address', 'dropoff_address'],
  ['address 1&2 (shipping)', 'dropoff_address'],

  ['dropoff_address1', 'dropoff_address'],
  ['dropoff address 1', 'dropoff_address'],

  ['dropoff_address2', 'dropoff_address2'],
  ['dropoff address 2', 'dropoff_address2'],

  ['dropoff_city', 'dropoff_city'],
  ['dropoff city', 'dropoff_city'],
  ['dropoff_region', 'dropoff_region'],
  ['dropoff_country', 'dropoff_country'],
  ['dropoff country', 'dropoff_country'],

  ['shipping_address_1', 'dropoff_address'],
  ['customer_delivery_address', 'dropoff_address'],

  ['shipping_address_2', 'dropoff_address2'],

  ['earliest dropoff time', 'dropoff_open_time_ts'],
  ['fulfilment_time', 'dropoff_open_time_ts'],

  ['latest dropoff time', 'dropoff_close_time_ts'],
  ['fulfilment_end_time', 'dropoff_close_time_ts'],

  ['demand type', 'demand_type'],

  ['demand load', 'demand_load'],

  ['passengers', 'demand.passenger'],
  ['items', 'demand.item'],
  ['orders', 'demand.order'],

  ['remarks', 'remarks'],
  ['customer_remark', 'remarks'],

  ['delivery_instructions', 'instructions'],

  ['phone number', 'phone_number'],
  ['recipient_phone', 'phone_number'],

  ['pickup phone number', 'pickup_customer_phone'],
  ['pickup_customer_phone', 'pickup_customer_phone'],

  ['pickup service time', 'pickup_service_time'],
  ['pickup_service_time', 'pickup_service_time'],

  ['pickup_max_height', 'pickup_max_height'],
  ['pickup max height', 'pickup_max_height'],

  ['dropoff_max_height', 'dropoff_max_height'],
  ['dropoff max height', 'dropoff_max_height'],

  ['pickup_max_weight', 'pickup_max_weight'],
  ['pickup max weight', 'pickup_max_weight'],

  ['dropoff_max_weight', 'dropoff_max_weight'],
  ['dropoff max weight', 'dropoff_max_weight'],

  ['two_man', 'two_man'],
  ['2マン', 'two_man'],
  ['2マン - 2 man', 'two_man'],

  ['dropoff service time', 'dropoff_service_time'],
  ['dropoff_service_time', 'dropoff_service_time'],

  ['pickup unit number', 'pickup_unit_number'],
  ['pickup_unit_number', 'pickup_unit_number'],

  ['dropoff unit number', 'dropoff_unit_number'],
  ['dropoff_unit_number', 'dropoff_unit_number'],

  ['geofence_definition_strategy', 'geofence_definition_strategy'],

  ['group', 'groups'],
  ['groups', 'groups'],

  ['geofence', 'geofence_id'],
  ['geofence id', 'geofence_id'],
  ['geofence_id', 'geofence_id'],

  ['pickup_location_lon', 'pickup_location_lon'],
  ['pickup location lon', 'pickup_location_lon'],
  ['pickup_lon', 'pickup_location_lon'],
  ['pickup lon', 'pickup_location_lon'],

  ['pickup_location_lat', 'pickup_location_lat'],
  ['pickup location lat', 'pickup_location_lat'],
  ['pickup_lat', 'pickup_location_lat'],
  ['pickup lat', 'pickup_location_lat'],

  ['dropoff_location_lon', 'dropoff_location_lon'],
  ['dropoff location lon', 'dropoff_location_lon'],
  ['dropoff_lon', 'dropoff_location_lon'],
  ['dropoff lon', 'dropoff_location_lon'],

  ['dropoff_location_lat', 'dropoff_location_lat'],
  ['dropoff location lat', 'dropoff_location_lat'],
  ['dropoff_lat', 'dropoff_location_lat'],
  ['dropoff lat', 'dropoff_location_lat'],

  ['trip_cost', 'trip_cost'],
  ['trip cost', 'trip_cost'],

  ['vehicle_labels', 'labels'],

  ['label_operator', 'label_operator'],
  ['label operator', 'label_operator'],
]);

const columnNames = Object.keys(exportColumnNamesByLanguage).reduce(
  (langMemo, language) => {
    const exportColumnNames = exportColumnNamesByLanguage[language];

    return Object.entries(exportColumnNames).reduce(
      (memo, [k, v]) => ({
        ...memo,
        [v.toLowerCase()]: k.toLowerCase(),
      }),
      langMemo
    );
  },
  { ...fixedColumnNames }
);

export default columnNames;
