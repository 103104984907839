import { is } from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';

// import debug from 'utils/debug';

// const D2 = debug('u:immutable-selector');

// const pis = (...args) => {
//   const p = D2.S.PROFILER('pis', () => is(...args), true);
//   if (p.timings.pTime >= 100.0) {
//     console.log('*** is', { args, p });
//   }
//   return p.result;
// };

// const createImmutableSelector = createSelectorCreator(
//   D2.S.$FN(defaultMemoize, 'memoize'),
//   D2.S.$FN(is, 'is')
// );

const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

export default createImmutableSelector;
