import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { StyledSpinnerNext } from 'baseui/spinner';

import { Container, ContainerInner, TextInner } from './Container';

const LoadingWithSpinner = ({ message }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <ContainerInner>
        <StyledSpinnerNext $color='#fff' />
        {message && (
          <TextInner>
            <b>{message}</b>
          </TextInner>
        )}
      </ContainerInner>
    </Container>
  );
};

export default LoadingWithSpinner;
