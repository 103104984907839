// Vehicle panel - left panel
import React from 'utils/react';
import { useEffect, useState } from 'react';
import { styled } from 'baseui';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { toaster } from 'baseui/toast';
import { crc32 } from 'utils/crc32';
import { addVehicleToSimulation } from 'api/simulations';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import GradientBackground from './SaveBottom/GradientBackground';
import PanelHeader from '../PanelHeader';
import PanelList from '../PanelList';
import Vehicle from './Vehicle';
import Button from './SaveBottom/Button';
import SaveBottom from './SaveBottom';
import Filter from 'components/Filter';
import VehicleEditor from './VehiclesEditor';
import PanelTitle from '../PanelTitle';
import UnassignedBox from './UnassignedBox';

const D2 = debug('p:Logistics:Panels:Vehicles');

const defaultScrollToVehicle = newActiveVehicleId =>
  D2.S.FUNCTION(
    'geodisc$ScrollToVehicle<DOM>',
    { newActiveVehicleId },
    ({ $D2 }) => {
      const element = window.document.getElementById(newActiveVehicleId);
      $D2.S.INFO('element', {
        element,
        newActiveVehicleId,
      });
      if (element) {
        element.scrollIntoView();
      }

      return 'DOM';
    }
  );

global.geodisc$ScrollToVehicle = defaultScrollToVehicle;

const Wrapper = styled('div', () => {
  return {
    overflow: 'scroll',
    position: 'relative',
  };
});

// these invisible-divs is to support auto-scroll when dragging Stops
const InviTopDiv = styled('div', () => {
  return {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    right: 0,
    height: '150px',
    width: '100%',
  };
});

const InviBottomDiv = styled('div', () => {
  return {
    position: 'fixed',
    zIndex: 999,
    bottom: 0,
    left: 0,
    right: 0,
    height: '70px',
    width: '100%',
  };
});

const Vehicles = props =>
  D2.S.FUNCTION('Vehicles', { props }, () => {
    const {
      t,
      vehicles,
      commuteOfferIsReadOnly,
      projectConfig,
      isDeliveryLayout,
      commuteOfferRequestUpdate,
      simulation,
      canManageVehicles,
      isServiceDataSource,
      isFilteringEnabled,
      assignedVehicles,
      unassignedVehicles,
      toggleBookingsHidden,
      changeVehiclesFilter,
      bookings,
      cleanActiveBookingId,
      activeBookingId,
      resolvedAddress,
    } = props;
    // Note: actual displayed booking list. It is a subset of bookings
    // in which booking has no assigned vehicle.
    const [bookingsList, setBookingsList] = useState([]);
    const [wrapperStyle, setWrapperStyle] = useState({
      height: 'calc(100% - 126px)',
      zIndex: 0,
    });

    const [isDragging, setIsDragging] = useState(false);

    const history = useHistory();
    useEffect(() => {
      if (history.location.state && history.location.state.fromAutoAssign) {
        const { bookingsInfo } = history.location.state;
        const numberOfUnassignedBookings = bookingsInfo.objects.filter(
          booking => booking.state !== 'assigned'
        ).length;
        if (numberOfUnassignedBookings === bookingsInfo.objects.length) {
          global.openWarningMessage({
            title: t('p.Orders.autoAssign.error.titleAll'),
            message: t('p.Orders.autoAssign.error.message', {
              count: bookingsInfo.objects.length,
            }),
            buttons: [
              {
                text: t('c.messages.GotIt'),
                fill: true,
              },
            ],
          });
          return;
        }
        if (numberOfUnassignedBookings === 0) {
          toaster.info(
            <>
              {t('p.Orders.autoAssign.success.message', {
                count: bookingsInfo.objects.length,
              })}
            </>,
            {
              autoHideDuration: 2000,
              closeable: false,
            }
          );
          return;
        }
        global.openWarningMessage({
          title: t('p.Orders.autoAssign.error.title'),
          message: t('p.Orders.autoAssign.error.message', {
            count: numberOfUnassignedBookings,
          }),
          buttons: [
            {
              text: t('c.messages.GotIt'),
              fill: true,
            },
          ],
        });
      }
    }, [history.location.state]);

    useEffect(() => {
      const unassignedBookingsList = resolvedAddress.filter((booking) => {
        return !booking.$assignedVehicle;
      });
      setBookingsList(unassignedBookingsList);
    }, [props]);

    const onCommuteOfferAddVehicle = () =>
      D2.S.FUNCTION('onCommuteOfferAddVehicle', { props }, () => {
        const id = uuidv4();

        openPopup('CommuteOfferVehicleEditor', {
          mode: 'new',
          title: t('c.ModalWindow.VehicleEditor.Title.Add'),
          submitCaption: t('Create'),
          submittingCaption: t('Creating...'),
          initialValues: {
            id,
            capacity_passengers: '15',
            capacity_stops: '100',
            capacity_wheelchairs: '0',
            start_time: new Date(),
            routing_engine_name: { value: 'osrme', label: 'osrme' },
            road_network: 'driving',
            vehicle_color: crc32(id) % 360,
            readOnly: { label: 'No', value: false },
          },
        });
      });

    const onSimulationAddVehicle = () =>
      D2.S.FUNCTION(
        'onSimulationAddVehicle',
        { props, projectConfig },
        ({ $D2 }) => {
          global.openVehicleEditor(
            {},
            {
              title: t('c.ModalWindow.VehicleEditor.Title.Add'),
              timezone: global.GEODISC_TIMEZONE,
              date: simulation.start_time,
              onSubmit: async vehicle =>
                $D2.S.FUNCTION(
                  'onSubmit',
                  { vehicle, simulation },
                  async () => {
                    await addVehicleToSimulation(simulation.id, vehicle);
                    global.openInfoMessage(
                      t('c.ModalWindows.VehicleEditor.Insert.Success')
                    );
                    commuteOfferRequestUpdate(null, {
                      isInitRequired: false,
                      ...$D2.CONTEXT,
                    });
                  }
                ),
            }
          );
        }
      );

    const onAddVehicle = !isDeliveryLayout
      ? onCommuteOfferAddVehicle
      : onSimulationAddVehicle;

    const hasPermissionsToChangeVehicles =
      !simulation || (simulation && canManageVehicles);
    const canEditVehicles =
      hasPermissionsToChangeVehicles && !commuteOfferIsReadOnly;
    D2.S.INFO('canEditVehicles', {
      canEditVehicles,
      hasPermissionsToChangeVehicles,
      commuteOfferIsReadOnly,
      isDeliveryLayout,
    });

    return (
      <PanelContainer
        isServiceDataSource={isServiceDataSource}
        style={{ zIndex: 2 }}
      >
        <PanelHeader>
          {t('p.Editor.Panels.Vehicles.Header.Trips', {
            count: assignedVehicles?.length,
          })}
        </PanelHeader>
        {isFilteringEnabled && (
          <Filter
            changeFilter={changeVehiclesFilter}
            placeholder={t('p.Editor.Panels.Vehicles.Header.Search')}
          />
        )}
        <VehicleEditor />
        {/* Note
          - The wrapper component mainly purpose is to make the list scrollable
          when the list is too long.
          - In case we need the UnassignedBox to be located at a fixed position
          we need to bring it outside the Wrapper and recalculate the wrapper style height
          because the unassigned box won't be show if there is no unassigned order
        */}
        <Wrapper style={wrapperStyle}>
          {isDragging && <InviTopDiv />}
          {bookingsList.length !== 0 && (
            // the yellow box
            <UnassignedBox
              unassignedBookings={bookingsList?.length || 0}
              toggleBookings={toggleBookingsHidden}
              isHidden={bookingsList.length === 0}
              cleanActiveBookingId={cleanActiveBookingId}
              activeBookingId={activeBookingId}
            />
          )}
          {assignedVehicles?.length !== 0 && (
            <>
              <PanelTitle>
                {t('p.Editor.Panels.Vehicles.Titles.Assigned', {
                  count: assignedVehicles?.length,
                })}
              </PanelTitle>
              <PanelList isFilteringEnabled={isFilteringEnabled}>
                {assignedVehicles.map(vehicle => (
                  <Vehicle
                    key={vehicle.agent_id}
                    vehicle={vehicle}
                    setIsDragging={setIsDragging}
                  />
                ))}
              </PanelList>
            </>
          )}
          {/* 
            Start of: Unassigned vehicles list
          */}
          <PanelTitle>
            {t('p.Editor.Panels.Vehicles.Titles.Unassigned', {
              count: unassignedVehicles?.length,
            })}
          </PanelTitle>
          <PanelList isFilteringEnabled={isFilteringEnabled}>
            {unassignedVehicles.map(vehicle => (
              <Vehicle key={vehicle.agent_id} vehicle={vehicle} />
            ))}
          </PanelList>
          {/* 
          End of Unassigned vehicles list
           */}
          {isDragging && <InviBottomDiv />}
        </Wrapper>
        <GradientBackground />
        <SaveBottom vehicles={vehicles} addVehicle={onAddVehicle}></SaveBottom>
      </PanelContainer>
    );
  });

export default Vehicles;
