import React, { useState, useEffect, useMemo, useRef } from 'react';
import { styled } from 'baseui';
import Immutable from 'immutable';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { LOGISTICS_ROUTE } from 'utils/constants';
import { sleep } from 'utils/async';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { Notification, KIND } from 'baseui/notification';
import Alert from 'baseui/icon/alert';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import styledComponents from 'styled-components';
import LoadingWithSpinner from 'components/LoadingWithSpinner';

import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import Overflow from 'baseui/icon/overflow';
import mapboxgl from 'mapbox-gl';
import MapGL, { Image, Layer, Source } from '@urbica/react-map-gl';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { LabelSmall } from 'baseui/typography';

import { readXlsFileAsync } from 'utils/xls';
import { readCsvFileAsync } from 'utils/csv';
import logisticsCsvToJson from 'utils/Logistics/OrdersTable/Import/logisticsCsvToJson';
import exportLogisticsOrders from 'utils/Logistics/OrdersTable/Export/exportLogisticsOrders';

import {
  waitSimulationProcessor,
  waitSimulationUncalculatedBookings,
  getBookingsForSimulation,
} from 'api/simulations';
import { sendLogisticsOrders, optimizeLogisticsOrders } from 'api/logistics';

import { Delete } from 'baseui/icon';
import { map as mapConfig } from 'config';
import AddressVerification from '../Geocoding/Verification';
import Badge from './Badge';
import assignedPinIcon from 'assets/assigned-pin.png';
import unassignedPinIcon from 'assets/unassigned-pin.png';
import searchIcon from 'assets/search.svg';
import { getGeoCodedLocationName } from '../Geocoding/Verification/utils';
import OrderDetails from '../OrderDetails';

import InsignificantText from 'pages/Logistics/Common/InsignificantText';
import debug from 'utils/debug';
import getColumnLabelsForLanguage from 'utils/Logistics/OrdersTable/Export/getColumnLabelsForLanguage';
import { removeBookingFromRoute } from '../../../modules/commuteOffer/actions';

import { validateCSV, getImportErrorList } from './csvValidations';

const D2 = debug('p:Logistics:Panels:Orders');

const Container = styled('div', {
  position: 'fixed',
  top: '8px',
  left: '116px',
  bottom: '58px',
  zIndex: 1,
  display: 'flex',
  pointerEvents: 'none',
});

const Panel = styled('div', ({ hidden }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  backgroundColor: '#080d14bf',
  backgroundImage:
    'linear-gradient(180deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px), linear-gradient(0deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px)',
  backdropFilter: 'blur(8px)',
  width: '300px',
  color: '#fff',
  transition: hidden ? 'opacity 0.3s ease-out' : 'opacity 0.5s ease-out',
  opacity: hidden ? 0 : 1,
  pointerEvents: hidden ? 'none' : 'auto',
}));

const Heading = styled('h1', ({ $theme }) => ({
  fontSize: '16px',
  padding: '20px',
  margin: 0,
  ...$theme.typography.montserrat,
}));

const Dropzone = styled('div', {
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'stretch',
  position: 'relative',
  overflow: 'auto',
});

const DropzoneDragActive = styled('div', {
  position: 'absolute',
  top: 0,
  left: '20px',
  right: '20px',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #ffffff',
  borderRadius: '12px',
  backdropFilter: 'blur(8px)',
  padding: '20px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '17px',
});

const OrdersList = styled('ul', {
  listStyle: 'none',
  padding: '0 20px',
  margin: 0,
  overflow: 'auto',
  flexGrow: 1,
});

const OrderItem = styled('li', ({ selected }) => ({
  listStyle: 'none',
  padding: '16px',
  backgroundColor: selected ? '#232c35' : '#1c232d',
  borderRadius: '4px',
  marginBottom: '12px',
  fontSize: '14px',
  cursor: 'pointer',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: selected ? '#737780' : 'transparent',
}));

const Toolbar = styled('div', {
  padding: '0 20px 10px',
});

const Footer = styled('div', {
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
});

const Button = styled('button', ({ disabled }) => ({
  background: disabled ? '#424963' : '#1235B2',
  borderRadius: '4px',
  fontSize: '14px',
  color: disabled ? '#97a0c0' : '#fff',
  border: 0,
  margin: 0,
  padding: '12px',
  appearance: 'none',
  fontFamily: 'inherit',
  width: '100%',
  cursor: disabled ? '' : 'pointer',
}));

const FilterInput = styledComponents.input`
  width: 100%;
  border: 0;
  outline: none;
  color: #fff;
  background: transparent;
  margin-left: 10.5px;
  font-size: 14px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.placeholderColor};
  }

  :-ms-input-placeholder {
    color: ${props => props.placeholderColor};
  }
`;

const OverflowButton = styled('button', {
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  border: 0,
  padding: 0,
  margin: 0,
  appearance: 'none',
  verticalAlign: '32px',
  backgroundColor: 'transparent',
  color: '#fff',
});

const OrderItemTitle = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

const InputWrapper = styled('div', ({ $theme }) => ({
  padding: '8px 16px 8px 18.5px',
  backgroundColor: $theme.colors.menuBackground,
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
}));

const PADDING = {
  top: 100,
  bottom: 100,
  left: 730,
  right: 100,
};

const OrdersPanel = ({
  viewport,
  onViewportChange,
  orders,
  currentProject,
  currentProjectConfig,
  changeBookingsFilter,
  projects,
  data,
  simulation,
  commuteOfferRequestUpdate,
  pageAddress,
  numberOfUnResolvedAddresses,
  unfilteredOrders,
  finishOfferLoading,
  validOrders,
  removeOrder,
  hasOnlineVehicles,
  invalidateAllBookings,
  removeAllOrdersResult,
  removeAllOrdersLoading,
  isReadOnly,
  updateSimulation,
  vehicles,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const timezone = currentProject.get('timezone');
  const [orderDetails, setOrderDetails] = useState(false);
  const [
    isAddressVerificationAvailableAfterImport,
    setIsAddressVerificationAvailableAfterImport,
  ] = useState(false);
  const [css, theme] = useStyletron('');

  const mapRef = useRef();
  const removeAllOrdersLoadingRef = useRef(false);

  const ordersDisplay =
    simulation?.data.logistics_api_settings.orders_display || 'dropoff_only';

  useEffect(() => {
    if (!removeAllOrdersLoading && removeAllOrdersLoadingRef.current) {
      if (removeAllOrdersResult && removeAllOrdersResult.error) {
        global.openWarningMessage({
          title: t('p.Orders.removeAllOrders.error.title'),
          message: t('p.Orders.removeAllOrders.error.message'),
          buttons: [
            {
              text: t('c.messages.OK'),
              fill: true,
            },
          ],
        });
      } else {
        toaster.info(<>{t('p.Orders.removeAllOrders.success.message')}</>, {
          autoHideDuration: 2000,
          closeable: false,
        });
      }
    }
    removeAllOrdersLoadingRef.current = removeAllOrdersLoading;
  }, [removeAllOrdersLoading]);

  useEffect(() => {
    if (orderDetails) {
      const map = mapRef.current?.getMap();
      map.flyTo({
        zoom: 16,
        center: [
          orderDetails.dropoff_location_lon,
          orderDetails.dropoff_location_lat,
        ],
        padding: PADDING,
      });
    }
  }, [orderDetails]);
  const ordersGeoJSON = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features: validOrders?.map(
        order =>
          ({
            type: 'Feature',
            properties: {
              id: order.data?.external_id,
              assigned: !!(
                order?.$assignedVehicle && order?.assigned_vehicle_id
              ),
            },
            geometry: {
              type: 'Point',
              coordinates:
                ordersDisplay === 'pickup_only'
                  ? [order.pickup_location_lon, order.pickup_location_lat]
                  : [order.dropoff_location_lon, order.dropoff_location_lat],
            },
          } || [])
      ),
    };
    return geojson;
  }, [validOrders, ordersDisplay]);

  const [generatingRoute, setGeneratingRoute] = useState(false);
  const canGenerateRoute = useMemo(() => {
    if (!unfilteredOrders?.length) {
      return false;
    }
    // If all orders have dropoff and pickup coordinates
    const allContainCoords = unfilteredOrders.every(
      order =>
        order.dropoff_location_lat &&
        order.dropoff_location_lon &&
        order.pickup_location_lat &&
        order.pickup_location_lon
    );
    return allContainCoords;
  }, [unfilteredOrders]);

  useEffect(() => {
    if (
      !canGenerateRoute &&
      unfilteredOrders.length &&
      isAddressVerificationAvailableAfterImport
    ) {
      triggerAddressVerificationWarning();
    }
  }, [unfilteredOrders]);

  const [cursorStyle, setCursorStyle] = useState(null);

  useEffect(() => {
    const map = mapRef.current.getMap();
    if (validOrders?.length) {
      const bounds = validOrders.reduce(
        (bounds, order) =>
          bounds.extend([
            order.dropoff_location_lon,
            order.dropoff_location_lat,
          ]),
        new mapboxgl.LngLatBounds()
      );
      map.fitBounds(bounds, { padding: PADDING });
    }
    setOrderDetails(false);
  }, [validOrders]);

  const hasCompletedPickupNode = vehicles.some(vehicle =>
    vehicle.route.some(
      node => node.node_type === 'pickup' && node.status === 'completed'
    )
  );

  const currentProjectId = currentProject ? currentProject.get('id') : 0;
  const projectId =
    data && data.id
      ? (() => {
          const re = /\/.*\/([0-9]*)$/;
          return parseInt(data.project.match(re)[1], 10);
        })()
      : currentProjectId;

  const projectInfo =
    projectId &&
    projects &&
    Immutable.isImmutable(projects) &&
    projects.find(item => item.get('id') === projectId);

  const projectName = projectInfo ? projectInfo.get('name') : undefined;

  const [importingOrders, setImportingOrders] = useState(false);
  const [showImportErrors, setShowImportErrors] = useState(false);
  const [importErrors, setImportErrors] = useState({});

  const triggerAddressVerificationWarning = () => {
    setIsAddressVerificationAvailableAfterImport(false);
    global.openWarningMessage({
      title: t('p.Orders.addressVerification.dialog.title'),
      message: t('p.Orders.addressVerification.dialog.message'),
      buttons: [
        {
          text: t('p.Orders.addressVerification.dialog.btn.check'),
          action: () => {
            global.openFullScreen({
              modalContent: <AddressVerification />,
            });
            global.closeWarningMessage();
          },
          fill: true,
        },
      ],
    });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    // TODO: show progress

    const ACCEPTED_FILE_TYPES = [
      'text/csv',
      'text/plain',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    const filteredFiles = acceptedFiles.filter(file =>
      ACCEPTED_FILE_TYPES.includes(file.type)
    );

    if (!filteredFiles.length) {
      return;
    }

    (async () => {
      try {
        setImportingOrders(true);
        const csvResults = (
          await Promise.all(
            filteredFiles.map(async acceptedFile =>
              acceptedFile.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ? readXlsFileAsync(acceptedFile)
                : readCsvFileAsync(acceptedFile)
            )
          )
        ).filter(Boolean);

        const importColumnMap = projectInfo
          ?.get('data')
          ?.get('import_column_map');

        const jsResults = await Promise.all(
          csvResults.map(csvData =>
            logisticsCsvToJson({ data: csvData, importColumnMap })
          )
        );

        const jsData = jsResults.reduce(
          (memo, jsResult) => [...memo, ...jsResult.objects],
          []
        );

        const optionalFields = jsResults[0]?.remarks;
        const columnNames = getColumnLabelsForLanguage();

        jsData.map((single, index) => {
          // Transform dynamic demand types and demand loads to a single object
          const demandFields = {};
          optionalFields[0].map((data, optionalIndex) => {
            if (
              data.indexOf(columnNames.demand_type_dynamic.toLowerCase()) ===
                0 ||
              data.indexOf(columnNames.demand_load_dynamic.toLowerCase()) === 0
            ) {
              demandFields[data] = optionalFields[index + 1][optionalIndex];
            }
          });

          Object.keys(demandFields).forEach((data) => {
            if (
              data.indexOf(columnNames.demand_type_dynamic.toLowerCase()) === 0
            ) {
              const demandTypeNumber = data.split(
                columnNames.demand_type_dynamic.toLowerCase()
              )[1];
              jsData[index].demand_types = {
                ...jsData[index].demand_types,
                [demandFields[data]]:
                  demandFields[
                    columnNames.demand_load_dynamic.toLowerCase() +
                      demandTypeNumber
                  ],
              };
            }
          });
          //==
          if (single.vehicle_labels) {
            const lowercaseLabels = (jsData[index].vehicle_labels || [])
              .split(',')
              .map((label) => {
                return label?.toLowerCase().trim().replace(/ /g, '_');
              });

            jsData[index].vehicle_labels = {
              [jsData[index].label_operator?.toLowerCase() || 'or']: [
                ...new Set(lowercaseLabels),
              ],
            };
          }
        });

        const existExternalIdList = orders.map(
          order => order.data.external_id
        );

        const { hasImportError, importErrors, jsDataWithDates } = validateCSV(
          jsData,
          existExternalIdList,
          simulation,
          timezone,
          currentProjectConfig?.vehicle_models
        );

        // Select latest dropoff time
        /*
        Last dropoff time is the lst minute of the latest dropoff date
        */

        const { dropoff_close_date_ts, dropoff_close_time_ts } = data;

        const latestDropoffTimeStamp =
          dropoff_close_date_ts && dropoff_close_time_ts
            ? moment.tz(
                dropoff_close_date_ts + ' ' + '23:59:59',
                'DD/MM/YYYY HH:mm:ss',
                timezone
              )
            : moment(simulation.start_time).tz(timezone);

        let latestDropoffTimeOfSimulation = moment(simulation.end_time).tz(
          timezone
        );

        latestDropoffTimeOfSimulation =
          latestDropoffTimeOfSimulation > latestDropoffTimeStamp
            ? latestDropoffTimeOfSimulation
            : latestDropoffTimeStamp;

        const body = JSON.stringify({
          end_time: latestDropoffTimeOfSimulation.format(),
        });

        updateSimulation(simulation.id, body);

        if (hasImportError) {
          setShowImportErrors(true);
          setImportErrors(importErrors);
        } else {
          const result = await sendLogisticsOrders(
            simulation.id,
            jsDataWithDates,
            {
              defaultDate: moment(simulation.start_time)
                .tz(timezone)
                .format('YYYY-MM-DD'),
              timezone,
              currentProjectConfig,
            }
          );

          if (result.response?.error || result.error) {
            throw new Error(result.error);
          }
          commuteOfferRequestUpdate();
          toaster.info(
            <>
              {t('p.Orders.importSuccess', {
                count: result.data?.length || '.',
              })}
            </>,
            {
              autoHideDuration: 2000,
              closeable: false,
            }
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setImportingOrders(false);
        setIsAddressVerificationAvailableAfterImport(true);
      }
    })();
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      accept: {
        'text/csv': [],
        'text/plain': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      },
      disabled: !finishOfferLoading || isReadOnly,
    });

  const totalOrdersCount = unfilteredOrders?.length ?? 0;
  const ordersCount = orders?.length ?? 0;
  const onDropOutside = (e) => {
    e.preventDefault();
  };
  const hasUnassignedOrders = orders.find(
    order => !order?.$assignedVehicle || !order?.assigned_vehicle_id
  );

  const onRemoveBooking = ({ commuteOffer, uid, id }) => {
    try {
      if (!isReadOnly) {
        removeOrder({ commuteOffer, uid, id });

        toaster.info(
          <>
            {t('p.booking.card.modal.remove.order.OrderID.msg', {
              orderId: id,
            })}
          </>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );
      } else {
        toaster.negative(
          <>{t('p.booking.card.modal.remove.booking.read.only')}</>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );
      }
    } catch (e) {
      toaster.negative(
        <>
          {t('p.booking.card.modal.remove.booking.remove.OrderId.failed', {
            orderId: id,
          })}
        </>,
        {
          autoHideDuration: 4000,
          closeable: false,
        }
      );
    }
  };

  const removeBooking = (props) => {
    D2.S.FUNCTION('onRemoveBooking', { props }, () => {
      global.openWarningMessage({
        title: t('p.booking.card.modal.remove'),
        message: t('p.Orders.remove.modal.message', {
          orderId: props.orderId,
        }),
        buttons: [
          {
            text: t('c.messages.ea4788705e6873b424c65e91c2846b19'),
            action: () => {
              global.closeWarningMessage();
            },
            fill: false,
          },
          {
            text: t('p.booking.card.modal.remove'),
            action: () => {
              onRemoveBooking({
                commuteOffer: { ...data },
                uid: props.uid,
                id: props?.orderId,
              });

              setOrderDetails(false);
              global.closeWarningMessage();
            },
            fill: true,
          },
        ],
      });
    });
  };

  useEffect(() => {
    // If the dropzone is disabled, drag a file to upload will trigger the the default browser behavior, which is downloading the dropped file
    window.addEventListener('dragover', onDropOutside, false);
    window.addEventListener('drop', onDropOutside, false);

    return () => {
      window.removeEventListener('dragover', onDropOutside);
      window.removeEventListener('drop', onDropOutside);
    };
  }, []);

  const simulationDate = moment(simulation?.start_time)
    .tz(timezone)
    .format('DD/MM/YYYY');

  return (
    <>
      <MapGL
        ref={mapRef}
        style={{ width: '100%', height: 'calc(100vh - 50px)' }}
        mapStyle={mapConfig.mapStyle}
        accessToken={mapConfig.token}
        boxZoom={false}
        logoPosition='bottom-right'
        onViewportChange={onViewportChange}
        cursorStyle={cursorStyle}
        viewportChangeMethod='flyTo'
        {...viewport}
      >
        <Source id='orders' type='geojson' data={ordersGeoJSON} />
        <Image id='pin-icon' image={assignedPinIcon} sdf />
        <Layer
          id='orders'
          type='symbol'
          source='orders'
          layout={{
            'icon-image': 'pin-icon',
            'icon-allow-overlap': true,
            'icon-offset': [-0.5, 0],
          }}
          paint={{
            'icon-color': [
              'case',
              ['boolean', ['get', 'assigned'], true],
              theme.colors.assignedOrderPin,
              theme.colors.unassignedOrderPin,
            ],
          }}
          onClick={(e) => {
            const feature = e.features[0];
            const { id } = feature.properties;
            const order = orders.find(o => o.data?.external_id === id);
            setOrderDetails(order);
          }}
          onHover={() => {
            setCursorStyle('pointer');
          }}
          onLeave={() => {
            setCursorStyle(null);
          }}
        />
        <Layer
          id='orders-labels'
          type='symbol'
          source='orders'
          paint={{
            'text-color': '#fff',
            'text-halo-width': 1.5,
            'text-halo-color': 'rgba(0,0,0,0.5)',
          }}
          layout={{
            'text-field': '{id}',
            'text-size': 10,
            'text-variable-anchor': ['left', 'right'],
            'text-radial-offset': 0.5,
          }}
          onClick={(e) => {
            const feature = e.features[0];
            const { id } = feature.properties;
            const order = orders.find(o => o.data?.external_id === id);
            setOrderDetails(order);
          }}
          onHover={() => {
            setCursorStyle('pointer');
          }}
          onLeave={() => {
            setCursorStyle(null);
          }}
        />
      </MapGL>
      <Container>
        <Panel>
          <Heading>
            {t('p.Orders.panel.heading', {
              count: ordersCount,
            })}
          </Heading>
          <Toolbar>
            <InputWrapper>
              <img src={searchIcon} />
              <FilterInput
                placeholder={t('p.Orders.search.placeholder')}
                onChange={(e) => {
                  changeBookingsFilter(e.target.value.toLowerCase());
                }}
                placeholderColor={theme.colors.placeholder}
              />
            </InputWrapper>
          </Toolbar>
          <Dropzone {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive && isDragAccept && (
              <DropzoneDragActive>
                <p>{t('p.Orders.import.dropHere')}</p>
              </DropzoneDragActive>
            )}
            <OrdersList>
              {orders.map(order => (
                <OrderItem
                  key={order.id}
                  onClick={(e) => {
                    setOrderDetails(order);
                  }}
                  selected={order.id === orderDetails?.id}
                >
                  <StatefulPopover
                    placement={PLACEMENT.bottom}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    content={({ close }) => {
                      const isAssigned = !!order?.assigned_vehicle_id;
                      return (
                        <StatefulMenu
                          items={[
                            {
                              label: t('p.Orders.order.menu.details'),
                              value: 'details',
                            },
                            {
                              label: t('p.booking.card.modal.remove'),
                              value: 'remove',
                              disabled: isAssigned,
                            },
                          ]}
                          onItemSelect={({ item }) => {
                            if (item?.value === 'details') {
                              setOrderDetails(order);
                            } else if (item?.value === 'remove') {
                              removeBooking({
                                orderId: order?.data?.external_id || order.id,
                                uid: order.uid,
                              });
                            }
                            close();
                          }}
                        />
                      );
                    }}
                  >
                    <OverflowButton
                      style={{
                        float: 'right',
                        margin: '-8px -8px 0 0',
                        color: '#97A0C0',
                      }}
                    >
                      <Overflow
                        overrides={{
                          Svg: {
                            style: { transform: 'rotate(90deg)' },
                          },
                        }}
                      />
                    </OverflowButton>
                  </StatefulPopover>
                  <OrderItemTitle>
                    <InsignificantText>
                      {t('p.Orders.order.id')}
                    </InsignificantText>{' '}
                    {order.data?.external_id || '-'}
                    <img
                      style={{ marginLeft: '9px' }}
                      src={
                        !order?.$assignedVehicle || !order?.assigned_vehicle_id
                          ? unassignedPinIcon
                          : assignedPinIcon
                      }
                    />
                  </OrderItemTitle>
                  {(ordersDisplay === 'pickup_and_dropoff' ||
                    ordersDisplay === 'pickup_only') && (
                    <div style={{ marginTop: 8 }}>
                      <InsignificantText>
                        {t('p.Orders.order.pickupAddress')}
                      </InsignificantText>
                      <br />
                      {getGeoCodedLocationName({
                        type: 'pickup',
                        addressData: order,
                      })}
                    </div>
                  )}
                  {(ordersDisplay === 'pickup_and_dropoff' ||
                    ordersDisplay === 'dropoff_only') && (
                    <div style={{ marginTop: 8 }}>
                      <InsignificantText>
                        {t('p.Orders.order.dropoffAddress')}
                      </InsignificantText>
                      <br />
                      {getGeoCodedLocationName({
                        type: 'dropoff',
                        addressData: order,
                      })}
                    </div>
                  )}
                </OrderItem>
              ))}
            </OrdersList>
          </Dropzone>
          <Footer>
            <Button
              type='button'
              disabled={totalOrdersCount === 0 || !hasUnassignedOrders}
              onClick={async (e) => {
                if (!canGenerateRoute) {
                  triggerAddressVerificationWarning();
                  return;
                }

                try {
                  global.openMethodWindow({
                    manualAction: () => {
                      history.push(
                        pageAddress({
                          page: LOGISTICS_ROUTE + '/routes',
                          serviceDate: moment(simulation.start_time)
                            .tz(timezone)
                            .format('YYYY-MM-DD'),
                        })
                      );
                    },
                    autoAction: async () => {
                      setGeneratingRoute(true);
                      const result = await optimizeLogisticsOrders(
                        simulation.id,
                        orders.map(o => o.uid),
                        { originalOffer: data }
                      );

                      await waitSimulationProcessor(
                        result.response?.processor_id
                      );
                      await waitSimulationUncalculatedBookings(simulation.id);
                      const bookingsInfo = await getBookingsForSimulation(
                        simulation.id
                      );
                      await sleep(5000);

                      // Redirect to Routes URL
                      history.push(
                        pageAddress({
                          page: LOGISTICS_ROUTE + '/routes',
                          serviceDate: moment(simulation.start_time)
                            .tz(timezone)
                            .format('YYYY-MM-DD'),
                        }),
                        {
                          fromAutoAssign: true,
                          bookingsInfo,
                        }
                      );
                    },
                  });
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e);
                } finally {
                  setGeneratingRoute(false);
                }
              }}
            >
              {t('p.Orders.footer.generateRoutes')}
            </Button>
            <StatefulPopover
              // placement={PLACEMENT.bottomLeft}
              content={({ close }) => (
                <StatefulMenu
                  items={[
                    {
                      label: (
                        <LabelSmall>
                          {t('p.Orders.footer.menu.addressVerification', {
                            numberOfRecords: numberOfUnResolvedAddresses,
                          })}
                        </LabelSmall>
                      ),
                      value: 'address-verification',
                      disabled: totalOrdersCount === 0,
                    },
                    {
                      label: (
                        <LabelSmall
                          data-debug={`finishOfferLoading:${finishOfferLoading}-isReadOnly:${isReadOnly}`}
                        >
                          {t('p.Orders.footer.menu.importCSV')}
                        </LabelSmall>
                      ),
                      value: 'import-csv',
                      disabled: !finishOfferLoading || isReadOnly,
                    },
                    {
                      label: (
                        <LabelSmall>
                          {t('p.Orders.footer.menu.exportCSV')}
                        </LabelSmall>
                      ),
                      value: 'export-csv',
                      disabled: totalOrdersCount === 0,
                    },
                    {
                      label: (
                        <LabelSmall>
                          {t('p.Orders.footer.menu.removeAllOrders')}
                        </LabelSmall>
                      ),
                      value: 'invalidate-all-bookings',
                      disabled:
                        totalOrdersCount === 0 ||
                        hasCompletedPickupNode ||
                        isReadOnly,
                    },
                  ]}
                  onItemSelect={({ item }) => {
                    const value = item?.value;
                    switch (value) {
                      case 'address-verification': {
                        global.openFullScreen({
                          modalContent: <AddressVerification />,
                        });
                        break;
                      }
                      case 'import-csv': {
                        open();
                        break;
                      }
                      case 'export-csv': {
                        const exportColumnMap = projectInfo
                          ?.get('data')
                          ?.get('export_column_map');

                        exportLogisticsOrders(data, simulation, {
                          t,
                          language: i18n.language,
                          projectName,
                          exportColumnMap,
                        });
                        break;
                      }
                      case 'invalidate-all-bookings': {
                        global.openWarningMessage({
                          title: t('p.Orders.footer.menu.removeAllOrders'),
                          message: t('p.Orders.removeAllOrders.dialog.message'),
                          buttons: [
                            {
                              text: t('c.messages.Cancel'),
                              fill: false,
                            },
                            {
                              text: t('p.Orders.footer.menu.removeAllOrders'),
                              action: () => {
                                invalidateAllBookings(data);
                                global.closeWarningMessage();
                                global.closeFullScreen();
                              },
                              fill: true,
                            },
                          ],
                        });
                        break;
                      }
                    }
                    close();
                  }}
                  overrides={{
                    List: {
                      style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.menuBackground,
                      }),
                    },
                    Option: {
                      props: {
                        overrides: {
                          ListItem: {
                            style: {
                              paddingBottom: '16px',
                              paddingRight: '16px',
                              paddingLeft: '16px',
                              paddingTop: '16px',
                            },
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            >
              <OverflowButton>
                <Overflow
                  overrides={{
                    Svg: {
                      style: { transform: 'rotate(90deg)' },
                    },
                  }}
                />
              </OverflowButton>
            </StatefulPopover>
            <div
              className={css({
                position: 'relative',
                top: '-6px',
                left: '-7px',
              })}
            >
              {numberOfUnResolvedAddresses > 0 && (
                <Badge> {numberOfUnResolvedAddresses}</Badge>
              )}
            </div>
          </Footer>
        </Panel>
        <Panel hidden={!orderDetails} style={{ marginLeft: 8 }}>
          {!!orderDetails && (
            <>
              <Heading
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {orderDetails.data?.external_id || '-'}
                <Delete
                  size={24}
                  style={{ cursor: 'pointer' }}
                  color='#97A0C0'
                  onClick={() => {
                    setOrderDetails(false);
                  }}
                />
              </Heading>
              <OrderDetails orderDetails={orderDetails} />
            </>
          )}
        </Panel>
      </Container>
      {(generatingRoute || importingOrders || removeAllOrdersLoading) && (
        <LoadingWithSpinner
          message={
            (generatingRoute && t('p.Orders.generatingRoutes')) ||
            (importingOrders && t('p.Orders.importingOrders')) ||
            (removeAllOrdersLoading &&
              t('p.Orders.footer.menu.removeAllOrders'))
          }
        />
      )}
      <Modal
        isOpen={showImportErrors}
        onClose={({ closeSource }) => {
          if (closeSource !== 'backdrop') {
            setShowImportErrors(false);
            setImportErrors({});
          }
        }}
        overrides={{
          Dialog: {
            style: {
              backgroundColor: '#080D14',
              marginTop: '60px',
              marginBottom: '60px',
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat',
            fontSize: '24px',
            fontWeight: 700,
            marginTop: '10px !important',
          })}
        >
          {t('p.Orders.importError.heading')}
        </ModalHeader>
        <ModalBody
          className={css({
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 500,
            color: '#fff',
          })}
        >
          <Notification
            kind={KIND.negative}
            overrides={{
              Body: {
                style: {
                  width: 'auto',
                  backgroundColor: '#D91E3626',
                  border: '1px solid #D91E3666',
                  borderRadius: '4px',
                },
              },
              InnerContainer: { style: { color: '#fff' } },
            }}
          >
            <Alert
              size={16}
              overrides={{
                Svg: {
                  style: {
                    verticalAlign: 'middle',
                  },
                },
              }}
            />{' '}
            {t('p.Orders.importError.error')}
          </Notification>
          <p>{t('p.Orders.importError.invalidData')}</p>
          <ul>{getImportErrorList(importErrors, t)}</ul>
          <p>{t('p.Orders.importError.uploadAgain')}</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrdersPanel;
