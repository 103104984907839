import React from 'utils/react';

import debug from 'utils/debug';
import Container from './Container';

import TimelineViewer from './TimelineViewer';
import SourceEditor from './SourceEditor';
import DiffViewer from './DiffViewer';
import PassengersTableViewer from './PassengersTableViewer';
import TableViewer from './TableViewer';
import DataTableViewer from './DataTableViewer';
import NameAndProject from './NameAndProject';
import FileUploaderDialog from './FileUploaderDialog';
import VehicleEditor from './VehicleEditor';
import ProgressWindow from './ProgressWindow';
import InfoMessage from './InfoMessage';
import WarningMessage from './WarningMessage';
import FullScreen from './FullScreen';
import VehicleEditorV2 from './VehicleEditorV2';
import VehicleTypeEditor from './VehicleTypeEditor';
import MasterSettings from './MasterSettings';
import MethodWindow from './MethodWindow';

const D2 = debug('c:ModalWindows');

export default () => {
  D2.S.INFO('ModalWindows');

  return (
    <React.Fragment>
      <Container>
        <PassengersTableViewer />
        <TableViewer />
        <DataTableViewer />
        <TimelineViewer />
        <DiffViewer />
        <SourceEditor />
        <NameAndProject />
        <FileUploaderDialog />
        <VehicleEditor />
        <ProgressWindow />
        <InfoMessage />
        <WarningMessage />
        <FullScreen />
        <VehicleEditorV2 />
        <VehicleTypeEditor />
        <MasterSettings />
        <MethodWindow />
      </Container>
    </React.Fragment>
  );
};
