import { fromJS } from 'immutable';
import {
  ADD_VEHICLE_TYPE_FAILURE,
  ADD_VEHICLE_TYPE_REQUEST,
  ADD_VEHICLE_TYPE_SUCCESS,
  SET_FLEET_DATA_SUCCESS,
  SET_VEHICLE_TYPES_SUCCESS,
  UPDATE_VEHICLE_TYPE_FAILURE,
  UPDATE_VEHICLE_TYPE_REQUEST,
  UPDATE_VEHICLE_TYPE_SUCCESS,
} from './types';

export const initialState = fromJS({
  vehicles: [],
  vehicleTypes: [],
  loading: false,
  error: '',
  success: '',
  currentStorageIndex: 0,
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FLEET_DATA_SUCCESS: {
      return state.merge({ vehicles: payload });
    }
    case SET_VEHICLE_TYPES_SUCCESS: {
      return state.merge({ vehicleTypes: payload });
    }
    case ADD_VEHICLE_TYPE_REQUEST: {
      return state.merge({ ...state, loading: true, error: '' });
    }
    case ADD_VEHICLE_TYPE_SUCCESS: {
      return state.merge({
        ...state,
        vehicleTypes: payload,
        loading: false,
        error: '',
      });
    }
    case ADD_VEHICLE_TYPE_FAILURE: {
      return state.merge({ ...state, loading: false, error: payload });
    }
    case UPDATE_VEHICLE_TYPE_REQUEST: {
      return state.merge({ ...state, loading: true, error: '' });
    }
    case UPDATE_VEHICLE_TYPE_SUCCESS: {
      return state.merge({
        ...state,
        vehicleTypes: payload,
        loading: false,
        error: '',
      });
    }
    case UPDATE_VEHICLE_TYPE_FAILURE: {
      return state.merge({ ...state, loading: false, error: payload });
    }
    default:
      return state;
  }
};

export default reducer;
