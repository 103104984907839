import React, { useEffect, useState } from 'react';
import { Checkbox } from 'baseui/checkbox';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import Overflow from 'baseui/icon/overflow';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import CustomButton from 'components/Forms/Button';
import { useStyletron, styled } from 'baseui';
import Pagination from './Pagination';
import { useTranslation } from 'react-i18next';

export const Toast = styled('div', ({ $theme }) => {
  const colors = $theme?.colors;

  return {
    maxWidth: '350px',
    textAlign: 'center',
    background: colors?.menuBackground,
    borderRadius: '4px',
    marginLeft: '175px',
    marginTop: '30px',
    padding: '15px',
  };
});

export default (props) => {
  const {
    columnName,
    menuItems,
    menuActions,
    data,
    setData,
    actions,
    bulkRemove,
    checkbox,
    cellColors,
    successMessage,
    isReadOnly,
    totalRecords,
    pageSize,
    pageNumber,
    setPageNumber,
    isFixedHeader = false,
  } = props;

  const hasAny = Boolean(data?.length);
  const hasAll = hasAny && data.every(x => x.selected);
  const hasSome = hasAny && data.some(x => x.selected);
  const [css, theme] = useStyletron();
  const colors = theme?.colors;
  const [showMessage, setShowMessage] = useState(false);
  const [pageData, setPageData] = useState([]);
  const showPagination = !!pageNumber && !!pageSize && !!totalRecords;
  const { t } = useTranslation();
  function toggleAll() {
    setData(selectedData =>
      selectedData.map(row => ({
        ...row,
        selected: !hasAll,
      }))
    );
  }

  function toggle(event) {
    const { name, checked } = event.currentTarget;
    setData(selectedData =>
      selectedData.map(row => ({
        ...row,
        selected: String(row.name) === name ? checked : row.selected,
      }))
    );
  }
  const tableStyles = {
    backgroundColor: colors?.containerBackground,
    color: colors?.white,
    fontFamily: 'Montserrat',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors?.white,
  };

  useEffect(() => {
    if (showPagination) {
      setPageData(
        data?.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize)
      );
    } else {
      setPageData(data);
    }
  }, [pageNumber, data]);

  useEffect(() => {
    setShowMessage(true);
    const hider = setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    return () => clearTimeout(hider);
  }, [successMessage]);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {bulkRemove && (
          <span style={{ marginLeft: 'auto' }}>
            <CustomButton
              disabled={!hasSome}
              onClick={actions?.bulkRemove?.action}
            >
              {actions?.bulkRemove?.text}
            </CustomButton>
          </span>
        )}
      </div>

      <TableBuilder
        data={pageData}
        overrides={{
          Root: {
            style: isFixedHeader ? { height: '100%' } : {},
          },
          TableHead: {
            style: isFixedHeader ? { position: 'sticky' } : {},
          },
        }}
      >
        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: tableStyles },
            TableBodyCell: { style: tableStyles },
            TableBodyRow: {
              style: { borderBottom: `solid 1px ${colors?.white}` },
            },
          }}
          header={
            checkbox ? (
              <Checkbox
                checked={hasAll}
                isIndeterminate={!hasAll && hasSome}
                onChange={toggleAll}
              />
            ) : (
              <React.Fragment />
            )
          }
        >
          {row =>
            checkbox ? (
              <Checkbox
                name={row?.name}
                checked={row.selected}
                onChange={toggle}
              />
            ) : (
              <React.Fragment />
            )
          }
        </TableBuilderColumn>
        {columnName?.map((columnData) => {
          return (
            <TableBuilderColumn
              header={columnData?.name}
              key={columnData?.key}
              overrides={{
                TableHeadCell: {
                  style: {
                    ...tableStyles,
                    color: colors?.tableHeader,
                    width: columnData.width,
                  },
                },
                TableBodyCell: {
                  style: tableStyles,
                },
              }}
            >
              {(row) => {
                return (
                  <span
                    className={css({
                      color:
                        cellColors?.[columnData?.key]?.[row[columnData?.key]],
                    })}
                  >
                    {row[columnData?.key]}
                  </span>
                );
              }}
            </TableBuilderColumn>
          );
        })}
        {!isReadOnly && (
          <TableBuilderColumn
            header=''
            overrides={{
              TableHeadCell: {
                style: { ...tableStyles, color: colors?.tableHeader },
              },
              TableBodyCell: {
                style: tableStyles,
              },
            }}
          >
            {selectedRow =>
              menuItems({ selectedRow })?.length > 0 && (
                <StatefulPopover
                  placement={PLACEMENT.bottomLeft}
                  content={({ close }) => (
                    <StatefulMenu
                      items={menuItems({ selectedRow })}
                      onItemSelect={({ item }) => {
                        menuActions({ item, selectedRow });
                        close();
                      }}
                    />
                  )}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Overflow
                      overrides={{
                        Svg: {
                          style: { transform: 'rotate(90deg)' },
                        },
                      }}
                    />
                  </div>
                </StatefulPopover>
              )
            }
          </TableBuilderColumn>
        )}
      </TableBuilder>
      {showPagination && (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 40px',
          })}
        >
          <div
            className={css({
              fontSize: '14px',
              fontWeight: '500',
              fontFamily: 'Montserrat',
              marginTop: '18px',
            })}
          >
            {t('c.Datatable.pagination', {
              start: pageNumber * pageSize - pageSize,
              end:
                pageNumber * pageSize > totalRecords
                  ? totalRecords
                  : pageNumber * pageSize,
              total: totalRecords,
            })}
          </div>
          <div className={css({ marginTop: '12px' })}>
            <Pagination
              totalRecords={totalRecords}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      )}
      <div className={css({ display: 'flex', flexDirection: 'row' })}>
        {!isReadOnly && !!actions?.add?.action && (
          <CustomButton
            style={{
              marginTop: '30px',
              fontWeight: 500,
            }}
            onClick={actions?.add?.action}
          >
            {actions?.add?.text}
          </CustomButton>
        )}
        {successMessage && showMessage && <Toast>{successMessage}</Toast>}
      </div>
    </React.Fragment>
  );
};
