/* eslint-disable quotes */

const columnNames = {
  en: {
    external_id: 'ID',
    date: 'Date',
    vehicle_service_number: 'Vehicle service number',

    pickup_customer_name: "Pickup customer's name 1",
    pickup_customer_name2: "Pickup customer's name 2",
    pickup_customer_phone: "Pickup customer's phone number",
    pickup_zip_code: 'Pickup postal code',
    pickup_unit_number: 'Pickup unit number',
    pickup_address: 'Pickup address',
    pickup_address2: 'Pickup address 2',
    pickup_location_lon: 'Pickup longitude',
    pickup_location_lat: 'Pickup latitude',
    pickup_open_time_ts: 'Earliest pickup time',
    pickup_open_date_ts: 'Earliest pickup date',
    pickup_close_time_ts: 'Latest pickup time',
    pickup_close_date_ts: 'Latest pickup date',
    pickup_scheduled_ts: 'Scheduled pickup time',
    pickup_scheduled_date_ts: 'Scheduled pickup date',

    pickup_completed_ts: 'Actual pickup time',
    pickup_service_time: 'Pickup service time',
    pickup_status: 'Pickup status',

    name: "Customer's name 1",
    name2: "Customer's name 2",
    phone_number: "Dropoff customer's phone number",
    dropoff_zip_code: 'Dropoff postal code',
    dropoff_unit_number: 'Dropoff unit number',
    dropoff_address: 'Dropoff address',
    dropoff_address2: 'Dropoff address 2',
    dropoff_location_lon: 'Dropoff longitude',
    dropoff_location_lat: 'Dropoff latitude',
    dropoff_open_time_ts: 'Earliest dropoff time',
    dropoff_open_date_ts: 'Earliest dropoff date',
    dropoff_close_time_ts: 'Latest dropoff time',
    dropoff_close_date_ts: 'Latest dropoff date',
    dropoff_scheduled_ts: 'Scheduled dropoff time',
    dropoff_scheduled_date_ts: 'Scheduled dropoff date',
    dropoff_completed_ts: 'Actual dropoff time',
    dropoff_completed_date_ts: 'Actual dropoff date',
    dropoff_service_time: 'Dropoff service time',
    dropoff_status: 'Dropoff status',
    failed_to_deliver_time: 'Failed to deliver time',

    two_man: '2 man',

    demand_type: 'Demand type',
    demand_load: 'Demand load',

    demand_type_dynamic: 'Demand type ',
    demand_load_dynamic: 'Demand load ',

    tracking_page_url: 'Tracking page',

    remarks: 'Remarks',

    vehicle_labels: 'Labels',
    label_operator: 'Label operator',
  },
  jp: {
    external_id: 'ID',
    date: '日付 - Date',
    vehicle_service_number: '車両番号 - Vehicle service number',

    pickup_customer_name: "集荷先顧客名 1 - Pickup customer's name 1",
    pickup_customer_name2: "集荷先顧客名 2 - Pickup customer's name 2",
    pickup_customer_phone: "集荷先電話番号 - Pickup customer's phone number",
    pickup_zip_code: '集荷先郵便番号 - Pickup postal code',
    pickup_unit_number: '集荷先数 - Pickup unit number',
    pickup_address: '集荷先顧客住所 1 - Pickup address 1',
    pickup_address2: '集荷先顧客住所 2 - Pickup address 2',
    pickup_location_lon: '集荷先経度 - Pickup longitude',
    pickup_location_lat: '集荷先緯度 - Pickup latitude',
    pickup_open_time_ts: '最短集荷時刻 - Earliest pickup time',
    pickup_close_time_ts: '最長集荷時刻 - Latest pickup time',
    pickup_scheduled_ts: '集荷予定時刻 - Scheduled pickup time',
    pickup_completed_ts: '集荷完了時刻 - Actual pickup time',
    pickup_service_time: '集荷所要時間(秒) - Pickup service time(seconds)',
    pickup_status: '集荷状況 - Pickup status',

    pickup_open_date_ts: '最短集荷日 - Earliest pickup date',
    pickup_close_date_ts: '最長集荷日 - Latest pickup date',
    pickup_scheduled_date_ts: '集荷予定日 - Scheduled pickup date',
    dropoff_open_date_ts: '最短配送日 - Earliest dropoff date',
    dropoff_close_date_ts: '最長配送日 - Latest dropoff date',
    dropoff_scheduled_date_ts: '配送予定日 - Scheduled dropoff date',
    dropoff_completed_date_ts: '配送実績日 - Actual dropoff date',
    failed_to_deliver_time:
      '時間内に配送できませんでした。- Failed to deliver time',
    demand_type_dynamic: '貨物タイプ - Demand type ',
    demand_load_dynamic: '積載量 - Demand load ',

    name: "配達先顧客名 1 - Dropoff customer's name 1",
    name2: "配達先顧客名 2 - Dropoff customer's name 2",
    phone_number: "配達先電話番号 - Dropoff customer's phone number",
    dropoff_zip_code: '配達先郵便番号 - Dropoff postal code',
    dropoff_unit_number: '配達先数 - Dropoff unit number',
    dropoff_address: '配達先住所 1 - Dropoff address 1',
    dropoff_address2: '配達先住所 2 - Dropoff address 2',
    dropoff_location_lon: '配達先経度 - Dropoff longitude',
    dropoff_location_lat: '配達先緯度 - Dropoff latitude',
    dropoff_open_time_ts: '最短配達時刻 - Earliest dropoff time',
    dropoff_close_time_ts: '最長配達時刻 - Latest dropoff time',
    dropoff_scheduled_ts: '配達予定時刻 - Scheduled dropoff time',
    dropoff_completed_ts: '配達完了時刻 - Actual dropoff time',
    dropoff_service_time: '配達所要時間(秒) - Dropoff service time(seconds)',
    dropoff_status: '配達状況 - Dropoff status',

    two_man: '2名体制での配達要否 - 2 man',

    demand_type: '配送種別 - Demand type',
    demand_load: '配送数 - Demand load',

    tracking_page_url: 'Tracking page',

    remarks: '備考 - Other information',
  },
  th: {
    external_id: 'ไอดี - ID',
    date: 'วันที่ - Date',
    vehicle_service_number: 'เบอร์รถบริการ - Vehicle service number',

    pickup_customer_name: "ชื่อของผู้รับพัสดุ 1 - Pickup customer's name 1",
    pickup_customer_name2: "ชื่อของผู้รับพัสดุ 2 - Pickup customer's name 2",
    pickup_customer_phone:
      "หมายเลขโทรศัพท์ของลูกค้าสำหรับรับพัสดุ - Pickup customer's phone number",
    pickup_zip_code: 'รหัสไปรษณีย์สำหรับรับพัสดุ - Pickup postal code',
    pickup_unit_number: 'หมายเลขหน่วยรับ - Pickup unit number',
    pickup_address: 'ที่อยู่สำหรับรับพัสดุ - Pickup address 1',
    pickup_address2: 'ที่อยู่รับสำหรับรับพัสดุ 2 - Pickup address 2',
    pickup_location_lon: 'ลองจิจูดการรับพัสดุ - Pickup longitude',
    pickup_location_lat: 'ละติจูดการรับพัสดุ - Pickup latitude',
    pickup_open_time_ts: 'เวลารับพัสดุที่เร็วที่สุด - Earliest pickup time',
    pickup_close_time_ts: 'เวลารับพัสดุล่าสุด - Latest pickup time',
    pickup_scheduled_ts: 'กำหนดเวลารับพัสดุ - Scheduled pickup time',
    pickup_completed_ts: 'เวลารับจริง - Actual pickup time',
    pickup_service_time: 'เวลาให้บริการรับของ - Pickup service time(seconds)',
    pickup_status: 'สถานะการรับพัสดุ - Pickup status',

    pickup_open_date_ts: 'วันที่รับสินค้าเร็วที่สุด - Earliest pickup date',
    pickup_close_date_ts: 'วันที่รับสินค้าช้าที่สุด - Latest pickup date',
    pickup_scheduled_date_ts: 'กําหนดวันรับสินค้า - Scheduled pickup date',
    dropoff_open_date_ts: 'วันที่ส่งสินค้าเร็วที่สุด - Earliest dropoff date',
    dropoff_close_date_ts: 'วันที่ส่งสินค้าช้าที่สุด - Latest dropoff date',
    dropoff_scheduled_date_ts: 'กำหนดวันที่ส่ง - Scheduled dropoff date',
    dropoff_completed_date_ts: 'วันที่ส่งของจริง - Actual dropoff date',
    failed_to_deliver_time: 'ไม่สามารถส่งมอบได้ในเวลา - Failed to deliver time',
    demand_type_dynamic: 'หน่วย - Demand type ',
    demand_load_dynamic: 'ปริมาณ - Demand load ',

    name: "ชื่อของลูกค้า 1 - Dropoff customer's name 1",
    name2: "ชื่อของลูกค้า 2 - Dropoff customer's name 2",
    phone_number:
      "หมายเลขโทรศัพท์ของลูกค้าสำหรับส่ง - Dropoff customer's phone number",
    dropoff_zip_code: 'รหัสไปรษณีย์ของจุดส่ง - Dropoff postal code',
    dropoff_unit_number: 'หมายเลขหน่วยสำหรับส่ง - Dropoff unit number',
    dropoff_address: 'ที่อยู่สำหรับส่ง - Dropoff address 1',
    dropoff_address2: 'ที่อยู่สำหรับส่ง 2 - Dropoff address 2',
    dropoff_location_lon: 'ลองจิจูดจุดส่ง - Dropoff longitude',
    dropoff_location_lat: 'ละติจูดจุดส่ง - Dropoff latitude',
    dropoff_open_time_ts: 'เวลาส่งเร็วที่สุด - Earliest dropoff time',
    dropoff_close_time_ts: 'เวลาส่งล่าสุด - Latest dropoff time',
    dropoff_scheduled_ts: 'เวลาส่งตามกําหนดการ - Scheduled dropoff time',
    dropoff_completed_ts: 'เวลาส่งจริง - Actual dropoff time',
    dropoff_service_time: 'เวลาส่ง - Dropoff service time(seconds)',
    dropoff_status: 'สถานะการส่ง - Dropoff status',

    two_man: '2 คน - 2 man',

    demand_type: 'หน่วยบรรทุก - Demand type',
    demand_load: 'ขนาดบรรทุก - Demand load',

    tracking_page_url: 'หน้าการติดตาม - Tracking page',

    remarks: 'หมายเหตุ - Other information',
  },
};

export default columnNames;

// ユニット - Units
// 合計枚数 - Total items
// 01 ブラウス - Blouse
// 02 パンツ - Pants
// 03 スカート - Skirt
// 04 スーツ婦 - Suit (Women)
// 05 ジャ婦 - Jacket (Women)
// 06 ワンピー - Dress
// 07 コート -  Coat
// 08 スーツ紳 - Suit (Men)
// 09 ジャ紳 - Jacket (Men)
// 10 毛皮 - Fur
// 11 革 - Leather
// 12 ソノ他 - Others
