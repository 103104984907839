import { fetchData } from 'api/net';
import {
  addVehicleToSimulation,
  deleteVehicleFromSimulation,
  unassignAllOrders,
  updateProject,
  updateVehicleInSimulation,
  addOrUpdateStartAndEndNodesForVehicle,
  getProject,
} from 'api/simulations';
import {
  FLEET_ADD_VEHICLE_REQUEST,
  FLEET_ADD_VEHICLE_FAILURE,
  FLEET_ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_TYPE_FAILURE,
  ADD_VEHICLE_TYPE_REQUEST,
  ADD_VEHICLE_TYPE_SUCCESS,
  FLEET_DELETE_VEHICLE_FAILURE,
  FLEET_DELETE_VEHICLE_REQUEST,
  FLEET_DELETE_VEHICLE_SUCCESS,
  SET_FLEET_DATA_SUCCESS,
  SET_VEHICLE_TYPES_SUCCESS,
  FLEET_UPDATE_VEHICLE_FAILURE,
  FLEET_UPDATE_VEHICLE_REQUEST,
  FLEET_UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_TYPE_REQUEST,
  UPDATE_VEHICLE_TYPE_SUCCESS,
  UPDATE_VEHICLE_TYPE_FAILURE,
} from './types';
import { urls } from '../../config';
import getHeaders from 'api/headers';

export function setFleetData(vehicles) {
  return (dispatch) => {
    dispatch({
      type: SET_FLEET_DATA_SUCCESS,
      payload: vehicles,
    });
  };
}

export const fleetAddVehicle = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FLEET_ADD_VEHICLE_REQUEST,
      });

      const { data, values, startPoint, endPoint } = payload;
      const newVehicle = values;

      const simulationId = data?.sim_id;
      const response = await addVehicleToSimulation(simulationId, values);
      await addOrUpdateStartAndEndNodesForVehicle(
        response.id,
        startPoint,
        endPoint
      );
      dispatch({
        type: FLEET_ADD_VEHICLE_SUCCESS,
        payload: { ...newVehicle, id: response.id },
      });
    } catch (error) {
      dispatch({
        type: FLEET_ADD_VEHICLE_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateVehicle = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FLEET_UPDATE_VEHICLE_REQUEST,
      });
      const { data, values, startPoint, endPoint } = payload;

      const updatedVehicle = values;
      const otherVehicles = [...data?.vehicles].filter((obj) => {
        return obj.id !== values?.id;
      });

      const simulationId = data?.sim_id;
      await updateVehicleInSimulation(simulationId, values);
      await addOrUpdateStartAndEndNodesForVehicle(
        values.id,
        startPoint,
        endPoint
      );

      dispatch({
        type: FLEET_UPDATE_VEHICLE_SUCCESS,
        payload: { otherVehicles, updatedVehicle },
      });
    } catch (error) {
      dispatch({
        type: FLEET_UPDATE_VEHICLE_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteVehicle = (payload) => {
  return async (dispatch) => {
    try {
      const { vehicle, commuteOffer } = payload;
      const id = vehicle.id;
      const vehicles = commuteOffer?.stateless_api_request_data?.vehicles;
      const assignedBookings = commuteOffer?.result?.assigned_bookings;
      const rejectedBookings = commuteOffer?.result?.rejected_bookings;
      const updatedAssignedBookings = assignedBookings.filter(
        booking => booking.assigned_vehicle_id !== vehicle.agent_id
      );
      const unassignedBookingUids = [];
      assignedBookings.map((booking) => {
        if (booking.assigned_vehicle_id === vehicle.agent_id) {
          unassignedBookingUids.push(booking.uid);
        }
      });
      const vehicleResults = { ...commuteOffer?.result?.vehicles };

      delete vehicleResults?.[vehicle.agent_id];

      dispatch({
        type: FLEET_DELETE_VEHICLE_REQUEST,
      });
      const newVehicles = [...vehicles].filter((obj) => {
        return obj.id !== id;
      });
      const response = await unassignAllOrders({ commuteOffer, id });
      await deleteVehicleFromSimulation(id);
      const newRejectedBookings = unassignedBookingUids.map((uid) => {
        return {
          scheduled_dropoff_time: null,
          scheduled_pickup_time: null,
          uid,
        };
      });

      dispatch({
        type: FLEET_DELETE_VEHICLE_SUCCESS,
        payload: {
          vehicles: newVehicles,
          assignedBookings: updatedAssignedBookings,
          rejectedBookings: [...rejectedBookings, ...newRejectedBookings],
          vehicleResults,
          ...response,
        },
      });
    } catch (error) {
      dispatch({
        type: FLEET_DELETE_VEHICLE_FAILURE,
        payload: error,
      });
    }
  };
};

export function setVehicleTypes(types) {
  return (dispatch) => {
    dispatch({
      type: SET_VEHICLE_TYPES_SUCCESS,
      payload: types,
    });
  };
}

export const addVehicleType = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_VEHICLE_TYPE_REQUEST,
      });

      const { values, data } = payload;
      const newVehicleType = values;

      // Ticket : https://swatmobile.atlassian.net/browse/SP-1289
      // Since we save vehicle types inside the project data JSON field,
      // We have to send whole JSON every time we update or add new Vehicle Type
      // It makes concurrent writing issues
      // To minimize this issue we are using the following temporary solution

      const projectDataFromServer = await getProject({ data });
      const project = { ...projectDataFromServer };

      project.data.vehicle_models = [
        ...(projectDataFromServer?.data?.vehicle_models || []),
        newVehicleType,
      ];
      await updateProject({ data: project });
      dispatch({
        type: ADD_VEHICLE_TYPE_SUCCESS,
        payload: project?.data?.vehicle_models,
      });
    } catch (error) {
      dispatch({
        type: ADD_VEHICLE_TYPE_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateVehicleType = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_VEHICLE_TYPE_REQUEST,
      });

      const { data, deleteId, updateId } = payload;

      // Ticket : https://swatmobile.atlassian.net/browse/SP-1289
      // Since we save vehicle types inside the project data JSON field,
      // We have to send whole JSON every time we update or add new Vehicle Type
      // It makes concurrent writing issues
      // To minimize this issue we are using the following temporary solution
      const projectDataFromServer = await getProject({ data });

      let project;

      if (deleteId) {
        project = {
          ...projectDataFromServer,
          data: {
            ...projectDataFromServer?.data,
            vehicle_models: (projectDataFromServer?.data?.vehicle_models || []).filter(model => model?.id !== deleteId),
          },
        };
      } else {
        const vehicleTypeObjFromServer =
          projectDataFromServer?.data?.vehicle_models
            .filter(model => model?.id !== updateId)
            .reduce((memo, model) => {
              return { ...memo, [model.id]: model };
            }, {});

        const vehicleTypeObjFromState = data?.data?.vehicle_models.reduce(
          (memo, model) => {
            return { ...memo, [model.id]: model };
          },
          {}
        );

        const newVehicleTypesObj = {
          ...vehicleTypeObjFromServer,
          ...vehicleTypeObjFromState,
        };
        const newVehiclesArray = Object.values(newVehicleTypesObj);

        project = {
          ...projectDataFromServer,
          data: {
            ...projectDataFromServer.data,
            vehicle_models: newVehiclesArray,
          },
        };
      }

      await updateProject({
        data: project,
      });
      dispatch({
        type: UPDATE_VEHICLE_TYPE_SUCCESS,
        payload: project?.data?.vehicle_models,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VEHICLE_TYPE_FAILURE,
        payload: error,
      });
    }
  };
};
