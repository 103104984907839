import React from 'utils/react';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useTranslation from 'utils/react/useTranslation';
import args from 'utils/args';
import moment from 'moment-timezone';
import { LOGISTICS_SERVICES_ROUTE } from 'utils/constants';
import { currentProjectIdSelector } from 'modules/user/selectors';

import { DatePicker } from 'baseui/datepicker';
import ja from 'date-fns/locale/ja';

import debug from 'utils/debug';
import Container from './Container';

const D2 = debug('p:CommuteOffer:Panels:Footer');

const resources = {
  jp: ja,
  ja,
};

const formatStringsData = {
  jp: 'y年 M月 d日',
  en: 'dd/MM/yyyy',
  th: 'dd/MM/yyyy',
};

const formatStrings = {
  ...formatStringsData,
  ja: formatStringsData.jp,
};

const Footer = (props) => {
  const {
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    setServiceDate,
    commuteOfferRequestUpdate,
    commuteOfferCurrentData,
    projectData,
    setFleetData,
    setVehicleTypes,
    pageAddress,
  } = props;

  const { i18n } = useTranslation();

  const { language } = i18n;

  const localeOverride = resources[language];
  const formatString = formatStrings[language];

  const [selectedDates, setSelectedDates] = useState(
    serviceDate ? [new Date(serviceDate)] : [new Date()]
  );

  const history = useHistory();
  const handleOnClick = useCallback(
    date =>
      history.push(
        pageAddress({
          page: LOGISTICS_SERVICES_ROUTE,
          serviceDate: moment(date).format('YYYY-MM-DD'),
        })
      ),
    [history]
  );
  useEffect(() => {
    const selectedDate = selectedDates[0];
    const date = selectedDate.toLocaleDateString('lt-LT');
    // console.log('*** Selected Date:', { date, selectedDate });
    setServiceDate(date);
    // commuteOfferRequestUpdate(null, { isInitRequired: false, ...D2.CONTEXT });
    if (window.location.pathname === LOGISTICS_SERVICES_ROUTE) {
      handleOnClick(selectedDates[0]);
    }
  }, [handleOnClick, selectedDates, commuteOfferRequestUpdate, setServiceDate]);

  const vehicleTypesList = projectData?.toJS()?.data?.vehicle_models;
  const vehiclesList =
    commuteOfferCurrentData?.stateless_api_request_data?.vehicles;
  useEffect(() => {
    // Load data to the fleet reducer from an existing selector
    // Using this method as a workaround until we drop 'reselect' and 'saga'
    setFleetData(vehiclesList);
    setVehicleTypes(vehicleTypesList);
  }, [setFleetData, setVehicleTypes, vehiclesList, vehicleTypesList]);

  const onDateChanged = value =>
    D2.S.FUNCTION('onDateChanged', { value }, () => {
      setSelectedDates([value]);
    });

  return isServiceDataSource ? (
    <Container isBookingsHidden={isBookingsHidden}>
      <DatePicker
        value={selectedDates}
        onChange={({ date }) =>
          Array.isArray(date) ? onDateChanged(date[0]) : onDateChanged(date)
        }
        locale={localeOverride}
        formatString={formatString}
        placeholder={formatString}
        mask={null}
      />
    </Container>
  ) : null;
};

export default Footer;
