import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';
import moment from 'moment-timezone';
import debug from 'utils/debug';
import { styled } from 'baseui';
import withDragDropContext from '../../../withDragDropContext';

import Container from './Container';
import { Stop, DraggableStop } from './Stop';
import { toaster } from 'baseui/toast';
import Snackbar from 'components/Snackbar';
import { SnackbarProvider } from 'baseui/snackbar';

const D2 = debug('p:Logistics:Panels:Vehicles:Vehicle:Route');

const DateLabel = styled('p', ({ $isOver, $isActive }) => {
  return {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: 'inherit',
    position: 'sticky',
    padding: '16px 0px',
    height: '40px',
    width: '100%',
    top: '0',
    zIndex: 2,
  };
});

class Route extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: '',
      dragStopIndex: -1,
      dropStopIndex: -1,
    };
  }
  isImpossibleRoute = (dragIndex, hoverIndex, props) => {
    // return { result: false };

    const { route, bookings } = props;
    const draggingStop = route[dragIndex];
    const hoverStop = route[hoverIndex];
    const idOfDragStop = draggingStop?.id;

    const typeOfDragStop = draggingStop?.node_type;
    const typeOfHoverStop = hoverStop?.node_type;

    const hoveredBooking = bookings.find((booking) => {
      if (typeOfHoverStop === 'pickup') {
        if (booking?.$routeStops[0]?.id === hoverStop.id) {
          return booking;
        }
      }
      if (typeOfHoverStop === 'dropoff') {
        if (booking?.$routeStops[1]?.id === hoverStop.id) {
          return booking;
        }
      }
    });

    const relatedBookings = bookings.find((booking) => {
      if (typeOfDragStop === 'pickup') {
        if (booking?.$routeStops[0]?.id === idOfDragStop) {
          return booking;
        }
      }
      if (typeOfDragStop === 'dropoff') {
        if (booking?.$routeStops[1]?.id === idOfDragStop) {
          return booking;
        }
      }
    });

    const allCompletedNodes = route.filter((data, index) => {
      if (data.status === 'completed') {
        return data;
      }
    });

    const completingNode =
      allCompletedNodes.length !== 0
        ? route[allCompletedNodes.length]
        : undefined;

    if (
      completingNode?.uid === hoverStop.uid ||
      completingNode?.uid === draggingStop.uid
    ) {
      return { result: true, message: 'p.booking.toaster.message.completing' };
    }

    return {
      result:
        !!draggingStop.$bookingsById[hoveredBooking?.uid] ||
        !!hoverStop.$bookingsById[relatedBookings?.uid],
      message: 'p.booking.toaster.message.impossible',
    };
  };

  moveCard = (dragIndex, hoverIndex) => {
    this.props.setIsDragging(true);
    if (this.state.dragStopIndex === -1) {
      this.setState({ dragStopIndex: dragIndex });
    }
    this.setState({ dropStopIndex: hoverIndex });
  };

  dropCard = (isDropSuccess) => {
    this.props.setIsDragging(false);
    if (
      this.state.dragStopIndex === -1 ||
      this.state.dropStopIndex === -1 ||
      this.state.dragStopIndex === this.state.dropStopIndex ||
      !isDropSuccess
    ) {
      this.setState({ dragStopIndex: -1, dropStopIndex: -1 });
      return;
    }
    const impossibleRoute = this.isImpossibleRoute(
      this.state.dragStopIndex,
      this.state.dropStopIndex,
      this.props
    );
    this.setState({ showError: false, errorMessage: '' });
    if (!impossibleRoute?.result) {
      const { route, vehicleId } = this.props;
      this.props.changeRouteOrder(
        this.state.dragStopIndex,
        this.state.dropStopIndex,
        route,
        vehicleId,
        'insert'
      );
    } else {
      const { t } = this.props;
      this.setState({ showError: true, errorMessage: impossibleRoute.message });
    }
    this.setState({ dragStopIndex: -1, dropStopIndex: -1 });
  };

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, ({ $D2 }) => {
      const {
        t,
        bookings,
        isReadOnly,
        vehicleId,
        route,
        color,
        activeRouteStopUid,
        setActiveRouteStop,
        cleanActiveRouteStop,
        removeBookingFromRoute,
        flyToCommuteOfferActiveStop,
        isHaveEditable,
        togglePointEditing,
        deleteRoutePoint,
        pointEditing,
        activeVehicleIds,
        commuteOfferIsReadOnly,
      } = this.props;

      $D2.COUNTER('render');

      if (!route.length) {
        return (
          <Container>
            {t(
              'p.CommuteOffer.Panels.Vehicles.Vehicle.Route.NoAssignedBookings'
            )}
          </Container>
        );
      }

      const routeLength = route?.length;
      const startPoint = route.find(stop => stop.partial_route_index === 1);
      const endPoint = route.find(stop => stop.partial_route_index === -1);
      const displayDates = {};
      route.map((stop) => {
        const scheduledTs = moment(stop?.scheduled_ts)
          .tz(global.GEODISC_TIMEZONE)
          .format('DD MMM');
        displayDates[scheduledTs] = { rendered: false };
      });

      const startPointScheduledTs = moment(startPoint?.scheduled_ts)
        .tz(global.GEODISC_TIMEZONE)
        .format('DD MMM');

      if (startPointScheduledTs) {
        displayDates[startPointScheduledTs] = { rendered: true };
      }

      const activeDropLineIndex =
        this.state.dropStopIndex +
        (startPoint ? -1 : 0) +
        (this.state.dragStopIndex > this.state.dropStopIndex ? -1 : 0);
      const deliveryDates = Object.keys(displayDates);

      const workStops = route.filter(
        stop =>
          stop.partial_route_index !== 1 && stop.partial_route_index !== -1
      );

      const firstWorkStop = workStops[0];
      const lastWorkStop = workStops[workStops.length - 1];

      return (
        <>
          <Container>
            {startPoint && startPointScheduledTs && (
              <DateLabel
                style={{ margin: '-5px 0' }}
                $isOver={this.props.isOver}
                $isActive={this.props.isActive}
              >
                {startPointScheduledTs}
              </DateLabel>
            )}
            {startPoint && (
              <Stop
                isActive={false}
                stop={{
                  ...startPoint,
                  status: firstWorkStop.status,
                }}
                id={0}
                index={0}
                key={0}
                color={color}
                hasNodesWithInvalidScheduledTime={false}
                isReadOnly={true}
                vehicleId={vehicleId}
                removeBookingFromRoute={removeBookingFromRoute}
                isHaveEditable={false}
                onClick={() => {
                  flyToCommuteOfferActiveStop(startPoint.uid);
                }}
                activeVehicleIds={activeVehicleIds}
                cleanActiveRouteStop={cleanActiveRouteStop}
              />
            )}
            {route
              .filter(
                stop =>
                  stop.partial_route_index !== 1 &&
                  stop.partial_route_index !== -1
              )
              .map((stop, i, stops) =>
                $D2.S.FUNCTION('route.map', { stop }, () => {
                  const nextStop = stops[i + 1];
                  const isActive = stop.uid === activeRouteStopUid;
                  const index = i + (startPoint ? 1 : 0);

                  const nodeWithInvalidScheduledTime = stop.bookings.find(
                    (booking) => {
                      const { open_time_ts, close_time_ts, scheduled_ts } =
                        booking.$node;

                      const open_time_mts = moment(open_time_ts);
                      const close_time_mts = moment(close_time_ts);
                      const scheduled_mts = moment(scheduled_ts);

                      return (
                        scheduled_mts.isBefore(open_time_mts) ||
                        scheduled_mts.isAfter(close_time_mts)
                      );
                    }
                  );

                  const hasNodesWithInvalidScheduledTime =
                    !!nodeWithInvalidScheduledTime;

                  const isCompletedStop = stop.status === 'completed';

                  const onClick = () =>
                    D2.S.FUNCTION('onClick', { stop, isActive }, () => {
                      if (isActive) {
                        cleanActiveRouteStop();
                      } else {
                        setActiveRouteStop(stop.uid);
                        flyToCommuteOfferActiveStop(stop.uid);
                      }
                    });

                  const StopComponent =
                    commuteOfferIsReadOnly || isCompletedStop
                      ? Stop
                      : DraggableStop;
                  const scheduledTs = moment(stop?.scheduled_ts)
                    .tz(global.GEODISC_TIMEZONE)
                    .format('DD MMM');
                  let dateText = '';
                  if (!displayDates[scheduledTs].rendered) {
                    displayDates[scheduledTs].rendered = true;
                    dateText = scheduledTs;
                  }

                  return (
                    <React.Fragment key={i}>
                      {dateText && (
                        <DateLabel
                          style={{ margin: '0px 0px' }}
                          $isOver={this.props.isOver}
                          $isActive={this.props.isActive}
                        >
                          {dateText}
                        </DateLabel>
                      )}
                      {this.state.dropStopIndex === (startPoint ? 1 : 0) &&
                        i === 0 &&
                        this.state.dropStopIndex !==
                          this.state.dragStopIndex && (
                          <div
                            style={{
                              width: '90%',
                              height: 1,
                              background: 'white',
                              marginLeft: 25,
                            }}
                          />
                        )}
                      <StopComponent
                        showRouteExtensionLine={
                          deliveryDates.indexOf(dateText) > 0
                        }
                        isActive={isActive}
                        stop={stop}
                        nextStop={nextStop}
                        bookings={bookings}
                        index={index}
                        id={index}
                        key={index}
                        color={color}
                        hasNodesWithInvalidScheduledTime={
                          hasNodesWithInvalidScheduledTime
                        }
                        isReadOnly={isReadOnly}
                        vehicleId={vehicleId}
                        removeBookingFromRoute={removeBookingFromRoute}
                        isHaveEditable={isHaveEditable}
                        onClick={onClick}
                        moveCard={this.moveCard}
                        dropCard={this.dropCard}
                        togglePointEditing={togglePointEditing}
                        pointEditing={pointEditing}
                        deleteRoutePoint={deleteRoutePoint}
                        activeVehicleIds={activeVehicleIds}
                        cleanActiveRouteStop={cleanActiveRouteStop}
                      />
                      {this.state.dropStopIndex !== -1 &&
                        i === activeDropLineIndex && (
                          <div
                            style={{
                              width: '90%',
                              height: 1,
                              background: 'white',
                              marginLeft: 25,
                            }}
                          />
                        )}
                    </React.Fragment>
                  );
                })
              )}
            {endPoint && (
              <Stop
                isActive={false}
                stop={{
                  ...endPoint,
                  status: lastWorkStop.status,
                }}
                id={routeLength - 1}
                index={routeLength - 1}
                key={routeLength - 1}
                color={color}
                hasNodesWithInvalidScheduledTime={false}
                isReadOnly={true}
                vehicleId={vehicleId}
                removeBookingFromRoute={removeBookingFromRoute}
                isHaveEditable={false}
                onClick={() => {
                  flyToCommuteOfferActiveStop(endPoint.uid);
                }}
                activeVehicleIds={activeVehicleIds}
                cleanActiveRouteStop={cleanActiveRouteStop}
              />
            )}
          </Container>
          <SnackbarProvider
            placement='bottom'
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.negative,
                  color: '#fff',
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  marginBottom: '50px',
                }),
              },
            }}
          >
            <Snackbar
              show={this.state.showError}
              errorMessage={this.state.errorMessage}
            />
          </SnackbarProvider>
        </>
      );
    });
  }
}

export default withDragDropContext(withTranslation()(Route));
