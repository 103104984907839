import Immutable from 'immutable';
import moment from 'moment-timezone';

import args from 'utils/args';
import {
  GEODISC_CURRENT_PROJECT_KEY,
  GEODISC_PROJECTS_LIST_KEY,
} from 'utils/constants';
import cloneLocation from 'utils/location';

import debug from 'utils/debug';
import * as actions from './actions';
import * as commuteOfferActions from '../commuteOffer/actions';

const D2 = debug('m:ui:user');

const initialProjectList = JSON.parse(
  global.localStorage.getItem(GEODISC_PROJECTS_LIST_KEY) ||
    JSON.stringify([
      {
        id: 0,
        name: 'Loading...',
        lat: 1.3644,
        lon: 103.9915,
        created_at: moment().tz(global.GEODISC_TIMEZONE).format(),
      },
    ])
);
D2.S.INFO('initialProjectList', { initialProjectList });

const getCurrentProject = projects =>
  D2.S.FUNCTION('getCurrentProject', { projects }, ({ $D2 }) => {
    const projectName =
      args.get('project') ||
      global.localStorage.getItem(GEODISC_CURRENT_PROJECT_KEY);
    $D2.S.INFO('projectName', { projectName, projects });

    const projectIdFromURL = args.search?.get('project-id');
    let savedProject = projects.get(0);
    if (projectIdFromURL) {
      savedProject = projects.find(
        x => x.get('id') === Number(projectIdFromURL)
      );
    } else if (projectName) {
      savedProject = projects.find(x => x.get('name') === projectName);
    }

    const selectedProject = savedProject || projects.get(0);
    $D2.S.INFO('selectedProject', {
      selectedProject,
      savedProject,
      projectName,
      projects,
    });

    return selectedProject;
  });

const initialProject = getCurrentProject(Immutable.fromJS(initialProjectList));
D2.S.INFO('initialProject', { initialProject, initialProjectList });

export const initialState = Immutable.Map({
  lastProcessedDataHash: null,
  location: cloneLocation(),
  projects: initialProjectList,
  currentProject: initialProject,
});
D2.S.INFO('initialState', { initialState });

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@@router/LOCATION_CHANGE': {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const newLocation = cloneLocation();
        return state.set('location', newLocation);
      });
    }
    case actions.AUTHORIZATION_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { user, projects, projectMembers } = payload;

        const selectedProject = getCurrentProject(projects);
        $D2.S.INFO('selectedProject', { selectedProject, projects, user });

        global.localStorage.setItem(
          GEODISC_PROJECTS_LIST_KEY,
          JSON.stringify(projects.toJS())
        );

        return state
          .set('projects', projects)
          .set('projectMembers', projectMembers)
          .set('currentProject', selectedProject)
          .merge(user);
      });
    }
    case actions.PROJECTS_CLEAR_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state
          .set('projects', Immutable.fromJS([]))
          .set('currentProject', null);
      });
    }
    case actions.PROJECTS_FETCH_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state;
      });
    }
    case actions.PROJECTS_FETCH_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const projects = payload;

        const selectedProject = getCurrentProject(projects);

        global.localStorage.setItem(
          GEODISC_PROJECTS_LIST_KEY,
          JSON.stringify(projects.toJS())
        );

        return state
          .set('projects', projects)
          .set('currentProject', selectedProject);
      });
    }
    case actions.CURRENT_PROJECT_CHANGE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { newProject, oldProject, nextPage } = payload;

      const projectName = newProject?.get('name') || '';

      if (global.GEODISC_LOCATION_HASH_OPTS.get('project')) {
        global.GEODISC_LOCATION_HASH_OPTS.set('project', projectName);
        // window.location.hash = '#';
      }

      global.localStorage.setItem(GEODISC_CURRENT_PROJECT_KEY, projectName);

      const getProjectLayoutName = project =>
        project?.get('data')?.get('layout_name') || 'default';
      const newLayoutName = getProjectLayoutName(newProject);
      const oldLayoutName = getProjectLayoutName(oldProject);

      const logisticsServicesURL = global.geodisc$setWindowLocation(nextPage);
      if (newLayoutName !== oldLayoutName) {
        if (newLayoutName === 'delivery') {
          if (logisticsServicesURL) {
            return state;
          }
        } else if (global.geodisc$setWindowLocation('/simulations')) {
          return state;
        }
      } else if (
        newLayoutName === oldLayoutName &&
        newLayoutName === 'delivery' &&
        logisticsServicesURL
      ) {
        return state;
      }

      return state.set('currentProject', newProject);
    }
    case actions.DELETE_USER: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      global.localStorage.removeItem(global.GEODISC_AUTH_LOGIN);
      global.localStorage.removeItem(global.GEODISC_AUTH_TOKEN);

      return initialState;
    }
    case commuteOfferActions.COMMUTE_OFFER_FETCH_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { isInitRequired = true } = payload;
        $D2.S.INFO('isInitRequired', { isInitRequired });

        if (!isInitRequired) {
          return state;
        }

        $D2.S.INFO('Init');

        return state;
        // return state.set('currentProject', null);
      });
    }
    case commuteOfferActions.COMMUTE_OFFER_FETCH_RESULTS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const { error } = payload;
        if (error) {
          return state;
        }

        const { result } = payload;

        const lastProcessedDataHash = state.get('lastProcessedDataHash');
        if (result.loadedHash === lastProcessedDataHash) {
          return state;
        }

        const { projectMembers, projects, projectRecord } = result;

        return state
          .set('lastProcessedDataHash', result.loadedHash)
          .set('projectMembers', Immutable.fromJS(projectMembers))
          .set('projects', projects)
          .update(
            'currentProject',
            currentProject =>
              projectRecord || getCurrentProject(projects) || currentProject
          );
      });
    }
    default:
      return state;
  }
};

export default reducer;
