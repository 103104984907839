import AdvancedSettings from 'components/AdvancedSettings';
import RadioField from 'components/Forms/RadioWithButtons';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import edit from 'assets/edit.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { styled } from 'baseui';

const EditBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '21.25px 22px',
  };
});

EditBtn.defaultProps = {
  src: edit,
};

const Templates = ({ finishOfferLoading, commuteOfferData, isSuperUser }) => {
  const { t } = useTranslation();
  const { control } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues: {
      template: 'default',
    },
  });
  const simulationId = commuteOfferData?.$source?.id;
  const actionButtons = [
    {
      icon: <EditBtn />,
      action: (data) => {
        global.openFullScreen({
          modalContent: (
            <AdvancedSettings
              title={t('c.MasterSettings.content.template.modal.title')}
              trip={data?.label}
              description={t(
                'c.MasterSettings.content.template.modal.description'
              )}
            />
          ),
        });
      },
    },
  ];

  if (isSuperUser) {
    actionButtons.push({
      icon: (
        <SettingsIcon
          style={{
            color: 'white',
            width: '15px',
            height: '15px',
            margin: '21.25px 5px',
          }}
        />
      ),
      action: () => {
        if (finishOfferLoading) {
          window.open(
            `${window.GEODISC_API_URL}/admin/simulation/simulation/${simulationId}/change/`,
            '_blank'
          );
        }
      },
    });
  }
  return (
    <>
      <h1 style={{ margin: 0 }}>
        {t('c.MasterSettings.content.template.title')}
      </h1>
      <p>{t('c.MasterSettings.content.template.description')}</p>
      <RadioField
        name='template'
        options={[
          {
            value: 'default',
            label: t('c.MasterSettings.content.template.option.weekday'),
          },
        ]}
        selected='default'
        control={control}
        buttonActions={actionButtons}
      />
    </>
  );
};

export default Templates;
