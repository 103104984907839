import moment from 'moment-timezone';

import debug from 'utils/debug';
const D2 = debug('utils:sgerp-api');

const formatVersionTs = value =>
  moment(moment(value).valueOf() + 1).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');

const indexCollection = async (collection, opts = {}) =>
  D2.A.FUNCTION('indexCollection', { collection }, async ({ $D2 }) => {
    if (collection.error) {
      const { error } = collection;
      return { error };
    }

    const { uid = 'uid' } = opts;

    const indexedCollection = collection.objects.reduce(
      (memo, item) => {
        const $id = String(item.id);
        return {
          objects: {
            ...memo.objects,
            [item[uid]]: item.$id !== $id ? { ...item, $id } : item,
          },
          index_by_id: {
            ...memo.index_by_id,
            [item.$id]: item[uid],
          },
          index_by_resource_uri: {
            ...memo.index_by_resource_uri,
            [item.resource_uri]: item[uid],
          },
          version_mts: memo.version_mts
            ? moment.max(memo.version_mts, moment(item.modified_at))
            : moment(item.modified_at),
        };
      },
      { objects: {}, index_by_resource_uri: {}, version_mts: moment.unix(0) }
    );

    return {
      objects: indexedCollection.objects,
      index_by_resource_uri: indexedCollection.index_by_resource_uri,
      version_ts: formatVersionTs(indexedCollection.version_mts),
    };
  });

export default indexCollection;
