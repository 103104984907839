import React from 'utils/react';
import debug from 'utils/debug';
import parseColor from 'parse-color';
import displayNames from 'utils/display-names';
import { removeInternalFieldsFromObject } from 'utils/object';

import { DropTarget } from 'react-dnd';
import Button from 'pages/CommuteOffer/TextButton';
import moment from 'moment-timezone';
import Menu from 'components/Menu';
import {
  addVehicleToSimulation,
  updateVehicleInSimulation,
  deleteVehicleFromSimulation,
} from 'api/simulations';
import MenuButton from '../MenuButton';

import Title from './Title';
import VehicleName from './VehicleName';
import VehicleType from './VehicleType';
import Caption from './Caption';
import Border from './Border';
import Container from './Container';
import Header from './Header';
import RouteData from './RouteData';
import Route from './Route';
// import BookingStopsEditor from './BookingStopsEditor';
import Label from './Label';
import Value from './Value';
import ButtonsContainer from './ButtonsContainer';
import Input from './Input';
import SearchResult from './SearchResults';
import UpdatedBadge from './UpdatedBadge';
import IdleBadge from './IdleBadge';
import OnlineBadge from './OnlineBadge';
import ProgressIndicator from '../../ProgressIndicator';
import menu from 'components/Menu/new-menu.svg';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import MenuToggleButton from 'components/Menu/Button';
import MenuContentV2 from 'components/Menu/ContentV2';
import { toaster } from 'baseui/toast';
import { getVehicleOnlineStatus } from 'utils/CommuteOffer';
import truckIcon from 'assets/truck.svg';

const D2 = debug('p:Logistics:Panels:Vehicles:Vehicle');

const defaultRoutingProfile = (engine) => {
  if (engine === 'asteria') {
    return window.ASTERIA_ROUTING_PROFILE;
  }
  return window.OSRM_ROUTING_PROFILE;
};

class Vehicle extends React.PureComponent {
  onClickHeader = () => {
    D2.S.INFO('onClickHeader');

    const { activeVehicleIds = [], vehicle } = this.props;

    if (!activeVehicleIds.includes(vehicle.agent_id)) {
      this.props.setActiveVehicleId(vehicle.agent_id);
    } else {
      this.props.cleanActiveVehicleId(vehicle.agent_id);
    }
  };

  onAddPoint = () => {
    D2.S.INFO('onAddPoint');

    if (this.props.activeRouteStop) {
      this.props.cleanActiveRouteStop();
    } else {
      this.props.setAddPointMode(this.props.vehicle.agent_id);
    }
  };

  onAddStop = () => {
    D2.S.INFO('onAddStop');

    this.props.setAddStopMode(this.props.vehicle.agent_id);
  };

  onEditCommuteOfferVehicle = () =>
    D2.S.FUNCTION(
      'onEditCommuteOfferVehicle',
      { props: this.props },
      ({ $D2 }) => {
        const { t, vehicle } = this.props;
        const { agent_id, capacity, route, vehicle_color, color, readOnly } =
          vehicle;
        const { routing_engine_name, road_network, osrme_timestamp_mode } =
          vehicle.routing_engine
            ? vehicle.routing_engine
            : {
                routing_engine_name: 'osrm',
                road_network: 'driving',
              };

        const timestampMode = osrme_timestamp_mode || 'start_time';

        const start_time =
          timestampMode !== 'end_time' && route.length
            ? moment(route[0].scheduled_ts).toDate()
            : undefined;
        const end_time =
          timestampMode === 'end_time' && route.length
            ? moment(route[route.length - 1].scheduled_ts).toDate()
            : undefined;

        const parsedVehicleColor = vehicle_color
          ? parseColor(vehicle_color).hsl[0]
          : parseColor(color).hsl[0];
        $D2.S.INFO('parsedVehicleColor', {
          parsedVehicleColor,
          vehicle_color,
          color,
        });

        const editorOptions = {
          mode: 'edit',
          title: t('c.ModalWindows.VehicleEditor.Title.Edit'),
          submitCaption: t('c.ModalWindows.VehicleEditor.Button.Save'),
          submittingCaption: t('c.ModalWindows.VehicleEditor.Progress.Saving'),
          vehicle,
          initialValues: {
            id: agent_id,
            capacity_passengers:
              capacity.passenger || capacity.passengers || '0',
            capacity_stops: capacity.stop || '0',
            capacity_wheelchairs: capacity.wheelchair || '0',
            start_time,
            end_time,
            routing_engine_name: {
              value: routing_engine_name,
              label: routing_engine_name,
            },
            road_network,
            vehicle_color: parsedVehicleColor,
            readOnly: readOnly
              ? { label: t('Yes'), value: true }
              : { label: t('No'), value: false },
          },
        };
        $D2.S.INFO('editorOptions', {
          editorOptions,
        });

        this.props.openPopup('CommuteOfferVehicleEditor', editorOptions);
      }
    );

  onEditSimulationVehicle = () =>
    D2.S.FUNCTION(
      'onEditSimulationVehicle',
      { props: this.props },
      ({ $D2 }) => {
        const { t, vehicle, simulation, commuteOfferRequestUpdate } =
          this.props;

        global.openVehicleEditor(vehicle, {
          title: t('c.ModalWindows.VehicleEditor.Title.Edit'),
          timezone: global.GEODISC_TIMEZONE,
          date: simulation.start_time,
          onSubmit: async newVehicle =>
            $D2.S.FUNCTION(
              'onSubmit',
              { newVehicle, vehicle, simulation },
              async () => {
                await updateVehicleInSimulation(simulation.id, {
                  ...newVehicle,
                  resource_uri: undefined,
                });
                global.openInfoMessage(
                  t('c.ModalWindows.VehicleEditor.Update.Success')
                );
                commuteOfferRequestUpdate(null, {
                  isInitRequired: false,
                  ...$D2.CONTEXT,
                });
              }
            ),
        });
      }
    );

  onClone = () =>
    D2.S.FUNCTION('onClone', { props: this.props }, ({ $D2 }) => {
      const { t, vehicle, simulation, commuteOfferRequestUpdate } = this.props;

      global.openVehicleEditor(
        {
          ...removeInternalFieldsFromObject(vehicle),
          agent_id: undefined,
          id: undefined,
        },
        {
          title: t('c.ModalWindows.VehicleEditor.Title.Clone'),
          timezone: global.GEODISC_TIMEZONE,
          date: simulation.start_time,
          prohibitedServiceNumber: vehicle?.service_number ?? '',
          onSubmit: async newVehicle =>
            $D2.S.FUNCTION(
              'onSubmit',
              { newVehicle, vehicle, simulation },
              async () => {
                await addVehicleToSimulation(simulation.id, newVehicle);
                global.openInfoMessage(
                  t('c.ModalWindows.VehicleEditor.Insert.Success')
                );
                commuteOfferRequestUpdate(null, {
                  isInitRequired: false,
                  ...$D2.CONTEXT,
                });
              }
            ),
        }
      );
    });

  onDeleteCommuteOfferVehicle = () => {
    D2.S.INFO('onDeleteCommuteOfferVehicle');

    const { vehicle } = this.props;
    this.props.deleteVehicle(vehicle.agent_id);
  };

  onRemoveAllBookingsFromVehicle = () => {
    D2.S.INFO('onRemoveAllBookingsFromVehicle');
    try {
      this.props.removeAllBookings();
      global.openWarningMessage({
        title: this.props.t('c.messages.4059b0251f66a18cb56f544728796875'),
        message: this.props.t('p.booking.card.modal.remove.succesfully'),
        buttons: [
          {
            text: this.props.t('c.messages.26b63f278101527e06a5547719568bb5'),
            fill: true,
          },
        ],
      });
    } catch (e) {
      global.openWarningMessage({
        title: this.props.t('c.messages.4059b0251f66a18cb56f544728796875'),
        message: this.props.t('p.booking.card.modal.remove.failed'),
        buttons: [
          {
            text: this.props.t('c.messages.26b63f278101527e06a5547719568bb5'),
            fill: true,
          },
        ],
      });
    }
  };

  onDeleteSimulationVehicle = () => {
    D2.S.INFO('onDeleteSimulationVehicle');

    const {
      t,
      vehicle,
      commuteOfferRequestUpdate,
      deleteLogisticsVehicle,
      currentCommuteOffer,
    } = this.props;
    const vehicles = currentCommuteOffer?.stateless_api_request_data?.vehicles;

    (async () => {
      try {
        await deleteLogisticsVehicle({
          vehicle: vehicle,
          commuteOffer: currentCommuteOffer,
        });

        toaster.info(
          <>
            {t('c.ModalWindows.VehicleEditor.Delete.Success.Vehicle.name', {
              name: vehicle.service_number || '',
            })}
          </>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );

        commuteOfferRequestUpdate(null, {
          isInitRequired: false,
          ...D2.CONTEXT,
        });
      } catch (e) {
        toaster.negative(
          <>{t('c.ModalWindows.VehicleEditor.Delete.Failure')}</>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );
      }
    })();
  };

  onShowVehicleSource = () => {
    D2.S.INFO('onShowVehicleSource');

    const { vehicle } = this.props;

    this.props.showVehicleSource(vehicle.agent_id);
  };

  onShowVehicleUpdates = () => {
    D2.S.INFO('onShowVehicleUpdates');
    const { vehicle } = this.props;

    this.props.showVehicleUpdates(vehicle.agent_id);
  };

  onSearchInputChange = (e) => {
    D2.S.INFO('onSearchInputChange');

    this.props.setStopSearchQuery(e.target.value);
  };

  onChangeStartTime = (value) => {
    D2.S.INFO('onChangeStartTime');

    const { vehicle } = this.props;
    if (value instanceof moment) {
      this.props.setVehicleStartTime(
        vehicle.agent_id,
        value.tz(global.GEODISC_TIMEZONE).format()
      );
    }
  };

  onVehicleAdmin = () => {
    D2.S.INFO('onVehicleAdmin', { props: this.props });

    const { vehicle } = this.props;

    window.open(
      `${window.GEODISC_API_URL}/admin/simulation/vehicle/${vehicle.id}/change/`,
      '_blank'
    );
  };

  filteredVehicles = (vehicle) => {
    return {
      id: vehicle?.id,
      name: vehicle?.service_number,
      agent_id: vehicle?.agent_id,
      geofences: vehicle?.geofence_ids,
      typeLabel: vehicle?.routing_engine?.vehicle_model,
      startTimeStamp: vehicle?.start_time,
      endTimeStamp: vehicle?.end_time,
      lat: vehicle?.lat,
      lon: vehicle?.lon,
      route: vehicle?.route || [],
    };
  };
  render() {
    return D2.S.FUNCTION('render', { props: this.props }, ({ $D2 }) => {
      const {
        t,
        d,
        vehicle,
        activeVehicleIds,
        isOver,
        connectDropTarget,
        setActiveRouteStop,
        activeRouteStopUid,
        editableBooking,
        pointEditing,
        addStopMode,
        filteredStops,
        stopSearchQuery,
        cleanAddStopMode,
        cleanAddPointMode,
        addStopToRoute,
        isReadOnly,
        canManageVehicles,
        simulation,
        isDeliveryLayout,
        isStaff,
        isSuperuser,
        capacityFunctor,
        currentUserConfig,
        setIsDragging,
      } = this.props;

      $D2.COUNTER('render');

      const routeData = global.GEODISC_COMMUTE_OFFER_ROUTES.get(
        this.props.vehicle.agent_id
      );

      const onEdit = (vehicle) => {
        global.openVehicleEditorV2({
          size: '648px',
          data: this.filteredVehicles(vehicle),
          title: t('p.Editor.Panels.Vehicles.Vehicle.Edit.Vehicle'),
        });
      };

      const onClone = (vehicle) => {
        global.openVehicleEditorV2({
          size: '648px',
          data: this.filteredVehicles(vehicle),
          isClone: true,
        });
      };

      const onDelete = () => {
        global.openWarningMessage({
          title: t('p.Editor.Panels.Vehicles.Vehicle.Remove.Vehicle'),
          message:
            `Remove ${vehicleDisplayNames.join(' ')} from today’s service? ` +
            'All the orders assigned to this vehicle will be unassigned.',
          buttons: [
            {
              text: t('c.messages.Cancel'),
              fill: false,
            },
            {
              text: t('c.messages.26b63f278101527e06a5547719568bb5'),
              action: () => {
                const deleteFn = simulation
                  ? this.onDeleteSimulationVehicle
                  : this.onDeleteCommuteOfferVehicle;
                deleteFn();
                global.closeWarningMessage();
              },
              fill: true,
            },
          ],
        });
      };

      const { $capacity_info } = vehicle;

      // TODO: Move this logic to a selector.
      // const isOperatedVehicle = false;
      const isOperatedVehicle = !!vehicle.route.find(
        stop =>
          stop.status === 'completed' ||
          stop.status === 'cancelled_by_user' ||
          stop.status === 'fail_to_deliver' ||
          stop.status == 'in_service'
      );

      // TODO: Move this logic to a selector.
      // const isActive = false;
      const isActive = activeVehicleIds.includes(vehicle.agent_id);

      const { occupied, transferred } = vehicle.passengers;

      const capacity = capacityFunctor.functor(vehicle.capacity);

      const routing_engine = vehicle.routing_engine || {};

      const routingEngine = routing_engine.routing_engine_name || 'osrme';
      const routingProfile =
        routing_engine.road_network ||
        defaultRoutingProfile(routing_engine.routing_engine_name);

      const isProcessing = false;

      const vehicleDisplayNames = displayNames(
        vehicle.display_name || vehicle.service_number || vehicle.agent_id
      );
      $D2.S.INFO('render:vehicleDisplayNames', { vehicleDisplayNames });

      const isAssignedToDriver = vehicle.driver;
      const { isIdle, isOnline } = getVehicleOnlineStatus(vehicle);

      const canAddStop = !isReadOnly && !isDeliveryLayout;
      const canAddPoint = !isReadOnly && !isDeliveryLayout;
      // const canEdit = !isReadOnly && canManageVehicles;
      // const canClone = simulation && canManageVehicles;
      // const canDelete = !isReadOnly && canManageVehicles;
      const canViewSource = !isDeliveryLayout;
      const hasPermissionsToChangeVehicles =
        !simulation || (simulation && canManageVehicles);
      const canEditVehicles =
        hasPermissionsToChangeVehicles && !isReadOnly && !isOperatedVehicle;

      const canAccessAdminInterface = isStaff || isSuperuser;

      const isMenuVisible =
        canAccessAdminInterface ||
        canAddStop ||
        canAddPoint ||
        canEditVehicles ||
        canViewSource;

      const isRemoveAllBookingsAvailable = vehicle?.$hasResult;
      const isVehicleReadOnly = vehicle?.$isReadOnly;

      const isDisplayHighestLoad =
        simulation?.data.logistics_api_settings
          .display_highest_load_for_each_vehicle ?? true;

      return (
        <Container
          id={vehicle.agent_id}
          ref={connectDropTarget}
          isOver={isOver}
          isActive={isActive}
        >
          <Header onClick={this.onClickHeader}>
            <Title color={vehicle.$activeColor} data-testid='Vehicle-Title'>
              <Caption>
                <div>
                  {vehicleDisplayNames.map((x, i) => (
                    <VehicleName key={`vehicle-name-${i}`}>{d(x)}</VehicleName>
                  ))}
                </div>
                <VehicleType>
                  {vehicle?.routing_engine?.vehicle_model ||
                    vehicle?.routing_engine_settings?.vehicle_model}
                </VehicleType>
              </Caption>
              {vehicle.isChanged && (
                <UpdatedBadge onClick={this.onShowVehicleUpdates} />
              )}
              {isAssignedToDriver && isIdle && <IdleBadge />}
              {isAssignedToDriver && isOnline && <OnlineBadge />}
              {isProcessing ? (
                <ProgressIndicator />
              ) : (
                isMenuVisible && (
                  <StatefulPopover
                    placement={PLACEMENT.left}
                    content={({ close }) => (
                      <MenuContentV2
                        isleft='true'
                        style={{
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
                          borderRadius: '4px',
                        }}
                      >
                        <MenuButton
                          data-testid='Edit Vehicle'
                          onClick={() => {
                            onEdit(vehicle);
                            close();
                          }}
                          disabled={!canEditVehicles || isVehicleReadOnly}
                        >
                          {t('p.Editor.Panels.Vehicles.Vehicle.Edit.Vehicle')}
                        </MenuButton>
                        <MenuButton
                          data-testid='Clone Vehicle'
                          onClick={() => {
                            onClone(vehicle);
                            close();
                          }}
                          $divider={true}
                          disabled={isReadOnly}
                        >
                          {t('p.Editor.Panels.Vehicles.Vehicle.Clone.Vehicle')}
                        </MenuButton>
                        {/* TODO: This button should be uncommented when the Remove all feature is available */}
                        {/* <MenuButton
                              onClick={onRemoveAllBookings}
                              disabled={!isRemoveAllBookingsAvailable}
                            >
                              {t('p.Editor.Panels.Vehicles.Vehicle.Remove.Order')}
                            </MenuButton> */}
                        <MenuButton
                          data-testid='Delete Vehicle'
                          onClick={() => {
                            onDelete();
                            close();
                          }}
                          disabled={!canEditVehicles || isVehicleReadOnly}
                        >
                          {t('p.Editor.Panels.Vehicles.Vehicle.Remove.Vehicle')}
                        </MenuButton>
                        {canAccessAdminInterface && vehicle.id && (
                          <MenuButton
                            data-testid='Go to Admin Veh settings'
                            onClick={() => {
                              this.onVehicleAdmin();
                              close();
                            }}
                          >
                            {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Admin')}
                          </MenuButton>
                        )}
                      </MenuContentV2>
                    )}
                  >
                    <MenuToggleButton
                      icon={menu}
                      iconHover={menu}
                      data-testid='Menu-Button'
                    />
                  </StatefulPopover>
                )
              )}
            </Title>
            <RouteData>
              <Value>
                {t(
                  'p.Editor.Panels.Vehicles.Vehicle.RouteData',
                  vehicle.$hasResult
                    ? {
                        numberOfStop: routeData
                          ? vehicle.route?.length || 0
                          : 0,
                        distance:
                          routeData?.routes && routeData.routes[0]?.distance
                            ? Math.round(routeData.routes[0].distance / 10) /
                              100
                            : 0,
                      }
                    : {
                        numberOfStop: 0,
                        distance: 0,
                      }
                )}
              </Value>
            </RouteData>
            {!window.GEODISC_UI_COMMUTE_OFFER_TRANSFERRED_DISABLE &&
              !global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO && (
                <div>
                  <Label>
                    {t('p.Editor.Panels.Vehicles.Vehicle.Transferred')}
                  </Label>
                  <Value>{d(transferred)}</Value>
                </div>
              )}
            {!global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO && (
              <div>
                <Label>{t('p.Editor.Panels.Vehicles.Vehicle.Occupied')}</Label>
                <Value>{d(`${occupied} / ${capacity}`)}</Value>
              </div>
            )}
            {global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO &&
              isDisplayHighestLoad && (
                <>
                  <div style={{ marginBottom: 2 }}>
                    <Value>
                      <img src={truckIcon} alt='.' style={{ marginRight: 5 }} />
                      {t('p.Editor.Panels.Vehicles.Vehicle.HighestLoad')}:
                    </Value>
                  </div>
                  {Object.entries($capacity_info)
                    .sort(([a], [b]) => a - b)
                    .map(([key, info]) => {
                      const hasRoute = !!vehicle.route.length;
                      // Note: for some vehicle, the capacity could be an object with maxLoad and demandType
                      // This could lead to displaying [object Object] instead of the real capacity.
                      const capacity =
                        typeof info?.capacity === 'object'
                          ? parseInt(info?.capacity?.maxLoad)
                          : parseInt(info?.capacity);
                      const hasActiveTransfers =
                        capacity > 0 && (info.occupied > 0 || !hasRoute);
                      return (
                        <div
                          key={`capacity-info-${key}`}
                          style={{ marginLeft: 22 }}
                          data-testid='Vehicle-Label-Capacity'
                        >
                          <Value
                            $isActive={hasActiveTransfers}
                            $isOverLoad={info.occupied > capacity}
                          >
                            <span>{info.occupied}</span>
                            {` / ${capacity}`}
                          </Value>
                          <Label
                            isActive={hasActiveTransfers}
                          >{`   ${key}`}</Label>
                        </div>
                      );
                    })}
                </>
              )}
          </Header>
          {/* <div>
            {editableBooking &&
              editableBooking[vehicle.agent_id] &&
              isActive && (
                <BookingStopsEditor nodes={editableBooking[vehicle.agent_id]} />
              )}
          </div> */}
          {isActive && (
            <React.Fragment>
              <Route
                vehicleId={vehicle.agent_id}
                isReadOnly={
                  vehicle.readOnly || vehicle.readonly || vehicle.isReadOnly
                }
                route={vehicle.$hasResult ? vehicle.route : []}
                setActiveRouteStop={setActiveRouteStop}
                activeRouteStopUid={activeRouteStopUid}
                color={vehicle.$activeColor}
                isHaveEditable={editableBooking}
                setIsDragging={setIsDragging}
                isOver={isOver}
                isActive={isActive}
              />
              <i>
                {pointEditing &&
                  t('p.Editor.Panels.Vehicles.PointEditor.SelectOnMap')}
              </i>
              {addStopMode === vehicle.agent_id && (
                <React.Fragment>
                  <Input
                    onChange={this.onSearchInputChange}
                    placeholder={t(
                      'p.Editor.Panels.Vehicles.PointEditor.ClickOnMap'
                    )}
                    value={stopSearchQuery || ''}
                  />
                  <SearchResult
                    stops={filteredStops}
                    addStopToRoute={addStopToRoute}
                    vehicleId={vehicle.agent_id}
                  />
                </React.Fragment>
              )}
              {addStopMode === vehicle.agent_id && (
                <ButtonsContainer>
                  <Button onClick={cleanAddStopMode}>
                    {t('p.Editor.Panels.Vehicles.StopEditor.Finish')}
                  </Button>
                </ButtonsContainer>
              )}
              {pointEditing && (
                <ButtonsContainer>
                  <Button onClick={cleanAddPointMode}>
                    {t('p.Editor.Panels.Vehicles.StopEditor.Finish')}
                  </Button>
                </ButtonsContainer>
              )}
            </React.Fragment>
          )}
        </Container>
      );
    });
  }
}

export default DropTarget(
  'booking',
  {
    drop: props => ({
      ...props.vehicle,
      isHaveEditable: props.editableBooking,
    }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(Vehicle);
