import React from 'utils/react';

import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, DarkTheme, BaseProvider, ThemeProvider } from 'baseui';
import { ToasterContainer } from 'baseui/toast';

import usePrevious from 'utils/react/usePrevious';

import Login from 'pages/Login';

import LoadingScreen from 'components/LoadingScreen';
import ModalWindows from 'components/ModalWindows';

import LocaleProvider from 'components/BaseUI/LocaleProvider';

import debug from 'utils/debug';
import MapDataset from '../MapDataset';
import MapSimulation from '../MapSimulation';
import MapGeofences from '../MapGeofences';

// const ErrorBoundary = React.Fragment;
import ErrorBoundary from 'components/ErrorBoundary';

import routes from './routes';
import { useEffect } from 'react';
import MasterSettings from 'components/MasterSettings';
import { LOGISTICS_ROUTE } from 'utils/constants';
import { locationUrl, parseLocationArgs } from 'utils/args';

const D2 = debug('c:App');

const renderRoute = ({
  path,
  exact,
  strict,
  component: Component,
  ...rest
}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    strict={strict}
    render={props => <Component {...props} {...rest} />}
  />
);

const engine = new Styletron();

const MyDarkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    accent: '#2F94FF',
    negative: '#D91E36',
    warning: '#F9C03A',
    positive: '#23BF9A',
    menuFill: 'rgb(43, 48, 58)',
    menuFillHover: 'rgb(54, 61, 74)',
    menuFontDefault: '#c5cede',
    menuFontHighlighted: '#ffffff',
    menuFontSelected: '#ffffff',
    buttonPrimaryFill: 'rgb(43, 48, 58)',
    buttonPrimaryHover: 'rgb(54, 61, 74)',
  },
};

const DarkThemeNew = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    accent: '#2F94FF',
    negative: '#D91E36',
    warning: '#F9C03A',
    positive: '#23BF9A',
    menuBackground: '#1C232D',
    menuFill: 'rgb(43, 48, 58)',
    menuFillHover: 'rgb(54, 61, 74)',
    menuFontDefault: '#c5cede',
    menuFontHighlighted: '#ffffff',
    menuFontSelected: '#ffffff',
    buttonPrimaryFill: 'rgb(43, 48, 58)',
    buttonPrimaryHover: 'rgb(54, 61, 74)',
    containerBackground: '#080D14',
    tableHeader: '#97A0C0',
    inputBorder: '#97A0C0',
    buttonBorder: '#1235b2',
    buttonBorderDisabled: '#424963',
    buttonTextDisabled: '#97A0C0',
    placeholder: '#97A0C0',
    assignedOrderPin: '#C3C7EF',
    unassignedOrderPin: '#F9C03A',
    modalBackground: '#97A0C070',
    scrollbarBackground: '#97A0C0',
    toastInfoBackground: '#080D14',
    toastNegativeBackground: '#D91E36',
  },
  typography: {
    ...DarkTheme.typography,
    montserrat: {
      fontFamily: 'Montserrat',
    },
    panelTitle: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
    },
    panelTitle500: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 500,
    },
    panelTitle700: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 700,
    },
    panelSmall500: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
};

const App = ({
  userName,
  authToken,
  isAuthorized,
  currentProject,
  currentProjectConfig,
  layoutName,
  defaultPage,
  authorization,
  setServiceDate,
}) => {
  const previousProject = usePrevious(currentProject);
  const previousLayoutName = usePrevious(layoutName);

  React.useEffect(() => {
    if (!userName && isAuthorized) {
      authorization(authToken, true);
    }
  }, [userName, isAuthorized, authToken, authorization]);

  React.useEffect(() => {
    D2.S.INFO('currentProject', {
      currentProject,
      layoutName,
      previousLayoutName,
    });
    if (
      previousProject &&
      currentProject &&
      previousLayoutName &&
      layoutName !== previousLayoutName &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/login'
    ) {
      D2.S.INFO('REDIRECT (disabled)', {
        address: defaultPage || '/',
      });
      // window.location.href = defaultPage || '/';
    }
  }, [
    currentProject,
    previousProject,
    layoutName,
    previousLayoutName,
    defaultPage,
  ]);

  // This is will open the master settings page if user refers the /template
  React.useEffect(() => {
    if (window.location.pathname === `${LOGISTICS_ROUTE}/template`) {
      global.openMasterSettings({ modalContent: <MasterSettings /> });
    }
    // Load service date from the url parameters
    const search = parseLocationArgs(locationUrl().query);
    if (search.get('simulation-date')) {
      setServiceDate(search.get('simulation-date'));
    }
  }, [window.location.pathname, setServiceDate]);

  return D2.S.FUNCTION(
    'App',
    {
      userName,
      authToken,
      isAuthorized,
      currentProject,
      previousLayoutName,
      layoutName,
      currentProjectConfig,
    },
    () => {
      const nextAddress = window.location.pathname + window.location.search;

      return (
        <StyletronProvider value={engine}>
          {/* TODO: I have to use zIndex 10 here to avoid
           overlapping between fullscreen modal
           and the header we have in our current app.
           We can remove this once we remove the old header menu */}
          <BaseProvider theme={DarkThemeNew} zIndex={10}>
            <LocaleProvider>
              <ErrorBoundary>
                {/*<ThemeProvider*/}
                {/*  theme={*/}
                {/*    window.GEODISC_NEW_UI_ENABLED ? DarkThemeNew : LightTheme*/}
                {/*  }*/}
                {/*>*/}
                {isAuthorized ? (
                  [
                    <Switch key='pages'>
                      <Route
                        key='MapDataset'
                        path={['/commuteoffer/:id']}
                        exact
                        render={e => (
                          <Redirect
                            to={`/map?commuteOffer=${e.match.params.id}`}
                          />
                        )}
                      />
                      {routes.map(renderRoute)}
                      <Route path='*' render={() => <Redirect to='/' />} />
                    </Switch>,
                    <Route
                      key='MapDataset'
                      path={['/dataset/:id']}
                      exact
                      component={MapDataset}
                    />,
                    <Route
                      key='MapSimulation'
                      path={['/simulation/:id']}
                      exact
                      component={MapSimulation}
                    />,
                    <Route
                      key='MapGeofences'
                      path='/geofences'
                      exact
                      component={MapGeofences}
                    />,
                  ]
                ) : (
                  <Switch>
                    <Route path='/login' component={Login} />
                    <Route
                      path='*'
                      render={() => (
                        <Redirect
                          to={`/login?next=${encodeURIComponent(nextAddress)}`}
                        />
                      )}
                    />
                  </Switch>
                )}

                <ModalWindows />
                {/*</ThemeProvider>*/}
                <ToasterContainer
                  placement='bottom'
                  overrides={{
                    Root: {
                      style: {
                        marginBottom: '72px',
                      },
                    },
                    ToastBody: {
                      style: ({ $theme }) => ({
                        boxShadow:
                          '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                        borderBottomLeftRadius: '4px',
                        borderBottomRightRadius: '4px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        width: 'auto',
                        minWidth: '360px',
                        maxWidth: '800px',
                        ...$theme.typography.panelTitle,
                      }),
                    },
                  }}
                />
                {!currentProject && <LoadingScreen />}
              </ErrorBoundary>
            </LocaleProvider>
          </BaseProvider>
        </StyletronProvider>
      );
    }
  );
};

export default App;

Route.propTypes = {
  computedMatch: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object,
};
