import React from 'utils/react';
import settings from 'config/settings';
import debug from 'utils/debug';
import moment from 'moment-timezone';

import ModalWindow from 'components/ModalWindow';
import Map from 'components/MapCommuteOfferEditor';
import Header from 'components/Header';
import LoadingScreen from 'pages/Loading';
import {
  commuteOfferIsReadOnlySelector,
  commuteOfferLoadedSimulationSelector,
} from 'modules/commuteOffer/selectors';
import Panels from './Panels';
import StopsButtons from './StopsButtons';
import RoutingButtons from './RoutingButtons';
import WalkingRoutingButtons from './WalkingRoutingButtons';
import TopButtons from './TopButtons';
import GeofencesMenu from './GeofencesMenu';
import TransitstopSetsMenu from './TransitstopSetsMenu';
import TransitstopTypesMenu from './TransitstopTypesMenu';
import MapStylesMenu from './MapStylesMenu';
import ErrorsReport from './ErrorsReport';

const D2 = debug('p:CommuteOffer');

global.GEODISC_LOADING_SCREEN_DISABLED = true;

class CommuteOffer extends React.PureComponent {
  state = {
    walkingRoutesSerialNumber: 0,
    drivingTimestampsSerialNumber: 0,
  };

  componentDidMount() {
    const { state, props } = this;
    D2.S.INFO('componentDidMount', { state, props });

    const { incPermissionsSerialNumber } = props;

    const startTs = moment().unix();

    // global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS = startTs;
    // global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS = startTs;
    global.GEODISC_INC_SERIAL_NUMBER_TS = startTs;

    if (!global.GEODISC_PERMISSIONS_MONITORING_DISABLED) {
      this.permissionsCheckerTimerId = setInterval(() => {
        incPermissionsSerialNumber();
      }, 5 * 1000);
    } else {
      // eslint-disable-next-line
      // console.log('=== Warning: Permissions monitoring is turned off.');
    }

    this.refreshTimerId = setInterval(
      () =>
        D2.S.FUNCTION('refreshTimer', { props: this.props }, ({ $D2 }) => {
          const now = window.performance.now();

          const { projectData } = props;

          const hasNeverBeenUpdated =
            typeof global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS ===
            'undefined';
          if (hasNeverBeenUpdated && projectData) {
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS =
              now - settings.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000;
            global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS =
              global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS - 1000;
          }

          const lastUpdateDuration =
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS &&
            global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS &&
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS -
              global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS;
          const canBeUpdated = lastUpdateDuration && lastUpdateDuration >= 0;

          if (canBeUpdated) {
            const timeDiff =
              now - global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS ?? now;
            $D2.S.INFO('timeDiff', { timeDiff, now });
            if (
              timeDiff >=
              settings.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000
            ) {
              this.props.fetchCommuteOffer(null, {
                isInitRequired: false,
                ...$D2.CONTEXT,
              });
            }
          }
        }),
      500
    );

    this.timerId = setInterval(() => {
      const { walkingRoutesSerialNumber, drivingTimestampsSerialNumber } =
        this.state;

      if (walkingRoutesSerialNumber !== global.WALKING_ROUTES_SN) {
        this.setState({
          walkingRoutesSerialNumber: global.WALKING_ROUTES_SN,
          drivingTimestampsSerialNumber,
        });
        this.props.incSerialNumber();
      }

      if (drivingTimestampsSerialNumber !== global.DRIVING_TIMESTAMPS_SN) {
        this.setState({
          walkingRoutesSerialNumber,
          drivingTimestampsSerialNumber: global.DRIVING_TIMESTAMPS_SN,
        });
        this.props.incSerialNumber();
      }
    }, 1000);

    // this.props.fetchCommuteOffer(id, { ...D2.CONTEXT });
    this.props.fetchStops();
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this;

    D2.S.INFO('componentDidUpdate', {
      state,
      prevProps,
      props,
    });

    // const { projectId, commuteOfferRequestUpdate } = props;

    // if (prevProps.projectId && prevProps.projectId !== projectId) {
    //   commuteOfferRequestUpdate();
    // }
  }

  componentWillUnmount() {
    D2.S.INFO('componentWillUnmount');

    if (this.permissionsCheckerTimerId) {
      clearInterval(this.permissionsCheckerTimerId);
    }

    if (this.refreshTimerId) {
      clearInterval(this.refreshTimerId);
    }

    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, () => {
      const { data, projectId, isDeliveryLayout } = this.props;

      if (!global.GEODISC_LOADING_SCREEN_DISABLED) {
        if (!data || !projectId) {
          return (
            <React.Fragment>
              <LoadingScreen />
              <ModalWindow />
            </React.Fragment>
          );
        }
      }

      const {
        $errors = [],
        $warnings = [],
        $fixes = [],
      } = data ? data.result : {};
      D2.S.INFO('render:$errors', { $errors });
      D2.S.INFO('render:$warnings', { $warnings });

      const hasErrors =
        ($errors && $errors.length > 0) ||
        ($warnings && $warnings.length > 0) ||
        ($fixes && $fixes.length > 0);

      if (hasErrors) {
        return (
          <ErrorsReport errors={$errors} warnings={$warnings} fixes={$fixes} />
        );
      }

      const isHeaderDisabled = !global.GEODISC_UI_COMMUTE_OFFER_HEADER_ENABLED;

      return (
        <React.Fragment>
          <Map />
          <Panels />
          <TopButtons />
          <MapStylesMenu />
          <GeofencesMenu />
          {!isDeliveryLayout && <StopsButtons />}
          {!isDeliveryLayout && <RoutingButtons />}
          {!isDeliveryLayout && <WalkingRoutingButtons />}
          {!isDeliveryLayout && <TransitstopSetsMenu />}
          {!isDeliveryLayout && <TransitstopTypesMenu />}
          {!isHeaderDisabled && (
            <Header
              hasBackground
              isShadow
              isProjectSelectionDisabled={!isDeliveryLayout}
              projectLabelTarget='/commuteoffers'
            />
          )}
          <ModalWindow />
        </React.Fragment>
      );
    });
  }
}

export default CommuteOffer;
