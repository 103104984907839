import React from 'utils/react';
import { useState } from 'react';
import useTranslation from 'utils/react/useTranslation';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { v4 as uuidv4 } from 'uuid';

import debug from 'utils/debug';
import FormField from 'components/Forms/FormField';
import { Divider } from 'components/Forms/FormStyles';
import CustomButton from 'components/Forms/Button';
import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useForm } from 'react-hook-form';
import Select from 'components/Forms/Select';
import { styled } from 'baseui';
import Accordion from 'components/Accordion';
import { defaultVehicleRoutingEngineJSON } from 'utils/CommuteOffer/defaults';
import formatForThisDate from 'utils/moment/formatForThisDate';
import TimePickerField from 'components/Forms/TimePickerField';
import moment from 'moment-timezone';
import regexValidation from 'utils/forms';
import { filteredGeofences, getReadableTime, vehicleModelsList } from './utils';
import { toaster } from 'baseui/toast';
import RadioField from 'components/Forms/RadioWithButtons';
import truckIcon from 'assets/white_truck.svg';
import characteristicsIcon from 'assets/characteristics.svg';
import labelIcon from 'assets/label.svg';

const D2 = debug('c:ModalWindows:VehicleEditorV2');
const FieldWrapper = styled('div', () => {
  return {
    marginTop: '45px',
  };
});

const FieldSection = styled('div', ({ $theme }) => {
  return {
    margin: '46px 0 16px 0',
    fontWeight: '600',
    fontSize: '16px',
    ...$theme.typography.montserrat,
  };
});

const DisplayText = styled('p', ({ $theme }) => {
  return {
    fontWeight: '500',
    fontSize: '12px',
    color: 'rgb(255 255 255 / 70%)',
    margin: '0 20px',
    overflowWrap: 'break-word',
    ...$theme.typography.montserrat,
  };
});

const DisplayTitle = styled('h3', ({ $theme, icon }) => {
  return {
    fontWeight: '500',
    fontSize: '12px',
    color: '#ffffff',
    marginBottom: '5px',
    ':before': {
      content: `url(${icon})`,
      width: '20px',
      height: '20px',
      display: 'inline-block',
    },
    ...$theme.typography.montserrat,
  };
});

const VehicleEditorV2 = (props) => {
  D2.S.INFO('VehicleEditorV2', props);
  const {
    geofences,
    vehicleTypes,
    simulation,
    vehicles,
    fleetAddVehicle,
    updateVehicle,
  } = props;
  const timezone = global.GEODISC_TIMEZONE;
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    reset,
    watch,
  } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues: {
      vehicleName: '',
      vehicleType: [],
      startTime: new Date(),
      endTime: new Date(),
      geofence: [],
      startPointLatitude: 0,
      startPointLongitude: 0,
      startPointName: '',
      endPointLatitude: 0,
      endPointLongitude: 0,
      endPointName: '',
    },
  });
  const values = getValues();

  const [vehicleId, setVehicleId] = useState(0);
  const [vehicleAgentId, setVehicleAgentId] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [accordionState, setAccordionState] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState({});

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState();

  const [css, theme] = useStyletron('');
  const watchOperationType = watch('operationType', 'single');

  const vehicleTypeOptions = [...vehicleModelsList(vehicleTypes)];

  useEffect(() => {
    window.openVehicleEditorV2 = (opts) => {
      const options = opts || {};
      const selectedVehicle = options?.data;
      const isEditVehicle = !!selectedVehicle && !options?.isClone;
      const isCloneVehicle = !!selectedVehicle && options?.isClone;
      setIsOpen(true);
      setSize(options?.size);
      setSelectedDate(moment(simulation?.start_time).tz(timezone).format());

      if (isEditVehicle) {
        setVehicleId(selectedVehicle?.id);
        setVehicleAgentId(selectedVehicle?.agent_id);
        setVehicleName(selectedVehicle?.name);
        const selectedGeofences = (selectedVehicle?.geofences || [])?.map(
          (key) => {
            return { value: key };
          }
        );
        const selectedType = vehicleTypeOptions.find(
          option => option.label === selectedVehicle?.typeLabel
        );
        const selectedTypes = selectedType ? [selectedType] : [];

        const startPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === 1
        );
        const endPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === -1
        );
        const isMultiDay =
          !selectedVehicle?.startTimeStamp || !selectedVehicle?.endTimeStamp;

        reset({
          vehicleName: selectedVehicle?.name,
          vehicleType: selectedTypes,
          startTime:
            getReadableTime({
              time: selectedVehicle?.startTimeStamp,
              timezone,
            }) || moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime:
            getReadableTime({
              time: selectedVehicle?.endTimeStamp,
              timezone,
            }) || moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
          geofence: selectedGeofences,
          startPointResourceUri: startPoint?.resource_uri,
          startPointLatitude: startPoint?.lat?.toString(),
          startPointLongitude: startPoint?.lon?.toString(),
          startPointName: startPoint?.location_name || '',
          endPointResourceUri: endPoint?.resource_uri,
          endPointLatitude: endPoint?.lat?.toString(),
          endPointLongitude: endPoint?.lon?.toString(),
          endPointName: endPoint?.location_name || '',
          operationType: isMultiDay ? 'multi' : 'single',
        });
      } else if (isCloneVehicle) {
        setVehicleId(0);
        setVehicleAgentId(uuidv4());
        const selectedGeofences = (selectedVehicle?.geofences || [])?.map(
          (key) => {
            return { value: key };
          }
        );

        const selectedType = vehicleTypeOptions.find(
          option => option.label === selectedVehicle?.typeLabel
        );
        const selectedTypes = selectedType ? [selectedType] : [];
        const startPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === 1
        );
        const endPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === -1
        );
        const isMultiDay =
          !selectedVehicle?.startTimeStamp || !selectedVehicle?.endTimeStamp;

        reset({
          vehicleType: selectedTypes,
          startTime:
            getReadableTime({
              time: selectedVehicle?.startTimeStamp,
              timezone,
            }) || moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime:
            getReadableTime({
              time: selectedVehicle?.endTimeStamp,
              timezone,
            }) || moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
          geofence: selectedGeofences,
          startPointLatitude: startPoint?.lat?.toString(),
          startPointLongitude: startPoint?.lon?.toString(),
          startPointName: startPoint?.location_name || '',
          endPointLatitude: endPoint?.lat?.toString(),
          endPointLongitude: endPoint?.lon?.toString(),
          endPointName: endPoint?.location_name || '',
          operationType: isMultiDay ? 'multi' : 'single',
        });
      } else {
        setVehicleId(0);
        setVehicleAgentId(uuidv4());
        reset({
          startTime: moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime: moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
        });
      }
    };
    window.closeVehicleEditorV2 = () => {
      setIsOpen(false);
      setAccordionState(false);
    };
  }, [t, simulation, reset, timezone]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (!value?.vehicleType?.[0]) {
        return;
      }

      setVehicleTypeData(
          typeof value?.vehicleType[0]?.value === 'string'
          ? JSON.parse(value?.vehicleType[0]?.value)
          : value?.vehicleType?.[0]
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function onClose({ closeSource }) {
    if (closeSource !== 'backdrop') {
      global.closeVehicleEditorV2();
    }
  }

  const onSubmit = async (formValues) => {
    const selectedVehicleModel =
      formValues?.vehicleType[0]?.value &&
      typeof formValues?.vehicleType[0]?.value === 'string'
        ? JSON.parse(formValues?.vehicleType[0]?.value)
        : {};

    const selectedVehicleAgent = {
      ...selectedVehicleModel,
      id: undefined,
    };

    const defaultVehicleRoutingEngine = {
      ...JSON.parse(defaultVehicleRoutingEngineJSON),
      routing_engine_name: 'osrme',
      road_network: 'alldriving',
    };
    const isMultiDay = formValues.operationType === 'multi';
    const vehicle = {
      lon: 0,
      lat: 0,
      speed: 0,
      ...(selectedVehicleAgent || {}),
      id: vehicleId,
      agent_id: vehicleAgentId,
      service_number: formValues?.vehicleName,
      labels: selectedVehicleModel?.vehicle_labels,
      start_time: isMultiDay
        ? null
        : formatForThisDate(
            selectedDate,
            `${formValues?.startTime.getHours()}:${formValues?.startTime.getMinutes()}`,
            timezone
          ),
      end_time: isMultiDay
        ? null
        : formatForThisDate(
            selectedDate,
            `${formValues?.endTime.getHours()}:${formValues?.endTime.getMinutes()}`,
            timezone
          ),
      geofence_ids: (formValues?.geofence || []).map(
        geofence => geofence.value
      ),
      routing_engine_settings: {
        ...(defaultVehicleRoutingEngine || {}),
        ...(selectedVehicleModel?.routing_engine_settings || {}),
        vehicle_model: selectedVehicleModel?.id,
      },
      efficiency: {},
    };

    const serviceNumbers = vehicles
      .filter(v => v.id !== vehicle.id)
      .map(vehicle => vehicle.service_number.toLowerCase());

    const normalizedServiceNumber = vehicle.service_number.trim().toLowerCase();

    if (serviceNumbers.includes(normalizedServiceNumber)) {
      toaster.negative(
        <>{t('p.fleet.vehicle.add.fail', { name: vehicle.service_number })}</>,
        {
          autoHideDuration: 4000,
          closeable: false,
        }
      );
      return;
    }

    const simulationStartTS = moment(simulation?.start_time)
      .tz(timezone)
      .format();
    const simulationEndTS = moment(simulation?.end_time).tz(timezone).format();
    // add MAX_SIMULATION_DURATION = 1 month to buffer for when simulation.end_time changed
    const maxSimulationEndTS = moment(simulation?.end_time)
      .tz(timezone)
      .add(1, 'month')
      .format();
    const fleetData = {
      data: { vehicles, sim_id: simulation?.id },
      values: vehicle,
    };
    if (formValues.startPointLatitude && formValues.startPointLongitude) {
      fleetData.startPoint = {
        resource_uri: formValues.startPointResourceUri,
        lat: Number(formValues.startPointLatitude) || 0,
        lon: Number(formValues.startPointLongitude) || 0,
        uid: uuidv4(),
        scheduled_ts: simulationStartTS,
        open_time_ts: simulationStartTS,
        close_time_ts: simulationEndTS,
        close_time_ts_dynamic: simulationEndTS,
        location_name:
          formValues.startPointName ||
          t('p.fleet.editor.advancedSettings.startPoint'),
      };
    } else if (formValues.startPointResourceUri) {
      fleetData.startPoint = {
        delete_resource_uri: formValues.startPointResourceUri,
      };
    }
    if (formValues.endPointLatitude && formValues.endPointLongitude) {
      fleetData.endPoint = {
        resource_uri: formValues.endPointResourceUri,
        lat: Number(formValues.endPointLatitude) || 0,
        lon: Number(formValues.endPointLongitude) || 0,
        uid: uuidv4(),
        scheduled_ts: simulationStartTS,
        open_time_ts: simulationStartTS,
        close_time_ts: maxSimulationEndTS,
        close_time_ts_dynamic: maxSimulationEndTS,
        location_name:
          formValues.endPointName ||
          t('p.fleet.editor.advancedSettings.endPoint'),
      };
    } else if (formValues.endPointResourceUri) {
      fleetData.endPoint = {
        delete_resource_uri: formValues.endPointResourceUri,
      };
    }
    if (vehicleId) {
      updateVehicle(fleetData);
    } else {
      fleetAddVehicle(fleetData);
    }

    global.closeVehicleEditorV2();
  };
  return (
    <>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 22,
            },
          },
          Dialog: {
            style: {
              backgroundColor: theme?.colors?.containerBackground,
            },
          },
          Close: {
            style: {
              color: theme?.colors?.white,
            },
          },
        }}
        size={size}
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat !important',
            fontSize: '24px',
            fontWeight: 700,
            color: `${theme?.colors?.white} !important`,
            marginTop: '10px !important',
          })}
        >
          {vehicleId
            ? t('p.fleet.editor.edit.title', { vehicleName: vehicleName })
            : t('p.fleet.editor.title')}
        </ModalHeader>
        <ModalBody
          className={css({
            fontFamily: 'Montserrat',
            color: `${theme?.colors?.white} !important`,
            fontSize: '14px',
            fontWeight: 500,
          })}
        >
          <div className='form-group' />
          <form onSubmit={handleSubmit(onSubmit)} id='add-vehicle-form'>
            <div className='form-group'>
              <div style={{ marginTop: '40px' }}>
                <FieldWrapper>
                  <FormField
                    type='string'
                    label={t('p.fleet.editor.vehicleName')}
                    name='vehicleName'
                    placeholder={t('p.fleet.editor.vehicleName')}
                    register={register('vehicleName', {
                      required: t('u.validation.required'),
                      maxLength: {
                        value: 50,
                        message: t('u.validation.maxLength', { count: '50' }),
                      },
                    })}
                    errors={errors.vehicleName}
                    width='288px'
                  />
                </FieldWrapper>
                <FieldWrapper data-testid='Vehicle Type'>
                  <Select
                    type='string'
                    label={t('p.fleet.editor.vehicleType')}
                    name='vehicleType'
                    errors={errors.vehicleType}
                    width='288px'
                    addLabel={t('p.fleet.editor.vehicleType.addLabel')}
                    options={vehicleTypeOptions}
                    control={control}
                    addAction={() => {
                      global.openVehicleTypeEditor();
                    }}
                    rules={{ required: t('u.validation.required') }}
                    labelKey='label'
                    valueKey='value'
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DisplayText style={{ marginLeft: 0, marginBottom: '18px' }}>
                    {t('p.fleet.editor.specifications.title', {
                      typeName: vehicleTypeData?.id,
                    })}
                  </DisplayText>
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: 'rgba(151, 160, 192, 0.04)',
                      border: '1px solid rgba(151, 160, 192, 0.3)',
                      borderRadius: '4px',
                      padding: '16px 20px',
                    }}
                  >
                    <div
                      style={{
                        width: '50%',
                      }}
                    >
                      <DisplayTitle icon={characteristicsIcon}>
                        {t(
                          'p.fleet.editor.specifications.characteristics.title'
                        )}
                      </DisplayTitle>
                      <DisplayText>
                        {vehicleTypeData?.characteristics?.height
                          ? t(
                              'p.fleet.editor.specifications.characteristics.height',
                              {
                                value: vehicleTypeData?.characteristics?.height,
                              }
                            )
                          : t('p.none')}
                      </DisplayText>
                      <DisplayText>
                        {vehicleTypeData?.characteristics?.weight
                          ? t(
                              'p.fleet.editor.specifications.characteristics.weight',
                              {
                                value: vehicleTypeData?.characteristics?.weight,
                              }
                            )
                          : t('p.none')}
                      </DisplayText>
                      <DisplayText>
                        {vehicleTypeData?.characteristics?.manpower
                          ? t(
                              'p.fleet.editor.specifications.characteristics.manpower',
                              {
                                value:
                                  vehicleTypeData?.characteristics?.manpower,
                              }
                            )
                          : t('p.none')}
                      </DisplayText>
                    </div>
                    <div
                      style={{
                        width: '50%',
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                        }}
                      >
                        <div>
                          <DisplayTitle icon={labelIcon}>
                            {t('p.fleet.editor.specifications.label.title')}
                          </DisplayTitle>
                          <DisplayText>
                            {vehicleTypeData.vehicle_labels?.length
                              ? vehicleTypeData.vehicle_labels.join(', ')
                              : t('p.none')}
                          </DisplayText>
                        </div>
                        <div>
                          <DisplayTitle icon={truckIcon}>
                            {t('p.fleet.editor.specifications.load.title')}
                          </DisplayTitle>
                          {Object.keys(vehicleTypeData?.capacity || {})?.map(
                            (key) => {
                              return (
                                <DisplayText
                                  key={key}
                                >{`${key} ${vehicleTypeData?.capacity?.[key]}`}</DisplayText>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </FieldWrapper>
                <FieldWrapper
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 0,
                  }}
                >
                  <div style={{ marginRight: 0 }}>
                    <FieldSection>
                      {t('p.fleet.editor.advancedSettings.operationType')}
                    </FieldSection>
                    <RadioField
                      color='white'
                      labelMargin='8px 14px'
                      name='operationType'
                      radioButtonSize='18px'
                      options={[
                        {
                          value: 'multi',
                          label: t(
                            'p.fleet.editor.advancedSettings.operationType.multi'
                          ),
                        },
                        {
                          value: 'single',
                          label: t(
                            'p.fleet.editor.advancedSettings.operationType.single'
                          ),
                        },
                      ]}
                      selected='single'
                      control={control}
                    />
                  </div>
                </FieldWrapper>
                {watchOperationType !== 'multi' && (
                  <FieldWrapper
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <div data-testid='Start Time' style={{ marginRight: 0 }}>
                      <TimePickerField
                        name='startTime'
                        style={{}}
                        label={t('p.fleet.editor.startTime')}
                        width='184px'
                        maxTime={values?.endTime}
                        errors={errors.startTime}
                        control={control}
                        rules={{}}
                      />
                    </div>
                    <div
                      data-testid='End Time'
                      style={{ marginRight: 'auto', marginLeft: '20px' }}
                    >
                      <TimePickerField
                        name='endTime'
                        style={{}}
                        label={t('p.fleet.editor.endTime')}
                        width='184px'
                        minTime={values?.startTime}
                        errors={errors.endTime}
                        control={control}
                      />
                    </div>
                  </FieldWrapper>
                )}

                <Divider style={{ marginTop: '45px' }} />

                <div>
                  <Accordion
                    title={t('p.fleet.editor.advancedSettings')}
                    accordionState={accordionState}
                    setAccordionState={setAccordionState}
                  >
                    <>
                      <FieldSection>
                        {t('p.fleet.editor.advancedSettings.startLocation')}
                      </FieldSection>
                      <FieldWrapper
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 0,
                        }}
                      >
                        <div style={{ marginRight: 0 }}>
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.latitude')}
                            name='startPointLatitude'
                            value={values?.startPointLatitude}
                            register={register('startPointLatitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('startPointLongitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.latitudeRegex,
                                message: t('u.validation.isLatitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.startPointLatitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          style={{ marginRight: 'auto', marginLeft: '20px' }}
                        >
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.longitude')}
                            name='startPointLongitude'
                            value={values?.startPointLongitude}
                            register={register('startPointLongitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('startPointLatitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.longitudeRegex,
                                message: t('u.validation.isLongitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.startPointLongitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          style={{ marginRight: 'auto', marginLeft: '20px' }}
                        >
                          <FormField
                            type='string'
                            label={t(
                              'p.fleet.editor.advancedSettings.startPointName'
                            )}
                            name='startPointName'
                            placeholder={t(
                              'p.fleet.editor.advancedSettings.startPointName'
                            )}
                            register={register('startPointName', {
                              maxLength: {
                                value: 50,
                                message: t('u.validation.maxLength', {
                                  count: '50',
                                }),
                              },
                            })}
                            errors={errors.startPointName}
                            width='184px'
                          />
                        </div>
                      </FieldWrapper>
                      <FieldSection>
                        {t('p.fleet.editor.advancedSettings.endLocation')}
                      </FieldSection>
                      <FieldWrapper
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 0,
                        }}
                      >
                        <div style={{ marginRight: 0 }}>
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.latitude')}
                            name='endPointLatitude'
                            value={values?.endPointLatitude}
                            register={register('endPointLatitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('endPointLongitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.latitudeRegex,
                                message: t('u.validation.isLatitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.endPointLatitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          style={{ marginRight: 'auto', marginLeft: '20px' }}
                        >
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.longitude')}
                            name='endPointLongitude'
                            value={values?.endPointLongitude}
                            register={register('endPointLongitude', {
                              validate: {
                                required: (value) => {
                                  if (!value && getValues('endPointLatitude')) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.longitudeRegex,
                                message: t('u.validation.isLongitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.endPointLongitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          style={{ marginRight: 'auto', marginLeft: '20px' }}
                        >
                          <FormField
                            type='string'
                            label={t(
                              'p.fleet.editor.advancedSettings.endPointName'
                            )}
                            name='endPointName'
                            placeholder={t(
                              'p.fleet.editor.advancedSettings.endPointName'
                            )}
                            register={register('endPointName', {
                              maxLength: {
                                value: 50,
                                message: t('u.validation.maxLength', {
                                  count: '50',
                                }),
                              },
                            })}
                            errors={errors.endPointName}
                            width='184px'
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <Select
                          type='string'
                          label={t('p.fleet.editor.geofence')}
                          name='geofence'
                          control={control}
                          value={values?.geofence}
                          register={register('geofence')}
                          errors={errors.geofence}
                          width='288px'
                          labelKey='label'
                          valueKey='value'
                          placeholder={t('p.fleet.editor.geofence.placeholder')}
                          multi={true}
                          options={[...(filteredGeofences(geofences) || [])]}
                        />
                      </FieldWrapper>
                    </>
                  </Accordion>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {[
            {
              text: t('c.messages.Cancel'),
              fill: false,
              action: () => {
                global.closeVehicleEditorV2();
              },
            },
            {
              text: t('c.messages.Save'),
              type: 'submit',
              formId: 'add-vehicle-form',
              fill: true,
            },
          ]?.map((data, i) => {
            return (
              <CustomButton
                key={i}
                type={data?.type}
                form={data?.formId}
                $filled={data?.fill}
                onClick={data?.action}
              >
                {data?.text}
              </CustomButton>
            );
          })}
        </ModalFooter>
      </Modal>
    </>
  );
};
window.openVehicleEditorV2 = () => {};
window.closeVehicleEditorV2 = () => {};

export default VehicleEditorV2;
