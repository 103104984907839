import React, { useState, useEffect } from 'react';
import GradientBackground from './GradientBackground';
import CustomButton from 'components/Forms/Button';
import MenuButton from '../MenuButton';
import Menu from 'components/Menu';
import menu from 'components/Menu/new-menu.svg';
import { commuteOffersIsTemplateDataSourceSelector } from 'modules/ui/selectors';
import debug from 'utils/debug';
import { useStyletron } from 'baseui';
import { optimizeLogisticsOrders } from 'api/logistics';
import {
  waitSimulationProcessor,
  waitSimulationUncalculatedBookings,
} from 'api/simulations';
import { sleep } from 'utils/async';
import { StyledSpinnerNext } from 'baseui/spinner';
import LoadingWithSpinner from 'components/LoadingWithSpinner';

const D2 = debug('p:Logistics:Panels:TopButtons');

const SaveBottom = (props) => {
  D2.S.INFO('SaveBottom', { props });
  const [isActive, setActiveState] = useState('false');
  const [generatingRoute, setGeneratingRoute] = useState(false);
  const [css, theme] = useStyletron();
  const {
    t,
    bookings,
    history,
    isSaving,
    data,
    originalData,
    updateCommuteOffer,
    isReadOnly,
    canBeSaved,
    simulation,
    fetchCommuteOffer,
    hasOnlineVehicles,
  } = props;
  const isDataValid = !!data;

  useEffect(() => {
    setActiveState(
      !(
        isReadOnly ||
        !canBeSaved ||
        (!window.GEODISC_UI_COE_SAVE_BUTTON_ALWAYS_ACTIVE &&
          !history?.past?.size)
      )
    );
  }, [history]);

  useEffect(() => {
    //Show browser warning when user leave/reload the page without save changes
    if (isActive) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = '';
        return '';
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [isActive]);

  const addVehicle = () => {
    global.openVehicleEditorV2({ size: '648px' });
  };

  const onSave = () =>
    D2.S.FUNCTION('onSave', { data, id: data.id, isDataValid }, () => {
      if ((isDataValid && data.id) || data.$source?.simulation) {
        global.openProgressWindow(t('c.ProgressWindow.Validating'), {
          title: t('info'),
        });

        setTimeout(() => {
          updateCommuteOffer(data.id, data, originalData, true, t);
        }, 0);
      } else if (!isDataValid) {
        global.openInfoMessage(t('p.Editor.CommuteOffer.CannotBeSaved'), {
          title: t('error'),
        });
      } else {
        // doSaveAs();
      }
    });

  const handleSave = () => {
    if (hasOnlineVehicles) {
      global.openWarningMessage({
        title: t('c.Save.publish.modal.title'),
        message: `${t('c.Save.publish.modal.message')}
            <br />
            <br />
            <b>${t('c.Save.publish.modal.warning')}</b>`,
        buttons: [
          {
            text: t('c.messages.Cancel'),
            fill: false,
          },
          {
            text: t('c.Save.publish.modal.button.publish'),
            action: () => {
              global.closeWarningMessage();
              onSave();
            },
            fill: true,
          },
        ],
      });
    } else {
      onSave();
    }
  };

  const hasUnassignedBookings = bookings.find(
    booking => !booking?.$assignedVehicle || !booking?.assigned_vehicle_id
  );

  const handleAutoAssignOrders = async () => {
    try {
      setGeneratingRoute(true);
      global.closeWarningMessage();

      const result = await optimizeLogisticsOrders(
        simulation.id,
        bookings.map(o => o.uid),
        { originalOffer: data }
      );

      await waitSimulationProcessor(result.response?.processor_id);
      await waitSimulationUncalculatedBookings(simulation.id);
      await sleep(5000);
      fetchCommuteOffer(null, {
        isInitRequired: false,
        ...D2.CONTEXT,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setGeneratingRoute(false);
    }
  };

  return (
    <>
      <GradientBackground>
        <CustomButton
          style={{
            width: '100%',
            margin: '0 10px 0 0',
          }}
          disabled={!isActive}
          $filled
          onClick={handleSave}
          size='sm'
        >
          {t('c.Routes.panel.footer.menu.save')}
        </CustomButton>
        <Menu
          icon={menu}
          position='right'
          type='logistics'
          style={{ marginLeft: 'auto' }}
        >
          <MenuButton disabled={isReadOnly} onClick={addVehicle}>
            {t('p.fleet.table.add.vehicle')}
          </MenuButton>
          <MenuButton
            // $divider={true}
            disabled={!hasUnassignedBookings || hasOnlineVehicles}
            onClick={() => {
              global.openWarningMessage({
                title: t('c.ModalWindow.AutoAssignment.modal.title'),
                message: (
                  <>
                    {t('c.ModalWindow.AutoAssignment.modal.message')}
                    <br />
                    <br />
                    <b>{t('c.ModalWindow.AutoAssignment.modal.warning')}</b>
                  </>
                ),
                buttons: [
                  {
                    text: t('c.messages.Cancel'),
                    fill: false,
                  },
                  {
                    text: t('c.ModalWindow.AutoAssignment.modal.button'),
                    action: handleAutoAssignOrders,
                    fill: true,
                  },
                ],
              });
            }}
          >
            {t('c.Orders.panel.footer.menu.optimise')}
          </MenuButton>
          {/* <MenuButton disabled={vehicles.length === 0}>
          {t('p.fleet.table.remove.all')}
        </MenuButton> */}
          {/* 
        Note: Reset to default may contains bulk removal feature 
        and this feature is not ready yet. So when it is ready
        uncomment this code.
         */}
          {/* <MenuButton
          onClick={() => {
            global.openWarningMessage({
              title: t('p.fleet.table.reset.default'),
              message: t('c.ModalNew.Reset.Default'),
              buttons: [
                {
                  text: t('c.messages.ea4788705e6873b424c65e91c2846b19'),
                  fill: false,
                },
                {
                  text: t('c.ModalNew.Reset.Default.yes'),
                  action: () => {
                    global.closeWarningMessage();
                  },
                  fill: true,
                },
              ],
            });
          }}
        >
          {t('p.fleet.table.reset.default')}
        </MenuButton> */}
        </Menu>
      </GradientBackground>
      {generatingRoute && (
        <LoadingWithSpinner
          message={generatingRoute && t('p.Orders.generatingRoutes')}
        />
      )}
    </>
  );
};

export default SaveBottom;
