import { currentProjectSelector } from 'modules/user/selectors';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { addVehicleType, updateVehicleType } from 'modules/fleet/actions';
import VehicleTypeEditor from './VehicleTypeEditor';
import { vehicleTypesSelector } from 'modules/fleet/selectors';

const mapStateToProps = createImmutableSelector(
  currentProjectSelector,
  vehicleTypesSelector,
  (projectData, vehicleTypes) => ({
    projectData,
    vehicleTypes,
  })
);

const mapDispatchToProps = { addVehicleType, updateVehicleType };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VehicleTypeEditor);
