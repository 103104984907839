import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { openPopup, closePopup } from 'modules/ui/actions';
import {
  undoCommuteOffer,
  redoCommuteOffer,
  updateCommuteOffer,
  commuteOfferStatelessNodeScheduler,
  clearCommuteOffer,
  commuteOfferRequestUpdate,
  enableForceReload,
  invalidateAllBookings,
} from 'modules/commuteOffer/actions';

import {
  commuteOfferIsReadOnlySelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferHistorySelector,
  filteredVehiclesSelector,
  filteredVehicleIdsSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsFilteringEnabledSelector,
  commuteOfferCanBeSavedSelector,
  commuteOfferHasOnlineVehiclesSelector,
  availableBookingsSelector,
} from 'modules/commuteOffer/selectors';

import {
  currentProjectSelector,
  currentProjectConfigSelector,
  projectsSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  isCurrentDateEditableSelector,
  isSuperuserSelector,
} from 'modules/user/selectors';
import {
  isSavingSelector,
  isBookingsHiddenSelector,
  activeVehicleIdsSelector,
  commuteOffersIsTemplateDataSourceSelector,
} from 'modules/ui/selectors';

import { changeCurrentProject } from 'modules/user/actions';

import TopButtons from './TopButtons';

const mapStateToProps = createSelector(
  commuteOfferIsReadOnlySelector,
  currentProjectSelector,
  currentProjectConfigSelector,
  projectsSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferHistorySelector,
  isSavingSelector,
  isBookingsHiddenSelector,
  filteredVehicleIdsSelector,
  activeVehicleIdsSelector,
  commuteOfferLoadedSimulationSelector,
  currentProjectLayoutNameSelector,
  filteredVehiclesSelector,
  isDeliveryLayoutSelector,
  commuteOfferIsFilteringEnabledSelector,
  isCurrentDateEditableSelector,
  isSuperuserSelector,
  commuteOffersIsTemplateDataSourceSelector,
  commuteOfferCanBeSavedSelector,
  commuteOfferHasOnlineVehiclesSelector,
  availableBookingsSelector,
  (
    isReadOnly,
    currentProject,
    currentProjectConfig,
    projects,
    data,
    originalData,
    history,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds,
    simulation,
    layoutName,
    routes,
    isDeliveryLayout,
    isFilteringEnabled,
    isCurrentDateEditable,
    isSuperuser,
    isTemplate,
    canBeSaved,
    hasOnlineVehicles,
    unfilteredOrders
  ) => ({
    isReadOnly,
    currentProject,
    currentProjectConfig,
    projects,
    data,
    originalData,
    history,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds,
    simulation,
    layoutName,
    routes,
    isDeliveryLayout,
    isFilteringEnabled,
    isCurrentDateEditable,
    isSuperuser,
    isTemplate,
    canBeSaved,
    hasOnlineVehicles,
    unfilteredOrders,
  })
);

const mapDispatchToProps = {
  back: undoCommuteOffer,
  next: redoCommuteOffer,
  updateCommuteOffer,
  changeCurrentProject,
  commuteOfferStatelessNodeScheduler,
  openPopup,
  closePopup,
  clearCommuteOffer,
  commuteOfferRequestUpdate,
  enableForceReload,
  invalidateAllBookings,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(TopButtons));
