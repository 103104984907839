import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOffersServiceDateSelector,
} from 'modules/ui/selectors';
import { setFleetData, setVehicleTypes } from 'modules/fleet/actions';
import { commuteOfferRequestUpdate } from 'modules/commuteOffer/actions';

import { toggleBookingsHidden, setServiceDate } from 'modules/ui/actions';

import Footer from './Footer';
import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';
import { currentProjectSelector } from 'modules/user/selectors';
import { routePageAddressSelector } from 'modules/router/selectors';

const mapStateToProps = createImmutableSelector(
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOffersServiceDateSelector,
  commuteOfferCurrentDataSelector,
  currentProjectSelector,
  routePageAddressSelector,
  (
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    commuteOfferCurrentData,
    projectData,
    pageAddress
  ) => ({
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    commuteOfferCurrentData,
    projectData,
    pageAddress,
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden,
  setServiceDate,
  commuteOfferRequestUpdate,
  setFleetData,
  setVehicleTypes,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Footer));
