import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';

import debug from 'utils/debug';
import CustomButton from 'components/Forms/Button';
import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import theme from 'components/TimeSlider/Slider/theme';

const D2 = debug('c:ModalWindows:WarningMessage');

const WarningMessage = (props) => {
  D2.S.INFO('WarningMessage', props);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState(
    t('c.messages.4059b0251f66a18cb56f544728796875')
  );
  const [message, setMessage] = React.useState('');
  const [buttons, setButtons] = React.useState([]);
  const [size, setSize] = React.useState();
  const [css, theme] = useStyletron('');

  useEffect(() => {
    window.openWarningMessage = (opts) => {
      const options = opts || {};
      setMessage(options?.message);
      setTitle(
        options.title || t('c.messages.4059b0251f66a18cb56f544728796875')
      );
      setIsOpen(true);
      setButtons(options?.buttons);
      setSize(options?.size);
    };
    window.closeWarningMessage = () => {
      setIsOpen(false);
    };
  }, [t]);

  function onClose() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 22,
            },
          },
          Dialog: {
            style: {
              backgroundColor: theme.colors.containerBackground,
            },
          },
          DialogContainer: {
            style: {
              backgroundColor: theme.colors.modalBackground,
            },
          },
          Close: {
            style: {
              marginTop: '5px',
              marginRight: '5px',
            },
          },
        }}
        size={size}
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat !important',
            fontSize: '24px',
            fontWeight: '700 !important',
            color: '#fff',
            marginTop: '20px !important',
          })}
        >
          {title}
        </ModalHeader>
        <ModalBody
          className={css({
            fontFamily: 'Montserrat !important',
            color: '#fff !important',
            fontSize: '14px',
            fontWeight: 500,
          })}
        >
          {typeof message === 'string' ? (
            <span dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            <span>{message}</span>
          )}
        </ModalBody>
        <ModalFooter>
          {buttons?.map((data, i) => {
            return (
              <CustomButton
                key={i}
                $filled={data?.fill}
                onClick={data?.action || onClose}
              >
                {data?.text}
              </CustomButton>
            );
          })}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
window.openWarningMessage = () => {};
window.closeWarningMessage = () => {};

export default WarningMessage;
