export const INC_SERIAL_NUMBER = '@@commuteOffer/INC_SERIAL_NUMBER';
export const incSerialNumber = opts => ({
  type: INC_SERIAL_NUMBER,
  payload: opts,
});

export const ENABLE_FORCE_RELOAD = '@@commuteOffer/ENABLE_FORCE_RELOAD';
export const enableForceReload = () => ({
  type: ENABLE_FORCE_RELOAD,
});

export const ENABLE_FORCE_UPDATE = '@@commuteOffer/ENABLE_FORCE_UPDATE';
export const commuteOfferRequestUpdate = () => ({
  type: ENABLE_FORCE_UPDATE,
});

export const INC_PERMISSIONS_SERIAL_NUMBER =
  '@@commuteOffer/INC_PERMISSIONS_SERIAL_NUMBER';
export const incPermissionsSerialNumber = opts => ({
  type: INC_PERMISSIONS_SERIAL_NUMBER,
  payload: opts,
});

export const COMMUTE_OFFER_UNDO = '@@commuteOffer/COMMUTE_OFFER_UNDO';
export const undoCommuteOffer = () => ({
  type: COMMUTE_OFFER_UNDO,
});

export const COMMUTE_OFFER_REDO = '@@commuteOffer/COMMUTE_OFFER_REDO';
export const redoCommuteOffer = () => ({
  type: COMMUTE_OFFER_REDO,
});

export const COMMUTE_OFFER_FETCH_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_REQUEST';
export const COMMUTE_OFFER_FETCH_RESULTS =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_RESULTS';
export const COMMUTE_OFFER_FETCH_IGNORED =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_IGNORED';
export const fetchCommuteOffer = (id, options = {}) => ({
  type: COMMUTE_OFFER_FETCH_REQUEST,
  payload: { id, ...options },
});

export const COMMUTE_OFFERS_FETCH_REQUEST =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_REQUEST';
export const COMMUTE_OFFERS_FETCH_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_SUCCESS';
export const COMMUTE_OFFERS_FETCH_FAILURE =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_FAILURE';
export const fetchCommuteOffers = () => ({
  type: COMMUTE_OFFERS_FETCH_REQUEST,
});

export const UPDATE_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_REQUEST';
export const UPDATE_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_SUCCESS';
export const UPDATE_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_FAILURE';
export const updateCommuteOffer = (id, data, originalData, showModals, t) => ({
  type: UPDATE_COMMUTE_OFFER_REQUEST,
  payload: { id, data, originalData, showModals, t },
});

export const INVALIDATE_ALL_BOOKINGS_REQUEST =
  '@@commuteOffer/INVALIDATE_ALL_BOOKINGS_REQUEST';
export const INVALIDATE_ALL_BOOKINGS_RESULTS =
  '@@commuteOffer/INVALIDATE_ALL_BOOKINGS_RESULTS';
export const invalidateAllBookings = originalData => ({
  type: INVALIDATE_ALL_BOOKINGS_REQUEST,
  payload: { originalData },
});

export const DELETE_COMMUTE_OFFER_REQUEST =
  '@@simulations/DELETE_COMMUTE_OFFER_REQUEST';
export const DELETE_COMMUTE_OFFER_SUCCESS =
  '@@simulations/DELETE_COMMUTE_OFFER_SUCCESS';
export const DELETE_COMMUTE_OFFER_FAILURE =
  '@@simulations/DELETE_COMMUTE_OFFER_FAILURE';
export const deleteCommuteOffer = id => ({
  type: DELETE_COMMUTE_OFFER_REQUEST,
  payload: id,
});

export const ROUTE_FETCH_REQUEST = '@@commuteOffer/ROUTE_FETCH_REQUEST';
export const ROUTE_FETCH_SUCCESS = '@@commuteOffer/ROUTE_FETCH_SUCCESS';
export const ROUTE_FETCH_FAILURE = '@@commuteOffer/ROUTE_FETCH_FAILURE';
export const ROUTE_FETCH_RESULTS = '@@commuteOffer/ROUTE_FETCH_RESULTS';
export const fetchRoute = (vehicle, routingEngine) => ({
  type: ROUTE_FETCH_REQUEST,
  payload: { vehicle, routingEngine },
});

export const FETCH_ALL_ROUTES_REQUEST =
  '@@commuteOffer/FETCH_ALL_ROUTES_REQUEST';
export const FETCH_ALL_ROUTES_SUCCESS =
  '@@commuteOffer/FETCH_ALL_ROUTES_SUCCESS';
export const FETCH_ALL_ROUTES_FAILURE =
  '@@commuteOffer/FETCH_ALL_ROUTES_FAILURE';
export const fetchAllRoutes = () => ({
  type: ROUTE_FETCH_REQUEST,
});

export const SET_RESULT_OFFER_REQUEST =
  '@@commuteOffer/SET_RESULT_OFFER_REQUEST';
export const SET_RESULT_OFFER_RESULTS =
  '@@commuteOffer/SET_RESULT_OFFER_RESULTS';
export const SET_RESULT_OFFER_SUCCESS =
  '@@commuteOffer/SET_RESULT_OFFER_SUCCESS';
export const SET_RESULT_OFFER_FAILURE =
  '@@commuteOffer/SET_RESULT_OFFER_FAILURE';
export const setResultOffer = (commuteOffer, mode) => {
  return {
    type: SET_RESULT_OFFER_REQUEST,
    payload: { commuteOffer, mode },
  };
};

export const SET_RESULT_VEHICLES = '@@commuteOffer/SET_RESULT_VEHICLES';
export const setResultVehicles = (vehicleId, nodes) => ({
  type: SET_RESULT_VEHICLES,
  payload: { vehicleId, nodes },
});

export const SET_VEHICLE_START_TIME = '@@commuteOffer/SET_VEHICLE_START_TIME';
export const setVehicleStartTime = (vehicleId, time) => ({
  type: SET_VEHICLE_START_TIME,
  payload: { vehicleId, time },
});

export const SET_VEHICLE_END_TIME = '@@commuteOffer/SET_VEHICLE_END_TIME';
export const setVehicleEndTime = (vehicleId, time) => ({
  type: SET_VEHICLE_END_TIME,
  payload: { vehicleId, time },
});

export const RECALCULATE_VEHICLE_TIME =
  '@@commuteOffer/RECALCULATE_VEHICLE_TIME';
export const recalculateVehicleTime = (vehicleIds, mode) => ({
  type: RECALCULATE_VEHICLE_TIME,
  payload: { vehicleIds, mode },
});

export const CLEAR_COMMUTE_OFFER = '@@commuteOffer/CLEAR_COMMUTE_OFFER';
export const clearCommuteOffer = () => ({ type: CLEAR_COMMUTE_OFFER });

export const RECALCULATE_ROUTE_REQUEST =
  '@@commuteOffer/RECALCULATE_ROUTE_REQUEST';
export const RECALCULATE_ROUTE_SUCCESS =
  '@@commuteOffer/RECALCULATE_ROUTE_SUCCESS';
export const RECALCULATE_ROUTE_FAILURE =
  '@@commuteOffer/RECALCULATE_ROUTE_FAILURE';
export const recalculateRoute = vehicleId => ({
  type: RECALCULATE_ROUTE_REQUEST,
  payload: { vehicleId },
});

export const REMOVE_BOOKING_FROM_ROUTE =
  '@@commuteOffer/REMOVE_BOOKING_FROM_ROUTE';
export const removeBookingFromRoute = bookingId => ({
  type: REMOVE_BOOKING_FROM_ROUTE,
  payload: { bookingId },
});

export const REMOVE_ORDER_REQUEST = '@@commuteOffer/REMOVE_ORDER_REQUEST';
export const REMOVE_ORDER_SUCCESS = '@@commuteOffer/REMOVE_ORDER_SUCCESS';
export const REMOVE_ORDER_FAILURE = '@@commuteOffer/REMOVE_ORDER_FAILURE';
export const removeOrder = data => ({
  type: REMOVE_ORDER_REQUEST,
  payload: data,
});

export const ADD_BOOKING_TO_ROUTE_REQUEST =
  '@@commuteOffer/ADD_BOOKING_TO_ROUTE_REQUEST';
export const ADD_BOOKING_TO_ROUTE_SUCCESS =
  '@@commuteOffer/ADD_BOOKING_TO_ROUTE_SUCCESS';
export const ADD_BOOKING_TO_ROUTE_FAILURE =
  '@@commuteOffer/ADD_BOOKING_TO_ROUTE_FAILURE';
export const addBookingToRoute = (bookingId, vehicleId) => ({
  type: ADD_BOOKING_TO_ROUTE_REQUEST,
  payload: { bookingId, vehicleId },
});

export const ADD_POINT_TO_ROUTE = '@@commuteOffer/ADD_POINT_TO_ROUTE';
export const addPointToRoute = (lon, lat, vehicleId) => ({
  type: ADD_POINT_TO_ROUTE,
  payload: { lon, lat, vehicleId },
});

export const SET_POINT_TO_ROUTE = '@@commuteOffer/SET_POINT_TO_ROUTE';
export const setPointToRoute = (lon, lat, vehicleId, route, position) => ({
  type: SET_POINT_TO_ROUTE,
  payload: { lon, lat, vehicleId, route, position },
});

export const ADD_STOP_TO_ROUTE = '@@commuteOffer/ADD_STOP_TO_ROUTE';
export const addStopToRoute = stopId => ({
  type: ADD_STOP_TO_ROUTE,
  payload: stopId,
});

export const SET_STOP_TO_ROUTE = '@@commuteOffer/SET_STOP_TO_ROUTE';
export const setStopToRoute = (feature, vehicleId, route) => ({
  type: SET_STOP_TO_ROUTE,
  payload: { feature, vehicleId, route },
});

export const CHANGE_ROUTE_POINT = '@@commuteOffer/CHANGE_ROUTE_POINT';
export const changeRoutePoint = (lon, lat, id, vehicleId) => ({
  type: CHANGE_ROUTE_POINT,
  payload: { lon, lat, id, vehicleId },
});

export const DELETE_ROUTE_POINT = '@@commuteOffer/DELETE_ROUTE_POINT';
export const deleteRoutePoint = (id, vehicleId) => ({
  type: DELETE_ROUTE_POINT,
  payload: { id, vehicleId },
});

export const SET_BOOKING_NODE = '@@commuteOffer/SET_BOOKING_NODE';
export const setBookingNode = nodes => ({
  type: SET_BOOKING_NODE,
  payload: nodes,
});

export const REMOVE_BOOKING_NODE = '@@commuteOffer/REMOVE_BOOKING_NODE';
export const removeBookingNode = (bookingId, nodeType) => ({
  type: REMOVE_BOOKING_NODE,
  payload: { bookingId, nodeType },
});

export const REMOVE_ALL_BOOKING = '@@commuteOffer/REMOVE_ALL_BOOKING';
export const removeAllBookings = () => ({
  type: REMOVE_ALL_BOOKING,
});

export const CHANGE_ROUTE_ORDER = '@@commuteOffer/CHANGE_ROUTE_ORDER';
export const changeRouteOrder = (
  currentIndex,
  newIndex,
  route,
  vehicleId,
  changeType
) => ({
  type: CHANGE_ROUTE_ORDER,
  payload: { currentIndex, newIndex, route, vehicleId, changeType },
});

export const CHANGE_ROUTE_STOP = '@@commuteOffer/CHANGE_ROUTE_STOP';
export const changeRouteStop = stopId => ({
  type: CHANGE_ROUTE_STOP,
  payload: stopId,
});

export const CHANGE_ROUTE_EMPTY_STOP = '@@commuteOffer/CHANGE_ROUTE_EMPTY_STOP';
export const changeRouteEmptyStop = (id, stop, vehicleId) => ({
  type: CHANGE_ROUTE_EMPTY_STOP,
  payload: { id, stop, vehicleId },
});

export const UPDATE_VEHICLE = '@@commuteOffer/UPDATE_VEHICLE';
export const updateVehicle = (vehicleId, vehicle) => ({
  type: UPDATE_VEHICLE,
  payload: { vehicleId, vehicle },
});

export const NEW_VEHICLE = '@@commuteOffer/NEW_VEHICLE';
export const newVehicle = () => ({
  type: NEW_VEHICLE,
});

export const CLONE_VEHICLE = '@@commuteOffer/CLONE_VEHICLE';
export const clonedVehicle = vehicle => ({
  type: CLONE_VEHICLE,
  payload: vehicle,
});

export const DELETE_VEHICLE = '@@commuteOffer/DELETE_VEHICLE';
export const deleteVehicle = vehicleId => ({
  type: DELETE_VEHICLE,
  payload: vehicleId,
});

export const ADD_NODE = '@@commuteOffer/ADD_NODE';
export const addNode = newNodes => ({
  type: ADD_NODE,
  payload: newNodes,
});

export const ADD_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/ADD_COMMUTE_OFFER_REQUEST';
export const ADD_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/ADD_COMMUTE_OFFER_SUCCESS';
export const ADD_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/ADD_COMMUTE_OFFER_FAILURE';
export const addCommuteOffer = (params, data) => ({
  type: ADD_COMMUTE_OFFER_REQUEST,
  payload: { params, data },
});

export const DUPLICATE_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_REQUEST';
export const DUPLICATE_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_SUCCESS';
export const DUPLICATE_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_FAILURE';
export const duplicateCommuteOffer = (id, params) => ({
  type: DUPLICATE_COMMUTE_OFFER_REQUEST,
  payload: { id, params },
});

export const COMMUTE_OFFER_ADD_VEHICLE_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_REQUEST';
export const COMMUTE_OFFER_ADD_VEHICLE_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_SUCCESS';
export const COMMUTE_OFFER_ADD_VEHICLE_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_FAILURE';
export const commuteOfferAddVehicleRequest = (data, values) => ({
  type: COMMUTE_OFFER_ADD_VEHICLE_REQUEST,
  payload: { data, values },
});

export const COMMUTE_OFFER_EDIT_VEHICLE_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_REQUEST';
export const COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS';
export const COMMUTE_OFFER_EDIT_VEHICLE_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_FAILURE';
export const commuteOfferEditVehicleRequest = (data, id, values) => ({
  type: COMMUTE_OFFER_EDIT_VEHICLE_REQUEST,
  payload: { data, id, values },
});

export const COMMUTE_OFFER_SHOW_VEHICLE_UPDATES =
  '@@commuteOffer/COMMUTE_OFFER_SHOW_VEHICLE_UPDATES';
export const showVehicleUpdates = id => ({
  type: COMMUTE_OFFER_SHOW_VEHICLE_UPDATES,
  payload: id,
});

export const COMMUTE_OFFER_SHOW_VEHICLE_SOURCE =
  '@@commuteOffer/COMMUTE_OFFER_SHOW_VEHICLE_SOURCE';
export const showVehicleSource = id => ({
  type: COMMUTE_OFFER_SHOW_VEHICLE_SOURCE,
  payload: id,
});

export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST';
export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS';
export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE';
export const commuteOfferStatelessNodeScheduler = (request, opts) => ({
  type: COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST,
  payload: { request, opts },
});

export const COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE';
export const commuteOfferImportBookingsRequest = (
  currentOffer,
  data,
  params,
  opts
) => ({
  type: COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST,
  payload: { currentOffer, data, params, opts },
});
export const commuteOfferImportBookingsProgress = (status, progress) => ({
  type: COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS,
  payload: { status, progress },
});

export const TRANSITSTOPSETS_FETCH_REQUEST =
  '@@geofences/TRANSITSTOPSETS_FETCH_REQUEST';
export const TRANSITSTOPSETS_FETCH_SUCCESS =
  '@@geofences/TRANSITSTOPSETS_FETCH_SUCCESS';
export const TRANSITSTOPSETS_FETCH_FAILURE =
  '@@geofences/TRANSITSTOPSETS_FETCH_FAILURE';
export const fetchTransitStopSets = () => ({
  type: TRANSITSTOPSETS_FETCH_REQUEST,
});

export const COMMUTE_OFFER_CLEAR_WARNINGS =
  '@@commuteOffer/COMMUTE_OFFER_CLEAR_WARNINGS';
export const clearCommuteOfferWarnings = () => ({
  type: COMMUTE_OFFER_CLEAR_WARNINGS,
});

export const COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_REQUEST =
  '@@simulations/COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_REQUEST';
export const COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_SUCCESS =
  '@@simulations/COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_SUCCESS';
export const COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_FAILURE =
  '@@simulations/COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_FAILURE';
export const confirmMatchedAddress = data => ({
  type: COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_REQUEST,
  payload: data,
});
