import React from 'react';

const ErrorPage = (props) => {
  const message = props.message || '';
  const title = props.message ? 'Something went wrong' : 'Page Not Found!';
  return (
    <div style={{ margin: '0 1rem' }}>
      <h2>{title}</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        <span style={{ lineHeight: '32px' }}>{message.toString()}</span>
      </details>
    </div>
  );
};

export default ErrorPage;
