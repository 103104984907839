import md5 from 'blueimp-md5';
import args from 'utils/args';
import Immutable from 'immutable';
import moment from 'moment-timezone';

import getAPI from 'utils/api/getAPI';
import SGERP from 'utils/sgerp-api';

import { call, put, select } from 'redux-saga/effects';
import { api$CommuteOffer, api$Simulation } from 'api';

import { ActionCreators } from 'redux-undo';

import {
  normalizeCommuteOffer,
  commuteOffer$MergeCommuteOffers,
} from 'utils/CommuteOffer';

import {
  isForceReloadEnabledSelector,
  commuteOfferLlastProcessedDataHashSelector,
} from 'modules/commuteOffer/selectors';

import {
  commuteOffersIsTemplateDataSourceSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOffersServiceDateSelector,
} from 'modules/ui/selectors';

import {
  userNameSelector,
  currentProjectSelector,
  isDeliveryLayoutSelector,
} from 'modules/user/selectors';

import RoutingPlan from 'utils/RoutingPlan';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

import * as actions from 'modules/commuteOffer/actions';

import debug from 'utils/debug';

const D2 = debug('m:CommuteOffer:saga:fetchCommuteOffer');

const mergeCommuteOffers = async loadedOffers =>
  D2.A.FUNCTION('mergeCommuteOffers', { loadedOffers }, async ({ $D2 }) => {
    if (loadedOffers.length === 0) {
      return JSON.parse(emptyCommuteOfferJSON);
    }

    if (loadedOffers.length === 1) {
      return loadedOffers[0];
    }

    const mergedOffers = await commuteOffer$MergeCommuteOffers(loadedOffers);
    $D2.S.INFO('mergedOffers', { mergedOffers });

    const commuteOffer = await normalizeCommuteOffer(mergedOffers);
    $D2.S.INFO('commuteOffer', { commuteOffer });

    return commuteOffer;
  });

const cache = { loadedHash: null };

const resultCache = { data: null };

const commuteOfferCache = { data: {} };

const normalizeProjectRecords = (projects) => {
  return projects
    .map(project => ({
      ...project,
      $name: project.name.toLocaleUpperCase(),
    }))
    .sort((a, b) => ('' + a.$name).localeCompare(b.$name));
};

export const fetchCommuteOfferMethod = async (id, opts = {}) =>
  D2.A.FUNCTION('Method', {}, async ({ $D2 }) => {
    const { api = getAPI(), forceReload = false } = opts;
    const sgerp = SGERP(api);

    const username = global.geodisc$select(userNameSelector);
    $D2.S.INFO('userName', { username });

    const projectResponses = await Promise.all([
      // sgerp?.loadCollection(
      //   'user',
      //   {
      //     username,
      //   },
      //   { uid: 'username' }
      // ),
      sgerp?.loadCollection(
        'projectmember',
        { user__username: username },
        { uid: 'id' }
      ),
      sgerp?.loadCollection('project', {}, { uid: 'id' }),
    ]);
    $D2.S.INFO('projectResponses', { projectResponses, id });

    const [projectMembersResponse, projectResponse] = projectResponses;

    const projectMembers = Object.values(projectMembersResponse.latest.objects);

    const projectMembersIdentity = [projectMembersResponse.latest.version_ts];
    const projectsIdentity = [projectResponse.latest.version_ts];

    // const userRecord = userResponse.latest.objects[username];
    // const user = userRecord;

    const projectRecords = normalizeProjectRecords(
      Object.values(projectResponse.latest.objects)
    );
    $D2.S.INFO('projectRecords', { projectRecords });

    const projects = Immutable.fromJS(projectRecords);
    $D2.S.INFO('projects', { projects, projectRecords });

    // const projectsResult = await api$User.getProjects();
    // $D2.S.INFO('projectsResult', { projectsResult });

    // const projects = Immutable.fromJS(projectsResult.objects);
    // $D2.S.INFO('projects', { projects });

    try {
      const currentProject = global.geodisc$select(currentProjectSelector);
      $D2.S.INFO('currentProject', { currentProject });
      const projectIdFromURL = args.search?.get('project-id');
      const currentProjectId = projectIdFromURL || currentProject.get('id');

      const isDeliveryLayout = global.geodisc$select(isDeliveryLayoutSelector);
      $D2.S.INFO('isDeliveryLayout', { isDeliveryLayout, currentProject });

      const isTemplateData = global.geodisc$select(
        commuteOffersIsTemplateDataSourceSelector
      );
      $D2.S.INFO('isTemplateData', { isTemplateData });

      const isServiceData = global.geodisc$select(
        commuteOffersIsServiceDataSourceSelector
      );
      $D2.S.INFO('isServiceData', { isServiceData });
      const projectTemplateSimulationInfo =
        isDeliveryLayout && (isTemplateData || isServiceData)
          ? await api$Simulation.getProjectTemplateSimulationInfo(
              currentProjectId
            )
          : {};
      $D2.S.INFO('projectTemplateSimulationInfo', {
        projectTemplateSimulationInfo,
      });

      const projectTemplateSimulationIds = isTemplateData
        ? [projectTemplateSimulationInfo?.simulation_id]
        : [];
      $D2.S.INFO('projectTemplateSimulationId', {
        projectTemplateSimulationIds,
        projectTemplateSimulationInfo,
      });

      const serviceDate = isServiceData
        ? global.geodisc$select(commuteOffersServiceDateSelector)
        : null;
      $D2.S.INFO('serviceDate', { serviceDate });

      const selectedServiceDate = serviceDate
        ? [
            moment(serviceDate).format('YYYY-MM-DD'),
            moment
              .tz('00:00', 'LT', global.GEODISC_TIMEZONE)
              .format('HH:mm:ssZ'),
          ].join('T')
        : null;
      $D2.S.INFO('selectedServiceDate', { selectedServiceDate, serviceDate });
      const projectServiceSimulationInfo =
        isDeliveryLayout && isServiceData
          ? await api$Simulation.getProjectServiceSimulationInfo(
              currentProjectId,
              selectedServiceDate
            )
          : {};
      $D2.S.INFO('projectServiceSimulationInfo', {
        projectServiceSimulationInfo,
      });

      const projectServiceSimulationIds =
        isDeliveryLayout && isServiceData
          ? [projectServiceSimulationInfo?.simulation_id]
          : [];
      $D2.S.INFO('projectServiceSimulationIds', {
        projectServiceSimulationIds,
        projectServiceSimulationInfo,
      });

      const commuteOfferIdList =
        isDeliveryLayout && (isTemplateData || isServiceData)
          ? []
          : [
              ...new Set([
                ...[id]
                  .map(x => parseInt(x, 10))
                  .filter(x => !Number.isNaN(x)),
                ...args.getIds('commuteOffer'),
              ]).values(),
            ];
      $D2.S.INFO('commuteOfferIdList', { commuteOfferIdList });

      const simulationIdList =
        isDeliveryLayout && (isTemplateData || isServiceData)
          ? [
              ...projectTemplateSimulationIds.filter(x => !!x),
              ...projectServiceSimulationIds.filter(x => !!x),
            ]
          : args.getIds('simulation');
      $D2.S.INFO('simulationIdList', { simulationIdList });
      const loadedSimulations =
        simulationIdList.length === 0
          ? []
          : await Promise.all(
              simulationIdList.map(simulationId =>
                (async () =>
                  $D2.A.FUNCTION(
                    'simulationIdList.map',
                    { simulationId },
                    async () => {
                      const loadedData = await RoutingPlan.loadSimulation(
                        simulationId,
                        {
                          forceReload,
                        }
                      );
                      return loadedData;
                    }
                  ))()
              )
            );
      $D2.S.INFO('loadedSimulations', { loadedSimulations });
      const loadedSimulationsError = loadedSimulations.find(
        simulation => !!simulation.error
      );
      if (loadedSimulationsError) {
        throw loadedSimulationsError;
      }

      const isSimulationsUpdated = !!loadedSimulations.find(
        item => item.$isUpdated
      );

      const isSimulationsFirstLoaded = !!loadedSimulations.find(
        item => item.$isFirstLoaded
      );

      const simulationIdentities = loadedSimulations.map((simulation) => {
        return {
          responses: [
            simulation.$source.collections.simulations,
            simulation.$source.collections.vehicles,
            simulation.$source.collections.bookings,
            simulation.$source.collections.nodes,
          ].map(({ objects_hash, serialNumber }) => ({
            objects_hash,
            serialNumber,
          })),
        };
      });

      const simulationRealtimeIdentities = loadedSimulations.map(
        (simulation) => {
          return {
            responses: [
              simulation.$source.collections.simulations,
              simulation.$source.collections.vehicles,
              simulation.$source.collections.bookings,
              simulation.$source.collections.nodes,
            ].map(({ rtinfos_hash, serialNumber }) => ({
              rtinfos_hash,
              serialNumber,
            })),
          };
        }
      );

      const simulationProjects = loadedSimulations.reduce(
        (memo, simulation) => {
          memo.add(simulation.project);
          return memo;
        },
        new Set()
      );

      const loadedOffers =
        commuteOfferIdList.length === 0 ||
        (commuteOfferIdList.length === 1 && commuteOfferIdList[0] === 0)
          ? []
          : await Promise.all(
              commuteOfferIdList.map(offerId =>
                (async () =>
                  $D2.A.FUNCTION(
                    'commuteOfferIdList.map:offerId',
                    { offerId },
                    async () => {
                      const commuteOfferId = String(offerId);

                      const offerResults = await sgerp?.loadCollection(
                        'commuteoffer',
                        { id: commuteOfferId },
                        { uid: 'id' }
                      );

                      if (offerResults.error) {
                        throw offerResults.error;
                      }

                      const commuteOfferObjects = Object.values(
                        offerResults.latest.objects
                      );
                      if (!commuteOfferObjects.length) {
                        throw new Error('Not found');
                      }

                      const commuteOfferObject = commuteOfferObjects[0];

                      const undefinedFieldIndex = [
                        commuteOfferObject.stateless_api_request_data,
                        commuteOfferObject.result,
                      ].findIndex(x => typeof x === 'undefined');
                      const isCompleteObject = undefinedFieldIndex === -1;

                      if (!isCompleteObject) {
                        const reloadedCommuteOffer =
                          await api$CommuteOffer.getCommuteOffer(offerId);
                        // eslint-disable-next-line
                        console.log(
                          '*** Reloaded commuteoffer:',
                          reloadedCommuteOffer
                        );

                        const resultOffer = {
                          ...reloadedCommuteOffer,
                          $source: {
                            ...reloadedCommuteOffer.$source,
                            version: offerResults.latest.version_ts,
                          },
                        };

                        const result = await normalizeCommuteOffer(resultOffer);

                        offerResults.updateLatestCollection({
                          ...offerResults.latest,
                          objects: { [commuteOfferId]: result },
                        });

                        return result;
                      }

                      return commuteOfferObject;
                    }
                  ))()
              )
            );
      $D2.S.INFO('loadedOffers', { loadedOffers });

      const commuteOfferIdentities = loadedOffers.map(commuteOffer => [
        commuteOffer.id,
        commuteOffer.$source.version,
      ]);

      const commuteOfferProjects = loadedSimulations.reduce(
        (memo, commuteOffer) => {
          memo.add(commuteOffer.project);
          return memo;
        },
        simulationProjects
      );

      const loadedProjects = [...commuteOfferProjects.values()];
      $D2.S.INFO('loadedProjects', { loadedProjects });
      const mergedCommuteOffer = await mergeCommuteOffers([
        ...loadedOffers,
        ...loadedSimulations,
      ]);
      $D2.S.INFO('mergedCommuteOffer', { mergedCommuteOffer });

      const commuteOffer = {
        ...mergedCommuteOffer,
        $isFirstLoaded: isSimulationsFirstLoaded,
      };
      $D2.S.INFO('commuteOffer', { commuteOffer, mergedCommuteOffer });

      const { project } = commuteOffer;
      const projectPath = project ? project.split('/') : null;
      $D2.S.INFO('projectPath', { projectPath, project });

      const selectedProjectId = projectPath
        ? parseInt(projectPath[projectPath.length - 1], 10)
        : currentProjectId;
      $D2.S.INFO('selectedProjectId', {
        selectedProjectId,
        projectPath,
        project,
      });

      const isProjectChanged =
        String(currentProjectId) !== String(selectedProjectId);

      if (isProjectChanged) {
        // eslint-disable-next-line
        console.log(
          `*** Warning: The project has changed from ${currentProjectId} to ${selectedProjectId}`
        );
      }

      const projectRecord = selectedProjectId
        ? projects.find(x => x.get('id') === selectedProjectId)
        : currentProject;
      $D2.S.INFO('projectRecord', {
        projectRecord,
        selectedProjectId,
        projectPath,
        project,
      });

      const entityResponses = await Promise.all([
        sgerp?.loadCollection(
          'geofence',
          {
            project: selectedProjectId,
          },
          { uid: 'id' }
        ),
        sgerp?.loadCollection(
          'transitstopset',
          {
            project: selectedProjectId,
          },
          { uid: 'id' }
        ),
      ]);
      $D2.S.INFO('entityResponses', { entityResponses, selectedProjectId });

      const [geofencesResponse, transitstopsetResponse] = entityResponses;

      const geofences = Object.values(geofencesResponse.latest.objects);
      $D2.S.INFO('geofences', { geofences });

      const geofencesIdentity = [geofencesResponse.latest.version_ts];

      const transitstopSets = Object.values(
        transitstopsetResponse.latest.objects
      );
      $D2.S.INFO('transitstopSets', { transitstopSets });

      const transitstopsetIdentity = [transitstopsetResponse.latest.version_ts];

      const loadedIdentity = {
        username,
        selectedProjectId,
        isDeliveryLayout,
        isTemplateData,
        isServiceData,
        simulationIdList,
        simulationIdentities,
        commuteOfferIdList,
        commuteOfferIdentities,
        projectMembersIdentity,
        projectsIdentity,
        geofencesIdentity,
        transitstopsetIdentity,
      };
      const loadedHash = md5(JSON.stringify(loadedIdentity));

      const loadedRealtimeIdentity = {
        loadedIdentity,
        simulationRealtimeIdentities,
      };
      const loadedRealtimeHash = md5(JSON.stringify(loadedRealtimeIdentity));

      const isUpdated =
        loadedHash !== cache.loadedHash ||
        loadedOffers.length > 0 ||
        isSimulationsUpdated;

      if (!isUpdated) {
        return {
          isUpdated,
          loadedHash,
          loadedRealtimeHash,
          loadedIdentity,
          loadedRealtimeIdentity,
          ...resultCache.data,
        };
      }
      cache.loadedHash = loadedHash;

      const result = {
        // user,
        projectMembers,
        project,
        projectId: selectedProjectId,
        projectRecord,
        projects,
        commuteOffer,
        geofences,
        // geofencesResult,
        transitstopSets,
        // transitstopSetsResult,
        loadedSimulations,
        loadedOffers,
      };

      resultCache.data = result;

      // console.log('--- result[success]', { result, id });
      return {
        isUpdated,
        loadedHash,
        loadedRealtimeHash,
        loadedIdentity,
        loadedRealtimeIdentity,
        ...result,
      };
    } catch (error) {
      console.log(error); // eslint-disable-line
      if (global.GEODISC_DEBUG_ENABLED) {
        console.log(error); // eslint-disable-line
      }
      $D2.S.INFO('Error', { error, message: error.message });

      const emptyCommuteOffer = JSON.parse(emptyCommuteOfferJSON);

      const normalizedCommuteOffer = await normalizeCommuteOffer({
        ...emptyCommuteOffer,
      });

      const commuteOffer = {
        ...normalizedCommuteOffer,
        stateless_api_request_data: {
          ...normalizedCommuteOffer.stateless_api_request_data,
          readOnly: true,
        },
        result: {
          ...normalizedCommuteOffer.result,
          // $errors: [{ type: 'exception', error, message: error.message }],
        },
      };

      const result = {
        // user,
        projects,
        commuteOffer,
        geofences: [],
        transitstopSets: [],
        loadedSimulations: [],
        loadedOffers: [commuteOffer],
      };

      resultCache.data = result;

      // console.log('--- result[error]', { result, error, id });
      return { isUpdated: true, ...result };
    }
  });

const fetchCommuteOfferHandlerGlobals = { counter: 0 };

const fetchCommuteOfferAllowedOrigins = new Set([
  'p:CommuteOffer:refreshTimer',
  'p:Logistics:refreshTimer',
]);

export function* fetchCommuteOfferHandler({ payload }) {
  D2.S.INFO('Handler:Request', { payload });

  // if (fetchCommuteOfferHandlerGlobals.counter > 0) {
  //   D2.S.INFO('Handler:Ignored', { payload });
  //   console.log('Handler:Ignored', { payload });
  //   yield put({
  //     type: actions.COMMUTE_OFFER_FETCH_IGNORED,
  //   });
  //   return;
  // }

  try {
    const { id, $origin = '', ...opts } = payload;

    if (!fetchCommuteOfferAllowedOrigins.has($origin)) {
      const now = window.performance.now();
      global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS = now;

      // eslint-disable-next-line no-console
      console.log(
        [
          '*** Explicit call of the fetchCommuteOffer() is prohibited.',
          '    Use commuteOfferRequestUpdate() instead.',
          '',
        ].join('\n'),
        { $origin }
      );

      yield put({
        type: actions.ENABLE_FORCE_UPDATE,
      });

      return;
    }

    fetchCommuteOfferHandlerGlobals.counter++;

    // console.log('*** Updating...', { fetchCommuteOfferHandlerGlobals });

    const isForceReloadEnabled = yield select(isForceReloadEnabledSelector);
    const lastProcessedDataHash = yield select(
      commuteOfferLlastProcessedDataHashSelector
    );

    const result = yield call(fetchCommuteOfferMethod, id, {
      ...opts,
      forceReload: opts.forceReload || isForceReloadEnabled,
    });

    fetchCommuteOfferHandlerGlobals.counter--;
    D2.S.INFO('Handler:Success', { result, payload });

    const isUpdated = lastProcessedDataHash !== result.loadedHash;

    // the loading status is in the hook below.
    yield put({
      type: actions.COMMUTE_OFFER_FETCH_RESULTS,
      payload: { result, request: payload },
    });

    if (isUpdated) {
      yield put({
        type: actions.FETCH_ALL_ROUTES_REQUEST,
        payload: { result },
      });
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    fetchCommuteOfferHandlerGlobals.counter--;
    D2.S.INFO('Handler:Failure', { error, payload });
    yield put({
      type: actions.COMMUTE_OFFER_FETCH_RESULTS,
      payload: { error },
    });
  }
}
