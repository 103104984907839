// import Immutable from 'immutable';
import args from 'utils/args';
import md5 from 'blueimp-md5';
import { crc32 } from 'utils/crc32';
import { geoToH3 } from 'h3-js';
import { diffString } from 'json-diff';
import moment from 'moment-timezone';

import getAPI from 'utils/api/getAPI';
import SGERP from 'utils/sgerp-api';

import formatForDate from 'utils/moment/formatForDate';
import diffStringSafe from 'utils/diffStringSafe';

import { normalizeCommuteOffer } from 'utils/CommuteOffer';
import { isSame } from 'utils/object';

import debug from 'utils/debug';

const D2 = debug('u:RoutingPlan:loadSimulation');

const cache = { data: {} };
const resultCache = { data: {} };

const defaultTimeToLive = 0 * 1000;

const splitVehicle = (vehicle) => {
  const {
    bearing,
    current_sim_ts,
    lon,
    lat,
    current_route,
    path,
    modified_at,
  } = vehicle;
  const rtinfo = { bearing, current_sim_ts, lon, lat, current_route, path };
  const object = {
    ...vehicle,
    bearing: 0,
    current_sim_ts: null,
    lon: 0,
    lat: 0,
    current_route: null,
    path: null,
    modified_at: null,
  };
  return { object, rtinfo, modified_at };
};

const loadSimulation = async (id, opts = {}) =>
  D2.A.FUNCTION('loadSimulation', { id, opts }, async ({ $D2 }) => {
    const { api = getAPI(), forceReload = false } = opts;
    const sgerp = SGERP(api);

    const current_ts = window.performance.now();
    const cached_data = cache.data[String(id)];

    const selectedDate = args.get('date');

    const showCompleted = (args.get('completed') ?? 'show') === 'show';

    const startTime = selectedDate
      ? formatForDate(selectedDate, '00:00', global.GEODISC_TIMEZONE)
      : 'Invalid date';
    const endTime = selectedDate
      ? formatForDate(
          selectedDate,
          '00:00',
          global.GEODISC_TIMEZONE,
          24,
          'hours'
        )
      : 'Invalid date';

    const hasTimeFilters =
      selectedDate &&
      startTime.indexOf('Invalid') === -1 &&
      endTime.indexOf('Invalid') === -1;

    const nodesTimeFilter = hasTimeFilters
      ? {
          open_time_ts__lte: endTime,
          close_time_ts__gte: startTime,
        }
      : {};

    const bookingsTimeFilter = hasTimeFilters
      ? {
          min_pickup_time__lte: endTime,
          max_dropoff_time__gte: startTime,
        }
      : {};

    const vehiclesTimeFilter = hasTimeFilters
      ? {
          start_time__lte: endTime,
          end_time__gte: startTime,
        }
      : {};

    const responses =
      cached_data && current_ts < cached_data.expiration_ts
        ? cached_data.responses
        : await Promise.all([
            sgerp?.loadCollection(
              'simulation',
              {
                id,
              },
              { uid: 'id', forceReload }
            ),
            sgerp?.loadCollection(
              'node',
              {
                simulation: id,
                ...nodesTimeFilter,
              },
              { uid: 'uid', forceReload }
            ),
            sgerp?.loadCollection(
              'booking',
              {
                simulation: id,
                ...bookingsTimeFilter,
              },
              { uid: 'uid', forceReload }
            ),
            sgerp?.loadCollection(
              'vehicle',
              {
                simulation: id,
                ...vehiclesTimeFilter,
              },
              { uid: 'agent_id', forceReload }
            ),
            sgerp?.loadCollection(
              'deletionlog',
              {
                simulation_id: id,
              },
              { uid: 'id', forceReload }
            ),
          ]);

    cache.data = {
      ...cache.data,
      [String(id)]: {
        responses,
        expiration_ts: current_ts + defaultTimeToLive,
      },
    };

    const [
      simulationResponse,
      nodesResponse,
      bookingsResponse,
      vehiclesResponse,
      deletionlogResponse,
    ] = responses;

    // console.log('*** responses', {
    //   simulationResponse,
    //   nodesResponse,
    //   bookingsResponse,
    //   vehiclesResponse,
    //   deletionlogResponse,
    // });

    const failedResponse = [
      simulationResponse,
      nodesResponse,
      bookingsResponse,
      vehiclesResponse,
    ].find(x => !!x.error);
    if (failedResponse) {
      const { error } = failedResponse;
      if (error.exception) {
        // eslint-disable-next-line no-console
        console.log(error.exception);
        return { error: error.exception };
      }
      // eslint-disable-next-line no-console
      console.log(error);
      return { error };
    }

    const updatedResponse = [
      simulationResponse,
      nodesResponse,
      bookingsResponse,
      vehiclesResponse,
      deletionlogResponse,
    ].find(x => Object.values(x.updated.objects).length > 0);
    const isUpdated = !!updatedResponse;

    // console.log('*** isUpdated', { isUpdated });

    const cachedResult = resultCache.data[String(id)];
    const isFirstLoaded = !cachedResult;

    if (!isUpdated) {
      const oldResult = resultCache.data[String(id)];
      if (oldResult) {
        // eslint-disable-next-line no-console
        console.log('--- result', { oldResult });
        return { $isUpdated: false, ...oldResult };
      }
    }

    // Deletion log

    const deletedObjectsInfo = Object.values(
      deletionlogResponse.updates.objects
    ).reduce((memo, item) => {
      const { model_name } = item;
      const modelMemo = memo[model_name] ?? {};
      return {
        ...memo,
        [model_name]: {
          ...modelMemo,
          [String(item.object_id)]: item,
        },
      };
    }, {});

    // Simulations

    Object.values(simulationResponse.updates.objects).map(
      (updatedSimulation) => {
        const originalSimulation =
          simulationResponse.original.objects[String(updatedSimulation.id)] ||
          {};
        // eslint-disable-next-line no-console
        console.log('UPDATED SIMULATION', updatedSimulation.id, {
          originalSimulation,
          updatedSimulation,
        });
        // eslint-disable-next-line no-console
        console.log(diffStringSafe(originalSimulation, updatedSimulation));
      }
    );

    const latestSimulationObjects = Object.values(
      simulationResponse.latest.objects
    );
    if (!latestSimulationObjects.length) {
      const error = new Error(`Simulation with ID ${id} not found.`);
      return { error };
    }

    const simulation = latestSimulationObjects[0];

    const $source = {
      type: 'Simulation',
      id: simulation.id,
    };

    // Vehicles

    Object.values(vehiclesResponse.updates.objects).map((updatedVehicle) => {
      const originalVehicle =
        vehiclesResponse.original.objects[updatedVehicle.agent_id] || {};
      // eslint-disable-next-line no-console
      console.log('UPDATED VEHICLE', updatedVehicle.agent_id, {
        originalVehicle,
        updatedVehicle,
      });
      // eslint-disable-next-line no-console
      console.log(
        diffStringSafe(
          { ...originalVehicle, current_route: undefined, path: undefined },
          { ...updatedVehicle, current_route: undefined, path: undefined }
        )
      );
    });

    const latestVehicleObjects = Object.values(vehiclesResponse.latest.objects);

    const deletedVehiclesInfo = deletedObjectsInfo['simulation.vehicle'] ?? {};

    const isVehicleInvalidated = vehicle =>
      !!deletedVehiclesInfo[vehicle.$id];
    // const isVehicleInvalidated = () => false;

    const vehiclesLatestCollection = latestVehicleObjects.reduce(
      (memo, vehicle) => {
        const isDeleted = isVehicleInvalidated(vehicle);
        if (isDeleted) {
          // eslint-disable-next-line no-console
          console.log('*** Deleted vehicle:', {
            agent_id: vehicle.agent_id,
            vehicle,
          });
          return {
            ...memo,
            deleted_objects: {
              ...memo.deleted_objects,
              [vehicle.agent_id]: vehicle,
            },
            deleted_objects_by_resource_uri: {
              ...memo.deleted_objects_by_resource_uri,
              [vehicle.resource_uri]: vehicle.agent_id,
            },
          };
        }
        const currentVehicleInfo = splitVehicle(vehicle);
        const currentVehicleRealtimeInfo = {
          ...currentVehicleInfo.rtinfo,
          modified_at: currentVehicleInfo.modified_at,
        };
        if (vehiclesResponse.updates.objects[vehicle.agent_id]) {
          // eslint-disable-next-line
          // console.log('*** Updated vehicle:', { agent_id: vehicle.agent_id, vehicle });
          const originalVehicle =
            vehiclesResponse.original.objects[vehicle.agent_id];
          if (originalVehicle) {
            const originalVehicleInfo = splitVehicle(originalVehicle);
            if (isSame(originalVehicleInfo.object, currentVehicleInfo.object)) {
              const originalVehicleWithRealtimeInfo = {
                ...originalVehicle,
                ...currentVehicleInfo.rtinfo,
              };
              return {
                ...memo,
                objects: {
                  ...memo.objects,
                  [vehicle.agent_id]: originalVehicleWithRealtimeInfo,
                },
                rtinfos: {
                  ...memo.rtinfos,
                  [vehicle.agent_id]: currentVehicleRealtimeInfo,
                },
                unchanged_objects: {
                  ...memo.unchanged_objects,
                  [vehicle.agent_id]: vehicle,
                },
                index_by_resource_uri: {
                  ...memo.index_by_resource_uri,
                  [vehicle.resource_uri]: vehicle.agent_id,
                },
              };
            }
          }
        }
        return {
          ...memo,
          objects: { ...memo.objects, [vehicle.agent_id]: vehicle },
          rtinfos: {
            ...memo.rtinfos,
            [vehicle.agent_id]: currentVehicleRealtimeInfo,
          },
          index_by_resource_uri: {
            ...memo.index_by_resource_uri,
            [vehicle.resource_uri]: vehicle.agent_id,
          },
        };
      },
      {
        ...vehiclesResponse.latest,
        objects: {},
        rtinfos: {},
        index_by_resource_uri: {},
        unchanged_objects: {},
        deleted_objects: {},
        deleted_objects_by_resource_uri: {},
      }
    );
    // if (Object.values(vehiclesLatestCollection.deleted_objects).length) {
    vehiclesResponse.updateLatestCollection(vehiclesLatestCollection);
    // }

    const loadedVehicles = Object.values(vehiclesLatestCollection.objects);
    const deletedVehiclesByResourceURI =
      vehiclesLatestCollection.deleted_objects_by_resource_uri;

    const vehicles = loadedVehicles.map(vehicle => ({
      ...vehicle,
      routing_engine: vehicle.routing_engine_settings,
      routing_engine_settings: undefined,
      $source,
    }));
    $D2.S.INFO('vehicles', {
      vehicles,
      latestVehicleObjects,
      vehiclesResponse,
      id,
    });

    const vehiclesById = vehicles.reduce(
      (memo, vehicle) => ({
        ...memo,
        [vehicle.id]: vehicle,
      }),
      {}
    );
    $D2.S.INFO('vehiclesById', {
      vehiclesById,
      vehicles,
      vehiclesResponse,
      id,
    });

    // Bookings

    Object.values(bookingsResponse.updates.objects).map((updatedBooking) => {
      const originalBooking =
        bookingsResponse.original.objects[updatedBooking.uid] || {};
      // eslint-disable-next-line no-console
      console.log('UPDATED BOOKING', updatedBooking.uid, {
        originalBooking,
        updatedBooking,
      });
      // eslint-disable-next-line no-console
      console.log(diffStringSafe(originalBooking, updatedBooking));
    });

    const latestBookingObjects = Object.values(bookingsResponse.latest.objects);

    const isBookingHidden = showCompleted
      ? () => false
      : (booking) => {
          const result = booking.status === 'completed';
          // console.log('*** isBookingHidden', { result, booking });
          return result;
        };

    const deletedBookingsInfo = deletedObjectsInfo['simulation.booking'] ?? {};

    const isBookingInvalidated = booking =>
      !!deletedBookingsInfo[booking.$id] ||
      booking.is_invalidated ||
      booking.cancellation_time !== null ||
      isBookingHidden(booking);

    const bookingsLatestCollection = latestBookingObjects.reduce(
      (memo, booking) => {
        const isDeleted = isBookingInvalidated(booking);
        if (isDeleted) {
          // eslint-disable-next-line no-console
          console.log('*** Deleted booking:', { uid: booking.uid, booking });
          return {
            ...memo,
            deleted_objects: {
              ...memo.deleted_objects,
              [booking.uid]: booking,
            },
          };
        }
        if (bookingsResponse.updates.objects[booking.uid]) {
          // eslint-disable-next-line
          // console.log('*** Updated booking:', { uid: booking.uid, booking });
        }
        return {
          ...memo,
          objects: { ...memo.objects, [booking.uid]: booking },
        };
      },
      { ...bookingsResponse.latest, objects: {}, deleted_objects: {} }
    );
    // if (bookingsLatestCollection.deleted_objects.length) {
    bookingsResponse.updateLatestCollection(bookingsLatestCollection);
    // }

    const loadedBookings = Object.values(bookingsLatestCollection.objects);
    const deletedBookings = bookingsLatestCollection.deleted_objects;

    const bookings = loadedBookings.map(booking => ({
      ...booking,
      $source,
    }));
    $D2.S.INFO('bookings', { bookings, loadedBookings, id });

    // Nodes

    Object.values(nodesResponse.updates.objects).map((updatedNode) => {
      const originalNode =
        nodesResponse.original.objects[updatedNode.uid] || {};
      // eslint-disable-next-line no-console
      console.log('UPDATED NODE', updatedNode.uid, {
        originalNode,
        updatedNode,
      });
      // eslint-disable-next-line no-console
      console.log(diffStringSafe(originalNode, updatedNode));
    });

    const isNodeStartAndEndPoint = node =>
      node.partial_route_index === 1 || node.partial_route_index === -1;

    const isNodeHidden = showCompleted
      ? () => false
      : (node) => {
          const result = node.status === 'completed';
          // console.log('*** isNodeHidden', { result, node });
          return result;
        };

    const deletedNodesInfo = deletedObjectsInfo['simulation.node'] ?? {};

    const isNodeInvalidated = (node) => {
      if (isNodeStartAndEndPoint(node)) {
        return !!deletedNodesInfo[node.$id];
      }
      return (
        !!deletedNodesInfo[node.$id] ||
        !node.booking_uid ||
        !!deletedBookings[node.booking_uid] ||
        !bookingsLatestCollection.objects[node.booking_uid] ||
        !!deletedVehiclesByResourceURI[node.assigned_vehicle] ||
        isNodeHidden(node)
      );
    };

    const loadedNodeObjects = Object.values(nodesResponse.latest.objects);
    const nodesLatestCollection = loadedNodeObjects.reduce(
      (memo, node) => {
        const isDeleted = isNodeInvalidated(node);
        if (isDeleted) {
          // eslint-disable-next-line no-console
          console.log('*** Deleted node:', {
            uid: node.uid,
            booking_uid: node.booking_uid,
            node,
          });
          if (nodesResponse.updates.objects[node.uid]) {
            // eslint-disable-next-line
            // console.log('*** Updated node:', { uid: node.uid, node });
          }
          return {
            ...memo,
            deleted_objects: { ...memo.deleted_objects, [node.uid]: node },
          };
        }
        return { ...memo, objects: { ...memo.objects, [node.uid]: node } };
      },
      { ...nodesResponse.latest, objects: {}, deleted_objects: {} }
    );
    // if (nodesLatestCollection.deleted_objects.length) {
    nodesResponse.updateLatestCollection(nodesLatestCollection);
    // }

    const loadedNodes = Object.values(nodesLatestCollection.objects);

    const nodes = loadedNodes
      .map((node) => {
        const stop_id = crc32(geoToH3(node.lat, node.lon, 13));
        const $assigned_vehicle_id = !node.assigned_vehicle
          ? null
          : parseInt(node.assigned_vehicle.split('/').slice(-1)[0], 10);
        const assigned_vehicle_id = !node.assigned_vehicle
          ? null
          : vehiclesById[$assigned_vehicle_id]?.agent_id;

        const assignedVehicle = $assigned_vehicle_id
          ? vehiclesById[$assigned_vehicle_id]
          : null;

        const calculatedProperties = !assignedVehicle
          ? {}
          : {
              $assigned_vehicle_display_name:
                assignedVehicle.$display_name || assignedVehicle.service_number,
            };

        return {
          ...node,
          ...calculatedProperties,
          stop_id,
          assigned_vehicle_id,
          $scheduled_mts: node.scheduled_ts ? moment(node.scheduled_ts) : null,
          $scheduled_uts: node.scheduled_ts
            ? moment(node.scheduled_ts).unix()
            : 0,
          $assigned_vehicle_id,
          $source,
        };
      })
      .sort((a, b) =>
        a.$scheduled_uts !== b.$scheduled_uts
          ? a.$scheduled_uts - b.$scheduled_uts
          : a.assignment_order - b.assignment_order
      );
    $D2.S.INFO('nodes', { nodes, nodesResponse, id });

    const assignedNodes = nodes.filter(node => !!node.assigned_vehicle);
    $D2.S.INFO('assignedNodes', {
      assignedNodes,
      nodes,
      loadedBookings,
      id,
    });

    const resultVehicles = assignedNodes.reduce(
      (memo, node) => ({
        ...memo,
        [node.assigned_vehicle_id]: memo[node.assigned_vehicle_id]
          ? [...memo[node.assigned_vehicle_id], node]
          : [node],
      }),
      {}
    );
    $D2.S.INFO('resultVehicles', { resultVehicles, loadedBookings, id });

    const rejectedBookings = nodes.reduce(
      (memo, node) =>
        node.assigned_vehicle
          ? memo
          : {
              ...memo,
              [node.booking_uid]: { uid: node.booking_uid },
            },
      {}
    );
    $D2.S.INFO('rejectedBookings', {
      rejectedBookings,
      nodes,
      loadedBookings,
      id,
    });

    const logistics_api_settings =
      simulation.data?.logistics_api_settings ?? {};

    const engine_settings = logistics_api_settings.engine_settings ?? {};

    const newOffer = {
      $source: {
        ...$source,
        simulation,
        collections: {
          simulations: {
            ...simulationResponse,
            objects_hash: md5(
              Object.values(simulationResponse.latest.objects)
                .map(x => x.modified_at)
                .join(' ')
            ),
          },
          nodes: {
            ...nodesResponse,
            latest: nodesLatestCollection,
            objects_hash: md5(
              Object.values(nodesLatestCollection.objects)
                .map(x => x.modified_at)
                .join(' ')
            ),
          },
          bookings: {
            ...bookingsResponse,
            latest: bookingsLatestCollection,
            objects_hash: md5(
              Object.values(bookingsLatestCollection.objects)
                .map(x => x.modified_at)
                .join(' ')
            ),
          },
          vehicles: {
            ...vehiclesResponse,
            latest: vehiclesLatestCollection,
            objects_hash: md5(
              Object.values(vehiclesLatestCollection.objects)
                .map(x => x.modified_at)
                .join(' ')
            ),
            rtinfos_hash: md5(
              Object.values(vehiclesLatestCollection.rtinfos)
                .map(x => x.modified_at)
                .join(' ')
            ),
          },
        },
      },
      stateless_api_request_data: {
        nodes,
        vehicles: vehicles.map((vehicle) => {
          const readOnly = !global.GEODISC_SIMULATION_EDITOR_ENABLED;
          return { ...vehicle, readOnly };
        }),
        bookings: bookings.reduce(
          (memo, booking) => ({ ...memo, [booking.uid]: booking }),
          {}
        ),
        engine_settings,
        logistics_api_settings: {
          ...logistics_api_settings,
          engine_settings: undefined,
        },
      },
      result: {
        vehicles: resultVehicles,
        rejected_bookings: Object.keys(rejectedBookings).map(uid => ({
          uid,
        })),
      },
      project: simulation.project,
      name: simulation.name,
    };
    $D2.S.INFO('newOffer', { newOffer, id });

    const resultOffer = await normalizeCommuteOffer(newOffer);
    $D2.S.INFO('resultOffer', { resultOffer, newOffer, id });

    resultCache.data[String(id)] = resultOffer;

    const newResult = { $isFirstLoaded: isFirstLoaded, ...resultOffer };
    // eslint-disable-next-line no-console
    console.log('*** result', { newResult });

    return { $isUpdated: true, ...newResult };
  });

export default loadSimulation;
