import styled from 'styled-components';

const getBackground = ({ isOver, isActive }) => {
  if (isOver) {
    return '#5d6574';
  }

  if (isActive) {
    return '#1C232D';
  }

  return '#1C232D';
};

export default styled.div`
  position: relative;
  user-select: none;
  margin: 0 0 12px 0;
  border-radius: 4px;
  font-size: 12px;
  padding: 16px;

  // change background colour if dragging
  background: ${getBackground};
  box-shadow: 0 0 10px 0
    rgba(43, 48, 58, ${({ isActive }) => (isActive ? 0.5 : 0.1)});
  cursor: pointer;

  :hover {
    background: #23282e;
  }
`;
