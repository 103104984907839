// import React from 'utils/react';
// import debug from 'utils/debug';

import { DragSource } from 'react-dnd';

import BookingV2 from './BookingV2';

// const D2 = debug('p:Logistics:Panels:Bookings:DraggableBooking');

const DraggableBooking = DragSource(
  'booking',
  {
    beginDrag: props => ({ ...props.booking }),
    endDrag(props, monitor) {
      const item = monitor.getItem();
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        if (item.assigned_vehicle_id) {
          // eslint-disable-next-line no-alert
          alert('First unassigned the booking from the vehicles');
          // props.setActiveVehicleId(item.assigned_vehicle_id);
          // props.setActiveRouteStop(item.scheduled_pickup_stop_id);
          return;
        }

        // if (dropResult.isHaveEditable) {
        //   // eslint-disable-next-line no-alert
        //   alert('Finish editing booking!');
        //   return;
        // }

        props.addBookingToRoute(item.uid, dropResult.agent_id);
        props.setActiveVehicleId(dropResult.agent_id);

        if (!item.scheduled_pickup_stop_id) {
          props.setEditableBookingId(
            item.uid,
            'p:CommuteOffer:Panels:Bookings:Booking:endDrag',
            true
          );
        }
      }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(BookingV2);

export default DraggableBooking;
