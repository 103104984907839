import { v4 as uuidv4 } from 'uuid';

import moment from 'moment-timezone';
// import Object$fromEntries from 'fromentries';
import debug from 'utils/debug';
import formatForThisDate from 'utils/moment/formatForThisDate';
// import formatForNextDate from 'utils/moment/formatForNextDate';
import uuidByString from 'uuid-by-string';
import deepmerge from 'deepmerge';
import {
  removeUndefinedProperties,
  makeStructuredFromLinear,
  removeInternalFieldsFromObject,
} from 'utils/object';
import normalizePhoneNumber from 'utils/phone-numbers';
import normalizePostalCode from 'utils/postal-codes';
import settings from 'config/settings';

import { getHeaders } from './headers';
import { fetchData } from './net';
import { urls } from '../config';
import makeBulkUpdateRequest from './CommuteOffer/makeBulkUpdateRequest';

const D2 = debug('api:commuteOffer');

const parseValue = value => (value ? String(value).trim() : '');

const ensureValidValue = (value, defaultValue) =>
  typeof value !== 'undefined' && value !== null && !Number.isNaN(value)
    ? value
    : defaultValue;

const parseJsonValue = (value, defaultValue) => {
  if (!value) {
    return defaultValue;
  }
  try {
    return JSON.parse(value.replaceAll('“', '"').replaceAll('”', '"'));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Error parsing ${value}`, e);
    return defaultValue;
  }
};

const parseStringValue = (value, defaultValue) => {
  const parsedValue = parseValue(value);
  return parsedValue !== ''
    ? ensureValidValue(parsedValue, defaultValue)
    : defaultValue;
};

const parseIntValue = (value, defaultValue) => {
  const parsedValue = parseValue(value);
  return parsedValue !== ''
    ? ensureValidValue(parseInt(parsedValue, 10), defaultValue)
    : defaultValue;
};

const parseFloatValue = (value, defaultValue) => {
  const parsedValue = parseValue(value);
  return parsedValue !== ''
    ? ensureValidValue(parseFloat(parsedValue), defaultValue)
    : defaultValue;
};

const parseTimeValue = (value, date, timezone, defaultValue) => {
  if (typeof value === 'number') {
    const midnight = formatForThisDate(date, '00:00', timezone);
    const offset = 86400 * value;
    const result = moment(midnight)
      .add(offset, 'seconds')
      .tz(timezone)
      .format();
    // console.log(`*** parseTimeValue[${typeof value}]`, {
    //   result,
    //   value,
    //   date,
    //   timezone,
    //   defaultValue,
    // });
    return result;
  }

  const parseDefaultValue = () =>
    defaultValue ? formatForThisDate(date, defaultValue, timezone) : undefined;
  const parsedTime = parseStringValue(value)
    ? formatForThisDate(date, parseStringValue(value), timezone)
    : parseDefaultValue();

  // console.log(`*** parseTimeValue[${typeof value}]`, {
  //   result: parsedTime,
  //   value,
  //   date,
  //   timezone,
  //   defaultValue,
  // });

  return parsedTime;
};

const defaultCountry = 'SG';

const defaultRegionAndCityByCountries = {
  SG: ['Singapore', 'Singapore'],
  JP: ['Kantō', 'Tokyo'],
};
const getDefaultRegionAndCity = (country = defaultCountry) => {
  const info = defaultRegionAndCityByCountries[country];
  return info || [undefined, undefined];
};

// eslint-disable-next-line
export const importLogisticsOrders = async (simulation_id, src, opts = {}) =>
  D2.A.FUNCTION(
    'importLogisticsOrders',
    { simulation_id, src, opts },
    async ({ $D2 }) => {
      try {
        const { timezone = global.GEODISC_TIMEZONE } = opts;
        const data = src.map(order => makeStructuredFromLinear(order));
        $D2.S.INFO('data', { data, src });

        const {
          defaultDate = moment().tz(timezone).format('YYYY-MM-DD'),
          currentProjectConfig = {},
        } = opts;
        $D2.S.INFO('opts', { defaultDate, timezone, opts });

        const orders = data
          .map((order) => {
            const date = moment(
              parseStringValue(order.date, defaultDate)
            ).format('YYYY-MM-DD');
            return { ...order, date };
          })
          .filter(order => order.date === defaultDate);
        $D2.S.INFO('orders', { orders, data, defaultDate, timezone, opts });

        if (orders.length === 0) {
          throw new Error('Nothing to import');
        }

        const {
          api_request_template_defaults = {},
          api_request_template_overrides = {},
          booking_template_defaults = {},
          booking_template_overrides = {},
        } = {
          ...(currentProjectConfig?.logistics ?? {}),
          ...(currentProjectConfig?.logistics_settings ?? {}),
          ...(currentProjectConfig?.logistics_api_settings ?? {}),
        };
        $D2.S.INFO('api_request_template', {
          api_request_template_defaults,
          api_request_template_overrides,
        });

        const generatedRequest = {
          calculation_uid: uuidv4(),
          simulation_id,
          bookings: orders.map(sourceOrder =>
            $D2.S.FUNCTION('orders.map', { sourceOrder }, () => {
              const order = deepmerge.all([
                {},
                { vehicle_characteristics: {} },
                booking_template_defaults,
                sourceOrder,
                booking_template_overrides,
              ]);

              const { date } = order;

              const country = parseStringValue(order.country, defaultCountry);
              const [defaultRegion, defaultCity] =
                getDefaultRegionAndCity(country);
              const region = parseStringValue(order.region, defaultRegion);
              const city = parseStringValue(order.city, defaultCity);

              const pickup_country = parseStringValue(
                order.pickup_country,
                country
              );
              const [pickup_defaultRegion = region, pickup_defaultCity = city] =
                getDefaultRegionAndCity(pickup_country);
              const pickup_region = parseStringValue(
                order.pickup_region,
                pickup_defaultRegion
              );
              const pickup_city = parseStringValue(
                order.pickup_city,
                pickup_defaultCity
              );

              const dropoff_country = parseStringValue(
                order.dropoff_country,
                country
              );
              const [
                dropoff_defaultRegion = region,
                dropoff_defaultCity = city,
              ] = getDefaultRegionAndCity(dropoff_country);
              const dropoff_region = parseStringValue(
                order.dropoff_region,
                dropoff_defaultRegion
              );
              const dropoff_city = parseStringValue(
                order.dropoff_city,
                dropoff_defaultCity
              );

              const groups = parseStringValue(order.groups, null);

              const dropoffAddress1 = parseStringValue(order.dropoff_address);
              const dropoffAddress2 = parseStringValue(order.dropoff_address2);

              const dropoffAddress = dropoffAddress2
                ? [dropoffAddress1, dropoffAddress2].join(' ')
                : dropoffAddress1;

              const pickup_customer_name = parseStringValue(
                order.pickup_customer_name
              );
              const pickup_customer_name2 = parseStringValue(
                order.pickup_customer_name2
              );

              const pickup_customer_phone = normalizePhoneNumber(
                parseStringValue(order.pickup_customer_phone),
                country
              );

              const customer_name =
                order.first_name || order.last_name
                  ? [
                      parseStringValue(order.first_name),
                      parseStringValue(order.last_name),
                    ]
                      .join(' ')
                      .trim()
                  : parseStringValue(order.name);

              const customer_name2 = parseStringValue(order.name2);

              const customer_phone = normalizePhoneNumber(
                parseStringValue(order.phone_number),
                country
              );

              const demand_type = parseStringValue(order.demand_type);
              const demand_load = parseIntValue(order.demand_load);

              const customDemands =
                demand_type || demand_load
                  ? {
                      [demand_type || 'units']: demand_load || 1,
                    }
                  : {};

              const demand_units = parseIntValue(order.demand_units);

              const unitsDemands = demand_units
                ? {
                    units: demand_units,
                  }
                : {};

              const calculatedDemands = {
                ...customDemands,
                ...unitsDemands,
              };

              const generatedDemands = Object.keys(calculatedDemands).length
                ? calculatedDemands
                : {
                    units: 1,
                  };

              const demand = {
                ...parseJsonValue(order.demand_json, {}),
                ...generatedDemands,
              };

              const external_id = parseStringValue(order.external_id);

              const dropoff_customer_name = customer_name;
              const dropoff_customer_name2 = customer_name2;
              const dropoff_customer_phone = customer_phone;

              const customerFields = {
                demand,
                dropoff_postal_code: normalizePostalCode(
                  parseStringValue(order.dropoff_zip_code),
                  dropoff_country
                ),
                dropoff_location_name: dropoffAddress,
                dropoff_location_lon: parseFloatValue(
                  order.dropoff_location_lon
                ),
                dropoff_location_lat: parseFloatValue(
                  order.dropoff_location_lat
                ),
                min_dropoff_time: parseTimeValue(
                  order.dropoff_open_time_ts,
                  date,
                  timezone,
                  '00:00'
                ),
                max_dropoff_time: parseTimeValue(
                  order.dropoff_close_time_ts,
                  date,
                  timezone,
                  '23:59'
                ),
                data: {
                  pickup_country,
                  pickup_region,
                  pickup_city,
                  dropoff_country,
                  dropoff_region,
                  dropoff_city,
                  external_id,

                  pickup_customer_name,
                  pickup_customer_name2,
                  pickup_customer_phone,
                  dropoff_customer_name,
                  dropoff_customer_name2,
                  dropoff_customer_phone,

                  customer_name,
                  customer_name2,
                  customer_phone,
                  username: customer_name,
                  phone: customer_phone,

                  remarks: parseStringValue(order.remarks),
                  instructions: parseStringValue(order.instructions),
                  dropoff_unit_number: parseStringValue(
                    order.dropoff_unit_number
                  ),
                  dropoff_address: parseStringValue(order.dropoff_address),
                  dropoff_postal_code: normalizePostalCode(
                    parseStringValue(order.dropoff_zip_code),
                    dropoff_country
                  ),
                },
                dropoff_service_time: parseIntValue(order.dropoff_service_time),
              };

              const { dropoff_address, dropoff_postal_code } =
                customerFields.data;

              const pickup_postal_code = normalizePostalCode(
                parseStringValue(order.pickup_zip_code),
                pickup_country
              );
              const pickup_address = parseStringValue(order.pickup_address);

              const orderIdentity = external_id
                ? {
                    simulation_id,
                    external_id,
                  }
                : {
                    simulation_id,
                    pickup_customer_name,
                    pickup_customer_name2,
                    pickup_postal_code,
                    pickup_address,
                    dropoff_customer_name,
                    dropoff_customer_name2,
                    dropoff_postal_code,
                    dropoff_address,
                  };

              const uid = global.GEODISC_LOGISTICS_ORDER_PERSISTENT_UIDS_ENABLED
                ? uuidByString(JSON.stringify(orderIdentity))
                : uuidv4();

              const pickup_unit_number = parseStringValue(
                order.pickup_unit_number
              );
              const pickup_location_name = parseStringValue(
                order.pickup_address
              );
              const pickup_location_lon = parseFloatValue(
                order.pickup_location_lon
              );
              const pickup_location_lat = parseFloatValue(
                order.pickup_location_lat
              );

              const pickup_max_height = parseIntValue(order.pickup_max_height);
              const dropoff_max_height = parseIntValue(
                order.dropoff_max_height
              );
              const max_height_values = [
                pickup_max_height,
                dropoff_max_height,
              ].filter(x => typeof x !== 'undefined');
              const max_height =
                max_height_values.length > 0
                  ? Math.min(...max_height_values)
                  : undefined;
              const max_height_characteristics = !max_height
                ? {}
                : {
                    height: {
                      min: 0,
                      max: max_height,
                    },
                  };

              const pickup_max_weight = parseIntValue(order.pickup_max_weight);
              const dropoff_max_weight = parseIntValue(
                order.dropoff_max_weight
              );
              const max_weight_values = [
                pickup_max_weight,
                dropoff_max_weight,
              ].filter(x => typeof x !== 'undefined');
              const result_max_weight =
                max_weight_values.length > 0
                  ? Math.min(...max_weight_values)
                  : undefined;
              const max_weight_characteristics = !result_max_weight
                ? {}
                : {
                    weight: {
                      min: 0,
                      max: result_max_weight,
                    },
                  };
              // console.log('*** [1] max_weight_characteristics', {
              //   max_weight_characteristics,
              //   result_max_weight,
              //   max_weight_values,
              //   pickup_max_weight,
              //   dropoff_max_weight,
              //   raw_order_record: order,
              // });

              const min_workers = parseIntValue(order.min_workers);
              const min_workers_characteristics = !min_workers
                ? {}
                : {
                    manpower: {
                      min: min_workers,
                    },
                  };

              const geofence_definition_strategy = parseStringValue(
                order.geofence_definition_strategy
              );

              const trip_cost = parseIntValue(order.trip_cost);

              const generatedBooking = removeUndefinedProperties({
                ...customerFields,
                uid,
                pickup_postal_code,
                pickup_location_name,
                pickup_location_lon,
                pickup_location_lat,
                min_pickup_time: parseTimeValue(
                  order.pickup_open_time_ts,
                  date,
                  timezone,
                  '00:00'
                ),
                max_pickup_time: parseTimeValue(
                  order.pickup_close_time_ts,
                  date,
                  timezone,
                  '23:59'
                ),
                data: {
                  ...customerFields.data,
                  pickup_unit_number,
                  pickup_location_name,
                  pickup_location_lon,
                  pickup_location_lat,
                  pickup_address,
                  pickup_postal_code,
                  order_identity: orderIdentity,
                },
                pickup_service_time: parseIntValue(order.pickup_service_time),
                groups: [
                  ...(groups ? groups.split(',').map(x => x.trim()) : []),
                ],
                geofence_id: parseIntValue(order.geofence_id),
                geofence_definition_strategy,
                trip_cost,
                vehicle_characteristics: {
                  ...parseJsonValue(order.vehicle_characteristics_json, {}),
                  ...max_height_characteristics,
                  ...max_weight_characteristics,
                  ...min_workers_characteristics,
                },
              });

              const resultBooking = deepmerge.all([
                {},
                { vehicle_characteristics: {} },
                // booking_template_defaults,
                generatedBooking,
                // booking_template_overrides,
              ]);
              $D2.S.INFO('orders.map:resultBooking', {
                resultBooking,
                generatedBooking,
                booking_template_defaults,
                booking_template_overrides,
              });

              return {
                ...resultBooking,
                data: {
                  ...resultBooking.data,
                  geofence_definition_strategy:
                    resultBooking.geofence_definition_strategy,
                  raw_order_record: order,
                },
              };
            })
          ),
        };
        $D2.S.INFO('generatedRequest', { generatedRequest });

        const { upload_strategy } = settings.logistics.api;

        const request = deepmerge.all([
          {
            upload_strategy,
          },
          api_request_template_defaults,
          generatedRequest,
          api_request_template_overrides,
        ]);
        $D2.S.INFO('request', {
          request,
          generatedRequest,
          api_request_template_defaults,
          api_request_template_overrides,
          booking_template_defaults,
          booking_template_overrides,
        });
        const body = JSON.stringify(request);

        const response = await fetchData(urls.logisticsapi, {
          method: 'POST',
          headers: getHeaders(),
          body,
        });
        const responseJSON = await response.json();

        if (!response.ok) {
          throw new Error(response.status);
        }

        return { response: responseJSON, request, data };
      } catch (error) {
        return { error };
      }
    }
  );

const getStatus = ({ matchedAddress, item, statusLabels }) => {
  // When the Geo coding engine gives multiple variants
  if (
    item?.data?.pickup_location_variants?.length > 1 ||
    item?.data?.dropdoff_location_variants?.length > 1 ||
    !matchedAddress
  ) {
    return statusLabels.enter;
  }
  // When the geocoding engine gives a stable response
  else if (matchedAddress) {
    return statusLabels.matched;
  }
  // TODO: Need to write a  logic to identify records with 'Check matched address' status.
  // Currently backend  doesn't send any score with the geocoded address
  else {
    return statusLabels.check;
  }
};

// eslint-disable-next-line
export const sendLogisticsOrders = async (simulation_id, src, opts = {}) => {
  try {
    const { timezone = global.GEODISC_TIMEZONE } = opts;

    const data = src.map(order => makeStructuredFromLinear(order));
    const {
      defaultDate = moment().tz(timezone).format('YYYY-MM-DD'),
      currentProjectConfig = {},
    } = opts;

    const orders = data
      .map((order) => {
        const date = moment(parseStringValue(order.date, defaultDate)).format(
          'YYYY-MM-DD'
        );
        return { ...order, date };
      })
      .filter(order => order.date === defaultDate);

    if (orders.length === 0) {
      throw new Error('Nothing to import');
    }

    const {
      api_request_template_defaults = {},
      api_request_template_overrides = {},
      booking_template_defaults = {},
      booking_template_overrides = {},
    } = {
      ...(currentProjectConfig?.logistics ?? {}),
      ...(currentProjectConfig?.logistics_settings ?? {}),
      ...(currentProjectConfig?.logistics_api_settings ?? {}),
    };

    const { upload_strategy } = settings.logistics.api;

    const generatedRequest = {
      upload_strategy,
      immediate_calculation: false,
      calculation_uid: uuidv4(),
      simulation_id,
      bookings: orders.map((sourceOrder) => {
        const order = deepmerge.all([
          {},
          { vehicle_characteristics: {} },
          booking_template_defaults,
          sourceOrder,
          booking_template_overrides,
        ]);

        const { date } = order;

        const country = parseStringValue(order.country, defaultCountry);
        const [defaultRegion, defaultCity] = getDefaultRegionAndCity(country);
        const region = parseStringValue(order.region, defaultRegion);
        const city = parseStringValue(order.city, defaultCity);

        const pickup_country = parseStringValue(order.pickup_country, country);
        const [pickup_defaultRegion = region, pickup_defaultCity = city] =
          getDefaultRegionAndCity(pickup_country);
        const pickup_region = parseStringValue(
          order.pickup_region,
          pickup_defaultRegion
        );
        const pickup_city = parseStringValue(
          order.pickup_city,
          pickup_defaultCity
        );

        const dropoff_country = parseStringValue(
          order.dropoff_country,
          country
        );
        const [dropoff_defaultRegion = region, dropoff_defaultCity = city] =
          getDefaultRegionAndCity(dropoff_country);
        const dropoff_region = parseStringValue(
          order.dropoff_region,
          dropoff_defaultRegion
        );
        const dropoff_city = parseStringValue(
          order.dropoff_city,
          dropoff_defaultCity
        );

        const groups = parseStringValue(order.groups, null);

        const dropoffAddress1 = parseStringValue(order.dropoff_address);
        const dropoffAddress2 = parseStringValue(order.dropoff_address2);

        const dropoffAddress = dropoffAddress2
          ? [dropoffAddress1, dropoffAddress2].join(' ')
          : dropoffAddress1;

        const pickup_customer_name = parseStringValue(
          order.pickup_customer_name
        );
        const pickup_customer_name2 = parseStringValue(
          order.pickup_customer_name2
        );

        const pickup_customer_phone = normalizePhoneNumber(
          parseStringValue(order.pickup_customer_phone),
          country
        );

        const customer_name =
          order.first_name || order.last_name
            ? [
                parseStringValue(order.first_name),
                parseStringValue(order.last_name),
              ]
                .join(' ')
                .trim()
            : parseStringValue(order.name);

        const customer_name2 = parseStringValue(order.name2);

        const customer_phone = normalizePhoneNumber(
          parseStringValue(order.phone_number),
          country
        );

        const demand_type = parseStringValue(order.demand_type);
        const demand_load = parseIntValue(order.demand_load);

        const multipleDemands = {};
        Object.keys(order.demand_types || {}).map((demand) => {
          multipleDemands[parseStringValue(demand)] = parseIntValue(
            order.demand_types[demand]
          );
        });

        const customDemands =
          demand_type || demand_load
            ? {
                [demand_type || 'units']: demand_load || 1,
              }
            : {};

        const demand_units = parseIntValue(order.demand_units);

        const unitsDemands = demand_units
          ? {
              units: demand_units,
            }
          : {};

        const calculatedDemands = {
          ...customDemands,
          ...unitsDemands,
          ...multipleDemands,
        };

        const generatedDemands = Object.keys(calculatedDemands).length
          ? calculatedDemands
          : {
              units: 1,
            };

        const demand = {
          ...parseJsonValue(order.demand_json, {}),
          ...generatedDemands,
        };

        const external_id = parseStringValue(order.external_id);

        const dropoff_customer_name = customer_name;
        const dropoff_customer_name2 = customer_name2;
        const dropoff_customer_phone = customer_phone;

        const customerFields = {
          demand,
          dropoff_postal_code: normalizePostalCode(
            parseStringValue(order.dropoff_zip_code),
            dropoff_country
          ),
          dropoff_location_name: dropoffAddress,
          dropoff_location_lon: parseFloatValue(order.dropoff_location_lon),
          dropoff_location_lat: parseFloatValue(order.dropoff_location_lat),
          min_dropoff_time: parseTimeValue(
            order.dropoff_open_time_ts,
            order.dropoff_open_date_ts,
            timezone,
            '00:00'
          ),
          max_dropoff_time: parseTimeValue(
            order.dropoff_close_time_ts,
            order.dropoff_close_date_ts,
            timezone,
            '23:59'
          ),
          data: {
            pickup_country,
            pickup_region,
            pickup_city,
            dropoff_country,
            dropoff_region,
            dropoff_city,
            external_id,

            pickup_customer_name,
            pickup_customer_name2,
            pickup_customer_phone,
            dropoff_customer_name,
            dropoff_customer_name2,
            dropoff_customer_phone,

            customer_name,
            customer_name2,
            customer_phone,
            username: customer_name,
            phone: customer_phone,
            remarks: parseStringValue(order.remarks),
            instructions: parseStringValue(order.instructions),
            dropoff_unit_number: parseStringValue(order.dropoff_unit_number),
            dropoff_address: parseStringValue(order.dropoff_address),
            dropoff_postal_code: normalizePostalCode(
              parseStringValue(order.dropoff_zip_code),
              dropoff_country
            ),
          },
          dropoff_service_time: parseIntValue(order.dropoff_service_time),
        };

        const { dropoff_address, dropoff_postal_code } = customerFields.data;

        const pickup_postal_code = normalizePostalCode(
          parseStringValue(order.pickup_zip_code),
          pickup_country
        );
        const pickup_address = parseStringValue(order.pickup_address);

        const orderIdentity = external_id
          ? {
              simulation_id,
              external_id,
            }
          : {
              simulation_id,
              pickup_customer_name,
              pickup_customer_name2,
              pickup_postal_code,
              pickup_address,
              dropoff_customer_name,
              dropoff_customer_name2,
              dropoff_postal_code,
              dropoff_address,
            };

        const uid = global.GEODISC_LOGISTICS_ORDER_PERSISTENT_UIDS_ENABLED
          ? uuidByString(JSON.stringify(orderIdentity))
          : uuidv4();

        const pickup_unit_number = parseStringValue(order.pickup_unit_number);
        const pickup_location_name = parseStringValue(order.pickup_address);
        const pickup_location_lon = parseFloatValue(order.pickup_location_lon);
        const pickup_location_lat = parseFloatValue(order.pickup_location_lat);

        const pickup_max_height = parseIntValue(order.pickup_max_height);
        const dropoff_max_height = parseIntValue(order.dropoff_max_height);
        const max_height_values = [
          pickup_max_height,
          dropoff_max_height,
        ].filter(x => typeof x !== 'undefined');
        const max_height =
          max_height_values.length > 0
            ? Math.min(...max_height_values)
            : undefined;
        const max_height_characteristics = !max_height
          ? {}
          : {
              height: {
                min: 0,
                max: max_height,
              },
            };

        const pickup_max_weight = parseIntValue(order.pickup_max_weight);
        const dropoff_max_weight = parseIntValue(order.dropoff_max_weight);
        const max_weight_values = [
          pickup_max_weight,
          dropoff_max_weight,
        ].filter(x => typeof x !== 'undefined');
        const result_max_weight =
          max_weight_values.length > 0
            ? Math.min(...max_weight_values)
            : undefined;
        const max_weight_characteristics = !result_max_weight
          ? {}
          : {
              weight: {
                min: 0,
                max: result_max_weight,
              },
            };
        // console.log('*** [2] max_weight_characteristics', {
        //   max_weight_characteristics,
        //   result_max_weight,
        //   max_weight_values,
        //   pickup_max_weight,
        //   dropoff_max_weight,
        //   raw_order_record: order,
        // });

        const min_workers = parseIntValue(order.min_workers);
        const min_workers_characteristics = !min_workers
          ? {}
          : {
              manpower: {
                min: min_workers,
              },
            };

        const geofence_definition_strategy = parseStringValue(
          order.geofence_definition_strategy
        );

        const trip_cost = parseIntValue(order.trip_cost);

        const generatedBooking = removeUndefinedProperties({
          ...customerFields,
          uid,
          pickup_postal_code,
          pickup_location_name,
          pickup_location_lon,
          pickup_location_lat,
          min_pickup_time: parseTimeValue(
            order.pickup_open_time_ts,
            order.pickup_open_date_ts,
            timezone,
            '00:00'
          ),
          max_pickup_time: parseTimeValue(
            order.pickup_close_time_ts,
            order.pickup_close_date_ts,
            timezone,
            '23:59'
          ),
          data: {
            ...customerFields.data,
            pickup_unit_number,
            pickup_location_name,
            pickup_location_lon,
            pickup_location_lat,
            pickup_address,
            pickup_postal_code,
            order_identity: orderIdentity,
          },
          pickup_service_time: parseIntValue(order.pickup_service_time),
          groups: [...(groups ? groups.split(',').map(x => x.trim()) : [])],
          geofence_id: parseIntValue(order.geofence_id),
          geofence_definition_strategy,
          trip_cost,
          vehicle_characteristics: {
            ...parseJsonValue(order.vehicle_characteristics_json, {}),
            ...max_height_characteristics,
            ...max_weight_characteristics,
            ...min_workers_characteristics,
          },
          vehicle_labels: order.vehicle_labels,
        });

        const resultBooking = deepmerge.all([
          {},
          { vehicle_characteristics: {} },
          // booking_template_defaults,
          generatedBooking,
          // booking_template_overrides,
        ]);

        return {
          ...resultBooking,
          data: {
            ...resultBooking.data,
            geofence_definition_strategy:
              resultBooking.geofence_definition_strategy,
            raw_order_record: order,
          },
        };
      }),
    };

    const request = deepmerge.all([
      {},
      api_request_template_defaults,
      generatedRequest,
      api_request_template_overrides,
    ]);

    const body = JSON.stringify(request);
    const response = await fetchData(urls.logisticsapi, {
      method: 'POST',
      headers: getHeaders(),
      body,
    });
    const responseJSON = await response.json();

    if (!response.ok) {
      throw new Error(response.status);
    }

    return { response: responseJSON, request, data };
  } catch (error) {
    return { error };
  }
};

export const updateAllRejectedToPreparedBookings = async (originalOffer) => {
  /* This is a workaround to prepare rejected bookings for the next calculation.
   * below code block change the booking state to the 'prepare' if its state is 'rejected_by_system'
   */
  try {
    const originalBookings = Object.values(
      originalOffer.stateless_api_request_data.bookings
    ).reduce(
      (memo, booking) => ({
        ...memo,
        [booking.uid]: removeInternalFieldsFromObject(booking),
      }),
      {}
    );

    const originalBookingArray = Object.values(originalBookings);

    const hasRejected = originalBookingArray.find(
      booking => booking.state === 'rejected_by_system'
    );

    if (!hasRejected) {
      return;
    }

    const updatedBookings = originalBookingArray.reduce(
      (memo, booking) => ({
        ...memo,
        [booking.uid]: {
          ...booking,
          state:
            booking.state === 'rejected_by_system' ? 'prepared' : booking.state,
        },
      }),
      {}
    );

    const bookingsRequest = makeBulkUpdateRequest(
      updatedBookings,
      originalBookings,
      'BOOKING'
    );

    const bookingsResponse = await fetchData(urls.simulationBookingAPI, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(bookingsRequest),
      maxAttempts: 1,
    });

    if (!bookingsResponse.ok) {
      throw new Error(bookingsResponse.status);
    }
  } catch (error) {
    return { error };
  }
};

export const optimizeLogisticsOrders = async (
  simulation_id,
  booking_uids,
  opts = {}
) => {
  const { originalOffer } = opts;

  await updateAllRejectedToPreparedBookings(originalOffer);

  try {
    const request = {
      calculation_uid: uuidv4(),
      simulation_id,
      bookings: [],
      booking_uids: booking_uids.map(String),
    };

    const body = JSON.stringify(request);

    const response = await fetchData(urls.logisticsapi_optimize, {
      method: 'POST',
      headers: getHeaders(),
      body,
    });
    const responseJSON = await response.json();

    if (!response.ok) {
      throw new Error(response.status);
    }

    return { response: responseJSON, request };
  } catch (error) {
    return { error };
  }
};

export const getNodeAction = async (nodeId) => {
  try {
    const response = await fetchData(urls.nodeAction(nodeId), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    return { error };
  }
};
