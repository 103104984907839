import { styled } from 'baseui';

export default styled('span', ({ $theme }) => {
  return {
    maxWidth: '215px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
    ...$theme.typography.montserrat,
  };
});
