import { v4 as uuidv4 } from 'uuid';

import moment from 'moment-timezone';
import { crc32 } from 'utils/crc32';

import weekdays from 'utils/weekdays';

import debug from 'utils/debug';

const D2 = debug('u:CommuteOffer:Silveray');

export const silverayScheduleInboundColumns = new Map([
  ['lon', 'lon'],
  ['x', 'lon'],

  ['lat', 'lat'],
  ['y', 'lat'],

  ['name', 'name'],
  ['resident', 'name'],
  ['name of resident', 'name'],

  ['zip_code', 'home_zip_code'],
  ['post code', 'home_zip_code'],
  ['postal code', 'home_zip_code'],
  ['home_zip_code', 'home_zip_code'],

  ['home_building_code', 'home_building_code'],

  ['home_building_id', 'home_building_id'],

  ['home_address', 'home_address'],

  ['calculation_group', 'calculation_group'],
  ['calculation group', 'calculation_group'],
  ['group', 'calculation_group'],

  ['mutually_exclusive_group', 'mutually_exclusive_group'],
  ['mutually exclusive group', 'mutually_exclusive_group'],
  ['bus', 'mutually_exclusive_group'],

  ['morning_mutually_exclusive_group', 'morning_mutually_exclusive_group'],
  ['morning mutually exclusive group', 'morning_mutually_exclusive_group'],
  ['morning bus', 'morning_mutually_exclusive_group'],

  ['evening_mutually_exclusive_group', 'evening_mutually_exclusive_group'],
  ['eveningmutually exclusive group', 'evening_mutually_exclusive_group'],
  ['evening bus', 'evening_mutually_exclusive_group'],

  ['special_demand', 'special_demand'],
  ['special demand', 'special_demand'],

  ['wheelchair', 'wheelchair'],
  ['m-aid', 'wheelchair'],
  ['is resident on wheelchair?', 'wheelchair'],

  ['morning_dropoff_ts', 'morning_dropoff_ts'],
  ['going to the eldercare center at', 'morning_dropoff_ts'],

  ['morning_pickup_from_ts', 'morning_pickup_from_ts'],
  ['earliest pick up time from home', 'morning_pickup_from_ts'],
  ['earliest possible pick up time from home', 'morning_pickup_from_ts'],
  [
    'earliest possible pick up time from home (optional)',
    'morning_pickup_from_ts',
  ],

  ['morning_pickup_till_ts', 'morning_pickup_till_ts'],
  ['latest pick up time from home', 'morning_pickup_till_ts'],
  ['latest possible pick up time from home', 'morning_pickup_till_ts'],
  [
    'latest possible pick up time from home (optional)',
    'morning_pickup_till_ts',
  ],

  ['evening_pickup_ts', 'evening_pickup_ts'],
  ['leaving the eldercare center at', 'evening_pickup_ts'],

  ['evening_dropoff_from_ts', 'evening_dropoff_from_ts'],
  ['earliest drop off time at home', 'evening_dropoff_from_ts'],
  ['earliest possible drop off time at home', 'evening_dropoff_from_ts'],
  [
    'earliest possible drop off time at home (optional)',
    'evening_dropoff_from_ts',
  ],

  ['evening_dropoff_till_ts', 'evening_dropoff_till_ts'],
  ['latest drop off time at home', 'evening_dropoff_till_ts'],
  ['latest possible drop off time at home', 'evening_dropoff_till_ts'],
  [
    'latest possible drop off time at home (optional)',
    'evening_dropoff_till_ts',
  ],

  ['morning_pickup_scheduled_time', 'morning_pickup_scheduled_time'],
  ['morning_pickup_scheduled_open_time', 'morning_pickup_scheduled_open_time'],
  [
    'Scheduled pick up time from home starts at',
    'morning_pickup_scheduled_open_time',
  ],

  [
    'morning_pickup_scheduled_close_time',
    'morning_pickup_scheduled_close_time',
  ],
  [
    'Scheduled pick up time from home ends at',
    'morning_pickup_scheduled_close_time',
  ],

  ['evening_dropoff_scheduled_time', 'evening_dropoff_scheduled_time'],
  [
    'evening_dropoff_scheduled_open_time',
    'evening_dropoff_scheduled_open_time',
  ],
  [
    'Scheduled drop off time at home starts at',
    'evening_dropoff_scheduled_open_time',
  ],

  [
    'evening_dropoff_scheduled_close_time',
    'evening_dropoff_scheduled_close_time',
  ],
  [
    'Scheduled drop off time at home ends at',
    'evening_dropoff_scheduled_close_time',
  ],

  ['Destination', 'office_zip_code'],
  ['destination', 'office_zip_code'],
  ['Depot', 'office_zip_code'],
  ['depot', 'office_zip_code'],
  ['office_zip_code', 'office_zip_code'],

  ['office_building_code', 'office_building_code'],

  ['office_building_id', 'office_building_id'],

  ['office_address', 'office_address'],

  ['country', 'country'],
  ['region', 'region'],
  ['city', 'city'],

  ['monday', 'mon'],
  ['tuesday', 'tue'],
  ['wednesday', 'wed'],
  ['thursday', 'thu'],
  ['friday', 'fri'],
  ['saturday', 'sat'],
  ['sunday', 'sun'],
  ['mon', 'mon'],
  ['tue', 'tue'],
  ['wed', 'wed'],
  ['thu', 'thu'],
  ['fri', 'fri'],
  ['sat', 'sat'],
  ['sun', 'sun'],

  ['service_time', 'service_time'],
  ['service time', 'service_time'],

  ['center_service_time', 'center_service_time'],
  ['center service time', 'center_service_time'],

  ['home_service_time', 'home_service_time'],
  ['home service time', 'home_service_time'],

  ['max_trip_duration', 'max_trip_duration'],
  ['Max trip duration', 'max_trip_duration'],

  ['trip_cost', 'trip_cost'],
  ['trip cost', 'trip_cost'],
]);

const allPossibleTimes = Array.from({ length: 24 }, (v, k) => k).reduce(
  (memo, item) => [
    ...memo,
    ...Array.from({ length: 12 }, (v, k) => k * 5).map(x =>
      moment(`${item}:${x}`, 'LT').format('LT')
    ),
  ],
  []
);

export const silverayDestinationTimes = {
  H2C: new Set(allPossibleTimes),
  C2H: new Set(allPossibleTimes),
};

export const silverayPossibleDestinationTimes = {
  H2C: [...silverayDestinationTimes.H2C.keys()].map(x => `"${x}"`).join(', '),
  C2H: [...silverayDestinationTimes.C2H.keys()].map(x => `"${x}"`).join(', '),
};

const silverayVanguardBuilding = {
  lon: 103.80458840692,
  lat: 1.44613248896987,
  nearestPoint: {
    annotate_distance_point: '0.4234625 m',
    camera_heading: null,
    camera_lat: null,
    camera_lon: null,
    camera_pitch: null,
    code: 'BLD267043',
    created_at: '2019-11-27T07:06:28.648000+00:00',
    extended_name: 'woodlands care home',
    extended_street: '2 woodlands rise woodlands care home singapore 737749',
    id: 349421,
    lat: 1.44612881154562,
    lon: 103.804589469079,
    name: 'WOODLANDS CARE HOME',
    name_translations: {},
    point: {
      coordinates: [103.804589469079, 1.44612881154562],
      type: 'Point',
    },
    resource_uri: '/api/v2/transitstop/349421',
    stop_type: 'building',
    street: '2 WOODLANDS RISE WOODLANDS CARE HOME SINGAPORE 737749',
    updated_at: '2019-11-27T07:06:28.648000+00:00',
    verified: false,
  },
};

const silverayTampinesCareHomeBuilding = {
  lon: 103.937076698873,
  lat: 1.36331926727404,
  nearestPoint: {
    annotate_distance_point: '0.0 m',
    camera_heading: null,
    camera_lat: null,
    camera_lon: null,
    camera_pitch: null,
    code: 'BLD221948',
    created_at: '2019-11-27T06:57:34.339000+00:00',
    display_name:
      'TAMPINES CARE HOME 31 TAMPINES STREET 61 TAMPINES CARE HOME SINGAPORE 528564',
    extended_name: 'tampines care home',
    extended_street:
      '31 tampines street 61 tampines care home singapore 528564',
    id: 307961,
    lat: 1.36331926727404,
    lon: 103.937076698873,
    modified_at: '2019-11-27T06:57:34.339000+00:00',
    name: 'TAMPINES CARE HOME',
    name_translations: {},
    point: {
      coordinates: [103.937076698873, 1.36331926727404],
      type: 'Point',
    },
    resource_uri: '/api/v2/transitstop/307961',
    stop_type: 'building',
    street: '31 TAMPINES STREET 61 TAMPINES CARE HOME SINGAPORE 528564',
    verified: false,
  },
};

export const silverayDestinations = new Map([
  ['737749', silverayVanguardBuilding],
  ['528564', silverayTampinesCareHomeBuilding],
]);

export const silverayVehicleCost = 100000;

export const silverayVehicleTemplate = {
  agent_id: null,
  assigned_nodes: [],
  capacity: {
    passenger: 10,
    wheelchair: 2,
  },
  completed_nodes: [],
  geofence_ids: [],
  lat: 1.3600612,
  lon: 103.991377,
  max_physical_stops: null,
  partial_route: [],
  routing_engine: {
    url: global.GEODISC_INTERNAL_OSRM_URL,
    key: global.GEODISC_INTERNAL_OSRM_TOKEN,
    make_depot_zero: true,
    road_network: 'alldriving',
    routing_engine_name: 'osrme',
    speed: null,
    time_factor: 1,
    use_speed_in_routing: false,
  },
  vehicle_cost: silverayVehicleCost,
};

export const silverayVehicleTemplateJSON = JSON.stringify(
  silverayVehicleTemplate
);

export const csvToMap = (csv, opts) =>
  D2.S.FUNCTION('csvToMap', { csv, opts }, ({ $D2 }) => {
    const [firstLine, ...lines] = csv;

    const params = opts || {};
    const columns = params.columns || new Map();
    $D2.S.INFO('columns', { firstLine, columns });

    const header = firstLine.map((column) => {
      const key = column.toString().trim().toLowerCase();
      return columns.has(key)
        ? { key: columns.get(key), required: true }
        : { key, required: false };
    });

    const nameIndex = header.findIndex(x => x.key === 'name');
    const firstNameIndex = header.findIndex(x => x.key === 'first_name');
    const lastNameIndex = header.findIndex(x => x.key === 'last_name');
    const labelsIndex = header.findIndex(x => x.key === 'vehicle_labels');

    if (!nameIndex && (!firstNameIndex || !lastNameIndex)) {
      throw Error('Customer name is required');
    }
    const zipCodeIndex = header.findIndex(x => x.key === 'home_zip_code');
    // if (zipCodeIndex < 0) {
    //   throw Error('Column "home.zip_code" is required');
    // }

    const resultObjects = lines.map(line =>
      header.reduce(
        (memo, item, index) =>
          item.required
            ? {
                ...memo,
                [item.key]: line[index],
              }
            : memo,
        {}
      )
    );

    const optionalColumns = [
      [
        ...[
          'name',
          'first_name',
          'last_name',
          'home_zip_code',
          'vehicle_labels',
        ],
        ...header.filter(x => !x.required).map(x => x.key),
      ],
    ];
    const optionalRows = lines.map((line) => {
      const labels =
        labelsIndex > -1
          ? (line[labelsIndex] || '').split(',')?.map((label) => {
              return label?.toLowerCase().replace(/ /g, '_');
            })
          : [];
      return header.reduce(
        (memo, item, index) => (!item.required ? [...memo, line[index]] : memo),
        [
          line[nameIndex],
          line[firstNameIndex],
          line[lastNameIndex],
          line[zipCodeIndex],
          labels,
        ]
      );
    });

    return {
      objects: resultObjects,
      remarks: [...optionalColumns, ...optionalRows],
    };
  });

export const silverayCsvToJson = data =>
  D2.S.FUNCTION('silverayCsvToJson', { data }, () =>
    csvToMap(data, {
      columns: silverayScheduleInboundColumns,
    })
  );

const parseSilverayTime = (value, timezone) => moment.tz(value, 'LT', timezone);

const formatSilverayTime = value => value.format('LT');

export const normalizeSilverayTime = (value, timezone) =>
  formatSilverayTime(parseSilverayTime(value, timezone));

export const silverayNormalizeData = async (jsonData, opts) => {
  D2.S.INFO('silverayNormalizeData:Request', { jsonData, opts });

  const options = opts || {};

  const ignoreErrors = options.ignoreErrors || false;
  const timezone = options.timezone || 'Singapore';
  const centerServiceTime = options.centerServiceTime || 0;
  const homeServiceTime = options.homeServiceTime || 0;

  const normalizedData = jsonData.map((item) => {
    D2.S.INFO('silverayNormalizeData:item', { item });
    const $errors = [];
    const destination = item.destination || '737749';
    const wheelchair =
      item.wheelchair &&
      (item.wheelchair.toString().trim().toLowerCase() === 'yes' ||
        item.wheelchair[0] === 'W');
    const weekdaysRecords = Object.keys(weekdays).reduce((acc, weekday) => {
      acc[weekday] = !!item[weekday];
      return acc;
    }, {});
    const name = item.name && item.name.toString().trim();
    if (!name) {
      $errors.push('Name cannot be empty');
    }

    const country = item.country ? item.country.toString().trim() : 'Singapore';
    const city = item.city ? item.city.toString().trim() : 'Singapore';

    const validateLocation = (prefix, location) => {
      const normalizeLocation = (locationRecord) => {
        if (locationRecord.building_id) {
          return {
            $type: 'building_id',
            id: `${locationRecord.building_id}`,
            ...locationRecord,
          };
        }
        if (locationRecord.building_code) {
          return {
            $type: 'building_code',
            id: locationRecord.building_code,
            ...locationRecord,
          };
        }
        if (
          locationRecord.address &&
          locationRecord.city &&
          locationRecord.country
        ) {
          return {
            $type: 'address',
            id: [
              locationRecord.address,
              locationRecord.city,
              locationRecord.country,
            ].join(', '),
            ...locationRecord,
          };
        }
        if (locationRecord.zip_code && locationRecord.country) {
          return {
            $type: 'zip_code',
            id: [
              locationRecord.country,
              locationRecord.city,
              locationRecord.zip_code,
            ].join(':'),
            ...locationRecord,
          };
        }
        return undefined;
      };
      const newLocation = normalizeLocation(location);
      if (!newLocation) {
        $errors.push(
          `${name} (${prefix}): Either zip code, address, building code, building id or coordinates must be specified` // eslint-disable-line
        );
        return location;
      }
      return newLocation;
    };

    const parseLocation = (prefix, locationRecord) => {
      const getItem = locationName =>
        locationRecord[`${prefix}.${locationName}`];
      return validateLocation(prefix, {
        country: getItem('country')
          ? getItem('country').toString().trim()
          : country,
        city: getItem('city') ? getItem('city').toString().trim() : city,
        address: getItem('address') && getItem('address').toString().trim(),
        zip_code: getItem('zip_code')
          ? getItem('zip_code').toString().trim()
          : '737749',
        building_code:
          getItem('building_code') &&
          getItem('building_code').toString().trim(),
        building_id:
          getItem('building_id') &&
          parseInt(getItem('building_id').toString().trim(), 10),
        lon: getItem('lon') && parseFloat(getItem('lon').toString().trim()),
        lat: getItem('lat') && parseFloat(getItem('lat').toString().trim()),
      });
    };

    const home = parseLocation('home', item);
    if (!home.zip_code) {
      $errors.push('Postal code cannot be empty');
    }

    const office = parseLocation('office', item);

    const calculation_group = item.calculation_group
      ? item.calculation_group.toString().trim()
      : 'None';

    const mutually_exclusive_group =
      item.mutually_exclusive_group &&
      item.mutually_exclusive_group.toString().trim();
    const morning_mutually_exclusive_group =
      item.morning_mutually_exclusive_group
        ? item.morning_mutually_exclusive_group.toString().trim()
        : mutually_exclusive_group;
    const evening_mutually_exclusive_group =
      item.evening_mutually_exclusive_group
        ? item.evening_mutually_exclusive_group.toString().trim()
        : mutually_exclusive_group;

    const special_demand =
      item.special_demand && item.special_demand.toString().trim();

    const center_service_time = item.center_service_time
      ? parseInt(item.center_service_time.toString().trim(), 10)
      : centerServiceTime;
    const home_service_time = item.home_service_time
      ? parseInt(item.home_service_time.toString().trim(), 10)
      : homeServiceTime;

    const max_trip_duration = item.max_trip_duration
      ? parseInt(item.max_trip_duration.toString().trim(), 10)
      : 7200;

    const trip_cost = item.trip_cost
      ? parseFloat(item.trip_cost.toString().trim())
      : 0.01;

    const morning_dropoff_ts = item.morning_dropoff_ts
      ? normalizeSilverayTime(item.morning_dropoff_ts, timezone)
      : undefined;
    D2.S.INFO('silverayNormalizeData:item:morning_dropoff_ts', {
      morning_dropoff_ts,
    });

    if (
      morning_dropoff_ts &&
      !silverayDestinationTimes.H2C.has(morning_dropoff_ts)
    ) {
      $errors.push(
        [
          `${name}: "${morning_dropoff_ts}" is not allowed dropoff time`,
          `Possible values are ${silverayPossibleDestinationTimes.H2C}`,
        ].join('. ')
      );
    }

    const morning_pickup_from_ts =
      item.morning_pickup_from_ts &&
      normalizeSilverayTime(item.morning_pickup_from_ts, timezone);
    if (
      morning_dropoff_ts &&
      morning_pickup_from_ts &&
      !parseSilverayTime(morning_pickup_from_ts, timezone).isSameOrBefore(
        parseSilverayTime(morning_dropoff_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `${name}: morning_pickup_from_ts (${morning_pickup_from_ts}) is not before morning_dropoff_ts (${morning_dropoff_ts})`
      );
    }

    const morning_pickup_till_ts =
      item.morning_pickup_till_ts &&
      normalizeSilverayTime(item.morning_pickup_till_ts, timezone);
    if (
      morning_dropoff_ts &&
      morning_pickup_till_ts &&
      !parseSilverayTime(morning_pickup_till_ts, timezone).isSameOrBefore(
        parseSilverayTime(morning_dropoff_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `${name}: morning_pickup_till_ts (${morning_pickup_till_ts}) is not before morning_dropoff_ts (${morning_dropoff_ts})`
      );
    }

    const morning_pickup_scheduled_open_time =
      item.morning_pickup_scheduled_open_time || '';
    const morning_pickup_scheduled_close_time =
      item.morning_pickup_scheduled_close_time || '';

    const evening_pickup_ts = item.evening_pickup_ts
      ? normalizeSilverayTime(item.evening_pickup_ts, timezone)
      : undefined;
    D2.S.INFO('silverayNormalizeData:item:evening_pickup_ts', {
      evening_pickup_ts,
      item,
    });

    if (
      evening_pickup_ts &&
      !silverayDestinationTimes.C2H.has(evening_pickup_ts)
    ) {
      $errors.push(
        [
          `${name}: "${evening_pickup_ts}" is not allowed pickup time`,
          `Possible values are ${silverayPossibleDestinationTimes.C2H}`,
        ].join('. ')
      );
    }

    const evening_dropoff_from_ts =
      item.evening_dropoff_from_ts &&
      normalizeSilverayTime(item.evening_dropoff_from_ts, timezone);
    if (
      evening_pickup_ts &&
      evening_dropoff_from_ts &&
      !parseSilverayTime(evening_pickup_ts, timezone).isSameOrBefore(
        parseSilverayTime(evening_dropoff_from_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `evening_pickup_from_ts (${evening_pickup_ts}) is not before evening_dropoff_from_ts (${evening_dropoff_from_ts})`
      );
    }

    const evening_dropoff_till_ts =
      item.evening_dropoff_till_ts &&
      normalizeSilverayTime(item.evening_dropoff_till_ts, timezone);
    if (
      evening_pickup_ts &&
      evening_dropoff_till_ts &&
      !parseSilverayTime(evening_pickup_ts, timezone).isSameOrBefore(
        parseSilverayTime(evening_dropoff_till_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `evening_pickup_from_ts (${evening_pickup_ts}) is not before evening_dropoff_till_ts (${evening_dropoff_till_ts})`
      );
    }

    const evening_dropoff_scheduled_open_time =
      item.evening_dropoff_scheduled_open_time || '';
    const evening_dropoff_scheduled_close_time =
      item.evening_dropoff_scheduled_close_time || '';

    const uid = `${name} ${home.zip_code}`;

    const commonRecords = {
      ...item,
      ...weekdaysRecords,
      uid,
      name,
      country,
      city,
      calculation_group,
      morning_mutually_exclusive_group,
      evening_mutually_exclusive_group,
      special_demand,
      wheelchair,
      home,
      office,
      center_service_time,
      home_service_time,
      max_trip_duration,
      trip_cost,
      destination,
      // $destination_building,
      $errors,
    };

    const morningServiceRecords = {
      morning_pickup_from_ts,
      morning_pickup_till_ts,
      morning_dropoff_ts,
      morning_pickup_scheduled_open_time,
      morning_pickup_scheduled_close_time,
    };

    const eveningServiceRecords = {
      evening_pickup_ts,
      evening_dropoff_from_ts,
      evening_dropoff_till_ts,
      evening_dropoff_scheduled_open_time,
      evening_dropoff_scheduled_close_time,
    };

    return {
      ...commonRecords,
      ...morningServiceRecords,
      ...eveningServiceRecords,
    };
  });
  D2.S.INFO('importBookings:normalizedData', { jsonData, normalizedData });

  if (!ignoreErrors) {
    const error = normalizedData.find(item => item.$errors.length);
    if (error) {
      throw new Error(error.$errors[0]);
    }
  }

  return normalizedData;
};

export const generateVehiclesForEachBooking = (bookings, opts) => {
  D2.S.INFO('generateVehiclesForEachBooking:Request', { bookings, opts });

  const options = opts || {};

  const {
    areSpecialDemandsEnabled,
    human_readable_uids,
    currentDate,
    currentDestinationTimeName,
    currentDeliveryTypeTag,
    isMorning,
    timezone,
    vehicle_passenger,
    vehicle_wheelchair,
  } = options;

  const unassigned_only = options.unassigned_only || false;

  const vehicles = Object.keys(bookings).reduce((vehiclesAcc, uid) => {
    const booking = bookings[uid];

    const bookingSpecialDemand = areSpecialDemandsEnabled
      ? booking.$record.special_demand
      : undefined;
    D2.S.INFO('generateVehiclesForEachBooking:vehicles:bookingSpecialDemand', {
      areSpecialDemandsEnabled,
      bookingSpecialDemand,
    });

    if (unassigned_only && bookingSpecialDemand) {
      return vehiclesAcc;
    }

    const specialDemands = bookingSpecialDemand
      ? {
          [bookingSpecialDemand]: 1,
        }
      : {};

    const currentDestinationTimeTag = `#${currentDestinationTimeName}`;
    const currentCalculationGroupTag = `#CG:${
      booking.$record.calculation_group || 'None'
    }`;
    const currentSpecialDemandTag =
      bookingSpecialDemand && `#SD:${bookingSpecialDemand}`;
    const currentIdTag = `#${crc32(booking.$record.uid)}`;

    const newVehicleBase = JSON.parse(silverayVehicleTemplateJSON);
    const newVehicleUID = human_readable_uids
      ? [
          moment(currentDate).format('YYYY-MM-DD #ddd'),
          currentDeliveryTypeTag,
          currentDestinationTimeTag,
          currentCalculationGroupTag,
          currentSpecialDemandTag,
          currentIdTag,
        ]
          .filter(x => x)
          .join(' ')
      : uuidv4();
    const newVehicle = isMorning
      ? {
          ...newVehicleBase,
          start_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('08:30 AM', 'LT', timezone)
            .add(-180, 'minutes')
            .format('HH:mm:ssZ')}`,
          end_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('12:30 PM', 'LT', timezone)
            .format('HH:mm:ssZ')}`,
          agent_id: newVehicleUID,
          vehicle_color: crc32(newVehicleUID) % 360,
          routing_engine: {
            ...newVehicleBase.routing_engine,
            osrme_timestamp_mode: 'end_time',
          },
          capacity: {
            ...newVehicleBase.capacity,
            ...specialDemands,
            passenger: vehicle_passenger,
            wheelchair: vehicle_wheelchair,
          },
        }
      : {
          ...newVehicleBase,
          start_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('12:30 PM', 'LT', timezone)
            .format('HH:mm:ssZ')}`,
          end_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('17:30 PM', 'LT', timezone)
            .add(180, 'minutes')
            .format('HH:mm:ssZ')}`,
          agent_id: newVehicleUID,
          vehicle_color: crc32(newVehicleUID) % 360,
          routing_engine: {
            ...newVehicleBase.routing_engine,
            osrme_timestamp_mode: 'start_time',
          },
          capacity: {
            ...newVehicleBase.capacity,
            ...specialDemands,
            passenger: vehicle_passenger,
            wheelchair: vehicle_wheelchair,
          },
        };

    D2.S.INFO('generateVehiclesForEachBooking:vehicles:newVehicle', {
      newVehicle,
    });
    return [...vehiclesAcc, newVehicle];
  }, []);

  D2.S.INFO('generateVehiclesForEachBooking:Success', { vehicles });
  return vehicles;
};
