import settings from 'config/settings';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import Immutable, { fromJS } from 'immutable';
import { clearMemoCache } from 'utils/memoize';
import defaultVehicle from 'config/defaultVehicle';
import { registerSelectors } from 'reselect-tools';
import { dumpCommuteOfferChanges } from 'utils/CommuteOffer';

import debug from 'utils/debug';
import * as fleetActions from 'modules/fleet/types';
import * as uiActions from 'modules/ui/actions';
import * as actions from './actions';

import * as selectors from './selectors';
import { LOGISTICS_ROUTE } from 'utils/constants';

// import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

registerSelectors(selectors);

Object.keys(selectors).map((key) => {
  const selector = selectors[key];
  window[`geodisc$${key}`] = selector;
  return selector;
});

const D2 = debug('m:CommuteOffer:index');
const D0 = debug('_:geodisc');

export const initialState = fromJS({
  finishOfferLoading: false,
  isForceReloadEnabled: false,
  lastProcessedDataHash: null,
  lastProcessedRealtimeHash: null,
  serialNumber: 0,
  permissionsSerialNumber: 0,
  list: null,
  currentStorageIndex: 0,
  count: null,
  transitstopSets: [],
  loadedOffers: [],
  loadedSimulations: [],
  vehiclesRealtimeData: null,
});

global.geodisc$incSerialNumber = () =>
  D0.S.FUNCTION('commuteOffer$incSerialNumber', {}, () =>
    global.geodisc$store.dispatch({
      type: '@@commuteOffer/INC_SERIAL_NUMBER',
      payload: {},
    })
  );

global.geodisc$incPermissionSerialNumber = () =>
  D0.S.FUNCTION('geodisc$incPermissionSerialNumber', {}, () =>
    global.geodisc$store.dispatch({
      type: '@@commuteOffer/INC_PERMISSIONS_SERIAL_NUMBER',
      payload: {},
    })
  );

global.geodisc$fetchAllRoutes = () =>
  D0.S.FUNCTION('geodisc$fetchAllRoutes', {}, () =>
    global.geodisc$store.dispatch({
      type: '@@commuteOffer/FETCH_ALL_ROUTES_REQUEST',
    })
  );

global.GEODISC_COMMUTE_OFFER_ROUTES = Immutable.Map();

const getCurrentStorage = state =>
  D2.S.FUNCTION('getCurrentStorage', { state }, ({ $D2 }) => {
    const currentStorageIndex = state.get('currentStorageIndex');
    const storage = global.GEODISC_COMMUTE_OFFER_STORAGE;
    $D2.S.INFO('storage', { currentStorageIndex, storage, state });
    return storage.get(currentStorageIndex);
  });

const clearCurrentStorage = (opts = {}) =>
  D2.S.FUNCTION('clearCurrentStorage', { opts }, () => {
    const { keepRoutes = false, data = null } = opts;

    const routes =
      global.GEODISC_COMMUTE_OFFER_STORAGE && keepRoutes
        ? global.GEODISC_COMMUTE_OFFER_STORAGE.getIn(['0', 'routes'])
        : Immutable.fromJS({});

    global.GEODISC_COMMUTE_OFFER_STORAGE = Immutable.fromJS([
      {
        data,
        routes: {},
      },
    ]);

    if (keepRoutes) {
      global.GEODISC_COMMUTE_OFFER_STORAGE =
        global.GEODISC_COMMUTE_OFFER_STORAGE.setIn(['0', 'routes'], routes);
    }
  });

clearCurrentStorage();

const updateCurrentStorage = (state, reason, fn) =>
  D2.S.FUNCTION('updateCurrentStorage', { state, reason, fn }, ({ $D2 }) => {
    const { mode = 'update' } = reason;

    const currentStorage = getCurrentStorage(state);
    const currentOffer = currentStorage.get('data');

    const newStorage = fn(currentStorage);
    const isUpdated = !Immutable.is(currentStorage, newStorage);
    $D2.S.INFO('newStorage', { newStorage, currentStorage, isUpdated, reason });

    if (!isUpdated) {
      return state;
    }

    const currentStorageIndex = state.getIn(['currentStorageIndex']);
    $D2.S.INFO('currentStorageIndex', { currentStorageIndex, reason });

    if (mode === 'set' || !currentOffer) {
      global.GEODISC_COMMUTE_OFFER_STORAGE =
        global.GEODISC_COMMUTE_OFFER_STORAGE.setIn(
          [currentStorageIndex],
          newStorage
        );
    } else {
      global.GEODISC_COMMUTE_OFFER_STORAGE =
        global.GEODISC_COMMUTE_OFFER_STORAGE.slice(
          0,
          currentStorageIndex + 1
        ).push(newStorage);
    }

    return state.set(
      'currentStorageIndex',
      global.GEODISC_COMMUTE_OFFER_STORAGE.size - 1
    );
  });

export const updateCurrentOffer = (state, reason, fn) =>
  D2.S.FUNCTION('updateCurrentOffer', { state, reason, fn }, ({ $D2 }) =>
    updateCurrentStorage(state, reason, (currentStorage) => {
      const currentOffer = currentStorage.get('data');

      if (
        currentOffer &&
        (currentOffer.result.readOnly ||
          currentOffer.stateless_api_request_data.readOnly)
      ) {
        // global.openInfoMessage('This layer is read-only', { title: 'Error' });
        return currentStorage;
      }

      const newOffer = fn(currentOffer);
      $D2.S.INFO('newOffer', { newOffer, currentOffer, reason });
      if (!newOffer) {
        return currentStorage;
      }

      if (global.GEODISC_DEBUG_JSONDIFF_ENABLED) {
        dumpCommuteOfferChanges(reason, currentOffer, newOffer);
      }

      return currentStorage.setIn(['data'], newOffer);
    })
  );

const reducer = (state = initialState, { type, payload }) => {
  const newSerialNumber = (serialNumber, origin = type) => {
    // console.log(`--- serialNumber[${origin}]`, { serialNumber });
    return serialNumber;
  };

  switch (type) {
    case fleetActions.FLEET_ADD_VEHICLE_REQUEST: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [],
            $warnings: [],
            $fixes: [],
          },
        };
      });
    }
    case fleetActions.FLEET_ADD_VEHICLE_SUCCESS: {
      const newState = updateCurrentOffer(
        state,
        { type, payload },
        (currentOffer) => {
          const { stateless_api_request_data, result } = currentOffer;
          return {
            ...currentOffer,
            result: {
              ...result,
              $errors: [],
              $warnings: [],
              $fixes: [],
              success: {
                vehicle: payload?.service_number,
                action: 'add',
              },
            },
            stateless_api_request_data: {
              ...stateless_api_request_data,
              vehicles: [...stateless_api_request_data.vehicles, payload],
            },
          };
        }
      );
      return newState;
    }
    case fleetActions.FLEET_ADD_VEHICLE_FAILURE: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [...result?.$errors, payload],
            $warnings: [],
            $fixes: [],
          },
        };
      });
    }
    case fleetActions.FLEET_UPDATE_VEHICLE_REQUEST: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [],
            $warnings: [],
            $fixes: [],
          },
        };
      });
      // state.merge({ ...state, loading: true, error: '' });
    }
    case fleetActions.FLEET_UPDATE_VEHICLE_SUCCESS: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [],
            $warnings: [],
            $fixes: [],
            success: {
              vehicle: payload?.service_number,
              action: 'update',
            },
          },
          stateless_api_request_data: {
            ...stateless_api_request_data,
            vehicles: [...payload?.otherVehicles, payload?.updatedVehicle],
          },
        };
      });
    }
    case fleetActions.FLEET_UPDATE_VEHICLE_FAILURE: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [...result?.$errors, payload],
            $warnings: [],
            $fixes: [],
          },
        };
      });
    }
    case fleetActions.FLEET_DELETE_VEHICLE_REQUEST: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            $errors: [],
            $warnings: [],
            $fixes: [],
          },
        };
      });
      // state.merge({ ...state, loading: true, error: '' });
    }
    case fleetActions.FLEET_DELETE_VEHICLE_SUCCESS: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;
        const {
          nodes,
          bookings,
          vehicles,
          assignedBookings,
          rejectedBookings,
          vehicleResults,
        } = payload;

        return {
          ...currentOffer,
          result: {
            ...result,
            assigned_bookings: assignedBookings,
            rejected_bookings: rejectedBookings,
            vehicles: vehicleResults,
            $errors: [],
            $warnings: [],
            $fixes: [],
          },
          stateless_api_request_data: {
            ...stateless_api_request_data,
            vehicles,
            nodes,
            bookings,
          },
        };
      });
    }
    case fleetActions.FLEET_DELETE_VEHICLE_FAILURE: {
      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { result } = currentOffer;
        return {
          ...currentOffer,
          result: {
            ...result,
            // $errors: [...result?.$errors, payload],
            $warnings: [],
            $fixes: [],
          },
        };
      });
    }
    case actions.ENABLE_FORCE_RELOAD: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('isForceReloadEnabled', true);
    }
    case actions.ENABLE_FORCE_UPDATE: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const now = window.performance.now();

        const isUpdating =
          global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS &&
          global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS
            ? global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS >
              global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS
            : false;

        if (!isUpdating) {
          global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS =
            now - settings.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000;
          global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS =
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS - 1000;
        }

        return state;
      });
    }
    case actions.COMMUTE_OFFER_UNDO: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.update('currentStorageIndex', currentStorageIndex =>
        currentStorageIndex > 0 ? currentStorageIndex - 1 : currentStorageIndex
      );
    }
    case actions.COMMUTE_OFFER_REDO: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.update('currentStorageIndex', currentStorageIndex =>
        currentStorageIndex < global.GEODISC_COMMUTE_OFFER_STORAGE.size - 1
          ? currentStorageIndex + 1
          : currentStorageIndex
      );
    }
    case actions.TRANSITSTOPSETS_FETCH_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.TRANSITSTOPSETS_FETCH_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const data = payload.objects;

      return state.set('transitstopSets', data);
    }
    case actions.TRANSITSTOPSETS_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('transitstopSets', []);
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { newOffer } = payload;

      return updateCurrentOffer(state, { type, payload }, () => newOffer);
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const data = payload;

      return updateCurrentOffer(state, { type, payload }, currentOffer => ({
        ...currentOffer,
        result: data,
      }));
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const error = payload;

      global.openInfoMessage(error.message, { title: 'Error' });

      return state;
    }
    case actions.COMMUTE_OFFER_SHOW_VEHICLE_UPDATES: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.INC_SERIAL_NUMBER: {
      // D2.S.REDUX_EVENT_HANDLER(type, payload);

      global.GEODISC_INC_SERIAL_NUMBER_TS = moment().unix();

      return state.update('serialNumber', serialNumber =>
        newSerialNumber(serialNumber + 1)
      );
    }
    case actions.INC_PERMISSIONS_SERIAL_NUMBER: {
      // D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.update('permissionsSerialNumber', serialNumber =>
        newSerialNumber(serialNumber + 1)
      );
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const newOffer = payload;

      return updateCurrentOffer(state, { type, payload }, () => newOffer);
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const newOffer = payload;

      return updateCurrentOffer(state, { type, payload }, () => newOffer);
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case uiActions.SET_SERVICE_DATE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('finishOfferLoading', false);
    }
    // case uiActions.SET_IS_CHANGED: {
    //   D2.S.REDUX_EVENT_HANDLER(type, payload);

    //   return state;
    // }
    case uiActions.SET_IS_SAVING: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const isSaving = payload;

      if (!isSaving) {
        global.GEODISC_COMMUTE_OFFER_STORAGE = Immutable.List([
          global.GEODISC_COMMUTE_OFFER_STORAGE.last(),
        ]);
        return state.set('currentStorageIndex', 0);
      }
      return state;
    }
    case actions.COMMUTE_OFFER_FETCH_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        // eslint-disable-next-line no-console
        // console.log('--- COMMUTE_OFFER_FETCH_REQUEST', { payload });

        const T = global.TR()[0];
        const timings = {
          total: T.reduce((memo, info) => memo + info.t, 0.0),
          T,
        };
        // eslint-disable-next-line
        global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TIMINGS =
          global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TIMINGS
            ? [timings, ...global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TIMINGS]
            : [timings];
        // console.log('=== COMMUTE_OFFER_FETCH_REQUEST', timings);
        global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS =
          window.performance.now();

        const { isInitRequired = true } = payload;
        $D2.S.INFO('isInitRequired', { isInitRequired });

        if (!isInitRequired) {
          return state;
        }

        $D2.S.INFO('Init');

        clearMemoCache();

        global.WALKING_ROUTES = {};
        global.WALKING_ROUTES_BY_IDS = {};

        clearCurrentStorage();

        global.GEODISC_COMMUTE_OFFER_ROUTES = Immutable.Map();

        return state
          .set('currentStorageIndex', 0)
          .update('serialNumber', serialNumber =>
            newSerialNumber(serialNumber + 1)
          );
      });
    }
    case actions.COMMUTE_OFFER_FETCH_IGNORED: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state;
      });
    }
    case actions.COMMUTE_OFFER_FETCH_RESULTS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        // eslint-disable-next-line no-console
        // console.log('--- COMMUTE_OFFER_FETCH_RESULTS', { payload });
        global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS =
          window.performance.now();

        const updatedStateBase = state.set('isForceReloadEnabled', false);

        const { error } = payload;
        if (error) {
          return updatedStateBase;
        }

        const { result } = payload;

        const { rtinfos = {} } =
          result.commuteOffer.$source?.collections?.vehicles?.latest ?? {};

        const updatedStateWithVehiclesRealtimeData = updatedStateBase.set(
          'vehiclesRealtimeData',
          rtinfos
        );

        const lastProcessedDataHash = state.get('lastProcessedDataHash');
        if (result.loadedHash === lastProcessedDataHash) {
          return updatedStateWithVehiclesRealtimeData.set(
            'finishOfferLoading',
            true
          ); // set this to make sure that the data was fetched successfully before remove loading screen
        }

        // eslint-disable-next-line no-console
        console.log('%c*** UPDATED ***', 'background: #222; color: #ffffff');

        // const pathname = window.location.pathname;
        // const isRoutesOrOrdersPanelSelected =
        //   pathname === `${LOGISTICS_ROUTE}/routes` ||
        //   pathname === `${LOGISTICS_ROUTE}`;
        // if (
        //   result.loadedHash === lastProcessedDataHash &&
        //   !isRoutesOrOrdersPanelSelected
        // ) {
        //   return updatedStateWithVehiclesRealtimeData;
        // }

        const {
          commuteOffer,
          transitstopSets,
          loadedOffers,
          loadedSimulations,
        } = result;

        clearCurrentStorage();

        const newState = updatedStateWithVehiclesRealtimeData
          .set('finishOfferLoading', true) // set this to make sure that the data was fetched successfully before remove loading screen
          .set('lastProcessedDataHash', result.loadedHash)
          .set('loadedOffers', loadedOffers)
          .set('loadedSimulations', loadedSimulations)
          .set('transitstopSets', transitstopSets)
          .set('currentStorageIndex', 0);

        const resultState = updateCurrentOffer(
          newState,
          { type, payload },
          () => commuteOffer
        );
        return resultState;
      });
    }
    case actions.FETCH_ALL_ROUTES_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.FETCH_ALL_ROUTES_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { routes } = payload;

        global.GEODISC_COMMUTE_OFFER_ROUTES = routes.reduce(
          (memo, route) => memo.setIn([route.agent_id], route.data),
          global.GEODISC_COMMUTE_OFFER_ROUTES
        );

        return updateCurrentStorage(
          state,
          { type, payload, mode: 'set' },
          currentStorage =>
            $D2.S.FUNCTION('updateCurrentStorage:Fn', { currentStorage }, () =>
              routes.reduce((memo, route) => {
                return memo.setIn(['routes', route.agent_id], route.data);
              }, currentStorage)
            )
        ).update('serialNumber', serialNumber =>
          newSerialNumber(routes.length > 0 ? serialNumber + 1 : serialNumber)
        );
      });
    }
    case actions.FETCH_ALL_ROUTES_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.ROUTE_FETCH_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.ROUTE_FETCH_RESULTS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { error } = payload;
      if (error) {
        // eslint-disable-next-line
        console.log(error);
        return state;
      }

      const { data, agent_id } = payload;

      global.GEODISC_COMMUTE_OFFER_ROUTES =
        global.GEODISC_COMMUTE_OFFER_ROUTES.setIn([agent_id], data);

      return updateCurrentStorage(
        state,
        { type, payload, mode: 'set' },
        currentStorage => currentStorage.setIn(['routes', agent_id], data)
      ).update('serialNumber', serialNumber =>
        newSerialNumber(serialNumber + 1)
      );
    }
    case actions.ROUTE_FETCH_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { data, agent_id } = payload;

      global.GEODISC_COMMUTE_OFFER_ROUTES =
        global.GEODISC_COMMUTE_OFFER_ROUTES.setIn([agent_id], data);

      return updateCurrentStorage(
        state,
        { type, payload, mode: 'set' },
        currentStorage => currentStorage.setIn(['routes', agent_id], data)
      ).update('serialNumber', serialNumber =>
        newSerialNumber(serialNumber + 1)
      );
    }
    case actions.ROUTE_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFERS_FETCH_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const data = payload;

      return state
        .set('list', data.objects)
        .set('count', data.meta.total_count);
    }
    case actions.CLEAR_COMMUTE_OFFER: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      clearMemoCache();

      global.WALKING_ROUTES = {};
      global.WALKING_ROUTES_BY_IDS = {};

      clearCurrentStorage();
      // clearCurrentStorage({ data: JSON.parse(emptyCommuteOfferJSON) });

      global.GEODISC_COMMUTE_OFFER_ROUTES = Immutable.Map();

      return state
        .set('currentStorageIndex', 0)
        .update('serialNumber', serialNumber =>
          newSerialNumber(serialNumber + 1)
        );
    }
    case actions.RECALCULATE_VEHICLE_TIME: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.SET_RESULT_OFFER_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.SET_RESULT_OFFER_RESULTS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { error } = payload;
      if (error) {
        return state;
      }

      const { commuteOffer, mode } = payload;

      return updateCurrentOffer(
        state,
        { type, payload, mode },
        () => commuteOffer
      );
    }
    case actions.SET_RESULT_OFFER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { commuteOffer, mode } = payload;

      return updateCurrentOffer(
        state,
        { type, payload, mode },
        () => commuteOffer
      );
    }
    case actions.SET_RESULT_OFFER_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.SET_RESULT_VEHICLES: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { vehicleId, nodes } = payload;

      return updateCurrentOffer(state, { type, payload }, currentOffer => ({
        ...currentOffer,
        result: {
          ...currentOffer.result,
          vehicles: {
            ...currentOffer.result.vehicles,
            [vehicleId]: nodes,
          },
        },
      }));
    }
    case actions.SET_VEHICLE_START_TIME: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { vehicleId, time } = payload;

        return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
          const vehiclePath = currentOffer.result.vehicles[vehicleId];
          $D2.S.INFO('vehiclePath', { vehiclePath });

          const firstNode = vehiclePath[0];
          $D2.S.INFO('lastNode', { firstNode, vehiclePath });

          const oldTime = firstNode.scheduled_ts;
          const newTime = time;

          const timeDiff = moment(newTime).diff(moment(oldTime));
          $D2.S.INFO('timeDiff', { timeDiff, oldTime, newTime });

          const newPath = vehiclePath.map(node => ({
            ...node,
            scheduled_ts: moment(node.scheduled_ts).add(timeDiff).format(),
          }));
          $D2.S.INFO('newPath', { newPath, vehiclePath, timeDiff });

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...currentOffer.result.vehicles,
                [vehicleId]: newPath,
              },
            },
          };
        });
      });
    }
    case actions.SET_VEHICLE_END_TIME: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { vehicleId, time } = payload;

        return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
          const vehiclePath = currentOffer.result.vehicles[vehicleId];
          $D2.S.INFO('vehiclePath', { vehiclePath });

          const lastNode = vehiclePath[vehiclePath.length - 1];
          $D2.S.INFO('lastNode', { lastNode, vehiclePath });

          const oldTime = lastNode.scheduled_ts;
          const newTime = time;

          const timeDiff = moment(newTime).diff(moment(oldTime));
          $D2.S.INFO('timeDiff', { timeDiff, oldTime, newTime });

          const newPath = vehiclePath.map(node => ({
            ...node,
            scheduled_ts: moment(node.scheduled_ts).add(timeDiff).format(),
          }));
          $D2.S.INFO('newPath', { newPath, vehiclePath, timeDiff });

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...currentOffer.result.vehicles,
                [vehicleId]: newPath,
              },
            },
          };
        });
      });
    }
    case actions.SET_POINT_TO_ROUTE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { lon, lat, vehicleId, route, position } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        D2.S.TRACE($ => $('Current Offer:', currentOffer));

        const firstPartRoute = route.slice(0, position);
        const lastPartRoute = route.slice(position);
        D2.S.TRACE($ => $('Route Parts:', [firstPartRoute, lastPartRoute]));

        const point = {
          uid: uuidv4(),
          lat,
          lon,
          node_type: 'point',
          stop_id: null,
          location_name: null,
          service_time: 0,
          scheduled_ts: firstPartRoute.length
            ? firstPartRoute[firstPartRoute.length - 1].scheduled_ts
            : moment().tz(global.GEODISC_TIMEZONE).format(),
          booking_uid: null,
          location_code: null,
        };

        const newVehicle = [
          ...firstPartRoute.reduce(
            (acc, item) =>
              acc.concat(item.bookings.map(booking => booking.node)),
            []
          ),
          point,
          ...lastPartRoute.reduce(
            (acc, item) =>
              acc.concat(item.bookings.map(booking => booking.node)),
            []
          ),
        ];

        // currentOffer.stateless_api_request_data.nodes.push(point);

        const res = {
          ...currentOffer,
          stateless_api_request_data: {
            ...currentOffer.stateless_api_request_data,
            nodes: [...currentOffer.stateless_api_request_data.nodes, point],
          },
          result: {
            ...currentOffer.result,
            vehicles: {
              ...currentOffer.result.vehicles,
              [vehicleId]: newVehicle,
            },
          },
        };

        D2.S.TRACE($ => $('Result:', res));
        return res;
      });
    }
    case actions.SET_STOP_TO_ROUTE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { feature, vehicleId, route } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { vehicles } = currentOffer.result;
        const { coordinates } = feature.geometry;
        const { properties } = feature;

        const stop = {
          uid: `${Math.random()}`,
          lat: coordinates[1],
          lon: coordinates[0],
          stop_id: String(properties.id),
          location_code: properties.code,
          location_name: `${properties.name} #${properties.code}`,
          node_type: 'stop',
          service_time: 0,
          scheduled_ts: null,
          booking_uid: null,
        };

        const newVehicle = [
          ...route.reduce(
            (acc, item) =>
              acc.concat(item.bookings.map(booking => booking.node)),
            []
          ),
          stop,
        ];

        return {
          ...currentOffer,
          result: {
            ...currentOffer.result,
            vehicles: {
              ...vehicles,
              [vehicleId]: newVehicle,
            },
          },
        };
      });
    }
    case actions.CHANGE_ROUTE_POINT: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const { lon, lat, id, vehicleId } = payload;

        return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
          const { vehicles } = currentOffer.result;

          const currentVehicle = vehicles[vehicleId];

          if (!currentVehicle) {
            throw new Error(`Vehicle '${vehicleId}' does not exist.`);
          }

          const pointIndex = currentVehicle.findIndex(
            node => node.uid === id
          );

          if (pointIndex < 0) {
            throw new Error(`Node '${id}' does not exist.`);
          }

          currentVehicle[pointIndex] = {
            ...currentVehicle[pointIndex],
            lat,
            lon,
          };

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: currentVehicle,
              },
            },
          };
        });
      });
    }
    case actions.CHANGE_ROUTE_EMPTY_STOP: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { id, stop, vehicleId } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { vehicles } = currentOffer.result;

        const currentVehicle = vehicles[vehicleId];
        const pointIndex = currentVehicle.findIndex(node => node.uid === id);

        currentVehicle[pointIndex] = {
          ...currentVehicle[pointIndex],
          ...stop,
        };

        return {
          ...currentOffer,
          result: {
            ...currentOffer.result,
            vehicles: {
              ...vehicles,
              [vehicleId]: [...currentVehicle],
            },
          },
        };
      });
    }
    case actions.DELETE_ROUTE_POINT: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { id, vehicleId } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { vehicles } = currentOffer.result;

        const currentVehicle = vehicles[vehicleId];
        const pointIndex = currentVehicle.findIndex(node => node.uid === id);

        currentVehicle.splice(pointIndex, 1);

        return {
          ...currentOffer,
          result: {
            ...currentOffer.result,
            vehicles: {
              ...vehicles,
              [vehicleId]: currentVehicle,
            },
          },
        };
      });
    }
    case actions.RECALCULATE_ROUTE_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.RECALCULATE_ROUTE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { newOffer } = payload;

      if (newOffer) {
        return updateCurrentOffer(state, { type, payload }, () => newOffer);
      }
      return state;
    }
    case actions.RECALCULATE_ROUTE_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.ADD_BOOKING_TO_ROUTE_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.ADD_BOOKING_TO_ROUTE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { newOffer } = payload;

      if (newOffer) {
        return updateCurrentOffer(state, { type, payload }, () => newOffer);
      }
      return state;
    }
    case actions.ADD_BOOKING_TO_ROUTE_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.SET_BOOKING_NODE: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const nodes = payload;

        // eslint-disable-next-line arrow-parens
        return updateCurrentOffer(state, { type, payload }, (currentOffer) =>
          $D2.S.FUNCTION('Update', { currentOffer }, () => ({
            ...currentOffer,
            result: nodes.reduce(
              (acc, node) =>
                $D2.S.FUNCTION('Update:nodes.reduce', { acc, node }, () => {
                  const {
                    booking_uid,
                    stop_id,
                    node_type,
                    open_time_ts,
                    scheduled_ts,
                    $agent_id,
                  } = node;
                  const { assigned_bookings } = acc;

                  const assignedBookingIdx = booking_uid
                    ? assigned_bookings.findIndex(
                        item => item.uid === booking_uid
                      )
                    : -1;
                  $D2.S.INFO('Update:nodes.reduce:assignedBookingIdx', {
                    assignedBookingIdx,
                    booking_uid,
                  });

                  const assignedBooking =
                    assignedBookingIdx >= 0
                      ? assigned_bookings[assignedBookingIdx]
                      : null;
                  $D2.S.INFO('Update:nodes.reduce:assignedBooking', {
                    assignedBooking,
                    assignedBookingIdx,
                    booking_uid,
                  });

                  const currentVehicleId = $agent_id;
                  // assignedBooking.assigned_vehicle_id;
                  $D2.S.INFO('Update:nodes.reduce:currentVehicleId', {
                    currentVehicleId,
                  });

                  if (assignedBookingIdx >= 0) {
                    assigned_bookings[assignedBookingIdx] = {
                      ...assigned_bookings[assignedBookingIdx],
                      [`scheduled_${node_type}_stop_id`]: stop_id,
                      [`scheduled_${node_type}_time`]:
                        open_time_ts || scheduled_ts,
                    };
                  }

                  const currentVehicle = acc.vehicles[currentVehicleId];

                  if (!currentVehicle) {
                    return acc;
                  }

                  const index = booking_uid
                    ? currentVehicle.findIndex(
                        item =>
                          item.booking_uid === booking_uid &&
                          item.node_type === node_type
                      )
                    : currentVehicle.findIndex(
                        item => item.node_type === node_type
                      );

                  const newVehicle = {
                    booking_uid: node.booking_uid,
                    lat: node.lat,
                    lon: node.lon,
                    location_code: node.location_code,
                    location_name: node.location_name,
                    node_type: node.node_type,
                    service_time: node.service_time,
                    stop_id: node.stop_id,
                    uid: node.uid,
                    scheduled_ts: node.open_time_ts || node.scheduled_ts,
                  };

                  if (index === -1) {
                    return {
                      ...acc,
                      assigned_bookings,
                      vehicles: {
                        ...acc.vehicles,
                        [currentVehicleId]: [...currentVehicle, newVehicle],
                      },
                    };
                  }

                  return {
                    ...acc,
                    assigned_bookings: [...assigned_bookings],
                    vehicles: {
                      ...acc.vehicles,
                      [currentVehicleId]: acc.vehicles[currentVehicleId].map(
                        (vehicle) => {
                          const isChanged =
                            vehicle.booking_uid === booking_uid &&
                            vehicle.node_type === node_type;

                          if (isChanged) {
                            return {
                              ...vehicle,
                              ...newVehicle,
                            };
                          }

                          return vehicle;
                        }
                      ),
                    },
                  };
                }),
              currentOffer.result
            ),
          }))
        );
      });
    }
    case actions.ADD_NODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const newNodes = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { nodes } = currentOffer.stateless_api_request_data;

        return {
          ...currentOffer,
          stateless_api_request_data: {
            ...currentOffer.stateless_api_request_data,
            nodes: [...nodes, ...newNodes],
          },
        };
      });
    }
    case actions.REMOVE_ORDER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      const { newOffer } = payload;

      if (newOffer) {
        return updateCurrentOffer(state, { type, payload }, () => {
          return {
            ...newOffer,
          };
        });
      }
      return state;
    }
    case actions.REMOVE_ALL_BOOKING: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { assigned_bookings, rejected_bookings, vehicles } =
          currentOffer.result;
        const removedVehicleIdBookings = assigned_bookings.map((booking) => {
          return {
            uid: booking.uid,
            scheduled_dropoff_time: null,
            scheduled_pickup_time: null,
          };
        });
        return {
          ...currentOffer,
          result: {
            assigned_bookings: [],
            rejected_bookings: [
              ...rejected_bookings,
              ...removedVehicleIdBookings,
            ],
            vehicles,
          },
        };
      });
    }
    case actions.REMOVE_BOOKING_NODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { bookingId, nodeType } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { assigned_bookings, rejected_bookings, vehicles } =
          currentOffer.result;
        const assignedBookingIdx = assigned_bookings.findIndex(
          item => item.uid === bookingId
        );
        const currentVehicleId =
          assigned_bookings[assignedBookingIdx].assigned_vehicle_id;

        D2.S.TRACE($ => $('Booking:', assigned_bookings[assignedBookingIdx]));

        assigned_bookings[assignedBookingIdx] = {
          ...assigned_bookings[assignedBookingIdx],
          [`scheduled_${nodeType}_stop_id`]: null,
          [`scheduled_${nodeType}_time`]: null,
        };

        return {
          ...currentOffer,
          result: {
            assigned_bookings,
            rejected_bookings,
            vehicles: {
              ...vehicles,
              [currentVehicleId]: vehicles[currentVehicleId].map((vehicle) => {
                const isChanged =
                  vehicle.booking_uid === bookingId &&
                  vehicle.node_type === nodeType;

                if (isChanged) {
                  return {
                    booking_uid: bookingId,
                    node_type: nodeType,
                  };
                }

                return vehicle;
              }),
            },
          },
        };
      });
    }
    case actions.UPDATE_VEHICLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { vehicleId, vehicle } = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;

        const newRequest = {
          ...stateless_api_request_data,
          vehicles: stateless_api_request_data.vehicles.map((item) => {
            if (item.agent_id === vehicleId) {
              return vehicle;
            }

            return item;
          }),
        };

        if (vehicleId === vehicle.agent_id) {
          return {
            ...currentOffer,
            stateless_api_request_data: newRequest,
          };
        }

        const isExisting = stateless_api_request_data.vehicles.find(
          item => item.agent_id === vehicle.agent_id
        );

        if (isExisting) {
          return currentOffer;
        }

        const updatedVehicles = {
          ...result.vehicles,
          [vehicle.agent_id]: result.vehicles[vehicleId],
        };

        delete updatedVehicles[vehicleId];

        return {
          ...currentOffer,
          result: {
            ...result,
            assigned_bookings: result.assigned_bookings.map((booking) => {
              if (booking.assigned_vehicle_id === vehicleId) {
                return {
                  ...booking,
                  assigned_vehicle_id: vehicle.agent_id,
                };
              }

              return booking;
            }),
            vehicles: updatedVehicles,
          },
          stateless_api_request_data: newRequest,
        };
      });
    }
    case actions.CLONE_VEHICLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const vehicle = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;

        return {
          ...currentOffer,
          result: {
            ...result,
            vehicles: {
              ...result.vehicles,
              veh_new: [],
            },
          },
          stateless_api_request_data: {
            ...stateless_api_request_data,
            vehicles: [
              ...stateless_api_request_data.vehicles,
              {
                ...vehicle,
                agent_id: 'veh_new',
              },
            ],
          },
        };
      });
    }
    case actions.DELETE_VEHICLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const vehicleId = payload;

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;

        const requestVehicles = stateless_api_request_data.vehicles;

        const newResultVehicles = result.vehicles;

        delete newResultVehicles[vehicleId];

        const requestVehIdx = requestVehicles.findIndex(
          item => item.agent_id === vehicleId
        );

        return {
          ...currentOffer,
          result: {
            ...result,
            vehicles: newResultVehicles,
          },
          stateless_api_request_data: {
            ...stateless_api_request_data,
            vehicles: requestVehicles
              .slice(0, requestVehIdx)
              .concat(
                requestVehicles.slice(requestVehIdx + 1, requestVehicles.length)
              ),
          },
        };
      });
    }
    case actions.NEW_VEHICLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        const { stateless_api_request_data, result } = currentOffer;
        const vehicles = stateless_api_request_data.vehicles || [];

        return {
          ...currentOffer,
          result: {
            ...result,
            vehicles: {
              ...result.vehicles,
              veh_new: [],
            },
          },
          stateless_api_request_data: {
            ...stateless_api_request_data,
            vehicles: [...vehicles, defaultVehicle],
          },
        };
      });
    }
    case actions.CHANGE_ROUTE_ORDER: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const {
          currentIndex,
          newIndex,
          route,
          vehicleId,
          changeType = 'swap',
        } = payload;

        if (currentIndex === newIndex) {
          return state;
        }

        return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
          const requestVehicle =
            currentOffer.stateless_api_request_data.vehicles.find(
              vehicle => vehicle.agent_id === vehicleId
            );

          if (!requestVehicle || requestVehicle.readOnly) {
            return currentOffer;
          }

          const { vehicles } = currentOffer.result;

          const currentWaypoint = route[currentIndex];
          $D2.S.INFO('currentWaypoint', {
            currentWaypoint,
            route,
            currentIndex,
          });
          const newRoute = [...route];
          if (changeType === 'swap') {
            newRoute.splice(newIndex, 1, route[currentIndex]);
            newRoute.splice(currentIndex, 1, route[newIndex]);
          } else {
            // changeType === insert
            const element = newRoute.splice(currentIndex, 1)[0];
            newRoute.splice(newIndex, 0, element);
          }

          $D2.S.INFO('newRoute', {
            newRoute,
            route,
            newIndex,
            currentIndex,
            vehicleId,
          });

          const nodePositions = newRoute.reduce(
            (wpMemo, waypoint, waypointIndex) => {
              $D2.S.INFO('nodePositions:waypoint', {
                waypoint,
                waypointIndex,
              });
              if (!waypoint) {
                $D2.S.INFO('nodePositions:waypoint:null', {
                  waypoint,
                  waypointIndex,
                });
                return wpMemo;
              }
              return waypoint.bookings.reduce((nodesMemo, { node }) => {
                $D2.S.INFO('nodePositions:waypoint:node', {
                  node,
                  waypoint,
                  waypointIndex,
                });
                const { booking_uid, node_type } = node;
                const currentBookingInfo = nodesMemo[booking_uid] || {
                  pickup: -1,
                  dropoff: -1,
                };
                return {
                  ...nodesMemo,
                  [booking_uid]: {
                    ...currentBookingInfo,
                    [node_type]: waypointIndex,
                  },
                };
              }, wpMemo);
            },
            {}
          );
          $D2.S.INFO('nodePositions', {
            nodePositions,
            newRoute,
            newIndex,
            currentIndex,
          });

          const faultyNode = Object.values(nodePositions).find(
            nodeInfo =>
              nodeInfo.pickup === -1 ||
              nodeInfo.dropoff === -1 ||
              nodeInfo.pickup >= nodeInfo.dropoff
          );
          if (faultyNode) {
            $D2.S.INFO('faultyNode', {
              faultyNode,
              nodePositions,
              newRoute,
              newIndex,
              currentIndex,
            });
            // return null;
          }

          const newOffer = {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: newRoute.reduce((acc, item) => {
                  if (!item) {
                    return acc;
                  }

                  const { bookings } = item;
                  const nodes = bookings.map(booking => booking.node);

                  return acc.concat(nodes);
                }, []),
              },
            },
          };
          $D2.S.INFO('newOffer', {
            newOffer,
            newRoute,
            route,
            currentIndex,
            newIndex,
            vehicleId,
          });

          return newOffer;
        });
      });
    }
    case actions.ADD_COMMUTE_OFFER_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_COMMUTE_OFFER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_COMMUTE_OFFER_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.COMMUTE_OFFER_CLEAR_WARNINGS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return updateCurrentOffer(state, { type, payload }, (currentOffer) => {
        return {
          ...currentOffer,
          result: {
            ...currentOffer.result,
            $errors: global.GEODISC_COMMUTE_OFFER_ERROR_REPORTING_DISABLED
              ? []
              : currentOffer.result.$errors,
            $warnings: [],
            $fixes: [],
          },
        };
      });
    }
    case actions.COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      const { newOffer } = payload;

      if (newOffer) {
        return updateCurrentOffer(state, { type, payload }, () => {
          return {
            ...newOffer,
          };
        });
      }
      return state;
    }
    case actions.INVALIDATE_ALL_BOOKINGS_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('removeAllOrdersLoading', true);
    }
    case actions.INVALIDATE_ALL_BOOKINGS_RESULTS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('removeAllOrdersLoading', false)
        .set('removeAllOrdersResult', payload);
    }
    default:
      return state;
  }
};

export default reducer;
