import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { geofencesSelector } from 'modules/geofences/selectors';
import { commuteOfferEditVehicleRequest } from 'modules/commuteOffer/actions';
import {
  fleetAddVehicle,
  // addVehicle,
  updateVehicle,
} from 'modules/fleet/actions';
import { commuteOfferLoadedSimulationSelector } from 'modules/commuteOffer/selectors';
import {
  vehiclesSelector,
  vehicleTypesSelector,
} from 'modules/fleet/selectors';
import VehicleEditorV2 from './VehicleEditorV2';

const mapStateToProps = createImmutableSelector(
  geofencesSelector,
  vehicleTypesSelector,
  commuteOfferLoadedSimulationSelector,
  vehiclesSelector,
  (geofences, vehicleTypes, simulation, vehicles) => ({
    geofences,
    vehicleTypes,
    simulation,
    vehicles,
  })
);

const mapDispatchToProps = {
  fleetAddVehicle,
  // addVehicle,
  updateVehicle,
  commuteOfferEditVehicleRequest,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VehicleEditorV2);
