import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import locale_en from './locale/en.json';
import locale_pl from './locale/pl.json';
import locale_ru from './locale/ru.json';
import locale_jp from './locale/jp.json';
import locale_th from './locale/th.json';

import args from './args';

import { makeLinearFromStructured } from './utils/object';

const availableResources = {
  en: {
    translation: locale_en,
  },
  pl: {
    translation: locale_pl,
  },
  ru: {
    translation: locale_ru,
  },
  jp: {
    translation: locale_jp,
  },
  th: {
    translation: locale_th,
  },
};

const resources = global.GEODISC_I18N_DEBUG_ENABLED
  ? {
      en: {
        translation: Object.entries(makeLinearFromStructured(locale_en)).reduce(
          (memo, [k, v]) => ({
            ...memo,
            [k]: `🌍${v}`,
          })
        ),
      },
    }
  : availableResources;

global.GEODISC_UNTRANSLATED_RESOURCES = Object.entries(
  availableResources
).reduce((resourcesMemo, [lang, data]) => {
  if (data.translation === locale_en) {
    return resourcesMemo;
  }
  return {
    ...resourcesMemo,
    [lang]: Object.keys(locale_en).reduce((memo, key) => {
      if (!data.translation[key]) {
        return {
          ...memo,
          [key]: locale_en[key],
        };
      }
      return memo;
    }),
  };
}, {});

const detectLanguage = () => {
  const languageName = global.GEODISC_I18N_DEBUG_ENABLED
    ? 'en'
    : args.hash.get('lang') ||
      args.search.get('lang') ||
      global.localStorage.getItem('user:language') ||
      'en';
  // console.log('Language:', languageName);
  return resources[languageName] ? languageName : 'en';
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: detectLanguage(),

    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
