import waterfall from 'async/waterfall';

import debug from 'utils/debug';

import { dumpCommuteOfferChanges } from 'utils/CommuteOffer';

import validators from './validators';

import LegacyValidator from './Validators/Legacy';
import TransitstopsValidator from './Validators/Transitstops';
import AssignmentOrderValidator from './Validators/AssignmentOrder';
import RequestResultOrders from './Validators/RequestResultOrders';

const D2 = debug('u:CommuteOffer:Validation');

const prepareCommuteOffer = commuteOffer => ({
  ...commuteOffer,
  stateless_api_request_data: {
    ...commuteOffer.stateless_api_request_data,
    engine_settings: {
      ...commuteOffer.stateless_api_request_data.engine_settings,
      calculation_parameters: {
        ...commuteOffer.stateless_api_request_data.engine_settings
          .calculation_parameters,
        calculations_mode: 'async',
      },
    },
  },
  result: {
    ...commuteOffer.result,
    $errors: [],
    $warnings: [],
    $fixes: [],
  },
});

export const validateCommuteOffer = async commuteOffer =>
  D2.A.FUNCTION('validateCommuteOffer', { commuteOffer }, async () => {
    const LegacyValidatorResult = await LegacyValidator(
      prepareCommuteOffer(commuteOffer)
    );

    const TransitstopsValidatorResult = await TransitstopsValidator(
      LegacyValidatorResult
    );

    const AssignmentOrderValidatorResult = await AssignmentOrderValidator(
      TransitstopsValidatorResult
    );

    const RequestResultOrdersResult = await RequestResultOrders(
      AssignmentOrderValidatorResult
    );

    const result = RequestResultOrdersResult;

    if (global.GEODISC_DEBUG_JSONDIFF_ENABLED) {
      dumpCommuteOfferChanges(
        'validateCommuteOffer',
        LegacyValidatorResult,
        TransitstopsValidatorResult
      );
    }

    return result;
  });

export const validateCommuteOfferWaterfall = async commuteOffer =>
  D2.A.FUNCTION(
    'validateCommuteOfferWaterfall',
    { commuteOffer },
    async ({ $D2 }) => {
      const wfValidators = [
        ...[
          $D2.S.$FN(
            callback =>
              callback(null, prepareCommuteOffer({ ...commuteOffer, $sn: 0 })),
            '0'
          ),
        ],
        ...validators.map((validator, i) => {
          const fn = (data, callback) =>
            validator(data)
              .then(newOffer =>
                $D2.S.FUNCTION('then', { newOffer }, () =>
                  callback(null, newOffer)
                )
              )
              .catch(err =>
                $D2.S.FUNCTION('catch', { err }, () =>
                  callback(null, {
                    ...data,
                    result: {
                      ...data.result,
                      $errors: [
                        ...[{ type: 'exception', error: err }],
                        ...data.result.$errors,
                      ],
                    },
                  })
                )
              );
          return $D2.S.$FN(fn, `${i}`);
        }),
      ];
      return new Promise((resolve) => {
        waterfall(wfValidators, (err, res) =>
          D2.A.FUNCTION('result', { err, res }, () => {
            return resolve(res);
          })
        );
      });
    }
  );

export default validateCommuteOfferWaterfall;
