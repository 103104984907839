import moment from 'moment-timezone';

export const filteredGeofences = (geofences) => {
  return (geofences || [])?.map((geofence) => {
    return { value: geofence?.key, label: geofence?.name };
  });
};

export const vehicleModelsList = (vehicleTypes) => {
  return (vehicleTypes || [])?.map((model) => {
    return { value: JSON.stringify(model), label: model?.id };
  });
};

export const getReadableTime = ({ time, timezone }) => {
  return time
    ? moment
        .tz(moment(time).tz(timezone).format('HH:mm'), 'LT', moment.tz.guess())
        .toDate()
    : null;
};
