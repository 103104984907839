import { all, fork } from 'redux-saga/effects';

import DatasetsSaga from './datasets/saga';
import GeofencesSaga from './geofences/saga';
import SimulationsSaga from './simulations/saga';
import CommuteOfferSaga from './commuteOffer/saga';
import EntitiesSaga from './entities/saga';

import UiSaga from './ui/saga';
import UserSaga from './user/saga';
import MapsSaga from './maps/saga';

const sagas = [
  DatasetsSaga,
  GeofencesSaga,
  SimulationsSaga,
  UiSaga,
  UserSaga,
  CommuteOfferSaga,
  EntitiesSaga,
  MapsSaga,
];

function* Saga() {
  yield all(sagas.map(saga => fork(saga)));
}

export default Saga;
