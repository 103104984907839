import url from 'url';

import debug from 'utils/debug';

const D2 = debug('u:args');

export const parseLocationArgs = str =>
  str ? new Map(str.split('&').map(v => v.split('='))) : new Map();

export const locationUrl = () =>
  typeof window !== 'undefined' ? url.parse(window.location.href) : {};

const locationHashUrl = () =>
  typeof window !== 'undefined'
    ? url.parse(
        window.location.hash && window.location.hash[0] === '#'
          ? window.location.hash.substr(1)
          : window.location.hash
      )
    : {};

const search = () => parseLocationArgs(locationUrl().query);
const hash = () => parseLocationArgs(locationHashUrl().query);

const args = {
  search: search(),
  hash: hash(),
};

const get = name => D2.S.FUNCTION('get', { name }, () => hash().get(name) || search().get(name));

const getIds = name =>
  D2.S.FUNCTION('getIds', { name }, () =>
    [...new Set((get(name) || '').split(',').map(x => parseInt(x, 10)))].filter(
      x => !Number.isNaN(x)
    )
  );

const setWindowLocation = path => {
  // console.log('REDIRECT:', path);
  if (window.location.href === path || window.location.pathname === path) {
    // console.log('ERROR: New location is the same!!!');
    return false;
  }
  setTimeout(() => {
    window.location.href = path;
  }, 0);
  return true;
};

global.geodisc$setWindowLocation = setWindowLocation;

export default { ...args, get, getIds, setWindowLocation };
