import { memoize_async } from 'utils/memoize';
import { sleep } from 'utils/async';

import debug from 'utils/debug';

const D2 = debug('api:net');

export const fetchData = async (url, opts) =>
  D2.A.FUNCTION('fetchData', { url, opts }, async ({ $D2 }) => {
    const {
      method = 'GET',
      maxAttempts = global.NET_FETCH_MAX_ATTEMPTS || 10,
      t = m => m,
      isExceptionEnabled = true,
    } = opts || {};
    const options = opts?.body
      ? { ...opts, body: JSON.parse(opts.body) }
      : opts;

    let status = 200;

    const buildHttpError = () => {
      if (isExceptionEnabled) {
        throw new Error(status);
      } else {
        return {
          error: 'http',
          status,
        };
      }
    };

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 1; attempt <= maxAttempts; ++attempt) {
      $D2.S.INFO(method, { url, options, attempt });
      $D2.S.TRACE($ => $(method, url, options));

      // eslint-disable-next-line no-await-in-loop
      const response = await global.fetch(url, {
        ...opts,
        maxAttempts: undefined,
      });
      status = response.status;
      $D2.S.INFO('response', { response });

      if (status === 401) {
        global.localStorage.removeItem(global.GEODISC_AUTH_LOGIN);
        global.localStorage.removeItem(global.GEODISC_AUTH_TOKEN);
        const nextAddress = window.location.pathname + window.location.search;
        global.geodisc$setWindowLocation(
          `/login?next=${encodeURIComponent(nextAddress)}`
        );
        return buildHttpError();
      }

      if (status === 403 || status === 404) {
        // eslint-disable-next-line no-await-in-loop
        const jsonResponse = await response.json();
        $D2.S.INFO('jsonResponse', { jsonResponse });
        if (jsonResponse.error) {
          if (
            jsonResponse.error === 'Some postal codes not found' &&
            Array.isArray(jsonResponse?.details)
          ) {
            throw new Error(
              `${t(jsonResponse.error)}: ${jsonResponse.details.join(', ')}`
            );
          }
          if (jsonResponse.error === 'Some bookings are without postal codes') {
            throw new Error(
              t(
                'There is one or more postal code(s) missing. Please check the import file.'
              )
            );
          }
          throw new Error(t(jsonResponse.error));
        }
        return buildHttpError();
      }

      if (response.ok || (status >= 400 && status <= 404) || status === 504) {
        return response;
      }

      // eslint-disable-next-line no-await-in-loop
      await sleep(1000 + Math.floor(Math.random() * attempt * 500) + 1);
    }

    return buildHttpError();
  });

export const memoFetchData = memoize_async(fetchData);

export const fetchJSON = async (url, opts) =>
  D2.A.FUNCTION('fetchJSON', { url, opts }, async () => {
    const response = await fetchData(url, opts);

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  });

export const memoFetchJSON = memoize_async(fetchJSON);
