import * as React from 'react';
import { debounce } from 'debounce';
import { styled } from 'baseui';
import search from './search.svg';

const Form = styled('div', () => {
  return {
    position: 'relative',
    width: '100%',
    display: 'inline-block',
    marginRight: '10px',
    marginBottom: '10px',
    marginTop: '10px',
    background:
      'linear-gradient(180deg, #0C0F14 84.78%, rgba(12, 15, 20, 0) 100%)',
  };
});

const Input = styled('input', ({ $theme }) => {
  const colors = $theme?.colors;
  return {
    display: 'inline-block',
    width: '100%',
    padding: '8px 16px 8px 44px',
    border: 'none',
    borderRadius: '4px',
    // boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontStyle: 'normal',
    color: colors.inputBorder,
    backgroundColor: colors.menuBackground,
    lineHeight: '140%',
  };
});

const Img = styled('img', () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '18px',
    transform: 'translateY(-50%)',
  };
});

const Filter = (props) => {
  const { t, changeFilter, placeholder } = props;

  const delayedChangeFilterCriteria = debounce(changeFilter, 300);

  const onChange = (event) => {
    event.persist();
    delayedChangeFilterCriteria(event.target.value.toLowerCase());
  };

  return (
    <Form>
      <Img src={search} />
      <Input
        type='text'
        onChange={onChange}
        defaultValue={window.GEODISC_DEBUG_DEFAULT_VEHICLES_PANEL_FILTER || ''}
        placeholder={placeholder || t('c.search.placeholder')}
      />
    </Form>
  );
};

export default React.memo(Filter);
