import ErrorPage from 'pages/Error';
import Dataset from 'pages/Dataset';
import Datasets from 'pages/Datasets';
import Geofences from 'pages/Geofences';
import Simulation from 'pages/Simulation';
import Simulations from 'pages/Simulations';
import CommuteOffer from 'pages/CommuteOffer';
import CommuteOffers from 'pages/CommuteOffers';
import Logistics from 'pages/Logistics';

import RootRedirect from './RootRedirect';
import LoginRedirect from './LoginRedirect';

import {
  LOGISTICS_SERVICES_ROUTE,
  LOGISTICS_ROUTE,
  ACCESS_DENIED_URL,
} from '../../utils/constants';

const routes = [
  {
    path: '/',
    exact: true,
    component: RootRedirect,
  },
  {
    path: '/login',
    component: LoginRedirect,
  },
].concat(
  window.GEODISC_UI_DATASETS_DISABLE
    ? []
    : [
        {
          path: '/dataset/:id',
          component: Dataset,
        },
        {
          path: '/datasets/:page',
          component: Datasets,
        },
        {
          path: '/datasets',
          component: Datasets,
        },
      ],
  window.GEODISC_UI_GEOFENCES_DISABLE
    ? []
    : [
        {
          path: '/geofences',
          component: Geofences,
        },
      ],
  window.GEODISC_UI_SIMULATIONS_DISABLE
    ? []
    : [
        {
          path: '/simulation/:id',
          component: Simulation,
        },
        {
          path: '/simulations/:page',
          component: Simulations,
        },
        {
          path: '/simulations',
          component: Simulations,
        },
      ],
  window.GEODISC_UI_COMMUTE_OFFER_DISABLE
    ? []
    : [
        {
          path: '/commuteoffers/:page',
          component: CommuteOffers,
        },
        {
          path: '/commuteoffers',
          component: CommuteOffers,
        },
        {
          path: '/commuteoffer',
          component: CommuteOffer,
        },
        {
          path: '/map',
          component: CommuteOffer,
        },
        {
          path: LOGISTICS_SERVICES_ROUTE,
          component: CommuteOffer,
        },
        {
          path: '/logistics-template',
          component: CommuteOffer,
        },
      ],
  [
    {
      path: LOGISTICS_ROUTE,
      component: Logistics,
    },
  ],
  [
    {
      path: ACCESS_DENIED_URL,
      component: ErrorPage,
      message: '🛑  - Access denied',
    },
    {
      path: '*',
      component: ErrorPage,
    },
  ]
);

export default routes;
