import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';

import debug from 'utils/debug';
import MethodBtn from './MethodBtn';
import manual from 'assets/manual.svg';
import auto from 'assets/autoAssign.svg';
import { useStyletron } from 'baseui';
import { useState } from 'react';

const D2 = debug('c:ModalWindows:MethodWindow');

export default (props) => {
  D2.S.INFO('MethodWindow', props);
  const [css] = useStyletron('');
  const [actions, setActions] = useState({});
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const { hasOnlineVehicles } = props;

  React.useEffect(() => {
    window.openMethodWindow = (opts) => {
      const options = opts || {};
      setActions(options);
      setIsOpen(true);
    };
    window.closeMethodWindow = () => {
      setIsOpen(false);
    };
    return () => {
      window.MethodWindow = null;
    };
  });

  function onClose() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '648px',
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            color: '#fff',
            fontFamily: 'Montserrat !important',
            fontSize: '24px',
            fontWeight: '700 !important',
            marginTop: '10px !important',
          })}
          data-testid='MethodWindow-Header'
        >
          {t('c.ModalWindow.ChooseMethod.Title')}
        </ModalHeader>
        <ModalBody data-testid='MethodWindow-Body'>
          <MethodBtn
            img={manual}
            title={t('c.ModalWindow.ChooseMethod.button.manual.title')}
            description={t(
              'c.ModalWindow.ChooseMethod.button.manual.description'
            )}
            onClick={() => {
              actions.manualAction();
              setIsOpen(false);
            }}
          />
          <MethodBtn
            img={auto}
            title={t('c.ModalWindow.ChooseMethod.button.auto.title')}
            description={t(
              'c.ModalWindow.ChooseMethod.button.auto.description'
            )}
            onClick={() => {
              setIsOpen(false);
              global.openWarningMessage({
                title: t('c.ModalWindow.AutoAssignment.modal.title'),
                message: `
                    ${t('c.ModalWindow.AutoAssignment.modal.message')}
                    <br />
                    <br />
                    <b>${t('c.ModalWindow.AutoAssignment.modal.warning')}</b>
                  `,
                buttons: [
                  {
                    text: t('c.messages.Cancel'),
                    fill: false,
                  },
                  {
                    text: t('c.ModalWindow.AutoAssignment.modal.button'),
                    action: () => {
                      actions.autoAction();
                      global.closeWarningMessage();
                    },
                    fill: true,
                  },
                ],
              });
            }}
            disabled={hasOnlineVehicles}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

window.openMethodWindow = () => {};
window.closeMethodWindow = () => {};
