import Immutable from 'immutable';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api$User as api } from 'api';
import debug from 'utils/debug';
import * as actions from './actions';
import * as datasetsActions from '../datasets/actions';
import * as geofencesActions from '../geofences/actions';
import * as simulationsActions from '../simulations/actions';
import * as mapsActions from '../maps/actions';

import { currentProjectSelector } from './selectors';

const D2 = debug('m:user:saga');

function* authorization({ payload }) {
  D2.S.INFO('authorization:Request', payload);

  try {
    const { username, isAuto } = payload;

    const userData = yield call(api.getUser, username);
    D2.S.INFO('authorization:userData', { userData, username });

    const user = userData?.objects?.[0];

    global.localStorage.setItem(global.GEODISC_AUTH_LOGIN, username);

    let tokenData = { token: '0' };
    if (window.GEODISC_API_WEBSOCKET_AUTHORIZATION_ENABLED) {
      tokenData = yield call(api.getToken);
      D2.S.INFO('authorization:tokenData', { tokenData });
    }

    const projectMembers = yield call(api.getProjectMembersByUser, username);

    const projects = yield call(api.getProjects);
    D2.S.INFO('authorization:projects', { projects, userData, username });

    D2.S.INFO('authorization:Success', payload);
    yield put({
      type: actions.AUTHORIZATION_SUCCESS,
      payload: {
        user: Immutable.fromJS(user || {}),
        token: Immutable.fromJS(tokenData || {}),
        projects: Immutable.fromJS(projects?.objects || []),
        projectMembers: Immutable.fromJS(projectMembers?.objects || []),
      },
    });

    // redirect
    if (!isAuto) {
      const nextUrl = global.localStorage.getItem('next');
      global.localStorage.removeItem('next');
      const redirectUrl =
        nextUrl || window.GEODISC_UI_DEFAULT_ROUTE || '/datasets';
      D2.S.INFO('authorization:redirect', {
        redirectUrl,
        isAuto,
        search: global.GEODISC_LOCATION_SEARCH_OPTS,
      });
      yield put(push(redirectUrl));
    } else {
      D2.S.INFO('authorization:redirect', { isAuto });
      global.localStorage.removeItem('next');
    }
  } catch (error) {
    D2.S.INFO('authorization:Failure', { error });

    global.localStorage.removeItem(global.GEODISC_AUTH_LOGIN);
    global.localStorage.removeItem(global.GEODISC_AUTH_TOKEN);
    global.localStorage.removeItem('next');

    yield put({ type: actions.AUTHORIZATION_FAILURE, payload: error });
  }
}

// function* fetchProjects() {
//   try {
//     const data = yield call(api.getProjects);
//     yield put({
//       type: actions.PROJECTS_FETCH_SUCCESS,
//       payload: Immutable.fromJS(data.objects),
//     });
//   } catch (error) {
//     yield put({ type: actions.PROJECTS_FETCH_FAILURE, payload: error });
//   }
// }

function* changeProject() {
  D2.S.INFO('changeProject');

  yield put({ type: datasetsActions.CLEAR_LIST });
  yield put({ type: geofencesActions.CLEAR_LIST });
  yield put({ type: simulationsActions.CLEAR_LIST });

  const project = yield select(currentProjectSelector);
  const lat = project.get('lat');
  const lon = project.get('lon');

  D2.S.INFO('changeProject:changeViewportAllMaps', { project, lat, lon });
  yield put(mapsActions.changeViewportAllMaps(lat, lon));
}

function* Saga() {
  yield takeLatest(actions.AUTHORIZATION_REQUEST, authorization);
  // yield takeLatest(actions.PROJECTS_FETCH_REQUEST, fetchProjects);
  // yield takeLatest(actions.AUTHORIZATION_SUCCESS, fetchProjects);
  yield takeLatest(actions.CURRENT_PROJECT_CHANGE, changeProject);
}

export default Saga;
