// @flow
import debug from 'utils/debug';
import { fetchJSON } from './net';
import { getHeaders } from './headers';
import { urls } from '../config';

const D2 = debug('api:user');

export const getUser = async username =>
  D2.A.FUNCTION('getUser', { username }, async () =>
    fetchJSON(urls.user(username), {
      headers: getHeaders(),
    })
  );

export const getToken = async () =>
  D2.A.FUNCTION('getToken', {}, async () =>
    fetchJSON(urls.token, {
      headers: getHeaders(),
    })
  );

export const getProjects = async () =>
  D2.A.FUNCTION('getProjects', {}, async () =>
    fetchJSON(urls.projects, {
      headers: getHeaders(),
    })
  );

export const getProjectMembersByUser = async username =>
  D2.A.FUNCTION('getProjectMembersByUser', { username }, async () =>
    fetchJSON(urls.projectMemberByUser(username), {
      headers: getHeaders(),
    })
  );
