const hasMatchedAddress = ({ lat, lon, address }) => {
  if (lat === 0 && lon === 0) {
    return '';
  }
  return address;
};

const getStatus = ({ matchedAddress, item, statusLabels, isPickup }) => {
  //When the address has been manually confirmed
  if (
    (isPickup && item?.data?.confirm_pickup_address) ||
    (!isPickup && item?.data?.confirm_dropoff_address)
  ) {
    return statusLabels.manually;
  }

  // when it has lat and lon on the response object
  else if (matchedAddress) {
    return statusLabels.matched;
  }
  // When the Geocoding engine gives multiple variants
  else if (
    (isPickup && item?.data?.pickup_location_variants?.length > 1) ||
    (!isPickup && item?.data?.dropoff_location_variants?.length > 1)
  ) {
    return statusLabels.enter;
  }
  // When the Geocoding engine gives only one variant
  else if (
    (isPickup && item?.data?.pickup_location_variants?.length === 1) ||
    (!isPickup && item?.data?.dropoff_location_variants?.length === 1)
  ) {
    return statusLabels.check;
  } else {
    return statusLabels.enter;
  }
};
// Sort addresses 'Enter matched address', 'Check matched address', 'Manually matched', 'Matched'
export const sortAddressesList = ({ addressList, statusLabels }) => {
  const sortedAddressList = [...addressList];

  const ordering = {};
  const sortOrder = [
    statusLabels.enter,
    statusLabels.check,
    statusLabels.manually,
  ];

  sortOrder.forEach((item, i) => {
    return (ordering[sortOrder[i]] = i);
  });

  sortedAddressList.sort((a, b) => {
    return (
      ordering[a.status] - ordering[b.status] ||
      a.status.localeCompare(b.status)
    );
  });

  return sortedAddressList;
};
/**
 * get list of addresses after dropping all the duplicates
 */
export const getAddressList = ({ orders, statusLabels }) => {
  const finalAddressDictionary = {};
  orders?.map((item) => {
    // Extract drop-off address
    const matchedDropOffAddress = hasMatchedAddress({
      lat: item?.dropoff_location_lat,
      lon: item?.dropoff_location_lon,
      address: item?.dropoff_location_name,
    });

    const dropoffAddressStatus = getStatus({
      matchedAddress: matchedDropOffAddress,
      statusLabels,
      item,
    });

    const combinedDropoffAddress = [
      item?.data?.raw_order_record?.dropoff_address,
      item?.data?.raw_order_record?.dropoff_address2,
      item?.data?.raw_order_record?.dropoff_zip_code,
    ]
      .filter(item => item)
      .join(' ');

    finalAddressDictionary[combinedDropoffAddress] = {
      enteredAddress: combinedDropoffAddress,
      matchedAddress:
        dropoffAddressStatus === statusLabels.check ||
        dropoffAddressStatus === statusLabels.manually
          ? item?.data?.dropoff_location_variants[0]?.address
          : matchedDropOffAddress,
      status: dropoffAddressStatus,
      AddressTypesList: finalAddressDictionary?.[combinedDropoffAddress]
        ?.AddressTypesList
        ? [
            ...finalAddressDictionary?.[combinedDropoffAddress]
              ?.AddressTypesList,
            { uid: item?.uid, type: 'dropoff' },
          ]
        : [{ uid: item?.uid, type: 'dropoff' }],
    };

    // Extract pickup address
    const matchedPickUpAddress = hasMatchedAddress({
      lat: item?.pickup_location_lat,
      lon: item?.pickup_location_lon,
      address: item?.pickup_location_name,
    });

    const pickupAddressStatus = getStatus({
      matchedAddress: matchedPickUpAddress,
      item,
      statusLabels,
      isPickup: true,
    });

    const combinedPickupAddress = [
      item?.data?.raw_order_record?.pickup_address,
      item?.data?.raw_order_record?.pickup_address2,
      item?.data?.raw_order_record?.pickup_zip_code,
    ]
      .filter(item => item)
      .join(' ');

    finalAddressDictionary[combinedPickupAddress] = {
      enteredAddress: combinedPickupAddress,
      matchedAddress:
        pickupAddressStatus === statusLabels.check ||
        pickupAddressStatus === statusLabels.manually
          ? item?.data?.pickup_location_variants[0]?.address
          : matchedPickUpAddress,

      status: pickupAddressStatus,
      AddressTypesList: finalAddressDictionary?.[combinedPickupAddress]
        ?.AddressTypesList
        ? [
            ...finalAddressDictionary?.[combinedPickupAddress]
              ?.AddressTypesList,
            { uid: item?.uid, type: 'pickup' },
          ]
        : [{ uid: item?.uid, type: 'pickup' }],
    };
  });

  const addressList = [];
  Object.keys(finalAddressDictionary)?.forEach((address) => {
    addressList.push(finalAddressDictionary[address]);
  });

  return sortAddressesList({ addressList, statusLabels });
};

/**
 * get geocoded address from booking object. show '-' if lat & lon are 0
 */
export const getGeoCodedLocationName = ({ type, addressData }) => {
  const {
    pickup_location_lat,
    pickup_location_lon,
    pickup_location_name,
    dropoff_location_lat,
    dropoff_location_lon,
    dropoff_location_name,
  } = addressData;

  if (type === 'pickup') {
    if (pickup_location_lat === 0 && pickup_location_lon === 0) {
      return '-';
    } else {
      return pickup_location_name;
    }
  } else {
    if (dropoff_location_lat === 0 && dropoff_location_lon === 0) {
      return '-';
    } else {
      return dropoff_location_name;
    }
  }
};
