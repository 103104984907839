export default {
  token: window.GEODISC_MAPBOX_TOKEN,
  mapStyle: 'mapbox://styles/njachowski/cjxekj0sn00lq1cnst0fbpe4t',
  // mapStyle: 'mapbox://styles/njachowski/ck7yfctgx0uer1ir03rqh3sgh',
  initialViewState: {
    longitude: 103.732704,
    latitude: 1.334614,
    zoom: 10,
    pitch: 0,
    bearing: 0,
  },
  initialViewStateDataset: {
    longitude: 103.732704,
    latitude: 1.334614,
    zoom: 10,
    pitch: 30,
    bearing: 0,
  },
};
