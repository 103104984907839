import Alert from 'baseui/icon/alert';
import { useSnackbar } from 'baseui/snackbar';
import { useEffect } from 'react';
import useTranslation from 'utils/react/useTranslation';

const Snackbar = ({ show, errorMessage }) => {
  const { enqueue, dequeue } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      enqueue(
        {
          message: t(errorMessage),
          startEnhancer: ({ size }) => <Alert size={size} />,
        },
        3000
      );
    }
  }, [show]);

  return <></>;
};

export default Snackbar;
