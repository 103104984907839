import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { Select } from 'baseui/select';
import plusCircle from 'assets/plusCircle.svg';
import plusCircleFilled from 'assets/plusCircleFilled.svg';
import { InputWrapper } from './FormStyles';
import FormFieldLabel from './FormLabel';
import Error from './Error';
import useTranslationWrapper from 'utils/react/useTranslation';
import regexValidation from 'utils/forms';

const SelectWrapper = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: row;
`;
const getLabel = ({ option }) => {
  if (option?.value === '@actionItem') {
    return (
      <div
        style={{
          margin: '3px 0 3px 0',
        }}
      >
        <img
          src={plusCircle}
          alt='button icon'
          style={{ marginRight: '8px' }}
        />
        <span
          style={{
            position: 'relative',
            top: '-3px',
          }}
        >
          {option.label}
        </span>
      </div>
    );
  }
  return <span>{option.label}</span>;
};

const SelectComponent = ({
  label,
  name,
  errors,
  info,
  options,
  width,
  placeholder,
  addLabel,
  rules = { required: false },
  control,
  addAction,
  labelKey,
  valueKey,
  multi,
  clearable = false,
  creatable = false,
  setError,
  maxLabelLength = 100,
}) => {
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [inputText, setInputText] = useState('');
  const finalOptions = addLabel
    ? [{ [labelKey]: addLabel, [valueKey]: '@actionItem' }, ...options]
    : options;

  const error = errors?.message;
  const { t } = useTranslationWrapper();
  const popover_id = name + '_popover';

  // This function adds the insertion icons and
  // related styles to the insertion option of the dropdown
  const addInsertionOption = () => {
    const popoverElement = document.getElementById(popover_id);
    const myList = popoverElement?.querySelector('ul');
    const listItems = myList?.getElementsByTagName('li') || [];
    const lastItem = listItems[listItems.length - 1];
    const divElement = lastItem?.querySelector('div');

    const image = document.createElement('img');
    image.style.marginRight = '10px';
    image.src = plusCircleFilled;
    image.alt = 'icon';

    if (
      inputText &&
      divElement &&
      !divElement.getElementsByTagName('img').length
    ) {
      divElement.style.display = 'flex';
      const spanElement = divElement?.querySelector('span');
      spanElement.style.top = '-3px';
      spanElement.style.overflowWrap = 'break-word';
      spanElement.style.width = '97%';

      divElement.insertBefore(image, divElement.firstChild);
    }
    if (!inputText && divElement?.getElementsByTagName('img').length) {
      let imgTag = divElement?.querySelector('img');
      divElement.removeChild(imgTag);
    }
  };

  useEffect(() => {
    if (isValueChanged) {
      addInsertionOption();
      setIsValueChanged(false);
    }
  }, [isValueChanged]);

  return (
    <InputWrapper width={width}>
      <FormFieldLabel label={label} name={name} infoHover={info} />
      <SelectWrapper>
        <Controller
          rules={rules}
          control={control}
          name={name}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select
                id={name}
                clearable={clearable}
                overrides={{
                  Root: {
                    style: {
                      minHeight: '52px',
                      border: 'none',
                    },
                  },
                  Dropdown: {
                    style: ({ $theme }) => ({
                      backgroundColor: `${$theme?.colors.menuBackground}`,
                    }),
                  },
                  DropdownListItem: {
                    style: ({ $theme }) => ({
                      minHeight: '36px',
                    }),
                  },
                  OptionContent: {
                    style: {
                      fontFamily: 'Montserrat',
                      fontWeight: '500',
                    },
                  },
                  IconsContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: `${$theme?.colors.containerBackground}`,
                    }),
                  },
                  ValueContainer: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Montserrat',
                      backgroundColor: `${$theme?.colors.containerBackground}`,
                      lineHeight: '30px',
                      minHeight: '52px',
                    }),
                  },
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      borderLeftStyle: 'solid',
                      borderRightStyle: 'solid',
                      borderTopStyle: 'solid',
                      borderBottomStyle: 'solid',
                      borderLeftWidth: '1px',
                      borderRightWidth: '1px',
                      borderTopWidth: '1px',
                      borderBottomWidth: '1px',
                      borderLeftColor: error
                        ? $theme?.colors?.negative
                        : $theme?.colors?.inputBorder,
                      borderRightColor: error
                        ? $theme?.colors?.negative
                        : $theme?.colors?.inputBorder,
                      borderTopColor: error
                        ? $theme?.colors?.negative
                        : $theme?.colors?.inputBorder,
                      borderBottomColor: error
                        ? $theme?.colors?.negative
                        : $theme?.colors?.inputBorder,
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                    }),
                  },
                  Popover: {
                    props: {
                      id: popover_id,
                    },
                  },
                }}
                value={value}
                inputRef={ref}
                onChange={(data) => {
                  if (data.option?.[valueKey] === '@actionItem') {
                    addAction();
                  } else {
                    if (
                      creatable &&
                      (data?.option?.label.length > maxLabelLength ||
                        regexValidation.hasSpecialCharacters.test(
                          data?.option?.label
                        ))
                    ) {
                    } else {
                      if (data.type === 'select') {
                        if (multi) {
                          const selectedItemLabel = data.option.label
                            .replace(/ /g, '_')
                            .toLowerCase();
                          onChange([
                            ...value,
                            {
                              id: selectedItemLabel,
                              label: selectedItemLabel,
                              value: selectedItemLabel,
                            },
                          ]);
                        } else {
                          onChange([data.option]);
                        }
                      } else if (data.type === 'remove') {
                        onChange([
                          ...value.filter(
                            value => data.option.label !== value.label
                          ),
                        ]);
                      } else if (data.type === 'clear') {
                        onChange([]);
                      }
                    }
                  }
                }}
                onInputChange={(e) => {
                  const inputValue = e.target.value;
                  setInputText(inputValue);
                  setIsValueChanged(true);

                  const popoverElement = document.getElementById(popover_id);

                  if (
                    creatable &&
                    (inputValue.length > maxLabelLength ||
                      regexValidation.hasSpecialCharacters.test(inputValue))
                  ) {
                    popoverElement.style.display = 'none';
                    setError(name, {
                      type: 'custom',
                      message: t(
                        'p.fleet.type.editor.vehicleLabel.length.error',
                        { labelLength: maxLabelLength }
                      ),
                    });
                  } else {
                    if (popoverElement) {
                      popoverElement.style.display = 'block';
                    }
                    setError(name, null);
                  }
                }}
                options={finalOptions}
                labelKey={labelKey}
                valueKey={valueKey}
                autocomplete='off'
                getOptionLabel={getLabel}
                getValueLabel={getLabel}
                multi={multi}
                placeholder={placeholder}
                creatable={creatable}
              />
            );
          }}
        />
      </SelectWrapper>
      {error && <Error>{error}</Error>}
    </InputWrapper>
  );
};

export default SelectComponent;
