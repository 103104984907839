import React from 'utils/react';
import polyline from '@mapbox/polyline';
import { Marker } from '@urbica/react-map-gl';
import debug from 'utils/debug';

import BusMarker from 'components/BusMarker';
import NodeMarker from 'components/NodeMarker';

import cartesian from './cartesian';

const D2 = debug('u:simulations');

const colorArray = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

export const getColorByKey = key => colorArray[key % colorArray.length];

const getBusMarker = color => <BusMarker color={color} />;
const getNodeMarker = (color, passenger) => (
  <NodeMarker color={color} passenger={passenger} />
);

export const getLayer = (id, key) => [
  {
    id: `current_route_${id}`,
    type: 'line',
    source: `current_route_${id}`,
    layout: {
      'line-join': 'round',
    },
    paint: {
      'line-color': getColorByKey(key),
      'line-width': ['interpolate', ['linear'], ['zoom'], 11, 1, 15, 3],
      'line-opacity': 0.7,
    },
  },
  {
    id: `current_route_symbol_${id}`,
    type: 'symbol',
    source: `current_route_${id}`,
    layout: {
      'text-field': (key + 1).toString(),
      'text-size': ['interpolate', ['linear'], ['zoom'], 8, 8, 14, 10, 22, 18],
      'text-offset': [0, 0.2],
      'text-anchor': 'bottom',
      'symbol-placement': 'line',
      'symbol-spacing': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        50,
        14,
        150,
        16,
        250,
      ],
    },
    paint: {
      'text-color': getColorByKey(key),
    },
  },
];

export const getMarkers = (key, vehicle) => {
  if (!vehicle) {
    return [];
  }

  const { assigned_nodes } = vehicle;

  D2.S.INFO('assigned_nodes', assigned_nodes);

  return [
    ...assigned_nodes.map(
      node =>
        node.lon &&
        node.lat && (
          <Marker
            key={`node_marker_${node.id}`}
            longitude={node.lon}
            latitude={node.lat}
          >
            {getNodeMarker(getColorByKey(key), '---')}
          </Marker>
        )
    ),
    vehicle.lon && vehicle.lat && (
      <Marker
        key={`vehicle_marker_${vehicle.id}`}
        longitude={vehicle.lon}
        latitude={vehicle.lat}
      >
        {getBusMarker(getColorByKey(key))}
      </Marker>
    ),
  ];
};

export const getSourceLine = (vehicle) => {
  const c1 = vehicle.current_route
    ? vehicle.current_route.points.map(([lat, lon]) => [lon, lat])
    : [];

  const c2 = vehicle.current_route_polyline
    ? polyline
        .decode(vehicle.current_route_polyline)
        .map(([lat, lon]) => [lon, lat])
    : c1;

  return c2.length
    ? {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: vehicle
            ? [
                {
                  type: 'Feature',
                  properties: {
                    id: vehicle.id,
                  },
                  geometry: {
                    type: 'LineString',
                    coordinates: c2,
                  },
                },
              ]
            : [],
        },
      }
    : null;
};

export const getSourceGeofence = geom => ({
  type: 'FeatureCollection',
  features: geom
    ? [
        {
          type: 'Feature',
          geometry: geom,
        },
      ]
    : [],
});

export const getMultiBody = (options) => {
  const keys = Object.keys(options);

  const multiParams = keys.reduce((acc, key) => {
    const param = options[key];

    if (typeof param === 'string') {
      const params = param.split(',').filter(value => !!value);

      if (params.length) {
        acc[key] = params;
      }
    }

    return acc;
  }, {});

  if (Object.keys(multiParams).length === 0) {
    return [options];
  }

  const arrayKeyValue = Object.keys(multiParams).map(key =>
    multiParams[key].reduce((acc1, value) => {
      acc1.push({
        [key]: value,
      });

      return acc1;
    }, [])
  );

  return cartesian(...arrayKeyValue).map(item => ({
    ...options,
    ...Object.assign(...item),
  }));
};

export const getTimeInMinutes = (seconds) => {
  const value = seconds && parseInt(seconds, 10);
  return value && Math.round(value / 60);
};
