import moment from 'moment-timezone';
import md5 from 'blueimp-md5';
import fetchCollection from './fetchCollection';
import indexCollection from './indexCollection';

import debug from 'utils/debug';
const D2 = debug('utils:sgerp-api');

const cache = { data: {}, serialNumbers: {} };

const getPersistentCollection = () => {};
const setPersistentCollection = () => {};
const removePersistentCollection = () => {};

// const getPersistentKey = (name, requestHash) => [name, 'v1', requestHash].join(':');

// const getPersistentCollection = (name, requestHash) => {
//   try {
//     const persistentKey = getPersistentKey(name, requestHash);

//     const collection = JSON.parse(global.localStorage.getItem(persistentKey));
//     cache.data[requestHash] = collection;

//     return collection;
//   } catch (error) {
//     // eslint-disable-next-line
//     console.log(error);
//   }
// };

// const setPersistentCollection = (name, requestHash, collection) => {
//   try {
//     const persistentKey = getPersistentKey(name, requestHash);

//     global.localStorage.setItem(persistentKey, JSON.stringify(collection));
//   } catch (error) {
//     // eslint-disable-next-line
//     console.log(error);
//   }

//   return collection;
// };

// const removePersistentCollection = (name, requestHash) => {
//   try {
//     const persistentKey = getPersistentKey(name, requestHash);

//     global.localStorage.removeItem(persistentKey);
//   } catch (error) {
//     // eslint-disable-next-line
//     console.log(error);
//   }
// };

const loadCollection = async (api, collection, filter = {}, opts = {}) =>
  D2.A.FUNCTION(
    'loadCollection',
    { api, collection, filter, opts },
    async ({ $D2 }) => {
      const { address, prefix } = api;

      const { forceReload = false } = opts;

      const requestIdentity = { address, prefix, collection, filter };
      const requestHash = md5(JSON.stringify(requestIdentity));

      const memoryCachedResponse = cache.data[requestHash];

      const cachedResponse = !forceReload
        ? memoryCachedResponse ??
          getPersistentCollection(collection, requestHash)
        : undefined;

      const cahcedSerialNumber = cache.serialNumbers[requestHash] ?? 1;
      const serialNumber = forceReload
        ? cahcedSerialNumber + 1
        : cahcedSerialNumber;
      cache.serialNumbers[requestHash] = serialNumber;

      const isLoadedFromCache = !!cachedResponse?.version_ts;

      const originalCollection = isLoadedFromCache
        ? cachedResponse
        : await indexCollection(
            await fetchCollection(
              api,
              collection,
              {
                ...filter,
                // modified_at__gt: moment
                //   .unix(0)
                //   .tz('UTC')
                //   .format(),
                order_by: 'id',
              },
              opts
            ),
            opts
          );

      if (originalCollection.error) {
        return originalCollection;
      }

      const updatedCollection = originalCollection.version_ts
        ? await indexCollection(
            await fetchCollection(
              api,
              collection,
              {
                ...filter,
                modified_at__gt: originalCollection.version_ts,
                order_by: 'id',
              },
              opts
            ),
            opts
          )
        : { objects: {} };

      if (updatedCollection.error) {
        const { error } = updatedCollection;
        return { error };
      }

      const updatedObjects = Object.values(updatedCollection.objects);

      const isUpdated = updatedObjects.length && !!updatedCollection.version_ts;

      if (isUpdated) {
        updatedObjects.map((item) => {
          // eslint-disable-next-line
          console.log(`*** Updated ${collection}:`, item);
        });
      }

      const latestCollection = !isUpdated
        ? originalCollection
        : {
            objects: {
              ...originalCollection.objects,
              ...updatedCollection.objects,
            },
            index_by_id: {
              ...originalCollection.index_by_id,
              ...updatedCollection.index_by_id,
            },
            index_by_resource_uri: {
              ...originalCollection.index_by_resource_uri,
              ...updatedCollection.index_by_resource_uri,
            },
            version_ts: updatedCollection.version_ts,
          };

      const updateLatestCollection = (value) => {
        if (value.version_ts) {
          cache.data[requestHash] = value;
          setPersistentCollection(collection, requestHash, value);
          return value;
        }
        delete cache.data[requestHash];
        removePersistentCollection(collection, requestHash);
        return value;
      };

      if (isUpdated || !isLoadedFromCache) {
        updateLatestCollection(latestCollection);
      }

      const resultCollection = {
        original: originalCollection,
        updates: updatedCollection,
        updated: updatedCollection,
        latest: latestCollection,
        version: latestCollection.version_ts,
        serialNumber,
        requestHash,
        updateLatestCollection,
      };

      return resultCollection;
    }
  );

export default loadCollection;
