// eslint-disable-next-line import/prefer-default-export
export const LOGISTICS_SERVICES_ROUTE = '/logistics-services';
export const LOGISTICS_ROUTE = '/logistics';
export const ACCESS_DENIED_URL = '/access-denied';

export const NODE_STATUSES = {
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  FAILED_TO_DELIVER: 'failed_to_deliver',
  FAILED_TO_BOARD: 'fail_to_board',
};

export const GEODISC_CURRENT_PROJECT_KEY = `${global.GEODISC_API_URL}:user:currentProjectName`;
export const GEODISC_PREV_PROJECT_KEY = `${global.GEODISC_API_URL}:user:prevProjectName`;
export const GEODISC_PROJECTS_LIST_KEY = `${global.GEODISC_API_URL}:user:projects`;

export const MAX_VEHICLE_OFFLINE_DURATION = 30 * 60 * 1000; // 30 minutes
export const TEMPLATE_ROUTE = '/template';

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
