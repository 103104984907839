import moment from 'moment-timezone';
import memoizeOne from 'memoize-one';
import debug from 'utils/debug';
import { geoToH3 } from 'h3-js';
import { diffString } from 'json-diff';
import { removeInternalFieldsFromObject } from 'utils';
import { MAX_VEHICLE_OFFLINE_DURATION } from 'utils/constants';

const D2 = debug('u:CommuteOffer');

const completedNodeStates = new Set([
  'completed',
  'failed_to_board',
  'failed_to_deliver',
]);

export const calculateVehicleRoute = (
  $vehicle,
  path,
  nodes,
  vehicleId,
  capacityFunctor
) =>
  D2.S.FUNCTION(
    'calculateVehicleRoute',
    { path, nodes, vehicleId },
    ({ $D2 }) => {
      const { $isReadOnly: $isVehicleReadOnly } = $vehicle;

      const pathWithNodeGroups = path.map(node => ({
        ...node,
        $nodes_group_id: geoToH3(node.lat, node.lon, 13),
      }));

      const newRoute = pathWithNodeGroups.reduce((acc, node) => {
        const {
          booking_uid,
          uid,
          stop_id,
          // node_type,
          $nodes_group_id,
          ...routeItem
        } = node;

        if (!uid) {
          return acc;
        }

        const nodeByUID = nodes[uid];

        if (!nodeByUID && node.node_type !== 'point') {
          D2.S.MESSAGE('calculateVehicleRoute', {
            message: `Error: The result vehicle contains a ${node.node_type} node that cannot be found the request nodes list`, // eslint-disable-line
            vehicle: vehicleId,
            node,
          });
        }

        const pax = nodeByUID?.demand
          ? Math.abs(capacityFunctor.functor(nodeByUID.demand))
          : 0;
        const pax_type = capacityFunctor.type;

        const $demand_info = Object.entries(nodeByUID?.demand ?? {}).reduce(
          (memo, [demandKey, demandValue]) => ({
            ...memo,
            [demandKey]: Math.abs(demandValue),
          }),
          {}
        );

        const newStop = {
          ...routeItem,
          id: `${vehicleId}-${node.uid}`,
          position: 0,
          stop_id,
          $isVehicleReadOnly,
          $nodes_group_id,
          uid,
          pax,
          pax_type,
          bookings: [
            {
              id: booking_uid || uid,
              pax,
              pax_type,
              $demand_info,
              node,
              $node: nodeByUID,
            },
          ],
          $bookingsById: {
            [booking_uid || uid]: {
              pax,
              $demand_info,
              node,
              $node: nodeByUID,
            },
          },
        };

        // if no stops, add first
        if (!acc.length) {
          return [newStop];
        }

        const lastIndex = acc.length - 1;
        const lastStop = acc[lastIndex];

        const isDifferentStop =
          lastStop.$nodes_group_id !== $nodes_group_id ||
          lastStop.node_type !== node.node_type;
        $D2.S.INFO('isDifferentStop', {
          isDifferentStop,
          lastStop,
          node,
          $node: nodeByUID,
          nodeByUID,
        });

        if (
          acc[lastIndex].$nodes_group_id === $nodes_group_id &&
          acc[lastIndex].node_type === 'stop' &&
          node.node_type !== 'point' &&
          node.node_type !== 'stop'
        ) {
          acc[lastIndex] = { ...newStop, id: acc[lastIndex].id };
          return acc;
        }

        if (
          acc[lastIndex].$nodes_group_id === $nodes_group_id &&
          node.node_type === 'stop' &&
          acc[lastIndex].node_type !== 'point' &&
          acc[lastIndex].node_type !== 'stop'
        ) {
          return acc;
        }

        // if newStop
        if (isDifferentStop || node.node_type === 'point') {
          return [
            ...acc,
            {
              ...newStop,
              id: `${vehicleId}-${acc[lastIndex].position + 1}`,
              position: acc[lastIndex].position + 1,
            },
          ];
        }

        if (lastStop.$bookingsById[booking_uid]) {
          return acc;
        }

        // if same stop
        return [
          ...acc.slice(0, -1),
          {
            ...lastStop,
            service_time: lastStop.service_time + node.service_time,
            slack: lastStop.slack + node.slack,
            pax: lastStop.pax + pax,
            pax_type,
            bookings: [
              ...lastStop.bookings,
              {
                id: booking_uid,
                pax,
                pax_type,
                $demand_info,
                node,
                $node: nodeByUID,
              },
            ],
            $bookingsById: {
              ...lastStop.$bookingsById,
              [booking_uid]: {
                pax,
                pax_type,
                $demand_info,
                node,
                $node: nodeByUID,
              },
            },
            $isVehicleReadOnly,
          },
        ];
      }, []);

      const route = newRoute.map((waypoint) => {
        const $demand_info = waypoint.bookings.reduce(
          (waypointMemo, booking) => {
            return Object.entries(booking.$demand_info).reduce(
              (memo, [demandKey, demandValue]) => {
                return {
                  ...memo,
                  [demandKey]: (memo[demandKey] ?? 0) + demandValue,
                };
              },
              { ...waypointMemo }
            );
          },
          {}
        );
        const isStartEndNode =
          waypoint.partial_route_index === -1 ||
          waypoint.partial_route_index === 1;
        const completedNode = waypoint.bookings.find(
          ({ $node }) => $node && completedNodeStates.has($node.status ?? 'new')
        );
        const $isWaypointReadOnly = !!completedNode || isStartEndNode;
        return {
          ...waypoint,
          $isWaypointReadOnly,
          $demand_info,
        };
      });

      return route;
    }
  );

export const getBookingType = (isActiveVehicle, isActive, isAssigned) => {
  if (isAssigned) {
    if (isActive) {
      return 'active';
    }

    if (isActiveVehicle) {
      return 'activeVehicle';
    }

    return 'assigned';
  }

  if (isActive) {
    return 'unassignedActive';
  }

  return 'unassigned';
};

export const getWalkingRouteType = (
  isActive,
  isActiveStop,
  isActiveBooking
) => {
  if (isActive) {
    if (isActiveBooking) {
      return 'activeBooking';
    }

    if (isActiveStop) {
      return 'activeStop';
    }

    return 'active';
  }

  return 'inactive';
};

export const getCurbMode = curb => (curb ? 'curb' : 'unrestricted');

export const getRouteCoordinates = (route, approaches, curb) => {
  D2.S.INFO('getRouteCoordinates', { route, approaches, curb });

  if (approaches) {
    return `${route
      .map(stop => `${stop.lon},${stop.lat}`)
      .join(';')}?approaches=${route
      .map((stop) => {
        return typeof stop.curb !== 'undefined'
          ? getCurbMode(stop.curb)
          : getCurbMode(curb);
      })
      .join('%3B')}`;
  }

  return `${route.map(stop => `${stop.lon},${stop.lat}`).join(';')}?`;
};

export const humanize = item => ({
  ...item,
  scheduled_pickup_time: item?.scheduled_pickup_time
    ? moment(item.scheduled_pickup_time)
        .tz(global.GEODISC_TIMEZONE)
        .format('HH:mm')
    : '--:--',
  scheduled_dropoff_time: item?.scheduled_dropoff_time
    ? moment(item.scheduled_dropoff_time)
        .tz(global.GEODISC_TIMEZONE)
        .format('HH:mm')
    : '--:--',
  scheduled_pickup_stop_name: item?.pickup_location_name
    ? item.pickup_location_name
    : '---',
  scheduled_dropoff_stop_name: item?.dropoff_location_name
    ? item.dropoff_location_name
    : '---',
});

export const memoHumanize = memoizeOne(humanize);

export const filterCommuteOfferByVehicle = (commuteOffer, id) => {
  D2.S.INFO('filterCommuteOfferByVehicle:Enter', { commuteOffer });

  const { result, stateless_api_request_data } = commuteOffer;

  const readOnly = stateless_api_request_data.readOnly || false;

  const { vehicles, assigned_bookings } = result;

  const filteredAssignedBookings = assigned_bookings.filter(
    booking => booking.assigned_vehicle_id === id
  );
  D2.S.INFO('filterCommuteOfferByVehicle:filteredAssignedBookings', {
    filteredAssignedBookings,
  });

  const filteredVehicles = stateless_api_request_data.vehicles.filter(
    vehicle => vehicle.agent_id === id
  );
  D2.S.INFO('filterCommuteOfferByVehicle:filteredVehicles', {
    filteredVehicles,
  });

  const vehicleRoute = result.routes && result.routes[id];
  D2.S.INFO('filterCommuteOfferByVehicle:vehicleRoute', { vehicleRoute });

  const filteredRoutes = vehicleRoute ? { [id]: vehicleRoute } : {};
  D2.S.INFO('filterCommuteOfferByVehicle:filteredRoutes', { filteredRoutes });

  const newRoutes = {};

  const res = {
    ...commuteOffer,
    stateless_api_request_data: {
      vehicles: filteredVehicles,
    },
    result: {
      assigned_bookings: filteredAssignedBookings,
      vehicles: {
        [id]: vehicles[id],
      },
      routes: newRoutes,
      readOnly,
    },
  };

  D2.S.INFO('filterCommuteOfferByVehicle:Result', {
    commuteOffer,
    result: res,
  });
  return res;
};

export const diffCommuteOffers = (type, o, n, opts) => {
  D2.S.INFO('diffCommuteOffers:Enter', { type, o, n, opts });
  const normalize = (commuteOffer) => {
    D2.S.INFO('diffCommuteOffers:normalize:Enter', { commuteOffer });

    const { result, ...commuteOfferProps } = commuteOffer;
    // eslint-disable-next-line
    const { changelog, routes, ...resultProps } = result;
    const res = {
      ...commuteOfferProps,
      stateless_api_request_data: {
        ...commuteOfferProps.stateless_api_request_data,
        inbound: {
          schedule: [],
          offers: {},
        },
      },
      result: {
        ...resultProps,
        changelog: undefined,
      },
      current_time: undefined,
    };

    D2.S.INFO('diffCommuteOffers:normalize:Result', {
      commuteOffer,
      result: res,
    });
    return res;
  };

  return diffString(
    normalize(removeInternalFieldsFromObject(o)),
    normalize(removeInternalFieldsFromObject(n)),
    opts
  );
};

export const dumpCommuteOfferChanges = (
  origin,
  originalOffer,
  currentOffer
) => {
  if (!originalOffer || !currentOffer) {
    return;
  }
  // eslint-disable-next-line
  console.log(`--- dumpCommuteOfferChanges`, {
    origin,
    originalOffer,
    currentOffer,
  });
  // eslint-disable-next-line
  console.log(diffCommuteOffers('---', originalOffer, currentOffer));
};

export const getVehicleOnlineStatus = (vehicle) => {
  const positionAgeMs = vehicle.current_sim_ts
    ? moment().diff(moment(vehicle.current_sim_ts))
    : undefined;

  const isIdle = !positionAgeMs || positionAgeMs > MAX_VEHICLE_OFFLINE_DURATION;
  const isOnline = !isIdle && vehicle.lon && vehicle.lat;
  return { isIdle, isOnline };
};
